import React from 'react';
import classNames from 'classnames';

import highRisk from '../../../assets/images/highrisk.svg';
import smallRatio from '../../../assets/images/smallRatio.svg';
import { ERiskLevel } from '../../../types/VaultTypes';

type RiskLevelProps = {
  level: ERiskLevel;
  isSinglePool?: boolean;
};

const RiskLevel = ({ level, isSinglePool = true }: RiskLevelProps) => {
  return (
    <div className={classNames('flex items-center')}>
      <div className="flex items-center gap-1 px-3 py-2.5 dark:text-white-900 border-t border-b border-l rounded-tl-lg rounded-bl-lg border-white-500 font-poppins text-sm font-normal text-gray-200 dark:border-gray-600">
        <img src={smallRatio} alt="smallRatio" className="w-3" />
        Risk Rating
      </div>
      <div
        className={classNames(
          'flex items-center border-t border-r border-b border-l py-2.5 px-2.5 rounded-tr-lg rounded-br-lg font-poppins text-sm font-semibold',
          `${level}`
        )}
      >
        {(level === ERiskLevel.EXTREME || level === ERiskLevel.HIGH) && (
          <img src={highRisk} alt="highriskIcon" className="highrisk" />
        )}
        {isSinglePool && <p>{level}</p>}
      </div>
    </div>
  );
};

export default RiskLevel;
