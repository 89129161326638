import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { useVaultsContextProvider } from '../../../contexts/vaults';
import { isWalletApproveError } from '../../../utils/utils';
import Button from '../../Button';
import CustomInput from '../../CustomInput';
import AmountSlider from '../AmountSlider';
import LiquidationSlider from '../LiquidationSlider';
// import LiquidationEstimatedToken from '../LiquidationEstimatedToken';
import { useGetPoolManager } from '../../../hooks/useGetPoolManager';
import { LPair } from '../../../types/VaultTypes';
import { TokenAmount } from '../../../utils/safe-math';
import { calculateLiquidationPrice, DEPOSIT_ACTION } from '../../../utils/ratio-lending';
import { useAppendUserAction, usePoolInfo, useSubscribeTx, useUserVaultInfo } from '../../../contexts/state';
import { USDR_MINT_DECIMALS } from '../../../constants';
import { InProgressPage } from '../../../utils/utils';

const DepositModal = ({ data }: any) => {
  const theme = useContext(ThemeContext);
  const { darkMode } = theme.state;
  const [show, setShow] = useState(false);

  const { connection } = useConnection();
  const wallet = useWallet();
  const poolInfo = usePoolInfo(data?.mint);

  const { vaults } = useVaultsContextProvider();
  const vault = useMemo(() => vaults.find((vault) => vault.address_id === (data.mint as string)), [vaults]);
  const PoolManagerFactory = useGetPoolManager(vault);

  const [depositAmount, setDepositAmount] = useState<any>();

  const [didMount, setDidMount] = useState(false);

  const [depositStatus, setDepositStatus] = useState(false);
  const [invalidStr, setInvalidStr] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isDepositing, setIsDepositing] = useState(false);
  const [amountValue, setAmountValue] = useState(0);

  const appendUserAction = useAppendUserAction();
  const subscribeTx = useSubscribeTx();

  const depositAmountUSD = new TokenAmount(depositAmount * data.tokenPrice, USDR_MINT_DECIMALS).fixed();

  useEffect(() => {
    setDidMount(true);
    setDepositAmount('');
    return () => setDidMount(false);
  }, []);

  const userVaultInfo = useUserVaultInfo(data?.mint);
  const [estimatedHealth, setEstimatedHealth] = useState(0);

  // useEffect(() => {
  //   if (userVaultInfo) {
  //     const newHealth = calculateLiquidationPrice(
  //       userVaultInfo.poolInfo,
  //       userVaultInfo.lockedUiAmount + depositAmount,
  //       userVaultInfo.uiDebt
  //     );
  //     setEstimatedHealth(newHealth);
  //   }
  // }, [depositAmount, userVaultInfo]);

  if (!didMount) {
    return null;
  }

  const deposit = async () => {
    try {
      console.log('Depositing', depositAmount);
      if (!(depositAmount && data?.value >= depositAmount)) {
        setDepositStatus(true);
        setInvalidStr('Insufficient funds to deposit!');
        return;
      }
      setIsDepositing(true);
      const txHash = await PoolManagerFactory?.depositLP(
        connection,
        wallet,
        vault as LPair,
        depositAmount * Math.pow(10, poolInfo?.mintDecimals ?? 0)
      );
      subscribeTx(
        txHash,
        () => toast.info('Deposit Transaction Sent'),
        () => toast.success('Deposit Confirmed.'),
        () => toast.error('Deposit Transaction Failed')
      );
      appendUserAction(
        wallet.publicKey.toString(),
        data.mint,
        data.mint,
        DEPOSIT_ACTION,
        -depositAmount,
        txHash,
        poolInfo.fairPrice,
        poolInfo.virtualPrice,
        0
      );

      setDepositAmount(0);
    } catch (err) {
      console.error(err);
      if (isWalletApproveError(err)) toast.warn('Wallet is not approved!');
      else toast.error('Transaction Error!');
    }
    setIsDepositing(false);
    setShow(false);
  };

  return (
    <div className="">
      <Button
        className="px-8 py-2.5 font-poppins font-medium button--blue  min-w-[120px]"
        onClick={() => setShow(!show)}
      >
        Deposit
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setButtonDisabled(true);
          setShow(false);
        }}
        onEntered={() => {
          setAmountValue(0);
          setDepositAmount('');
          setDepositStatus(false);
          setButtonDisabled(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-theme={darkMode ? 'dark' : 'light'}
        className={darkMode ? 'dark' : 'light'}
      >
        <Modal.Header className="border-b rounded-tl-xl rounded-tr-xl bg-white-900 dark:bg-slate-700 border-white-500 dark:border-gray-600">
          <div className="p-[20px]">
            <IoMdClose
              size={32}
              className="absolute cursor-pointer top-12 right-10 hover:opacity-75 dark:text-white-900"
              onClick={() => {
                setButtonDisabled(true);
                setShow(false);
              }}
            />
            <div>{data.icon && <img src={data.icon} className="w-24" alt={data.title} />}</div>
            <p className="mt-3 text-2xl font-semibold text-gray-200 font-poppins dark:text-white-900">
              Deposit assets into vault
            </p>
            <p className="mt-1 text-base text-gray-100 font-poppins dark:text-white-900">
              {/* Deposit more <strong>{data.title}</strong> into your vault */}
              Deposit up to {data.value} of {data.title} into your vault
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="dark:bg-slate-800 bg-slate-100 rounded-bl-xl rounded-br-xl">
          <div className="p-[20px]">
            <p className="mb-2 text-sm text-gray-200 font-poppins dark:text-white-900">
              How much would you like to deposit?
            </p>
            <CustomInput
              appendStr="Max"
              initValue={0}
              appendValueStr={data.value}
              tokenStr={`${data.title}`}
              onTextChange={(value: any) => {
                setAmountValue((value / data.value) * 100);
                setDepositAmount(value);
                setDepositStatus(false);
                setButtonDisabled(false);
              }}
              maxValue={data.value}
              valid={depositStatus}
              invalidStr={invalidStr}
              value={depositAmount}
            />
            <p className="mt-2 text-sm text-gray-200 font-poppins dark:text-white-900">
              USD: <strong className="font-semibold font-poppins">${depositAmountUSD}</strong>
            </p>
            <AmountSlider
              onChangeValue={(value) => {
                setDepositAmount(Number(data.value * (value / 100)).toFixed(2));
                setAmountValue(value);
                setDepositStatus(false);
                setButtonDisabled(false);
              }}
              value={amountValue}
            />
            {/* {InProgressPage() && <LiquidationSlider currentValue={1} estimatedValue={depositAmount * 20} />} */}
            {/* <LiquidationEstimatedToken /> */}
            <Button
              disabled={depositAmount <= 0 || buttonDisabled || isNaN(depositAmount) || isDepositing}
              className="w-full py-3 mt-6 font-medium button--blue font-poppins"
              onClick={() => deposit()}
            >
              Deposit Assets
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DepositModal;
