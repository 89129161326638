import React, { useState } from 'react';
import classNames from 'classnames';

import { Tab } from '@headlessui/react';
import InstaBuyLpCard from '../../components/InstaBuyLpCard';
import InstaSellLpCard from '../../components/InstaSellLpCard';

const categories = ['Buy', 'Sell'];

const InstaBuyLp = () => {
  return (
    <div className="flex flex-col items-center mt-3 mb-5">
      <p className="mb-3 text-2xl font-medium text-gray-200 dark:text-slate-50 font-poppins">Insta-swap</p>
      <Tab.Group>
        <Tab.List className="flex p-1 space-x-1">
          {categories.map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  'w-32 font-poppins font-medium text-lg',
                  selected
                    ? 'text-gray-200 outline-none dark:text-white-900'
                    : 'text-gray-200/60 dark:text-white-900/30'
                )
              }
            >
              {({ selected }) => {
                return (
                  <div>
                    {category}{' '}
                    <div
                      className={classNames(
                        'h-[3px] mt-2',
                        selected ? ' bg-gradient-to-r from-pink-200 to-pink-100' : ' bg-transparent'
                      )}
                    ></div>
                  </div>
                );
              }}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel className={classNames('rounded-xlp-3')}>
            <InstaBuyLpCard />
          </Tab.Panel>
          <Tab.Panel className={classNames('rounded-xlp-3')}>
            <InstaSellLpCard />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default InstaBuyLp;
