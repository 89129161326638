import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST_ADD_TRANSACTION, RECEIVE_ADD_TRANSACTION } from './actionTypes';
import { apiCall } from '../../utils/apiCall';

const addSwapTransactionApi = (data: any, token: string) =>
  apiCall(`/transaction/instaswap/${data.wallet_id}/new`, data, 'POST', token);

function* addNewSwapTransactions(action: any) {
  try {
    const { data } = yield call(addSwapTransactionApi, action.payload, null);
    yield put({ type: RECEIVE_ADD_TRANSACTION, payload: data });
  } catch (e) {
    console.log(e.message);
  }
}

export default function* adminpanelSaga() {
  yield takeLatest(REQUEST_ADD_TRANSACTION, addNewSwapTransactions);
}
