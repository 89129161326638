/* eslint-disable */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TokenSelection, LpTokenSelection } from '../InstaBuyLpCard/TokenSelection';
import PlatformSelection from '../InstaBuyLpCard/PlatformSelection';
import TokenAmounts from '../InstaBuyLpCard/TokenAmounts';
import LoadingSpinner from '../../atoms/LoadingSpinner';

import { Platform } from '../../types/VaultTypes';
import { TokenAmount } from '../../utils/safe-math';
import { useUserAccounts } from '../../hooks/useUserAccounts';
import { platforms } from '../../utils/helper';
import { useWallet } from '@solana/wallet-adapter-react';

import { actionTypes } from '../../features/instaswap';
import { WRAPPED_SOL_MINT, USDC_MINT, REAL_USDR_MINT, RAYDIUM_USDT_USDC_LP_MINT } from '../../constants';
import { useInstaSwap, useInstaSwapProps, LpTokenInfo } from '@ratio-finance/instaswap-hook';
import { TokenInfo } from '@solana/spl-token-registry';
import { NATIVE_MINT } from '@solana/spl-token';

const InstaSellLpCard = () => {
  const [inputValue, setInputValue] = useState(0);
  const [formValue, setFormValue] = useState<useInstaSwapProps>({
    amount: 0,
    token: USDC_MINT,
    lpToken: RAYDIUM_USDT_USDC_LP_MINT,
    slippage: 1, // 0.1%,
    direction: false, // InstaSell
  });

  const { avaLpTokens, avaTokens, estimatedOutAmount, avaLpMints, avaTokenMints, loading, execute } =
    useInstaSwap(formValue);

  const dispatch = useDispatch();

  const [singleTokenInfo, selectedLpTokenInfo] = useMemo(() => {
    return [avaTokens.get(formValue.token || ''), avaLpTokens.get(formValue.lpToken || '')];
  }, [formValue.token, formValue.lpToken, avaTokens, avaLpTokens]);

  const [executeEnabled, setExecuteEnabled] = useState(false);
  const [executeLoading, setExecuteLoading] = useState(false);
  const [availablePlatforms, setAvailablePlatforms] = useState<any[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>(null);

  const wallet = useWallet();

  // use accounts
  const { userAccounts, toggleRefresh } = useUserAccounts();

  const location = useLocation();

  const routerStateValue: any = useMemo(() => {
    return location.state;
  }, [location]);

  const availableTokens = useMemo(() => {
    const priorityTokenMints = [WRAPPED_SOL_MINT, USDC_MINT, REAL_USDR_MINT];
    const tokenInfos = [];
    userAccounts.forEach((account) => {
      if (account.info.amount.toString() !== '0') {
        const tokenInfo = avaTokens.get(account.info.mint.toBase58());
        // To avoid displaying duplicated SOL tokens in tokenlist. Will only show Native Sol Amount.
        if (account.info.mint.equals(NATIVE_MINT) && account.account.data.length > 0) return;
        if (tokenInfo) {
          tokenInfos.push({
            ...tokenInfo,
            balance: new TokenAmount(account.info.amount.toString(), tokenInfo.decimals),
          });
        }
      }
    });

    return tokenInfos.sort((a, b) => {
      const idxA = priorityTokenMints.findIndex((mint) => mint === a?.address);
      const idxB = priorityTokenMints.findIndex((mint) => mint === b?.address);
      if (idxA >= 0 && idxA < idxB) return -1;
      if (idxA >= 0 && idxB < 0) return -1;
      return 1;
    });
  }, [userAccounts, avaTokens]);

  const [singleTokenBalance, lpTokenBalance] = useMemo(() => {
    let balances: TokenAmount[] = [];
    [singleTokenInfo, selectedLpTokenInfo].forEach((tokenInfo) => {
      if (tokenInfo) {
        const tokenAta = userAccounts.find((account) => account.info.mint.toBase58() === tokenInfo.address);
        if (tokenAta) {
          balances.push(new TokenAmount(tokenAta.info.amount.toString(), tokenInfo.decimals));
          return;
        }
      }
      balances.push(new TokenAmount(0, 1));
    });
    return balances;
  }, [singleTokenInfo, selectedLpTokenInfo, userAccounts]);

  const platformLpTokens = useMemo(() => {
    let platformLpInfoList = [];
    avaLpMints.forEach((lpMint) => {
      let lpInfo = avaLpTokens.get(lpMint);
      if (lpInfo.platform === selectedPlatform?.name) {
        platformLpInfoList.push(lpInfo);
      }
    });
    return platformLpInfoList;
  }, [avaLpMints, selectedPlatform]);

  useEffect(() => {
    if (platformLpTokens && platformLpTokens[0]) {
      setFormValue((formValue) => ({
        ...formValue,
        lpToken: platformLpTokens[0].address,
      }));
    }
  }, [platformLpTokens]);

  // first-loading
  useEffect(() => {
    let _availablePlatforms = platforms.filter((platform) => platform.name !== 'SWIM');
    setAvailablePlatforms(_availablePlatforms);
    if (routerStateValue?.platform && routerStateValue?.platform !== '') {
      let p = _availablePlatforms.find((item) => item.name === routerStateValue.platform);
      setSelectedPlatform(p);
    } else {
      setSelectedPlatform(_availablePlatforms[0]);
    }
  }, [routerStateValue]);

  const changeSingleToken = useCallback((token: TokenInfo) => {
    setFormValue((formValue) => ({
      ...formValue,
      amount: 0,
      token: token.address,
    }));
    setInputValue(0);
  }, []);

  const changeLpToken = useCallback((lp: LpTokenInfo) => {
    setFormValue((formValue) => ({
      ...formValue,
      lpToken: lp.address,
    }));
  }, []);

  const onChangeInputValue = (value) => {
    console.log('loading =', loading);
    let parsedValue = value.replace(',', '.').replace(/[^0-9.]/g, '');
    let amount = Number.parseFloat(parsedValue);
    if (isNaN(amount)) amount = 0.0;
    if (amount === 0) {
      setExecuteEnabled(false);
    } else {
      setExecuteEnabled(true);
    }
    if (amount <= Number.parseFloat(lpTokenBalance.fixed() ?? '0')) {
      setInputValue(parsedValue);
      setFormValue((formValue) => ({
        ...formValue,
        amount,
      }));
    }
  };

  const onChangeSlippageValue = (value) => {
    const amount = value.replace(',', '.').replace(/[^0-9.]/g, '');
    if (amount > 0 && amount < 50) {
      setFormValue((formValue) => ({
        ...formValue,
        slippage: amount,
      }));
    }
  };

  const executeInstaswap = () => {
    if (wallet.sendTransaction && wallet.signAllTransactions && wallet.signTransaction) {
      setExecuteLoading(true);
      execute({
        wallet: {
          sendTransaction: wallet.sendTransaction,
          publicKey: wallet.publicKey,
          signAllTransactions: wallet.signAllTransactions as any,
          signTransaction: wallet.signTransaction as any,
        },
        beforeSend: (totalTxCnt, txIdx) => {
          toast.info(`Sending ${txIdx + 1} of ${totalTxCnt} Transactions`, { delay: 300 });
        },
        afterSend: (totalTxCnt, txIdx, txHash: string) => {},
        onSuccess: (txResults: string[]) => {
          const values: any = {};
          values.signature = txResults.toString();
          values.swapped_from = formValue.lpToken;
          values.swapped_to = formValue.token;
          values.amount = formValue.amount;
          values.wallet_id = wallet.publicKey.toString();
          dispatch(actionTypes.addNewSwapTransaction(values));
          toast.success('Swap Finished');
          toggleRefresh();
        },
        onFail: (txResults: string[]) => {},
        confirmType: 2,
      }).then(async (res) => {
        if (res.err) {
          toast.error('Swap Failed');
          console.error(res.err);
        }
        setExecuteLoading(false);
      });
    }
  };

  return (
    <div className="border-t border-b border-l border-r rounded-xl border-white-400 dark:border-gray-200">
      <div className="p-6">
        <p className="mb-2 text-sm text-gray-200 dark:text-white-900 font-regular font-poppins">
          Please select a platform
        </p>
        <PlatformSelection
          options={availablePlatforms}
          onSelectToken={setSelectedPlatform}
          selected={selectedPlatform}
        />
        <p className="mt-4 mb-2 text-sm text-gray-200 dark:text-white-900 font-regular font-poppins">
          Please select an LP token
        </p>
        <LpTokenSelection
          options={platformLpTokens}
          onSelectToken={changeLpToken}
          selected={selectedLpTokenInfo}
          iconClassName="w-14"
        />
        <div className="flex items-center justify-between mt-1">
          <div className="flex items-center gap-1">
            <p className="text-sm text-gray-200 font-poppins font-regular dark:text-white-900">Platform:</p>
            <p className="text-sm font-medium text-gray-200 font-poppins font-regular dark:text-white-900">
              {selectedLpTokenInfo?.platform}
            </p>
          </div>
          <div className="flex justify-end gap-1">
            <p className="text-sm text-gray-200 font-poppins font-regular dark:text-white-900">
              <span className="font-medium">{selectedLpTokenInfo?.name}</span> Balance:{' '}
            </p>
            <p className="text-sm font-semibold text-gray-200 font-poppins dark:text-white-900">
              {lpTokenBalance.fixed() ?? 0}
            </p>
          </div>
        </div>
        <p className="mt-4 mb-2 text-sm text-gray-200 font-regular font-poppins dark:text-white-900">
          How much <strong>{selectedLpTokenInfo?.name}</strong> would you like to swap?
        </p>
        <TokenAmounts
          changeAmounts={onChangeInputValue}
          tokenName={selectedLpTokenInfo?.name}
          amounts={inputValue}
          hasMax
          onClickMax={() => onChangeInputValue(lpTokenBalance.fixed())}
        />
      </div>
      <div className="relative p-6 border-t border-b border-white-400 dark:border-gray-200 bg-slate-100 dark:bg-slate-800">
        {/* <button className="absolute px-1 py-px transform -translate-x-1/2 -translate-y-1/2 rounded-md shadow-sm -top-1 left-1/2 bg-white-900">
          <ChevronDownIcon className="text-violet-300 w-7 h-7" />{' '}
        </button> */}

        <p className="mb-2 text-sm text-gray-200 dark:text-white-900 font-regular font-poppins">
          Please select a token
        </p>
        <TokenSelection
          options={availableTokens}
          onSelectToken={changeSingleToken}
          selected={singleTokenInfo}
          iconClassName="w-8 rounded-full"
        />
        <p className="mt-6 mb-2 text-sm text-gray-200 dark:text-white-900 font-regular font-poppins">
          Estimated <strong className="text-sm font-semibold">{singleTokenInfo?.symbol}</strong> value
        </p>
        <TokenAmounts
          amounts={estimatedOutAmount}
          tokenName={singleTokenInfo?.symbol}
          loading={!executeLoading && loading}
        />
        <div className="flex justify-end gap-1 mt-1">
          <p className="text-base text-gray-200 font-poppins font-regular dark:text-white-900">Wallet Balance: </p>
          <p className="text-base font-semibold text-gray-200 font-poppins dark:text-white-900">
            {singleTokenBalance.fixed()}
          </p>
        </div>

        <div className="flex items-center justify-between mt-4">
          <p className="text-sm text-gray-200 font-regular font-poppins dark:text-white-900">Slippage Tolerance:</p>
          <div className="flex items-center border rounded-sm border-white-400 bg-white-700 dark:bg-gray-200 dark:border-gray-600">
            <input
              value={formValue.slippage}
              onChange={(e) => onChangeSlippageValue(e.target.value)}
              className="w-8 py-1 text-sm font-medium text-center text-gray-200 border-r rounded-tl-sm rounded-bl-sm bg-white-900 dark:bg-gray-800 dark:text-white-900 focus:outline-none rouneded-xl border-white-500 dark:border-gray-600 font-poppins"
            />
            <p className="px-2 text-sm font-poppins dark:text-white-900">%</p>
          </div>
        </div>
      </div>
      <div className="p-4">
        <button
          disabled={!executeEnabled || loading || executeLoading}
          className="w-full py-3 text-base font-medium text-white rounded-lg disabled:bg-slate-500 disabled:bg-none disabled:opacity-100 bg-gradient-to-br from-blue-200 via-blue-300 to-blue-400 font-poppins hover:opacity-90"
          onClick={executeInstaswap}
        >
          {loading || executeLoading ? (
            <LoadingSpinner className="spinner-border-lg text-info" />
          ) : (
            <span>{wallet.connected ? 'Sell LP' : 'Connect your wallet'}</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default InstaSellLpCard;
