import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Platform } from '../../../types/VaultTypes';

type props = {
  options: Array<Platform>;
  selected: Platform;
  onSelectToken: (token: Platform) => void;
};

const PlatformSelection = ({ options, selected, onSelectToken }: props) => {
  if (!selected) return <div></div>;
  return (
    <div className="">
      <Listbox value={selected} onChange={onSelectToken}>
        <div className="relative mt-1">
          <Listbox.Button className="w-full py-2.5 pl-3 pr-10 text-left bg-white-900 dark:bg-gray-800 border-t border-r border-l border-b dark:border-gray-200 rounded-md cursor-default border-white-400 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <div className="flex items-center justify-between">
              <span className="flex items-center gap-2 text-base font-medium text-gray-200 uppercase truncate font-poppins dark:text-white-900">
                <img src={selected.icon} alt={selected.name} className="w-8" />
                {selected.name}
              </span>
            </div>

            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="w-5 h-5 text-gray-400 dark:text-white-900" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base border rounded-md shadow-lg border-white-500 dark:border-gray-600 bg-white-900 dark:bg-gray-800 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((token, tokenIdx) => (
                <Listbox.Option
                  key={tokenIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-white-600 dark:bg-slate-400' : 'text-gray-900'
                    }`
                  }
                  value={token}
                >
                  {({ selected }) => (
                    <>
                      <div className={`flex items-center gap-2 truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        <img src={token?.icon} alt={token.name} className="w-6 h-6" />
                        <p className="text-base font-medium text-gray-200 dark:text-white-900 font-poppins">
                          {token.name}
                        </p>
                      </div>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-900">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default PlatformSelection;
