import React from 'react';
import classNames from 'classnames';
import { CgDanger } from 'react-icons/cg';
import { IoWarningOutline } from 'react-icons/io5';

type props = {
  price?: number;
  buffer: number; //rage of 0 - 1, or -1 is not set
};

const LiquidationBuff = ({ price, buffer }: props) => {
  const status = buffer > 0.05 || buffer < 0 ? '' : buffer > 0.025 ? 'warning' : 'danger';
  return (
    <div>
      <p className="text-lg font-medium text-gray-200 font-poppins dark:text-white-900">Liquidation</p>
      <div
        className={classNames(
          'border border-slate-300 rounded-2xl relative dark:border-gray-600 shadow-sm mt-2',
          { 'border-violet-300 dark:border-violet-300': status === 'warning' },
          { 'border-red-500 dark:border-red-500': status === 'danger' }
        )}
      >
        <div className="p-6 space-y-1">
          <div className="flex items-center gap-2">
            <p className="text-lg font-medium text-gray-200 font-poppins dark:text-white-900">Liquidation Buffer</p>
          </div>
          <div className="flex items-center gap-2">
            {status === 'danger' && (
              <CgDanger className={classNames('w-7 h-7', { '!text-red-500': status === 'danger' })} />
            )}
            {status === 'warning' && (
              <IoWarningOutline className={classNames('w-7 h-7', { '!text-violet-300': status === 'warning' })} />
            )}
            <p
              className={classNames(
                'text-2xl font-semibold text-blue-500 font-poppins',
                { '!text-violet-300 dark:!text-violet-300': status === 'warning' },
                { '!text-red-500 dark:!text-red-500': status === 'danger' }
              )}
            >
              {buffer > 0 ? `${(buffer * 100).toFixed(2)}%` : '-'}
            </p>
          </div>
        </div>
        <div
          className={classNames(
            'p-6 border-t border-white-500 bg-slate-100 rounded-b-2xl grid grid-cols-2 dark:bg-slate-800',
            { 'border-violet-300 dark:border-violet-300': status === 'warning' },
            { 'border-red-500 dark:border-red-500': status === 'danger' }
          )}
        >
          <div className="space-y-1">
            <p className="text-sm font-normal text-gray-100 font-poppins dark:text-white-900">Liquidation Price</p>
            <p
              className={classNames(
                'font-semibold text-gray-200 dark:text-white-900 text-md font-poppins',
                { 'text-violet-300 dark:text-violet-300': status === 'warning' },
                { 'text-red-500 dark:text-red-500 ': status === 'danger' }
              )}
            >
              ${price.toFixed(2)}
            </p>
          </div>
          {/* <div className="space-y-1">
            <p className="text-sm font-normal text-gray-100 dark:text-white-900 font-poppins">Grace Period</p>
            <p
              className={classNames(
                'font-semibold text-gray-200  dark:text-white-900 text-md font-poppins',
                { 'text-violet-300 dark:text-violet-300': status === 'warning' },
                { 'text-red-500 dark:text-red-500 ': status === 'danger' }
              )}
            >
              30:00:00
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LiquidationBuff;
