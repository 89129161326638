import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import Button from '../Button';
import { TokenPairCardProps } from '../../types/VaultTypes';
import { TokenAmount } from '../../utils/safe-math';
import { formatUSD, isWalletApproveError } from '../../utils/utils';
import { isSwimPool, hasInstaLp } from '../../utils/helper';

import linkIcon from '../../assets/images/link.svg';
import { IoAlertCircleOutline } from 'react-icons/io5';
import LoadingSpinner from '../../atoms/LoadingSpinner';
import SwimVaultInfoPopover from '../../components/SwimVaultInfoPopover';
import { useGetPoolManager } from '../../hooks/useGetPoolManager';
import { useUserVaultInfo, usePoolInfo, useAppendUserAction, useRFStateInfo } from '../../contexts/state';
import { HARVEST_ACTION } from '../../utils/ratio-lending';

import infoIcon from '../../assets/images/risklevel.svg';
import USDrIcon from '../../assets/images/USDr.svg';
import { USDR_MINT_DECIMALS } from '../../constants';
import { IoWarningOutline } from 'react-icons/io5';
import { MdOutlineErrorOutline } from 'react-icons/md';

import Experimental from '../Experimental';
import ExperimentalModal from '../ExperimentalModal';

const ActivePairListItem = (tokenPairCardProps: TokenPairCardProps) => {
  const { data, liquidationStatus } = tokenPairCardProps;
  const history = useHistory();
  const wallet = useWallet();

  const { publicKey, connected } = useWallet();
  const { connection } = useConnection();

  const [expand, setExpand] = useState(false);

  const vaultState = useUserVaultInfo(data.mint);
  const poolState = usePoolInfo(data.mint);

  const positionValue = +new TokenAmount((vaultState as any)?.tvlUsd ?? 0, USDR_MINT_DECIMALS).fixed();
  // eslint-disable-next-line
  const [tvl, setTVL] = useState(0);
  // eslint-disable-next-line
  const [tvlUSD, setTVLUSD] = useState(0);
  const [totalDebt, setTotalDebt] = useState(0);
  const [mintableUSDr, setMintableUsdr] = useState(0);

  const [hasUserReachedDebtLimit, setHasUserReachedDebtLimit] = useState('');

  const [isHarvesting, setIsHarvesting] = useState(false);

  const PoolManagerFactory = useGetPoolManager(data.item);

  const appendUserAction = useAppendUserAction();
  const globalState = useRFStateInfo();

  const harvest_reward_fee = poolState.harvestFeeNumer.toNumber() / globalState.feeDeno.toNumber();

  useEffect(() => {
    // replace this boolean value with a function to determine wether user limit reached
    const userLimitReached = false;
    // replace this boolean value with a function to determine wether global limit reached
    const globalLimitReached = false;
    if (userLimitReached) {
      setHasUserReachedDebtLimit('You have reached your USDr debt limit.');
    }
    if (globalLimitReached) {
      setHasUserReachedDebtLimit('The global USDr debt limit has been reached.');
    }
  }, [publicKey, connection]);

  useEffect(() => {
    if (connection && poolState) {
      const tvlAmount = new TokenAmount((poolState as any)?.totalColl ?? 0, poolState?.mintDecimals);
      const tvlUSDAmount = new TokenAmount((poolState as any)?.tvlUsd ?? 0, USDR_MINT_DECIMALS);
      const totalDebtAmount = new TokenAmount((vaultState as any)?.debt ?? 0, USDR_MINT_DECIMALS);
      const mintableUSDrAmount = new TokenAmount((vaultState as any)?.mintableUSDr ?? 0, USDR_MINT_DECIMALS);

      setTVL(Number(tvlAmount.fixed()));
      setTVLUSD(Number(tvlUSDAmount.fixed()));

      setTotalDebt(Number(totalDebtAmount.fixed()));
      setMintableUsdr(Number(mintableUSDrAmount.fixed()));
    }
    return () => {
      setTVL(0);
      setTotalDebt(0);
    };
  }, [connection, poolState]);

  const showDashboard = () => {
    if (!connected) {
      toast('Please connect your wallet!');
    } else {
      history.push(`/app/vaultdashboard/${data.mint}`);
    }
  };
  const harvest = async () => {
    if (!publicKey) {
      console.log('error', 'Wallet not connected!');
      return;
    }
    setIsHarvesting(true);

    try {
      if (!PoolManagerFactory || !PoolManagerFactory?.harvestReward) {
        throw new Error('Pool manager factory not initialized');
      }

      console.log('Harvesting...');
      const txHash = await PoolManagerFactory?.harvestReward(connection, wallet, data.item);
      appendUserAction(
        publicKey.toString(),
        data.mint,
        data.realUserRewardMint,
        HARVEST_ACTION,
        vaultState ? vaultState.reward : 0,
        txHash,
        0,
        0,
        harvest_reward_fee
      );

      toast.success('Successfully Harvested!');
    } catch (err) {
      console.error(err);
      if (isWalletApproveError(err)) toast.warn('Wallet is not approved!');
      else toast.error('Transaction Error!');
    }

    setIsHarvesting(false);
  };
  const renderModalButton = () => {
    return (
      <div>
        <Button
          disabled={!connected || isHarvesting}
          onClick={harvest}
          className="py-2.5 button button--blue w-full font-poppins font-medium mb-2"
        >
          Harvest
        </Button>
        {data?.experimentalStatus ? (
          <ExperimentalModal connected={connected} onClickEvent={showDashboard} btnText="Enter Vault" />
        ) : (
          <Button
            disabled={!connected}
            className="py-2.5 button button--blue w-full font-poppins font-medium"
            onClick={showDashboard}
          >
            Enter Vault
          </Button>
        )}
      </div>
    );
  };

  // eslint-disable-next-line
  const showExpand = () => {
    setExpand(!expand);
  };

  const printTvl = () => {
    if (isNaN(data.tvl)) {
      return <LoadingSpinner className="spinner-border-sm text-info" />;
    }
    return formatUSD.format(data.tvl);
  };

  return (
    <>
      <tr>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <div>
            <div className="flex items-start gap-2">
              <img src={data.icon} alt={'Token1'} className="w-16" />
              {data.platform.name === 'SWIM' && <SwimVaultInfoPopover tokenData={data} />}
              <div>
                <div className="flex items-center gap-1">
                  <p
                    className={classNames(
                      'text-base font-semibold font-poppins text-slate-800 dark:text-white-900 whitespace-nowrap',
                      { '!text-violet-300 dark:text-violet-300': liquidationStatus === 'warning' },
                      { '!text-red-500 dark:text-red-500': liquidationStatus === 'danger' }
                    )}
                  >
                    {data.title === 'USDC-USDR' ? 'USDC-USDr' : data.title}
                  </p>
                  {data?.experimentalStatus && (
                    <Experimental className="w-5 h-5 mb-1 text-gray-100 dark:text-white-900" />
                  )}
                  {liquidationStatus === 'warning' && (
                    <IoWarningOutline className="w-5 h-5 mb-1 text-violet-300 dark:text-violet-300" />
                  )}
                  {liquidationStatus === 'danger' && (
                    <MdOutlineErrorOutline className="w-5 h-5 text-red-500 dark:text-red-500" />
                  )}
                </div>
                <p className="text-xs font-medium font-poppins text-slate-600 dark:text-white-900">TVL {printTvl()}</p>
                {hasInstaLp(data.title) ? (
                  <a href={data.item.page_url} target="_blank" rel="noreferrer">
                    <div className="relative inline-flex items-center gap-1 mt-1">
                      <img src={data.platform.icon} className="w-4" />
                      <p className="text-xs font-medium text-gray-200 font-poppins dark:text-white-900">
                        {data.platform.name}
                      </p>
                      <img src={linkIcon} alt="linkIcon" className="absolute top-1 -right-2" />
                    </div>
                  </a>
                ) : (
                  <Link to={{ pathname: '/app/instaswap', state: { platform: data.platform.name, token: data.title } }}>
                    <div className="relative inline-flex items-center gap-1 mt-1">
                      <img src={data.platform.icon} className="w-4" />
                      <p className="text-xs font-medium text-gray-200 font-poppins dark:text-white-900">
                        {data.platform.name}
                      </p>
                      <img src={linkIcon} alt="linkIcon" className="absolute top-1 -right-2" />
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {hasUserReachedDebtLimit && (
            <div className="flex gap-2 mt-2 font-semibold text-red-500 font-poppins">
              <IoAlertCircleOutline size={20} />
              <p>USDr Limit Reached: {hasUserReachedDebtLimit}</p>
            </div>
          )}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <div className="flex items-center gap-2">
            <p className="text-base font-semibold font-poppins text-slate-700 dark:text-white-900">
              {Number(data?.apr + data?.ratioAPY).toFixed(2)}%
            </p>
            {data.ratioAPY !== 0 && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 100 }}
                overlay={
                  <Tooltip id="tooltip">
                    <div className="font-medium font-poppins">
                      <p>
                        <strong>APR</strong> is made up of:
                      </p>
                      <div className="flex items-center gap-2 mt-1">
                        <img src={USDrIcon} alt="USDrIcon" /> Mint APR: {Number(data?.ratioAPY).toFixed(2)}%
                      </div>
                      {!isSwimPool(data.platform.symbol) && (
                        <div className="flex items-center gap-2 mt-2">
                          <img src={USDrIcon} alt="USDrIcon" /> Yield Farming: {Number(data?.apr).toFixed(2)}%
                        </div>
                      )}
                    </div>
                  </Tooltip>
                }
              >
                <div className="cursor-pointer hover:opacity-75">
                  <img src={infoIcon} alt="infoIcon" />
                </div>
              </OverlayTrigger>
            )}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
            {(100 / poolState.ratio).toFixed(2)}%
          </p>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          {!isSwimPool(data.platform.symbol) && (
            <p className={classNames('font-poppins font-medium bg-transparent', data.risk)}>{data.risk} </p>
          )}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
            {Number(totalDebt.toFixed(2))}
          </p>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
            {Number(mintableUSDr?.toFixed(2))}
          </p>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">{`$ ${vaultState.rewardUSD}`}</p>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">$ {positionValue.toFixed(2)}</p>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          {renderModalButton()}
        </td>
      </tr>
    </>
  );
};

export default ActivePairListItem;
