import React from 'react';
import DepositModal from '../DepositModal';
import PaybackModal from '../PaybackModal';
import WithdrawModal from '../WithdrawModal';
import GenerateModal from '../GenerateModal';
import usdrIcon from '../../../assets/images/USDr.png';
import SwimVaultInfoPopover from '../../SwimVaultInfoPopover';
import { USDR_MINT_KEY } from '../../../constants';
import InterestRatePopover from './InterestRatePopover';
import { InProgressPage } from '../../../utils/utils';

const ModalCard = ({
  mintAddress,
  title,
  icon,
  icons,
  tokenName,
  type,
  depositValue,
  withdrawValue,
  generateValue,
  debtValue,
  debtInterestValue,
  interestAPY,
  usdrWalletValue,
  riskLevel,
  price,
  data,
}: any) => {
  const depositData = {
    mint: mintAddress,
    icon: icon,
    icons: icons,
    title: tokenName,
    value: depositValue,
    usdrMint: USDR_MINT_KEY,
    riskLevel: riskLevel,
    tokenPrice: price,
  };

  const withdrawData = {
    mint: mintAddress,
    icon: icon,
    icons: icons,
    title: tokenName,
    value: withdrawValue,
    debtValue: debtValue,
    ableToMint: generateValue,
    usdrMint: USDR_MINT_KEY,
    riskLevel: riskLevel,
    tokenPrice: price,
  };
  const paybackData = {
    mint: mintAddress,
    icons: [usdrIcon],
    title: tokenName,
    usdrValue: usdrWalletValue,
    debtValue: debtValue,
    debtInterestValue: debtInterestValue,
    interestAPY,
    usdrMint: USDR_MINT_KEY,
    riskLevel: riskLevel,
  };

  const generateData = {
    mint: mintAddress,
    icons: [usdrIcon],
    title: tokenName,
    usdrValue: generateValue,
    usdrMint: USDR_MINT_KEY,
    riskLevel: riskLevel,
  };

  return (
    <div className="">
      <p className="mb-2 text-lg font-medium text-gray-200 font-poppins dark:text-white-900">{title}</p>
      <div className="!border border-slate-300 rounded-2xl relative dark:border-gray-600 shadow-sm">
        <div className="flex justify-between p-6 space-y-1">
          <div className="flex items-center justify-start gap-1">
            <div>
              {icon && <img src={icon} alt={'Token1'} className="w-16" />}
              {/* {type === 'deposit_withdraw' && icons && <img src={icons[0]} alt={icons[0].toString()} />} */}
              {/* {icons && icons[1] && <img src={icons[1]} alt={icons[1].toString()} className="modalCard__header-icon" />} */}
            </div>
            {data?.platform?.name === 'SWIM' && <SwimVaultInfoPopover tokenData={data} />}
            <div className="modalCard__header_tokenName">
              {/* <p>{data.tokenNames}</p> */}
              {/* <h6>{data.tokenValue}</h6> */}
              {type === 'borrow_payback' && (
                <div>
                  <p className="text-sm text-gray-100 font-poppins dark:text-white-900">Able to mint</p>
                  <p className="mt-1 font-semibold text-gray-200 font-poppins dark:text-white-900">
                    {generateValue} USDr
                  </p>
                </div>
              )}
            </div>
          </div>
          <div>
            {type === 'deposit_withdraw' && (
              <div>
                <DepositModal data={depositData} />
              </div>
            )}
            {type === 'borrow_payback' && <GenerateModal data={generateData} />}
          </div>
        </div>
        <div className="flex items-center justify-between p-6 border-t border-white-500 bg-slate-100 rounded-b-2xl dark:bg-slate-800">
          <div>
            {type === 'borrow_payback' && (
              <div className="flex items-start gap-3">
                {icons && <img src={icons[0]} alt="usdr" className="mt-1 w-9 h-9" />}
                <div>
                  <div className="flex items-start gap-2">
                    <p className="font-semibold text-gray-200 font-poppins dark:text-white-900">USDr</p>
                    <InterestRatePopover
                      total={paybackData.debtValue}
                      usdr={(parseFloat(paybackData.debtValue) - paybackData.debtInterestValue).toFixed(6)}
                      interest={paybackData.debtInterestValue.toFixed(6)}
                      apy={paybackData.interestAPY.toFixed(3)}
                    />
                  </div>
                  <p className="font-semibold text-blue-400 font-poppins dark:text-blue-300">
                    {paybackData.debtValue}{' '}
                  </p>
                </div>
              </div>
            )}
            {type === 'deposit_withdraw' && (
              <div className="-space-y-1">
                <p className="font-semibold text-gray-100 font-poppins dark:text-white-900">
                  {tokenName === 'USDC-USDR' ? 'USDC-USDr' : tokenName}
                </p>
                <p className="text-lg font-semibold text-blue-500 font-poppins">{withdrawValue}</p>
              </div>
            )}
          </div>
          <div>
            {type === 'deposit_withdraw' && <WithdrawModal data={withdrawData} />}
            {type === 'borrow_payback' && (
              <div>
                <PaybackModal data={paybackData} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCard;
