import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LPairsTable from './LPairsTable';
import { useAuthContextProvider } from '../../contexts/authAPI';
import { shortenizeAddress } from '../../utils/utils';
import { platforms } from '../../utils/helper';
import AdminFormLayout from '../AdminFormLayout';
import LPairModal from './LPairModal';
import { TrashIcon } from '@heroicons/react/solid';
import { selectors, actionTypes } from '../../features/adminpanel';

const SwapLpTokens = () => {
  const dispatch = useDispatch();

  const { accessToken } = useAuthContextProvider();

  const pairList = useSelector(selectors.getAllLpSwapPairs);
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ cell: { value } }) => shortenizeAddress(value),
        show: false,
      },
      {
        Header: 'Address',
        accessor: 'address_id',
        Cell: ({ cell: { value } }) => shortenizeAddress(value),
      },
      {
        Header: 'Decimals',
        accessor: 'decimals',
      },
      {
        Header: 'Platform',
        accessor: 'platform',
        Cell: ({ row }) => {
          const p = platforms.find((item) => item.id === row.values.platform);
          return (
            <div className={`flex items-center gap-2 truncate`}>
              <img src={p?.icon} alt={p.name} className="w-5 h-5" />
              <p className="text-sm font-medium text-gray-200 dark:text-white-900 font-poppins">{p.name}</p>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'TokenA',
        accessor: 'tokenA',
        Cell: ({ cell: { value } }) => shortenizeAddress(value),
      },
      {
        Header: 'DecimalsA',
        accessor: 'decimalsA',
      },
      {
        Header: 'TokenB',
        accessor: 'tokenB',
        Cell: ({ cell: { value } }) => shortenizeAddress(value),
      },
      {
        Header: 'DecimalsB',
        accessor: 'decimalsB',
      },
      {
        Header: 'Swap account',
        accessor: 'swap_account',
        Cell: ({ cell: { value } }) => shortenizeAddress(value),
      },
      {
        Header: 'Action',
        Cell: ({ row }) => {
          return (
            <div className="flex items-start justify-start gap-1">
              <LPairModal submitLPair={onUpdateLPair} isEditMode={true} initValues={row.values} platforms={platforms} />
              <button onClick={() => onDeleteLPair(row.values.id)}>
                <TrashIcon className="w-7 h-7 text-white-900 p-1.5 bg-orange-400 rounded-full hover:opacity-75 cursor-pointer" />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (accessToken) {
      dispatch(actionTypes.getAllLpSwapPairs());
    }
  }, [accessToken]);

  const onSubmitLPair = async (values) => {
    dispatch(actionTypes.addNewLpSwapPair(values));
  };

  const onUpdateLPair = async (values) => {
    dispatch(actionTypes.updateLpSwapPair(values));
  };

  const onDeleteLPair = async (id) => {
    dispatch(actionTypes.deleteLpSwapPair({ id: id }));
  };

  return (
    <>
      {pairList && (
        <AdminFormLayout>
          <p className="text-xl font-semibold font-work text-slate-700"> Swap Lp Tokens</p>
          <LPairModal submitLPair={onSubmitLPair} isEditMode={false} platforms={platforms} />
          <LPairsTable columns={columns} data={pairList} />
        </AdminFormLayout>
      )}
    </>
  );
};

export default SwapLpTokens;
