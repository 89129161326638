/* eslint-disable no-case-declarations */
import { RECEIVE_ADD_TRANSACTION } from './actionTypes';
import { instaswapActionTypes } from './types';

const initialState = {
  swap_transactions: [],
};

export default (state = initialState, action: instaswapActionTypes) => {
  switch (action.type) {
    case RECEIVE_ADD_TRANSACTION:
      return {
        ...state,
        lp_swap_pairs: [...state.swap_transactions, action.payload],
      };

    default:
      return state;
  }
};
