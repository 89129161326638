import { PublicKey } from '@solana/web3.js';
import React, { useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useRFStateInfo } from '../../contexts/state';
import { addBlacklist, createBlackList, remomveBlacklist } from '../../utils/ratio-lending-admin';
import AdminFormLayout from '../AdminFormLayout';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';

export default function BlacklistAdminForm() {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { blacklist } = useRFStateInfo();

  const [validated, setValidated] = useState(false);
  const [blockWalletAddress, setWalletAddress] = useState('');
  const resetValues = () => {
    setWalletAddress('');
  };

  const handleChange = (event: any) => {
    setWalletAddress(event.target.value);
  };
  const handleSubmit = async (evt: any) => {
    console.log(blockWalletAddress);
    evt.preventDefault();
    try {
      new PublicKey(blockWalletAddress);
      setValidated(true);
    } catch {
      console.log('Not PublicKey type');
    }
    await addBlacklist(connection, wallet, blockWalletAddress);
    resetValues();
  };
  return (
    <AdminFormLayout>
      {!blacklist && (
        <Button
          variant="primary"
          type="submit"
          onClick={() => {
            createBlackList(connection, wallet);
          }}
        >
          Create Blacklist
        </Button>
      )}
      {blacklist && (
        <>
          <h5 className="mt-3">Add new blacklist:</h5>
          <Form validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} xs="auto" md="6" controlId="wallet_address">
                <Form.Label>Wallet address</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    name="wallet_address"
                    type="text"
                    required
                    placeholder="Get solana address from user's wallet"
                    value={blockWalletAddress}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Button variant="primary" type="submit">
              Add
            </Button>
          </Form>
          <h5 className="mt-3">Blocked Users:</h5>
          <Table className="mt-3" striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Wallet address</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {((blacklist ?? { addresses: [] }).addresses as any)?.map((user: any) => (
                <tr key={user.toString()}>
                  <td>{user.toString()}</td>
                  <td>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => {
                        remomveBlacklist(connection, wallet, user.toString());
                      }}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </AdminFormLayout>
  );
}
