import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import Button from '../../Button';
import CustomInput from '../../CustomInput';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';

import { PublicKey } from '@solana/web3.js';
import { calculateLiquidationPrice, autoRepayUSDr, PAYBACK_ACTION, repayUSDr } from '../../../utils/ratio-lending';
import { toast } from 'react-toastify';
import AmountSlider from '../AmountSlider';
import { InProgressPage, isWalletApproveError } from '../../../utils/utils';
import LiquidationSlider from '../LiquidationSlider';
// import LiquidationEstimatedToken from '../LiquidationEstimatedToken';
import { useAppendUserAction, usePoolInfo, useSubscribeTx, useUserVaultInfo } from '../../../contexts/state';
import { USDR_MINT_DECIMALS, USDR_MINT_KEY } from '../../../constants';
import { useVaultsContextProvider } from '../../../contexts/vaults';
import { useGetPoolManager } from '../../../hooks/useGetPoolManager';

const PaybackModal = ({ data }: any) => {
  const maxPaybackAmount = Math.min(data.usdrValue, data.debtValue);

  const theme = useContext(ThemeContext);
  const { darkMode } = theme.state;
  const [show, setShow] = useState(false);
  const { connection } = useConnection();
  const wallet = useWallet();

  const [paybackAmount, setPayBackAmount] = useState<any>(0);
  const [paybackStatus, setPaybackStatus] = useState(false);
  const [invalidStr, setInvalidStr] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [didMount, setDidMount] = useState(false);

  const [isPayingBack, setIsPayingBack] = useState(false);
  const [amountValue, setAmountValue] = useState(0);

  const appendUserAction = useAppendUserAction();
  const subscribeTx = useSubscribeTx();
  const poolInfo = usePoolInfo(data?.mint);
  const { vaults } = useVaultsContextProvider();
  const vault = useMemo(() => vaults.find((vault) => vault.address_id === (data?.mint as string)), [vaults]);
  const poolManagerFactory = useGetPoolManager(vault);

  useEffect(() => {
    setDidMount(true);
    if (paybackAmount > 0) {
      setPaybackStatus(false);
    } else {
      // setPayBackAmount('');
    }
    return () => setDidMount(false);
  }, [paybackAmount]);

  const userVaultInfo = useUserVaultInfo(data?.mint);
  const [estimatedHealth, setEstimatedHealth] = useState(0);

  // useEffect(() => {
  //   if (userVaultInfo) {
  //     const newHealth = calculateLiquidationPrice(
  //       userVaultInfo.poolInfo,
  //       userVaultInfo.lockedUiAmount,
  //       userVaultInfo.uiDebt - paybackAmount
  //     );
  //     setEstimatedHealth(newHealth);
  //   }
  // }, [paybackAmount, userVaultInfo]);

  if (!didMount) {
    return null;
  }

  const repay = async () => {
    console.log('PayBack', paybackAmount);
    if (!(paybackAmount && maxPaybackAmount >= paybackAmount)) {
      setPaybackStatus(true);
      setInvalidStr('Insufficient funds to payback!');
      return;
    }

    setIsPayingBack(true);

    repayUSDr(connection, wallet, paybackAmount * Math.pow(10, USDR_MINT_DECIMALS), new PublicKey(data.mint))
      .then((txHash: string) => {
        subscribeTx(
          txHash,
          () => toast.info('Repay Transaction Sent'),
          () => toast.success('Repay Confirmed.'),
          () => toast.error('Repay Transaction Failed')
        );
        appendUserAction(
          wallet.publicKey.toString(),
          data.mint,
          USDR_MINT_KEY,
          PAYBACK_ACTION,
          -paybackAmount,
          txHash,
          poolInfo.fairPrice,
          poolInfo.marketPrice,
          0
        );
      })
      .catch((e) => {
        console.log(e);
        if (isWalletApproveError(e)) toast.warn('Wallet is not approved!');
        else toast.error('Transaction Error!');
      })
      .finally(() => {
        setIsPayingBack(false);
        setShow(false);
      });
  };

  const autoRepay = async () => {
    console.log('Auto PayBack Amount: ', paybackAmount);
    setIsPayingBack(true);
    await autoRepayUSDr(
      connection,
      wallet,
      paybackAmount * Math.pow(10, USDR_MINT_DECIMALS),
      data.mint,
      poolInfo,
      poolManagerFactory,
      vault
    )
      .then((result) => {
        if (result.err) {
          toast.error(`Error: ${result.err}`);
          toast.error(`Auto Repay Failed !`);
        } else {
          toast.success(`Auto Repay Success !`);
        }
      })
      .finally(() => {
        setIsPayingBack(false);
        setShow(false);
      });
  };

  return (
    <div className="">
      <Button
        className="px-6 py-2.5 text-sm font-medium hover:opacity-80 text-blue-300 border-t-2 border-b-2 border-l-2 border-r-2 border-blue-300 font-poppins dark:border-t-0 dark:border-l-0 dark:border-r-0 dark:border-b-0 dark:bg-white-900 dark:py-3 min-w-[120px]"
        onClick={() => setShow(!show)}
      >
        Pay Back
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setButtonDisabled(true);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-theme={darkMode ? 'dark' : 'light'}
        className={darkMode ? 'dark' : 'light'}
        onEntered={() => {
          setAmountValue(0);
          setPayBackAmount(0);
          setPaybackStatus(false);
          setButtonDisabled(false);
        }}
      >
        <Modal.Header className="border-b bg-white-900 rounded-tl-xl rounded-tr-xl dark:bg-slate-700 border-white-500 dark:border-gray-600">
          <div className="p-[20px]">
            <IoMdClose
              size={32}
              className="absolute cursor-pointer top-12 right-10 hover:opacity-75 dark:text-white-900"
              onClick={() => {
                setButtonDisabled(true);
                setShow(false);
              }}
            />
            <div>
              <img src={data.icons[0]} alt={data.icons[0].toString()} className="w-16" />
            </div>
            <p className="mt-3 text-2xl font-semibold text-gray-200 font-poppins dark:text-white-900">
              Pay back USDr debt
            </p>
            <p className="mt-1 text-base text-gray-100 font-poppins dark:text-white-900">
              You owe &nbsp;
              <span className="font-medium text-red-500">{data.debtValue} USDr </span>. Pay back some or all of your
              debt below.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="dark:bg-slate-800 bg-slate-100 rounded-bl-xl rounded-br-xl">
          <div className="p-[20px]">
            <label className="mb-2 text-sm text-gray-200 font-poppins dark:text-white-900">
              How much would you like to pay back?
            </label>
            <CustomInput
              appendStr="Max"
              // initValue={'0'}
              appendValueStr={'' + maxPaybackAmount}
              tokenStr={`USDr`}
              onTextChange={(value: any) => {
                setAmountValue((value / maxPaybackAmount) * 100);
                setPayBackAmount(value);
                setPaybackStatus(false);
                setButtonDisabled(false);
              }}
              maxValue={maxPaybackAmount}
              valid={paybackStatus}
              invalidStr={invalidStr}
              value={paybackAmount}
            />
            <AmountSlider
              onChangeValue={(value: any) => {
                setPayBackAmount(Number(maxPaybackAmount * (value / 100)).toFixed(2));
                setAmountValue(value);
                setPaybackStatus(false);
                setButtonDisabled(false);
              }}
              value={amountValue}
            />
            {/* {InProgressPage() && (
              <LiquidationSlider currentValue={userVaultInfo?.userHealth} estimatedValue={estimatedHealth} />
            )} */}
            {/* <LiquidationEstimatedToken /> */}
            <Button
              disabled={paybackAmount <= 0 || buttonDisabled || isNaN(paybackAmount) || isPayingBack}
              className="w-full py-3 mt-6 font-medium button--blue font-poppins"
              onClick={() => repay()}
            >
              Pay Back USDr
            </Button>
            <Button
              disabled={
                userVaultInfo?.debt <= 0 ||
                isNaN(paybackAmount) ||
                isPayingBack ||
                !userVaultInfo ||
                !poolManagerFactory
              }
              className="w-full py-3 mt-6 font-medium button--blue font-poppins"
              onClick={() => autoRepay()}
            >
              Auto Pay Back USDr
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaybackModal;
