import React, { useEffect, useMemo, useState } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useAllPoolInfo, useRFStateInfo } from '../../contexts/state';
import AdminFormLayout from '../AdminFormLayout';
import { calculateRewardByPlatform, getAllVault, getUserCount, PLATFORM_IDS } from '../../utils/ratio-lending';
import { USDR_MINT_DECIMALS } from '../../constants';
import { toUiAmount } from '../../utils/utils';
import { Button, Row } from 'react-bootstrap';
import AdminFormInput from '../../components/AdminFormInput';
import CustomInput from '../../components/CustomInput';
import { burnUSDrManually } from '../../utils/ratio-lending-admin';

export default function RFOverview() {
  const { connection } = useConnection();
  const wallet = useWallet();
  const pools = useAllPoolInfo();
  const [vaults, setVaults] = useState([]);
  const [saberReward, setSaberReward] = useState(0);
  const [rayReward, setRayReward] = useState(0);
  const [progress, setProgress] = useState(0);
  const [userCount, setUserCount] = useState(0);

  const globalState = useRFStateInfo();

  const [usdrToBurn, setUSDrToBurn] = useState(0);
  const maxToBurn = useMemo(() => {
    if (globalState && globalState?.usdrMintInfo?.supply && globalState?.totalDebt) {
      return toUiAmount(
        +globalState?.usdrMintInfo?.supply.toString() - globalState?.totalDebt.toNumber(),
        USDR_MINT_DECIMALS
      );
    } else return 0;
  }, [globalState]);

  const onClickToBurn = async () => {
    if (usdrToBurn > 0 && usdrToBurn <= maxToBurn) {
      try {
        await burnUSDrManually(connection, wallet, usdrToBurn * 10 ** USDR_MINT_DECIMALS);
      } catch (e) {
        console.log('Error on burn', e);
      }
    } else {
      console.log('Invalid USDr Amount to Burn');
    }
  };
  const calculateTotalReward = async function (connection, pools, vaults) {
    const totalReward = {
      sbr: 0,
      ray: 0,
    };
    const quarryCache = {};
    let count = 0;
    for (const { account: vault } of vaults) {
      const mintCollat = vault.mintCollat.toString();
      const pool = pools[mintCollat];
      const [rewardWithoutFee, cache] = await calculateRewardByPlatform(
        connection,
        vault.authority,
        mintCollat,
        pool.platformType,
        {
          quarry: quarryCache[mintCollat],
        }
      );
      quarryCache[mintCollat] = cache?.quarry;
      if (pool.platformType === PLATFORM_IDS.SABER) {
        totalReward.sbr += rewardWithoutFee;
        setSaberReward(totalReward.sbr);
      } else if (pool.platformType === PLATFORM_IDS.RAYDIUM) {
        totalReward.ray += rewardWithoutFee;
        setRayReward(totalReward.ray);
      }
      count += 1;
      setProgress(+((count / vaults.length) * 100).toFixed(2));
    }
    return totalReward;
  };
  useEffect(() => {
    getAllVault(connection).then((res) => {
      setVaults(res);
    });
    return () => {};
  }, [connection]);
  useEffect(() => {
    if (pools) {
      calculateTotalReward(connection, pools, vaults);
    }
    return () => {};
  }, [vaults, pools]);
  useEffect(() => {
    getUserCount(connection).then((count) => {
      setUserCount(count);
    });
  }, [connection]);

  useEffect(() => {
    getUserCount(connection).then((count) => {
      setUserCount(count);
    });
  }, [connection]);

  return (
    <AdminFormLayout>
      <h4>{`USDr Supply: ${toUiAmount(globalState?.usdrMintInfo?.supply, USDR_MINT_DECIMALS)}`}</h4>
      <h4>{`USDr Debt: ${toUiAmount(globalState?.totalDebt, USDR_MINT_DECIMALS)}`}</h4>

      <Row className="mb-3">
        <CustomInput
          appendStr="Max"
          initValue={maxToBurn}
          appendValueStr={maxToBurn.toString()}
          tokenStr={`USDr`}
          onTextChange={(value: any) => {
            setUSDrToBurn(+value);
          }}
          maxValue={+maxToBurn}
          valid={true}
          invalidStr={''}
          value={usdrToBurn}
        />

        <Button variant="primary" onClick={onClickToBurn}>
          Burn
        </Button>
      </Row>

      <h4>{`${userCount} Users, ${vaults.length} Vaults`}</h4>

      <h4>{`Analyzed: ${progress} %`}</h4>
      <h4>{`Total Saber Reward: ${saberReward.toFixed(6)} SBR`}</h4>
      <h4>{`Total RAY Reward: ${rayReward.toFixed(6)} RAY`}</h4>
    </AdminFormLayout>
  );
}
