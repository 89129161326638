import React from 'react';

import PlatformBox from './PlatformBox';

import atrixLogo from '../../../assets/images/elsewhere-atrix.png';
import raydiumLogo from '../../../assets/images/elsewhere-raydium.png';
import deltaLogo from '../../../assets/images/elsewhere-delta.png';

type props = {
  darkMode: boolean;
};

const ElseWhere = ({ darkMode }: props) => {
  return (
    <div className="space-y-3">
      <p className="text-lg font-medium text-gray-200 font-poppins dark:text-white-900">Find Ratio Elsewhere</p>
      <PlatformBox logo={atrixLogo} title="Stake" description="Stake USDr-USDC on Atrix" />
      <PlatformBox logo={raydiumLogo} title="Buy" description="Buy Ratio on Raydium" />
      <PlatformBox logo={deltaLogo} title="Lend" description="Lend Ratio on Delta" />
    </div>
  );
};

export default ElseWhere;
