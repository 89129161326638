import React from 'react';

import { HiChevronRight } from 'react-icons/hi';

type props = {
  logo: any;
  title: string;
  description: string;
};

const PlatformBox = ({ logo, title, description }: props) => {
  return (
    <div className="flex items-center justify-between px-4 py-3 border rounded-lg shadow-sm cursor-pointer border-white-500 bg-slate-100 hover:opacity-80">
      <div>
        <div className="flex items-center gap-2">
          <img src={logo} alt="logo" />
          <p className="font-semibold text-gray-200 text-md font-poppins">{title}</p>
        </div>
        <p className="text-sm text-gray-100 font-poppins">{description}</p>
      </div>
      <HiChevronRight className="w-5 h-5 text-slate-400" />
    </div>
  );
};

export default PlatformBox;
