import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import VaultCard from './VaultCard';
import ElseWhere from './ElseWhere';
import NewFeatures from './NewFeatures';
import UpToDate from './UpToDate';
import FilterContainer from '../FilterContainer';
import { CachedPoolInfo } from '../../types/VaultTypes';

type props = {
  connected: boolean;
  userOverview: any;
  userVaultInfos: any;
  vaultsData: any;
  poolInfos: any;
  darkMode: boolean;
  onViewType: (type: string) => void;
  viewType: string;
  cachedPoolInfos: Map<string, CachedPoolInfo>;
};

const AvailableVaults = ({
  connected,
  userOverview,
  userVaultInfos,
  vaultsData,
  poolInfos,
  darkMode,
  viewType,
  onViewType,
  cachedPoolInfos,
}: props) => {
  const [vaults, setVaults] = useState<any>();
  const history = useHistory();
  useEffect(() => {
    const p = vaultsData.filter((item: any) => {
      if (!(!poolInfos || (poolInfos[item.mint] && poolInfos[item.mint].isPaused > 0))) {
        return item;
      }
    });

    setVaults(p);
  }, [poolInfos, connected, userOverview, userVaultInfos, vaultsData]);

  const onClickDeposit = (mintAddress) => {
    if (!connected) {
      toast('Please connect your wallet!');
    } else {
      history.push(`/app/vaultsetup/${mintAddress}`);
    }
  };

  const onClickView = (mintAddress) => {
    if (!connected) {
      toast('Please connect your wallet!');
    } else {
      history.push(`/app/vaultdashboard/${mintAddress}`);
    }
  };

  return (
    <div className="mx-6 xl:mx-14 mt-4 min-h-[70vh] mb-20 dark:bg-gray-900 bg-white-900">
      <p className="mb-4 text-3xl font-medium text-gray-200 font-poppins dark:text-white-900">Available Vaults</p>
      <div className="grid gap-6 2xl:gap-20 xl:gap-10 xl:grid-cols-3 lg:grid-cols-none">
        <div className="overflow-x-auto xl:col-span-2">
          <p className="mb-3 text-lg font-medium text-gray-200 font-poppins dark:text-white-900">Featured Vault</p>
          {vaults && vaults[0] && (
            <VaultCard
              vault={vaults[0]}
              connected={connected}
              onClickDeposit={onClickDeposit}
              onClickView={onClickView}
              cachedPoolInfo={cachedPoolInfos?.[vaults[0].mint]}
            />
          )}
          <div className="mt-6">
            <FilterContainer onViewType={onViewType} viewType={viewType} />
          </div>
          {viewType === 'grid' && (
            <div className="grid gap-6 mt-6 lg:grid-cols-2">
              {vaultsData.slice(1).map((item: any) => {
                if (!(!poolInfos || (poolInfos[item.mint] && poolInfos[item.mint].isPaused > 0))) {
                  return (
                    <VaultCard
                      vault={item}
                      key={item.id}
                      connected={connected}
                      onClickDeposit={onClickDeposit}
                      onClickView={onClickView}
                      cachedPoolInfo={cachedPoolInfos?.[item.mint]}
                    />
                  );
                }
              })}
            </div>
          )}
          {viewType === 'list' && (
            <div className="overflow-x-auto">
              <table className="w-full mt-3 lg:min-w-full min-w-max">
                <thead>
                  <tr>
                    <th className="px-6 py-2 text-sm font-normal leading-4 tracking-wider text-left text-gray-200 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                      Vault:
                    </th>
                    <th className="px-6 py-2 text-sm font-normal leading-4 tracking-wider text-left text-gray-200 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                      APY:
                    </th>
                    <th className="px-6 py-2 text-sm font-normal leading-4 tracking-wider text-left text-gray-200 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                      Collateralization Debt Ratio::
                    </th>
                    <th className="px-6 py-2 text-sm font-normal leading-4 tracking-wider text-left text-gray-200 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                      Risk Rating:
                    </th>
                    <th className="px-6 py-2 text-sm font-normal leading-4 tracking-wider text-left text-gray-200 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600"></th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          )}
        </div>
        <div className="space-y-8">
          <ElseWhere darkMode={darkMode} />
          <NewFeatures />
          <UpToDate darkMode={darkMode} />
        </div>
      </div>
    </div>
  );
};

export default AvailableVaults;
