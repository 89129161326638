import React from 'react';

import yellowVIcon from '../../../assets/images/yellow-v.png';

const NewFeatures = () => {
  return (
    <div>
      <p className="text-lg font-medium text-gray-200 font-poppins dark:text-white-900">New Features</p>
      <div className="p-4 mt-3 space-y-2 border rounded-lg border-white-500 dark:border-gray-600">
        <div className="flex items-center gap-2">
          <img src={yellowVIcon} alt="yellowVIcon" />
          <p className="font-semibold text-gray-200 dark:text-white-900 text-md font-poppins">
            Introducing Volatile Vaults
          </p>
        </div>
        <p className="ml-1 text-sm text-gray-100 font-poppins dark:text-white-900">
          We have introduced volatile vaults which you cannot mint USDr from.
        </p>
      </div>
    </div>
  );
};

export default NewFeatures;
