import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import CustomSelect from '../CustomSelect';
import FilterSelect from '../FilterSelect';
import { actionTypes, selectors } from '../../features/dashboard';

import { ThemeContext } from '../../contexts/ThemeContext';

// import raydiumIcon from '../../assets/images/raydium.svg';
// import orcaIcon from '../../assets/images/orca.svg';
// import mercurialIcon from '../../assets/images/mercurial.svg';
// import saberIcon from '../../assets/images/saber.svg';

import { useFetchTokens } from '../../hooks/useFetchTokens';
import { FetchingStatus } from '../../types/fetching-types';
import { XCircleIcon, ViewGridIcon, ViewListIcon } from '@heroicons/react/solid';

type FilterPanelProps = {
  label: string;
  onViewType: (type: string) => void;
  viewType: string;
};

const optionsSortBy = [
  { value: 'apr', label: 'APR' },
  { value: 'riskLevel', label: 'RISK' },
  { value: 'tvl', label: 'TVL' },
];

const optionsViewBy = [
  { value: 'ascending', label: 'Ascending' },
  { value: 'descending', label: 'Descending' },
];

// eslint-disable-next-line
// const platformOptions = [
//   { value: 'ALL', label: 'All platforms', icon: null },
//   { value: 'RAYDIUM', label: 'Raydium', icon: raydiumIcon },
//   { value: 'ORCA', label: 'Orca', icon: orcaIcon },
//   { value: 'MERCURIAL', label: 'Mercurial', icon: mercurialIcon },
//   { value: 'SABER', label: 'Saber', icon: saberIcon },
// ];

const FilterPanel = ({ label, onViewType, viewType }: FilterPanelProps) => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const { darkMode } = theme.state;
  const isDefault = useMediaQuery({ minWidth: 992 });
  const [compareVaults, setCompareVaults] = useState(false);

  const filter_data = useSelector(selectors.getFilterData);
  const sort_data = useSelector(selectors.getSortData);
  const view_data = useSelector(selectors.getViewData);

  const { tokens, status, error } = useFetchTokens();

  useEffect(() => {
    if (!isDefault) {
      onViewType('grid');
    }
  }, [isDefault]);

  const filterOptions = useMemo(() => {
    if (tokens.length === 0 || status !== FetchingStatus.Finish) {
      return [];
    }
    const t = tokens.filter((item: any) => item.status === 1);

    return t.map(({ symbol, icon }) => {
      return {
        value: symbol,
        label: symbol,
        network: null, // For the moment we leave it as null, since we will have to integrate the rest of the platforms later on
        icon: [icon],
      };
    });
  }, [tokens, status]);

  // We check here for errors when fetch the tokens
  useEffect(() => {
    if (status === FetchingStatus.Error && error) {
      toast.error(`There was an error when fetching the tokens: ${error?.message}`);
    }
  }, [status, error]);

  // eslint-disable-next-line
  const platform_data = useSelector(selectors.getPlatformData);

  const onFilterChange = (values: any) => {
    dispatch({ type: actionTypes.SET_FILTER_DATA, payload: values });
  };

  // eslint-disable-next-line
  const handleCompareVaults = () => {
    setCompareVaults(!compareVaults);
    dispatch({ type: actionTypes.SET_COMPARE_VAULTS, payload: !compareVaults });
  };

  const onSortChange = (values: any) => {
    dispatch({ type: actionTypes.SET_SORT_DATA, payload: values });
  };

  const onViewChange = (values: any) => {
    dispatch({ type: actionTypes.SET_VIEW_DATA, payload: values });
  };

  // eslint-disable-next-line
  const onPlatformChange = (values: any) => {
    dispatch({ type: actionTypes.SET_PLATFORM_DATA, payload: values });
  };

  // eslint-disable-next-line
  const CustomOption = (props: any) => {
    const { children, innerProps, data } = props;
    return (
      <div className={classNames('platform-select__option', { 'platform-select__option--active': props.isSelected })}>
        <div {...innerProps} className="px-3 py-2">
          {data.icon && <img src={data.icon} alt={children} />}
          <span className="ml-3 platform-select__option--token">{children}</span>
        </div>
      </div>
    );
  };

  const handleRemoveValue = (e: any) => {
    if (!onFilterChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue = filter_data.find((val: any) => val.value === buttonName);
    if (!removedValue) return;
    onFilterChange(filter_data.filter((val: any) => val.value !== buttonName));
  };

  const onClearFilters = () => {
    onFilterChange([]);
  };

  return (
    <div className="relative">
      <p className="mb-2 text-3xl font-medium text-gray-200 font-poppins dark:text-white-900">{label}</p>
      <div className="items-start justify-between md:flex">
        <div className="">
          <FilterSelect
            options={filterOptions}
            onFilterChange={onFilterChange}
            filterValue={filter_data}
            placeholder="Search vaults by token"
            isMulti
          />
          {/* HIDE this filter, until we have more than one platform to be filtered */}
          {/*<Select*/}
          {/*components={{ Option: CustomOption }}*/}
          {/*options={platformOptions}*/}
          {/*value={platform_data}*/}
          {/*onChange={onPlatformChange}*/}
          {/*classNamePrefix="platform-select"*/}
          {/*defaultValue={platformOptions[0]}*/}
          {/*/>*/}
          {/* <Switch
            onChange={handleCompareVaults}
            checked={compareVaults}
            className="mt-2 ml-3"
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#07B127"
            offColor="#DBE0E2"
            handleDiameter={18}
            width={48}
          /> */}
        </div>
        <div className="flex items-center gap-3 mt-0 md:mt-0">
          <CustomSelect
            options={optionsSortBy}
            selected={sort_data}
            onChange={onSortChange}
            className="w-full md:w-28"
          />
          <CustomSelect
            options={optionsViewBy}
            selected={view_data}
            onChange={onViewChange}
            className="md:w-[150px] w-full"
          />
          {isDefault && (
            <div className="flex items-center gap-0">
              <ViewGridIcon
                onClick={() => onViewType('grid')}
                className={classNames([
                  'cursor-pointer opacity-60 w-7 h-7',
                  darkMode ? 'text-white-900' : 'text-slate-600',
                  { '!opacity-100': viewType === 'grid' },
                ])}
                aria-hidden="true"
              />
              <ViewListIcon
                onClick={() => onViewType('list')}
                className={classNames([
                  'cursor-pointer opacity-60 w-7 h-7',
                  darkMode ? 'text-white-900' : 'text-slate-600',
                  { '!opacity-100': viewType === 'list' },
                ])}
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      </div>
      <div className="md:absolute md:top-24 md:mt-0 mt-[10px]">
        <div className="flex flex-wrap items-center gap-2">
          {filter_data.map((val: any) => (
            <div
              key={val.value}
              className="flex items-center gap-1 pl-1.5 pr-1 border-t border-b border-l border-r rounded-xl border-slate-300 dark:border-slate-600 py-1 shadow-sm"
            >
              <img src={val.icon[0]} alt="icon" className="w-4 h-4 rounded-full" />
              <p className="text-xs font-medium font-poppins dark:text-white-900">{val.label}</p>
              <button name={val.value} onClick={handleRemoveValue} className="hover:opacity-80">
                <XCircleIcon className="w-4 text-slate-500 dark:text-white-900" />
              </button>
            </div>
          ))}
          {filter_data.length > 0 && (
            <button
              onClick={onClearFilters}
              className="text-xs font-medium text-gray-400 dark:text-white-900 font-poppins hover:opacity-75"
            >
              Clear all filters
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
