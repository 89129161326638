import React from 'react';

import { useAllPoolInfo } from '../../../contexts/state';
import { formatUSD } from '../../../utils/utils';

import FilterContainer from '../../FilterContainer';
import SwimVaultInfoPopover from '../../SwimVaultInfoPopover';
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import { BsBoxArrowUpRight } from 'react-icons/bs';

type props = {
  vaultsData: any;
  loading: boolean;
  connected: boolean;
};

const Positions = ({ vaultsData, loading, connected }: props) => {
  const poolInfos = useAllPoolInfo();

  const printTvl = (data) => {
    if (isNaN(data.tvl)) {
      return <LoadingSpinner className="spinner-border-sm text-info" />;
    }
    return formatUSD.format(data.tvl);
  };
  return (
    <div className="relative w-full min-h-[430px] my-5 overflow-x-auto">
      <p className="text-lg font-medium text-gray-200 font-poppins dark:text-white-900">My Positions</p>
      <FilterContainer />
      <div className="overflow-x-auto">
        <table className="w-full mt-3 lg:min-w-full min-w-max">
          <thead>
            <tr>
              <th className="px-6 py-2 text-sm font-normal leading-4 tracking-wider text-left text-gray-200 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                Vault:
              </th>
              <th className="px-6 py-2 text-sm font-normal leading-4 tracking-wider text-left text-gray-200 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                Position Value:
              </th>
              <th className="px-6 py-2 text-sm font-normal leading-4 tracking-wider text-left text-gray-200 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                Debt Value:
              </th>
              <th className="px-6 py-2 text-sm font-normal leading-4 tracking-wider text-left text-gray-200 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                Equity Value:
              </th>
              <th className="px-6 py-2 text-sm font-normal leading-4 tracking-wider text-left text-gray-200 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                Liquidation Buff:
              </th>
            </tr>
          </thead>
          <tbody>
            {!connected && (
              <tr>
                <td
                  colSpan={5}
                  className="col-span-5 py-4 text-base font-normal text-center text-gray-200 font-poppins dark:text-white-900"
                >
                  Please connect your wallet
                </td>
              </tr>
            )}
            {connected && loading && (
              <tr>
                <td colSpan={5} className="col-span-5 py-4 text-base font-normal text-center font-poppins">
                  <LoadingSpinner className="text-pink-200 spinner-border-sm dark:text-white-900" />
                </td>
              </tr>
            )}
            {connected && !loading && vaultsData.length === 0 && (
              <tr>
                <td
                  colSpan={5}
                  className="col-span-5 py-4 text-base font-normal text-center text-gray-200 font-poppins dark:text-white-900"
                >
                  There is no Active Vaults
                </td>
              </tr>
            )}
            {!loading &&
              vaultsData.map((vault: any, index: number) => {
                if (!(poolInfos[vault.mint] && poolInfos[vault.mint].isPaused > 0)) {
                  return (
                    <tr key={index}>
                      <td className="px-6 py-3 whitespace-no-wrap border-b border-white-500 dark:border-gray-600">
                        <div>
                          <div className="flex items-start gap-3">
                            <img src={vault.icon} alt={'Token1'} className="w-16" />
                            {vault.platform.name === 'SWIM' && <SwimVaultInfoPopover tokenData={vault} />}
                            <div>
                              <p className="text-base font-semibold text-black-100 font-poppins whitespace-nowrap dark:text-white-900">
                                {vault.title === 'USDC-USDR' ? 'USDC-USDr' : vault.title}
                              </p>
                              <p className="text-xs text-gray-200 font-poppins dark:text-white-900">
                                <span className="font-semibold">TVL:</span>{' '}
                                <span className="font-normal">{printTvl(vault)}</span>
                              </p>
                              <a href={vault.item.page_url} target="_blank" rel="noreferrer">
                                <div className="relative inline-flex items-center gap-1 mt-1">
                                  <img src={vault.platform.icon} className="w-4" />
                                  <p className="text-xs font-medium text-gray-200 underline font-poppins underline-offset-2 dark:text-white-900">
                                    {vault.platform.name}
                                  </p>
                                  <BsBoxArrowUpRight className="w-2 text-gray-200" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
                        <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
                          ${vault?.positionValue?.toFixed(2)}
                        </p>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
                        <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
                          ${vault?.totalDebt?.toFixed(2)}
                        </p>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
                        <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">--</p>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
                        <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">--</p>
                      </td>
                    </tr>
                  );
                }
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Positions;
