import React, { useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';

import Button from '../Button';
import { IoMdClose } from 'react-icons/io';
import classNames from 'classnames';

type props = {
  connected: boolean;
  onClickEvent: any;
  btnText: string;
};

const ExperimentalModal = ({ connected, onClickEvent, btnText }: props) => {
  const theme = useContext(ThemeContext);
  const { darkMode } = theme.state;
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      <div className="w-full">
        <Button
          disabled={!connected}
          className="py-2.5 button button--blue w-100 font-poppins font-medium whitespace-nowrap px-3"
          onClick={openModal}
        >
          {btnText}
        </Button>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className={classNames('fixed inset-0 overflow-y-auto', darkMode ? 'dark' : 'light')}>
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md overflow-hidden text-left align-middle transition-all transform shadow-xl bg-white-900 dark:bg-slate-700 rounded-2xl">
                  <div className="flex justify-end pt-6 pr-6">
                    <IoMdClose
                      size={32}
                      className="cursor-pointer hover:opacity-75 dark:text-white-900"
                      onClick={closeModal}
                    />
                  </div>
                  <div className="px-10 mt-2">
                    <p className="text-2xl font-semibold text-gray-200 font-poppins dark:text-white-900">
                      This is an experimental vault
                    </p>
                    <p className="mt-3 text-base text-gray-200 font-poppins dark:text-white-900">
                      By opening this vault you are accepting the risk assisocated with it.
                    </p>
                  </div>

                  <div className="flex gap-3 px-10 py-6 mt-4 border-t border-white-500 dark:border-gray-600 dark:bg-slate-800 bg-slate-100">
                    <Button
                      disabled={!connected}
                      className="py-2.5 button button--gradientBorder w-100 font-poppins font-medium"
                      onClick={closeModal}
                    >
                      Return
                    </Button>
                    <Button
                      disabled={!connected}
                      className="py-2.5 button button--blue w-100 font-poppins font-medium"
                      onClick={onClickEvent}
                    >
                      Proceed
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ExperimentalModal;
