import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';

import './index.scss';
import './index.css';
import '@dialectlabs/react-ui/lib/index.css';

import App from './App';

// const {} = configureStore();
require('@solana/wallet-adapter-react-ui/styles.css');
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
