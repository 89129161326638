import { LPEditionData } from '../../types/VaultTypes';
import AdminFormLayout from '../AdminFormLayout';
import VaultEditionForm from './VaultEditionForm';
import VaultsTable from './VaultsTable';

export default function VaultCreationAdminForm() {
  const emptyVault: LPEditionData = {
    address_id: '',
    vault_address_id: '',
    page_url: '',
    icon: '',
    platform_id: '',
    platform_symbol: '',
    pool_size: 0,
    symbol: '',
    collateralization_ratio: 0,
    liquidation_ratio: 0,
    borrow_fee_num: 0,
    harvest_fee_num: 0,
    deposit_fee_num: 0,
    risk_rating: null,
    reward_mint: '',
    assets: [],
  };

  return (
    <AdminFormLayout>
      <h5 className="mt-3">Add new vault:</h5>
      <VaultEditionForm values={emptyVault} />
      <VaultsTable />
    </AdminFormLayout>
  );
}
