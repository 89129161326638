import classNames from 'classnames';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { RiErrorWarningLine } from 'react-icons/ri';

type props = {
  className?: string;
};

const Experimental = ({ className }: props) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 100, hide: 100 }}
      overlay={
        <Tooltip id="tooltip">
          <div>
            This vault is experimental. Use at
            <br /> your own risk.
          </div>
        </Tooltip>
      }
    >
      <div className={classNames('cursor-pointer')}>
        <RiErrorWarningLine className={classNames(className)} />
      </div>
    </OverlayTrigger>
  );
};

export default Experimental;
