import * as anchor from '@project-serum/anchor';

import {
  DEFAULT_PROGRAMS,
  depositCollateralTx,
  distributeRewardTx,
  POOL_INFO_LIST,
  withdrawCollateralTx,
} from '../../ratio-lending';
import { PublicKey, Transaction, Connection } from '@solana/web3.js';
import { getProgramInstance, sendTransaction } from '../../rf-web3';

import { getATAKey, getGlobalStatePDA, getPoolPDA, getVaultPDA } from '../../ratio-pda';
import { RAYDIUM_FARM_PROGRAM_ID, RAYDIUM_FARM_VERSION } from '../../../constants';
import { RAYDIUM_FARMS } from './raydium-farms';
import { FARM_LEDGER_LAYOUT_V5_2, REAL_FARM_STATE_LAYOUT_V5 } from './raydium_layout';

import BigNumber from 'bignumber.js';
import axios from 'axios';
import { createAssociatedTokenAccountInstruction } from '@solana/spl-token-v2';
import { connection } from '@project-serum/common';

export const RAY_MINT_DECIMALS = 6;
export const RAY_MINT_KEY = '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R';

export async function deposit(
  connection: Connection,
  wallet: any,

  mintCollKey: PublicKey,

  amount: number
): Promise<string> {
  console.log('Deposit to Raydium', amount);

  const transaction = new Transaction();

  const tx1: any = await depositCollateralTx(connection, wallet, amount, mintCollKey);
  transaction.add(tx1);

  const tx3 = await stakeCollateralToRaydiumTx(amount, connection, wallet, mintCollKey);
  if (tx3) {
    transaction.add(tx3);
  }
  const txHash = await sendTransaction(connection, wallet, transaction);

  console.log('Raydium deposit tx', txHash);
  return txHash.toString();
}

export async function withdraw(connection: Connection, wallet: any, mintCollKey: PublicKey, amount: number) {
  console.log('Withdraw from Raydium', amount);

  const tx1 = new Transaction();

  const ix1 = await unstakeColalteralFromRaydiumTx(connection, wallet, amount, mintCollKey);
  if (ix1) {
    tx1.add(ix1);
  }

  const ix2 = await withdrawCollateralTx(connection, wallet, amount, mintCollKey);
  if (ix2) {
    tx1.add(ix2);
  }
  const txHash = await sendTransaction(connection, wallet, tx1);

  return txHash;
}

export async function withdrawIx(connection: Connection, wallet: any, mintCollKey: PublicKey, amount: number) {
  console.log('Withdraw from Raydium', amount);

  const tx1 = new Transaction();

  const ix1 = await unstakeColalteralFromRaydiumTx(connection, wallet, amount, mintCollKey);
  if (ix1) {
    tx1.add(ix1);
  }

  const ix2 = await withdrawCollateralTx(connection, wallet, amount, mintCollKey);
  if (ix2) {
    tx1.add(ix2);
  }

  return tx1;
}

export async function harvest(connection: Connection, wallet: any, mintCollKey: PublicKey, needTx = false) {
  console.log('Harvest from Raydium');

  const transaction = new Transaction();

  const tx1 = await harvestRewardsFromRaydiumTx(connection, wallet, mintCollKey);
  if (tx1) {
    transaction.add(tx1);
  }

  const tx2 = await distributeRewardTx(connection, wallet, mintCollKey);
  if (tx2) {
    transaction.add(tx2);
  }
  if (!needTx) {
    const txHash = await sendTransaction(connection, wallet, transaction);

    return txHash;
  }
  return transaction;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function isRaydiumLp(mintCollKey: PublicKey): boolean {
  if (!RAYDIUM_FARMS[mintCollKey.toBase58()]) {
    return false;
  }
  return true;
}
export const getRaydiumFarmInfo = async (connection: Connection, mintCollKey: PublicKey, ammVersion: number) => {
  const farmLayout = await getStateLayout(ammVersion);
  const farmKey = new PublicKey(RAYDIUM_FARMS[mintCollKey.toBase58()]);
  const farmInfo = await connection.getAccountInfo(farmKey);
  if (!farmInfo) {
    return null;
  }
  const parsedData = farmLayout.decode(farmInfo?.data);
  return {
    publicKey: farmKey,
    parsedData,
  };
};

export const getRaydiumLedgerInfo = async (
  userConnection: Connection,
  userKey: string | PublicKey,
  mintCollKey: PublicKey,
  farmVersion: number
) => {
  const vaultKey = getVaultPDA(userKey, mintCollKey);
  const ledgerLayout = await getLedgerLayout(farmVersion);
  const raydiumFarmInfo = await getRaydiumFarmInfo(userConnection, mintCollKey, farmVersion);
  if (!raydiumFarmInfo) {
    throw "Can't get raydium farm info";
  }

  const ledgerKey = await getAssociatedLedgerAccount({
    programId: RAYDIUM_FARM_PROGRAM_ID,
    poolId: raydiumFarmInfo.publicKey,
    owner: vaultKey,
  });
  const ledgerInfo = await userConnection.getAccountInfo(new PublicKey(ledgerKey));
  if (!ledgerInfo) {
    return null;
  }
  const parsedData = ledgerLayout.decode(ledgerInfo?.data);
  return {
    publicKey: ledgerKey,
    parsedData,
  };
};
export const stakeCollateralToRaydiumTx = async (
  amountToStake: number,
  userConnection: Connection,
  wallet: any,
  mintCollKey: PublicKey
) => {
  const program = getProgramInstance(userConnection, wallet);

  const globalStateKey = getGlobalStatePDA();
  const poolKey = getPoolPDA(mintCollKey);
  const vaultKey = getVaultPDA(wallet.publicKey, mintCollKey);

  const poolData = await program.account.pool.fetch(poolKey);
  const stateInfo = await program.account.globalState.fetch(globalStateKey);
  const ataRewardTreasury = getATAKey(stateInfo.treasury, poolData.mintReward);

  const ataCollatKey = getATAKey(vaultKey, mintCollKey);
  const ataRewardKey = getATAKey(vaultKey, poolData.mintReward);
  const ataRewardBKey = getATAKey(vaultKey, poolData.swapMintA);

  const raydiumFarmInfo = await getRaydiumFarmInfo(userConnection, mintCollKey, 5);
  if (!raydiumFarmInfo) {
    throw "Can't get raydium farm info";
  }
  const stakerInfo = await getAssociatedLedgerAccount({
    programId: RAYDIUM_FARM_PROGRAM_ID,
    poolId: raydiumFarmInfo.publicKey,
    owner: vaultKey,
  });
  const farmAuthority = (
    await getAssociatedAuthority({
      programId: RAYDIUM_FARM_PROGRAM_ID,
      poolId: raydiumFarmInfo.publicKey,
    })
  ).publicKey;
  console.log('staking to Raydium');
  const txn = new Transaction();
  if (!(await userConnection.getAccountInfo(ataRewardTreasury))) {
    txn.add(
      createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        ataRewardTreasury,
        stateInfo.treasury,
        new PublicKey(poolData.mintReward)
      )
    );
  }
  txn.add(
    program.instruction.stakeCollateralToRaydium(new anchor.BN(amountToStake), {
      accounts: {
        authority: wallet.publicKey,
        globalState: globalStateKey,
        ataRewardTreasury,
        pool: poolKey,
        vault: vaultKey,
        raydiumProgram: RAYDIUM_FARM_PROGRAM_ID,
        stakePool: raydiumFarmInfo.publicKey,
        poolAuthority: farmAuthority,
        stakerInfo,
        ataCollatVault: ataCollatKey,
        vaultLpToken: raydiumFarmInfo.parsedData.lpVault,
        destRewardTokenA: ataRewardKey,
        vaultRewardTokenA: raydiumFarmInfo.parsedData.rewardVaultA,
        destRewardTokenB: ataRewardBKey,
        vaultRewardTokenB: raydiumFarmInfo.parsedData.rewardVaultB,
        ...DEFAULT_PROGRAMS,
      },
    })
  );
  return txn;
};

export const unstakeColalteralFromRaydiumTx = async (
  userConnection: Connection,
  wallet: any,
  unstakeAmount: number,
  mintCollKey: PublicKey,
  user: PublicKey | string = wallet.publicKey
) => {
  const program = getProgramInstance(userConnection, wallet);

  const globalStateKey = getGlobalStatePDA();
  const poolKey = getPoolPDA(mintCollKey);
  const vaultKey = getVaultPDA(user, mintCollKey);

  const poolData = await program.account.pool.fetch(poolKey);
  const stateInfo = await program.account.globalState.fetch(globalStateKey);
  const ataRewardTreasury = getATAKey(stateInfo.treasury, poolData.mintReward);

  const ataCollatKey = getATAKey(vaultKey, mintCollKey);
  const ataRewardKey = getATAKey(vaultKey, poolData.mintReward);
  const ataRewardBKey = getATAKey(vaultKey, poolData.swapMintA);

  const raydiumFarmInfo = await getRaydiumFarmInfo(userConnection, mintCollKey, 5);
  if (!raydiumFarmInfo) {
    throw "Can't get raydium farm info";
  }
  const stakerInfo = await getAssociatedLedgerAccount({
    programId: RAYDIUM_FARM_PROGRAM_ID,
    poolId: raydiumFarmInfo.publicKey,
    owner: vaultKey,
  });
  const farmAuthority = (
    await getAssociatedAuthority({
      programId: RAYDIUM_FARM_PROGRAM_ID,
      poolId: raydiumFarmInfo.publicKey,
    })
  ).publicKey;

  const txn = new Transaction();
  const tokenAmount = +(await userConnection.getTokenAccountBalance(ataCollatKey)).value.amount;
  if (tokenAmount < unstakeAmount) {
    unstakeAmount -= tokenAmount;

    if (!(await userConnection.getAccountInfo(ataRewardTreasury))) {
      txn.add(
        createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          ataRewardTreasury,
          stateInfo.treasury,
          new PublicKey(poolData.mintReward)
        )
      );
    }
    txn.add(
      program.instruction.unstakeCollateralFromRaydium(new anchor.BN(unstakeAmount), {
        accounts: {
          authority: wallet.publicKey,
          globalState: globalStateKey,
          ataRewardTreasury,
          pool: poolKey,
          vault: vaultKey,
          raydiumProgram: RAYDIUM_FARM_PROGRAM_ID,
          stakePool: raydiumFarmInfo.publicKey,
          poolAuthority: farmAuthority,
          stakerInfo,
          ataCollatVault: ataCollatKey,
          vaultLpToken: raydiumFarmInfo.parsedData.lpVault,
          destRewardTokenA: ataRewardKey,
          vaultRewardTokenA: raydiumFarmInfo.parsedData.rewardVaultA,
          destRewardTokenB: ataRewardBKey,
          vaultRewardTokenB: raydiumFarmInfo.parsedData.rewardVaultB,
          ...DEFAULT_PROGRAMS,
        },
      })
    );
  }

  return txn;
};

export const harvestRewardsFromRaydiumTx = async (
  connection: Connection,
  wallet: any,
  mintCollKey: PublicKey,
  user: PublicKey | string = wallet.publicKey
) => {
  const program = getProgramInstance(connection, wallet);

  const globalStateKey = getGlobalStatePDA();
  const poolKey = getPoolPDA(mintCollKey);
  const vaultKey = getVaultPDA(user, mintCollKey);

  const poolData = await program.account.pool.fetch(poolKey);
  const stateInfo = await program.account.globalState.fetch(globalStateKey);

  const ataTreasuryReward = getATAKey(stateInfo.treasury, poolData.mintReward);
  const ataCollatKey = getATAKey(vaultKey, mintCollKey);
  const ataRewardKey = getATAKey(vaultKey, poolData.mintReward);
  const ataRewardBKey = getATAKey(vaultKey, poolData.swapMintA);

  const raydiumFarmInfo = await getRaydiumFarmInfo(connection, mintCollKey, 5);
  if (!raydiumFarmInfo) {
    throw "Can't get raydium farm info";
  }

  const stakerInfo = await getAssociatedLedgerAccount({
    programId: RAYDIUM_FARM_PROGRAM_ID,
    poolId: raydiumFarmInfo.publicKey,
    owner: vaultKey,
  });
  const farmAuthority = (
    await getAssociatedAuthority({
      programId: RAYDIUM_FARM_PROGRAM_ID,
      poolId: raydiumFarmInfo.publicKey,
    })
  ).publicKey;
  const tx = new Transaction();
  if (!(await connection.getAccountInfo(ataTreasuryReward))) {
    tx.add(
      createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        ataTreasuryReward,
        stateInfo.treasury,
        poolData.mintReward
      )
    );
  }
  tx.add(
    program.instruction.harvestRewardsFromRaydium({
      accounts: {
        globalState: globalStateKey,
        pool: poolKey,
        vault: vaultKey,
        raydiumProgram: RAYDIUM_FARM_PROGRAM_ID,
        stakePool: raydiumFarmInfo.publicKey,
        poolAuthority: farmAuthority,
        stakerInfo,
        ataCollatVault: ataCollatKey,
        vaultLpToken: raydiumFarmInfo.parsedData.lpVault,
        destRewardTokenA: ataRewardKey,
        ataRatioTreasury: ataTreasuryReward,
        vaultRewardTokenA: raydiumFarmInfo.parsedData.rewardVaultA,
        destRewardTokenB: ataRewardBKey,
        vaultRewardTokenB: raydiumFarmInfo.parsedData.rewardVaultB,
        ...DEFAULT_PROGRAMS,
      },
    })
  );
  return tx;
};
export async function calculateRaydiumTvlAndApr(
  userConnection: Connection,
  collat: {
    price: number;
    mint: PublicKey;
  },
  rewardToken: { price: number; decimals: number }
) {
  try {
    const poolVersion = POOL_INFO_LIST[collat.mint.toBase58()].version;

    const raydiumFarmInfo = await getRaydiumFarmInfo(userConnection, collat.mint, poolVersion);
    if (!raydiumFarmInfo) {
      throw "Can't get raydium farm info";
    }
    const tvl = (await userConnection.getTokenAccountBalance(raydiumFarmInfo.parsedData.lpVault)).value.uiAmount ?? 0;

    let slotsCountPerSecond = 2;
    const rpcResponse = await axios.post(
      'https://api.metaplex.solana.com',
      {
        id: 'getRecentPerformanceSamples',
        jsonrpc: '2.0',
        method: 'getRecentPerformanceSamples',
        params: [100],
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (rpcResponse.status === 200) {
      const sampleList: Array<{ numSlots: number }> = rpcResponse.data.result;
      const slotCountList = sampleList.map((item) => item.numSlots);
      slotsCountPerSecond = slotCountList.reduce((a, b) => a + b, 0) / slotCountList.length / 60;
    }

    const rewardPerSecond = new BigNumber(raydiumFarmInfo.parsedData.perSlotRewardA.toString()).multipliedBy(
      slotsCountPerSecond
    );

    const lpPrice = collat.price || POOL_INFO_LIST[collat.mint.toBase58()].lpPrice;
    const apr7d = 0 || POOL_INFO_LIST[collat.mint.toBase58()].apr7d;

    const apy = rewardPerSecond
      .multipliedBy(3600 * 24 * 365)
      .dividedBy(Math.pow(10, rewardToken.decimals))
      .multipliedBy(rewardToken.price)
      .dividedBy(tvl * lpPrice)
      .multipliedBy(100)
      .toFixed();
    return {
      tvl: tvl * lpPrice,
      apr: Number.parseFloat(apy) + apr7d,
    };
  } catch (e) {
    console.error(e);
    return {
      tvl: 0,
      apr: 0,
    };
  }
}

export async function calculateRaydiumReward(
  userConnection: Connection,
  userKey: PublicKey | string,
  mintCollKey: PublicKey | string,
  cacheData: { raydiumFarmInfo: any; ledgerInfo: any } | null = null
): Promise<[number, any]> {
  try {
    if (!cacheData || !cacheData.raydiumFarmInfo) {
      const raydiumFarmInfo = await getRaydiumFarmInfo(userConnection, new PublicKey(mintCollKey), 5);
      const ledgerInfo = await getRaydiumLedgerInfo(userConnection, userKey, new PublicKey(mintCollKey), 5);
      cacheData = { raydiumFarmInfo, ledgerInfo };
    }
    if (!cacheData.ledgerInfo) {
      cacheData.ledgerInfo = await getRaydiumLedgerInfo(userConnection, userKey, new PublicKey(mintCollKey), 5);
    }
    const { raydiumFarmInfo, ledgerInfo } = cacheData;

    const perShareReward = new BigNumber(raydiumFarmInfo.parsedData.perShareRewardA.toString());
    const ledgerDebt = new BigNumber(ledgerInfo.parsedData.rewardDebts[0].toString());
    const deposited = new BigNumber(ledgerInfo.parsedData.deposited.toString());
    const pendingRewards = deposited.multipliedBy(perShareReward).dividedBy(new BigNumber(1e15)).minus(ledgerDebt);
    const uiPendingRewards = pendingRewards.toNumber() / 10 ** RAY_MINT_DECIMALS;
    return [Math.max(0, uiPendingRewards), cacheData];
  } catch {
    return [0, cacheData];
  }
}
export const getAssociatedAuthority = ({ programId, poolId }: { programId: PublicKey; poolId: PublicKey }) => {
  return findProgramAddress([poolId.toBuffer()], programId);
};

export async function findProgramAddress(seeds: Array<Buffer | Uint8Array>, programId: PublicKey) {
  const [publicKey, nonce] = await PublicKey.findProgramAddress(seeds, programId);
  return { publicKey, nonce };
}
export async function getAssociatedLedgerAccount({
  programId,
  poolId,
  owner,
}: {
  programId: PublicKey;
  poolId: PublicKey;
  owner: PublicKey;
}) {
  // eslint-disable-next-line prefer-const
  let farmVersion = RAYDIUM_FARM_VERSION;
  const { publicKey } = await findProgramAddress(
    [
      poolId.toBuffer(),
      owner.toBuffer(),
      Buffer.from(farmVersion === 6 ? 'farmer_info_associated_seed' : 'staker_info_v2_associated_seed', 'utf-8'),
    ],
    programId
  );
  return publicKey;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getStateLayout(version: number) {
  const STATE_LAYOUT = REAL_FARM_STATE_LAYOUT_V5; //FARM_VERSION_TO_STATE_LAYOUT[version];
  return STATE_LAYOUT;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getLedgerLayout(version: number) {
  const LEDGER_LAYOUT = FARM_LEDGER_LAYOUT_V5_2; //FARM_VERSION_TO_LEDGER_LAYOUT[version];
  return LEDGER_LAYOUT;
}
