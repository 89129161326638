import React from 'react';

import PortfolioOverTime from '../../components/MyDashboard/PortfolioOverTime';
import Overview from '../../components/MyDashboard/Overview';
// import FairdropBox from '../../components/MyDashboard/FairdropBox';
import Positions from '../../components/MyDashboard/Positions';

type props = {
  connected: boolean;
  userOverview: any;
  userVaultInfos: any;
  vaultsData: any;
};

const MyDashboard = ({ connected, userOverview, userVaultInfos, vaultsData }: props) => {
  return (
    <div className="mx-6 xl:mx-14 mt-4 min-h-[70vh] mb-20 dark:bg-gray-900 bg-white-900">
      <p className="mb-4 text-3xl font-medium text-gray-200 font-poppins dark:text-white-900">My Dashboard</p>
      <div className="grid gap-6 2xl:gap-20 xl:gap-10 xl:grid-cols-3 lg:grid-cols-none">
        <div className="overflow-x-auto xl:col-span-2">
          <PortfolioOverTime />
          <Positions
            vaultsData={vaultsData}
            loading={userVaultInfos && Object.keys(userVaultInfos).length === 0}
            connected={connected}
          />
        </div>
        <div className="">
          <Overview connected={connected} userOverview={userOverview} userVaultInfos={userVaultInfos} />
          {/* <FairdropBox /> */}
        </div>
      </div>
    </div>
  );
};

export default MyDashboard;
