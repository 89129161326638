import { useCallback, useState } from 'react';

import { Connection, PublicKey } from '@solana/web3.js';
import { MintLayout } from '@solana/spl-token-v2';
import { TokenAmount } from './safe-math';

export function InProgressPage() {
  return window.location.hostname !== 'app.ratio.finance' && window.location.hostname !== 'demo.ratio.finance';
}

export function sleep(ms: number) {
  return new Promise((r) => setTimeout(r, ms));
}

export function useLocalStorageState(key: string, defaultState?: string) {
  const [state, setState] = useState(() => {
    // NOTE: Not sure if this is ok
    const storedState = localStorage.getItem(key);
    if (storedState) {
      return JSON.parse(storedState);
    }
    return defaultState;
  });

  const setLocalStorageState = useCallback(
    (newState) => {
      const changed = state !== newState;
      if (!changed) {
        return;
      }
      setState(newState);
      if (newState === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newState));
      }
    },
    [state, key]
  );

  return [state, setLocalStorageState];
}

// shorten the checksummed version of the input address to have 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
}

export const formatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});
export const isSinglePool = (platform_symbol) => {
  return platform_symbol?.toLowerCase() !== 'swim';
};

export function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}

export const calculateCollateralPrice = (
  lpSupply: number,
  tokenAmountA: number,
  priceA: number,
  tokenAmountB: number,
  priceB: number
) => {
  const valueA = tokenAmountA * priceA;
  const valueB = tokenAmountB * priceB;
  return {
    fairPrice: ((Math.sqrt(valueA) * Math.sqrt(valueB)) / lpSupply) * 2,
    virtualPrice: (valueA + valueB) / lpSupply,
  };
};

export const getMint = async (connection: Connection, key: any) => {
  try {
    const info = await connection.getAccountInfo(new PublicKey(key));
    return MintLayout.decode(info.data);
  } catch {
    return null;
  }
};

export const shortenizeAddress = (address: PublicKey | string) => {
  const LEN = 5;
  const str_addr = address.toString();
  return str_addr.slice(0, LEN) + '....' + str_addr.slice(-LEN);
};

/**
 * Get a random number between a min and a max
 * @param {number} min
 * @param {number} max
 * @returns {any}
 */
// const randomInteger = (min: number, max: number) => {
//   return Math.floor(Math.random() * (max - min + 1)) + min;
// };

export const isWalletApproveError = (e: any) => {
  return e && (e.code === 4001 || e.code === -32603);
};

export const getTokenIcon = (tokenName) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const imgAsset = require(`../assets/images/tokens/${tokenName}.png`).default;
    return imgAsset;
  } catch (e) {
    console.log(e);
    return null;
  }
};
export const getDateTimeStr = (num: number) => {
  if (num === 0) {
    return '';
  }
  const date = new Date(num * 1000);
  return (
    date.getHours() +
    ':' +
    date.getMinutes() +
    ':' +
    date.getSeconds() +
    ' ' +
    date.getDate() +
    '/' +
    (date.getMonth() + 1) +
    '/' +
    date.getFullYear()
  );
};

export const getDateStr = (num: number) => {
  if (num === 0) {
    return '';
  }
  const date = new Date(num * 1000);
  return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
};

export const toUiAmount = (inputNumber: number | string, numDecimals: number) => {
  return +new TokenAmount(inputNumber, numDecimals).fixed();
};
