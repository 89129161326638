import React, { useState, useMemo } from 'react';
import infoIcon from '../../assets/images/risklevel.svg';
import { useFetchTokens } from '../../hooks/useFetchTokens';
import { FetchingStatus } from '../../types/fetching-types';
type props = {
  tokenData: any;
};

const SwimVaultInfoPopover = ({ tokenData }: props) => {
  const { tokens, status } = useFetchTokens();
  const [tooltipStatus, setTooltipStatus] = useState(0);

  const tokenList = useMemo(() => {
    if (tokens.length === 0 || status !== FetchingStatus.Finish) {
      return [];
    }
    const mint_address_arr = tokenData.item.assets.map((item) => ({ id: item.mint }));
    const r = tokens.filter((elem: any) => mint_address_arr.find(({ id }) => elem.address_id === id));

    return r.map(({ symbol, icon }) => {
      return {
        label: symbol,
        icon: icon,
      };
    });
    // return tokens.filter(({ symbol, icon }) => {
    //   return {
    //     value: symbol,
    //     label: symbol,
    //     network: null, // For the moment we leave it as null, since we will have to integrate the rest of the platforms later on
    //     icon: [icon],
    //   };
    // });
  }, [tokens, status]);

  return (
    <div
      className="relative mt-0 md:mt-0"
      onMouseEnter={() => setTooltipStatus(1)}
      onMouseLeave={() => setTooltipStatus(0)}
    >
      <div className="mr-2 cursor-pointer">
        <img src={infoIcon} alt="info" />
      </div>
      {tooltipStatus === 1 && (
        <div
          role="tooltip"
          className="absolute left-0 z-20 px-3 py-2 ml-8 -mt-12 transition duration-150 ease-in-out shadow-lg w-44 dark:bg-gray-800 bg-slate-50 rounded-xl"
        >
          <svg
            className="absolute top-0 bottom-0 left-0 h-20 -ml-2 dark:fill-gray-900 fill-slate-50"
            width="9px"
            height="16px"
            viewBox="0 0 9 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
              <g
                id="Tooltips-"
                transform="translate(-874.000000, -1029.000000)"
                className="dark:fill-gray-800 fill-slate-50"
              >
                <g id="Group-3-Copy-16" transform="translate(850.000000, 975.000000)">
                  <g id="Group-2" transform="translate(24.000000, 0.000000)">
                    <polygon
                      id="Triangle"
                      transform="translate(4.500000, 62.000000) rotate(-90.000000) translate(-4.500000, -62.000000) "
                      points="4.5 57.5 12.5 66.5 -3.5 66.5"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <div>
            {tokenList.map((item, index) => {
              return (
                <div key={index} className="flex items-center gap-2 py-1.5">
                  <img src={item.icon} alt={item.label} className="w-7 " />
                  <p className="text-gray-200 font-poppins dark:text-slate-50">{item.label}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}{' '}
    </div>
  );
};

export default SwimVaultInfoPopover;
