import classNames from 'classnames';
import React from 'react';

type props = {
  changeAmounts?: (value) => void;
  amounts: number;
  hasMax?: boolean;
  tokenName?: string;
  onClickMax?: any;
  loading?: boolean;
};

const TokenAmounts = ({ changeAmounts, amounts, hasMax, tokenName = 'SOL', onClickMax, loading }: props) => {
  return (
    <div className="flex justify-between rounded-lg">
      <input
        value={loading ? 'Loading ...' : amounts}
        onChange={(e) => changeAmounts(e.target.value)}
        readOnly={!hasMax}
        className={classNames(
          'w-full px-3 py-2.5 text-lg border-t dark:bg-gray-800 bg-white-900 border-b border-l border-r  border-white-400 font-poppins font-medium focus:outline-none text-gray-200 dark:text-white-900 dark:border-gray-200 ',
          { 'rounded-bl-md rounded-tl-md': hasMax, 'rounded-md': !hasMax }
        )}
      />
      {hasMax && (
        <button
          onClick={() => onClickMax()}
          className="w-40 text-sm font-medium text-white rounded-tr-md rounded-br-md bg-gradient-to-br from-blue-200 via-blue-300 to-blue-400 font-poppins hover:opacity-90"
        >
          Wallet Max
        </button>
      )}
    </div>
  );
};

export default TokenAmounts;
