import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { WalletReducer } from './features/wallet';
import { DashboardReducer } from './features/dashboard';
import { AdminpanelReducer } from './features/adminpanel';
import { InstaSwapReducer } from './features/instaswap';

import { adminpanelSaga } from './features/adminpanel';
import { instaswapSaga } from './features/instaswap';

/* Create root reducer, containing all features of the application */

function* rootSaga() {
  yield all([adminpanelSaga(), instaswapSaga()]);
}
const rootReducer = combineReducers({
  wallet: WalletReducer,
  dashboard: DashboardReducer,
  adminpanel: AdminpanelReducer,
  instaswap: InstaSwapReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const middleware = [];
const sagaMiddleware = createSagaMiddleware();

middleware.push(sagaMiddleware);

const persistedReducer = persistReducer(persistConfig, rootReducer);
const enhancers = [applyMiddleware(...middleware)];

const initialState = {};

// const store = createStore(persistedReducer, /* preloadedState, */ devToolsEnhancer({}));

export const store = createStore(persistedReducer, initialState, composeWithDevTools(...enhancers));
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
