import { LPair, PoolProvider } from '../../types/VaultTypes';
import { IPoolManagerStrategy } from './IPoolManagerStrategy';
import { RaydiumPoolManager } from './raydium/RaydiumPoolManager';

// import { MercurialPoolManager } from './MercurialPoolManager';
// import { OrcaPoolManager } from './OrcaPoolManager';
// import { RaydiumPoolManager } from './RaydiumPoolManager';
import { SaberPoolManager } from './saber/SaberPoolManager';
import { SwimPoolManager } from './swim/SwimPoolManager';
import { KaminoPoolManager } from './kamino/KaminoPoolManager';

// Allows to obtain the specific provider related to the vault's platform.
export class PoolManagerFactory {
  private static instance: PoolManagerFactory;
  private static providers: {
    [Key in PoolProvider as string]: IPoolManagerStrategy;
  };
  private constructor() {
    PoolManagerFactory.providers = {
      [PoolProvider.RAYDIUM]: new RaydiumPoolManager(),
      [PoolProvider.SABER]: new SaberPoolManager(),
      [PoolProvider.SWIM]: new SwimPoolManager(),
      [PoolProvider.KAMINO]: new KaminoPoolManager(),
      // [PoolProvider.ORCA] : new OrcaPoolManager(_orcaPools),
      // [PoolProvider.MERCURIAL]: new MercurialPoolManager(_mercurialPools),
    };
  }
  getProviderForVault(vault: LPair): IPoolManagerStrategy {
    return PoolManagerFactory.providers[vault?.platform_name];
  }
  static getPoolManager = () => {
    if (!PoolManagerFactory.instance) {
      PoolManagerFactory.instance = new PoolManagerFactory();
    }
    return PoolManagerFactory.instance;
  };
}
