import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { formatUSD } from '../../utils/utils';
import { TokenAmount } from '../../utils/safe-math';
import { isSwimPool, hasInstaLp } from '../../utils/helper';

import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { useAccountByMint } from '../../contexts/accounts';
import { useRFStateInfo, usePoolInfo } from '../../contexts/state';

import linkIcon from '../../assets/images/link.svg';
import instaBuyIcon from '../../assets/images/insta-buy-icon.svg';

import { selectors } from '../../features/dashboard';
import VaultSetupContainer from '../../components/VaultSetupContainer';
import PriceCard from '../../components/Dashboard/PriceCard';
import WalletBalances from '../../components/Dashboard/AmountPanel/WalletBalances';
import RiskLevel from '../../components/Dashboard/RiskLevel';
import { USDR_MINT_KEY, USDR_MINT_DECIMALS } from '../../constants';
// import LiquidationBuff from '../../components/Dashboard/LiquidationBuff';

const VaultSetup = () => {
  const { mint: vault_mint } = useParams<{ mint?: string }>();

  const { connection } = useConnection();
  const wallet = useWallet();
  const poolInfo = usePoolInfo(vault_mint as string);

  const collAccount = useAccountByMint(vault_mint as string);
  const globalState = useRFStateInfo();
  const usdrAccount = useAccountByMint(USDR_MINT_KEY);

  const allVaults = useSelector(selectors.getAllVaults);
  const [vaultData, setVaultData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [lpWalletBalance, setLpWalletBalance] = useState(0);
  const [lpWalletBalanceUSD, setLpWalletBalanceUSD] = useState(0);
  const [usdrWalletBalance, setUsdrWalletBalance] = useState(0);
  const [depositValue, setDepositValue] = useState(0);

  useEffect(() => {
    if (wallet && wallet.publicKey && poolInfo && collAccount) {
      const tokenAmount = new TokenAmount(collAccount.info.amount + '', poolInfo?.mintDecimals);
      setLpWalletBalance(Number(tokenAmount.fixed()));
    }
    return () => {
      setLpWalletBalance(0);
    };
  }, [wallet, collAccount, connection, poolInfo]);

  useEffect(() => {
    if (wallet && wallet.publicKey && usdrAccount) {
      const tokenAmount = new TokenAmount(usdrAccount.info.amount + '', USDR_MINT_DECIMALS);
      setUsdrWalletBalance(Number(tokenAmount.fixed()));
    }
    return () => {
      setUsdrWalletBalance(0);
    };
  }, [wallet, usdrAccount, connection]);

  useEffect(() => {
    if (poolInfo && globalState) {
      //ternary operators are used here while the globalState paramters do not exist

      const tvlLimit = globalState?.tvlCollatCeilingUsd ? globalState?.tvlCollatCeilingUsd.toNumber() : 0;
      const tvl = globalState?.tvlUsd ? globalState?.tvlUsd.toNumber() : 0;
      const availableTVL = tvlLimit - tvl;
      //set the max amount of depositable LP to be equal to either the amount of lp the user holds, or the global limit
      const tmpMaxDeposit = Math.min(availableTVL, lpWalletBalance).toFixed(poolInfo?.mintDecimals);
      setDepositValue(Number(tmpMaxDeposit));
      setLpWalletBalanceUSD(poolInfo.currentPrice * lpWalletBalance);
    }
    return () => {
      // setDepositValue(0);
    };
  }, [lpWalletBalance, poolInfo, globalState]);

  useEffect(() => {
    setIsLoading(true);
    const result: any = allVaults.find((item: any) => item.mint === vault_mint);
    if (result) {
      setVaultData(result);
      setIsLoading(false);
    }
  }, [allVaults]);

  if (isLoading || !poolInfo)
    return (
      <div className="col allvaults__loading">
        <div className="spinner-border text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <div className="mx-6 mt-4 2xl:mx-20 xl:mx-10 min-h-[60vh] mb-20 dark:bg-gray-900 bg-white-900">
      <div>
        {/* <div className="pl-3">
          <Breadcrumb vaultData={vaultData} availableVaults={allVaults} />
        </div> */}
        <div>
          <p className="text-3xl font-medium text-gray-200 font-poppins dark:text-white-900">
            Open {vaultData.title === 'USDC-USDR' ? 'USDC-USDr' : vaultData.title} Vault
          </p>
          <div className="flex items-center justify-between mt-1">
            <div className="space-y-1 md:items-center md:flex">
              <div className="flex gap-2">
                <p className="text-sm font-normal text-gray-100 font-poppins dark:text-white-900">Platform:</p>
                {hasInstaLp(vaultData.title) && vaultData.platform && (
                  <a
                    href={vaultData.platform.link}
                    target="_blank"
                    rel="noreferrer"
                    className="relative flex gap-1 text-gray-200"
                  >
                    <img src={vaultData.platform.icon} alt="platform" className="w-4" />
                    <p className="text-sm font-semibold font-poppins dark:text-white-900">{vaultData.platform.name}</p>
                    <img src={linkIcon} alt="linkIcon" className="absolute top-1 -right-2" />
                  </a>
                )}
                {!hasInstaLp(vaultData.title) && vaultData.platform && (
                  <Link
                    to={{
                      pathname: '/app/instaswap',
                      state: { platform: vaultData.platform.name, token: vaultData.title },
                    }}
                    className="relative flex gap-1 text-gray-200"
                  >
                    <img src={vaultData.platform.icon} alt="platform" className="w-4" />
                    <p className="text-sm font-semibold font-poppins dark:text-white-900">{vaultData.platform.name}</p>
                    <img src={linkIcon} alt="linkIcon" className="absolute top-1 -right-2" />
                  </Link>
                )}
              </div>
              <p className="hidden ml-5 mr-3 font-medium text-gray-100 dark:text-white-900 md:block">|</p>
              <div className="flex gap-2">
                <p className="text-sm font-normal text-gray-100 font-poppins dark:text-white-900">APR:</p>
                <p className="text-sm font-semibold text-gray-200 font-poppins dark:text-white-900">
                  {Number(vaultData?.apr + vaultData?.ratioAPY).toFixed(2)}%
                </p>
              </div>
              <p className="hidden mx-3 font-medium text-gray-100 dark:text-white-900 md:block">|</p>
              <div className="flex gap-2">
                <p className="text-sm font-normal text-gray-100 font-poppins dark:text-white-900">TVL:</p>
                <p className="text-sm font-semibold text-gray-200 font-poppins dark:text-white-900">
                  {formatUSD.format(vaultData.tvl)}
                </p>
              </div>
            </div>
            <RiskLevel level={vaultData.risk} isSinglePool={!isSwimPool(vaultData?.platform?.symbol)} />
          </div>
        </div>
        <div className="grid gap-12 mt-4 xl:grid-cols-3 lg:grid-cols-1">
          <div className="grid gap-6 xl:grid-cols-2 xl:col-span-2 lg:grid-cols-2">
            <div className="space-y-6">
              <PriceCard
                price={{ currentPrice: poolInfo ? poolInfo.currentPrice : '0' }}
                tokenName={vaultData?.title}
                risk={poolInfo ? poolInfo.ratio : 0}
              />
              {/* <LiquidationBuff /> */}
            </div>

            <div className="border shadow-sm border-slate-300 rounded-2xl dark:border-gray-600 h-fit">
              <WalletBalances
                mintAddress={vault_mint}
                collAmount={lpWalletBalance}
                collAmountUSD={lpWalletBalanceUSD}
                icon={vaultData.icon}
                icons={vaultData.icons}
                tokenName={vaultData.title}
                usdrAmount={usdrWalletBalance}
                className="pt-6"
              />
              <div className="flex items-center justify-center gap-1 py-3 bg-gradient-to-r dark:from-gray-200 from-orange-200/60 to-orange-200/30 dark:to-gray-200/30 font-poppins underline-offset-4 rounded-bl-2xl rounded-br-2xl">
                <a
                  href="#"
                  rel="noreferrer"
                  className="flex gap-2 text-sm font-normal text-orange-500 underline cursor-pointer hover:opacity-80"
                >
                  <img src={instaBuyIcon} alt="instabuy" className="w-4 h-4" />
                  Need More USDC-USDr?
                </a>
              </div>
            </div>
          </div>
          <div className="">
            {vaultData && (
              <VaultSetupContainer
                data={{
                  mint: vault_mint,
                  icon: vaultData.icon,
                  page_url: vaultData.item?.page_url,
                  icons: vaultData.icons,
                  title: vaultData.title,
                  value: depositValue,
                  usdrMint: USDR_MINT_KEY,
                  risk: vaultData?.risk,
                  platform: vaultData?.platform?.name?.toLowerCase(),
                  tokenPrice: poolInfo?.oraclePrice,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VaultSetup;
