import clsx from 'clsx';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
// import IconButton from '../IconButton';
// import { useTheme } from './providers/DialectThemeProvider';
import { HiOutlineX, HiCheck, HiXCircle } from 'react-icons/hi';

interface ToastMessageProps {
  message?: string | JSX.Element;
  isSuccess?: boolean;
  isError?: boolean;
  onClose?: () => void;
}

// TODO: support stacking multiple toasts

const animations = {
  popup: {
    enter: 'dt-transition-opacity dt-duration-300',
    enterFrom: 'dt-opacity-0',
    enterTo: 'dt-opacity-100',
    leave: 'dt-transition-opacity dt-duration-100',
    leaveFrom: 'dt-opacity-100',
    leaveTo: 'dt-opacity-0',
  },
  // Uses `react-transition-group
  bottomSlide: {
    enter: 'dt-transition-transform dt-duration-300 dt-ease-in-out',
    enterFrom: 'dt-translate-y-[calc(100%-3.5rem)]',
    enterTo: '!dt-translate-y-0',
    leave: 'dt-transition-transform dt-duration-100 dt-ease-in-out',
    leaveFrom: 'dt-translate-y-0',
    leaveTo: '!dt-translate-y-[calc(100%-3.5rem)]',
  },
  toast: {
    enter: 'dt-transition dt-ease-in-out dt-duration-150',
    enterFrom: 'dt-opacity-0 dt-translate-y-[calc(100%+1rem)]',
    enterTo: 'dt-opacity-100 !dt-translate-y-0',
    leave: 'dt-transition dt-ease-in-out dt-duration-150',
    leaveFrom: 'dt-opacity-100 dt-translate-y-0',
    leaveTo: 'dt-opacity-0 !dt-translate-y-[calc(100%+1rem)]',
  },
};

function ToastMessage({ message, isSuccess, isError, onClose }: ToastMessageProps) {
  const [hide, setHide] = useState(false);
  //   const { icons, toast } = useTheme();

  let icon = null;
  if (isSuccess) {
    icon = <HiCheck className="dt-w-5 dt-h-5 dt-shrink-0" />;
  }
  if (isError) {
    icon = <HiXCircle className="dt-w-5 dt-h-5 dt-text-red-500 dt-shrink-0" />;
  }
  const timeout = 150;

  return (
    <CSSTransition
      in={Boolean(message) && !hide}
      classNames={{
        enter: animations.toast.enterFrom,
        enterActive: clsx(animations.toast.enter, animations.toast.enterTo),
        enterDone: clsx(animations.toast.enterTo, 'dt-pointer-events-auto'),
        exit: animations.toast.leaveFrom,
        exitActive: clsx(animations.toast.leave, animations.toast.leaveTo),
        exitDone: clsx(animations.toast.leaveTo, 'dt-pointer-events-none'),
      }}
      timeout={timeout}
    >
      <div
        className={clsx(
          'dt-pointer-events-none dt-flex dt-justify-center dt-fixed dt-bottom-4 dt-left-0 dt-right-0 dt-mx-auto',
          animations.toast.enterFrom
        )}
      >
        <div
          className={clsx(
            'dt-border dt-rounded-lg dt-border-subtle-day dt-px-4 dt-py-2 bg-white-900 shadow-md',
            'dt-max-w-[100vw-2rem] dt-mx-2 dt-flex dt-items-center dt-space-between'
          )}
        >
          <div className="text-sm text-gray-200 dt-flex dt-items-center dt-space-x-2 font-poppins">
            {icon}
            <span>{message}</span>
          </div>
          {onClose ? (
            <HiOutlineX
              className=" dt-text-current dt-ml-4 -dt-mr-2"
              onClick={() => {
                setHide(true);
                setTimeout(() => {
                  onClose();
                  setHide(false);
                }, timeout);
              }}
            />
          ) : null}
        </div>
      </div>
    </CSSTransition>
  );
}

export default ToastMessage;
