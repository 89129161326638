import classNames from 'classnames';
import React, { ReactNode } from 'react';

type props = {
  children: ReactNode;
  className?: string;
};

const ValueBox = ({ children, className }: props) => {
  return (
    <div
      className={classNames(
        'border-white-500 border-t border-l border-b border-r bg-white-700 rounded-xl dark:border-gray-600 dark:bg-slate-800',
        className
      )}
    >
      {children}
    </div>
  );
};

export default ValueBox;
