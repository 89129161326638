import React, { useState, Fragment } from 'react';
import classNames from 'classnames';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon, SearchIcon } from '@heroicons/react/solid';
// import { BiSearch } from 'react-icons/bi';
// import Select, { components } from 'react-select';

interface Option {
  value: string;
  label: string;
  network: Maybe<string>;
  icon: Array<any>;
}

type props = {
  options: Array<Option>;
  onFilterChange: (value: any) => void;
  filterValue: Array<Option>;
  isMulti?: boolean;
  placeholder: string;
};

const TokenSelect = ({ options, onFilterChange, filterValue, placeholder }: props) => {
  const [query, setQuery] = useState('');

  const filteredToken =
    query === ''
      ? options
      : options.filter((token) => {
          return token.label.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div className={classNames('w-full', { 'md:mb-10 mb-2': filterValue.length > 0 })}>
      <Combobox value={filterValue} onChange={onFilterChange} multiple>
        <div className="relative w-full mt-1">
          <div
            className={classNames(
              'relative w-full pl-4 overflow-hidden text-left border-t border-b border-l border-r rounded-lg cursor-default bg-white-900 dark:bg-slate-800 dark:border-gray-600 border-white-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'
            )}
          >
            <div className="absolute top-2 left-2">
              <SearchIcon className="w-6 h-6 ml-0 text-slate-500 dark:text-white-200" />
            </div>
            <Combobox.Input
              className="w-full py-2.5 pl-5 pr-10 text-sm font-medium leading-5 text-gray-200 dark:text-white-900 !bg-white-900 dark:!bg-slate-800 border-none font-poppins focus:ring-0 rounded-xl"
              displayValue={(token: any) => {
                return token[0]?.label;
              }}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon className="w-5 h-5 text-gray-200 dark:text-white-900" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg bg-white-900 dark:bg-slate-800 max-h-44 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredToken.length === 0 && query !== '' ? (
                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">Nothing found.</div>
              ) : (
                filteredToken.map((token: any, index: number) => {
                  return (
                    <Combobox.Option
                      key={index}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-white-600 dark:bg-slate-400' : 'text-gray-900 dark:text-white-900'
                        }`
                      }
                      value={token}
                    >
                      {({ selected, active }) => {
                        return (
                          <>
                            <div
                              className={`flex items-center gap-2 font-poppins text-gray-200 dark:text-white-900 truncate ${
                                selected ? 'font-semibold' : 'font-medium'
                              }`}
                            >
                              <img src={token.icon[0]} alt={token.label} className="rounded-full w-7 h-7" />
                              {token.label}
                            </div>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-teal-600' : 'text-teal-600'
                                }`}
                              >
                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        );
                      }}
                    </Combobox.Option>
                  );
                })
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default TokenSelect;
