import classNames from 'classnames';
import { NavBarProgressBar, ProgressBarLabelType } from './NavBarProgressBar';
// import { useRFStateInfo } from '../../contexts/state';
import { TokenAmount } from '../../utils/safe-math';
import { usePoolInfo } from '../../contexts/state';
import { USDR_MINT_DECIMALS } from '../../constants';
import { CachedPoolInfo } from '../../types/VaultTypes';

interface ProgressBarVaultUSDrProps {
  className?: string;
  shouldDisplayLabel?: boolean;
  shouldDisplayCurrency?: boolean;
  mint: string;
  cachedPoolInfo?: CachedPoolInfo;
}

export const ProgressBarVaultUSDr = (data: ProgressBarVaultUSDrProps) => {
  const { className, shouldDisplayLabel = true, shouldDisplayCurrency, cachedPoolInfo } = data;
  // const globalState = useRFStateInfo();
  // const vaultState = useUserVaultInfo(data.mint);
  const poolInfo = usePoolInfo(data.mint);
  let currentValue: number, limit: number;
  if (cachedPoolInfo) {
    currentValue = +new TokenAmount(cachedPoolInfo.total_debt ?? 0, USDR_MINT_DECIMALS).fixed();
    limit = Number(new TokenAmount(cachedPoolInfo.debt_ceiling ?? 1, USDR_MINT_DECIMALS).fixed());
  } else {
    currentValue = +new TokenAmount((poolInfo as any)?.totalDebt ?? 0, USDR_MINT_DECIMALS).fixed();
    limit = Number(new TokenAmount(poolInfo?.debtCeiling ?? 1, USDR_MINT_DECIMALS).fixed());
  }
  const percentage = (currentValue * 100) / limit;
  const success = percentage <= 80;
  const caution = percentage < 100 && percentage > 80;
  const warning = percentage >= 100;

  const label = shouldDisplayLabel ? ProgressBarLabelType.USDr : ProgressBarLabelType.None;

  return (
    <NavBarProgressBar
      className={classNames(
        className,
        { 'navbarprogressbar--warning': warning && !caution && !success },
        { 'navbarprogressbar--caution': caution && !warning && !success },
        { 'navbarprogressbar--success': success && !caution && !warning }
      )}
      label={label}
      shouldDisplayCurrency={shouldDisplayCurrency}
      currentValue={currentValue}
      percentage={percentage}
    />
  );
};
