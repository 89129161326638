import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { SOLANA_CLUSTER } from '../../../constants';
import linkIcon from '../../../assets/images/link.svg';
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  formatTxHistory,
  getInterestUpdateTxHistory,
  makeRatioApiEndpointTxHistory,
} from '../../../hooks/useFetchRatioApi';
import { FormattedTX } from '../../../types/transaction-types';
import { useUserOverview } from '../../../contexts/state';

const VaultHistoryTable = ({ mintAddress }: any) => {
  const { publicKey } = useWallet();
  const wallet = publicKey?.toString();

  const [lastTen, setLastTen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState<any>();
  const overview = useUserOverview();

  useEffect(() => {
    let cancelRequest = false;
    const fetchData = async () => {
      setLoading(true);
      const headers: { [k: string]: any } = {
        'Content-Type': 'application/json',
      };
      const response = await fetch(makeRatioApiEndpointTxHistory(wallet, mintAddress), {
        headers: headers,
        method: 'GET',
      });
      const interestxResponse = await fetch(getInterestUpdateTxHistory(wallet, mintAddress), {
        headers: headers,
        method: 'GET',
      });
      if (response.ok) {
        const rawTransactions = await response.json();
        if (interestxResponse.ok) {
          const interestTransactions = await interestxResponse.json();
          rawTransactions.push(...interestTransactions);
        }
        const d: any = rawTransactions.sort(function (a: any, b: any) {
          const c: any = new Date(a.created_on);
          const d: any = new Date(b.created_on);
          return d - c;
        });
        const formattedTxData = formatTxHistory(d, SOLANA_CLUSTER);
        if (lastTen) {
          setHistoryData(formattedTxData.slice(0, 10));
        } else {
          setHistoryData(formattedTxData);
        }
        // setHistoryData(formattedTxData);
        if (cancelRequest) return;
        setLoading(false);
      } else {
        setLoading(false);
        if (cancelRequest) return;
        setLoading(false);
      }
    };

    // call the function
    fetchData();
    return () => {
      cancelRequest = true;
    };
  }, [overview, lastTen]);

  const onClickReadMore = () => {
    setLastTen(!lastTen);
  };

  return (
    <div className="relative w-full overflow-x-auto ">
      <div className="flex items-end justify-between">
        <p className="text-lg font-medium text-gray-200 font-poppins dark:text-white-900">Vault History</p>
        <p
          onClick={onClickReadMore}
          className="text-sm text-gray-200 cursor-pointer dark:text-white-900 font-poppins hover:opacity-80"
        >
          {lastTen ? 'Read more' : 'Read less'}
        </p>
      </div>
      <div className="!border border-white-500 dark:border-gray-600 rounded-xl shadow-sm mt-3 overflow-x-auto">
        <table className="w-full mt-4 lg:min-w-full min-w-max ">
          <thead>
            <tr>
              <th className="pl-6 text-sm font-normal text-gray-100 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                No
              </th>
              <th className="pl-6 text-sm font-normal text-gray-100 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                Date
              </th>
              <th className="pl-6 text-sm font-normal text-gray-100 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                Type
              </th>
              {/* <th>LP price</th> */}
              <th className="pl-6 text-sm font-normal text-gray-100 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                Amount
              </th>
              <th className="pl-6 text-sm font-normal text-gray-100 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                Status
              </th>
              <th className="pr-6 text-sm font-normal text-right text-gray-100 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
                Tx Signature
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={7} className="text-center">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : historyData && historyData?.length > 0 ? (
              historyData?.map((tx: FormattedTX, index: number) => {
                return (
                  <tr key={index}>
                    <td className="py-[15px] font-poppins font-medium text-gray-200 dark:text-white-900 pl-6 text-sm border-t border-white-500 dark:border-gray-600">
                      {index + 1}
                    </td>
                    <td className="gap-3 py-[15px] font-poppins font-medium text-gray-200 dark:text-white-900 pl-6 text-sm border-t border-white-500 dark:border-gray-600">
                      {tx?.date}
                    </td>
                    <td className="py-[15px] font-poppins font-medium text-gray-200 dark:text-white-900 pl-6 text-sm border-t border-white-500 dark:border-gray-600">
                      {tx?.txType}
                    </td>
                    {/* <td className="activity">{tx?.market_price}</td> */}
                    <td className="py-[15px] font-poppins font-medium text-gray-200 dark:text-white-900 pl-6 text-sm border-t border-white-500 dark:border-gray-600">
                      {(Math.ceil(tx?.amount * 10000) / 10000).toFixed(4)}
                    </td>
                    <td className="py-[15px] font-poppins font-medium text-gray-200 dark:text-white-900 pl-6 text-sm border-t border-white-500 dark:border-gray-600">
                      {tx?.status}
                    </td>
                    <td className="py-[15px] font-poppins font-medium text-gray-200 dark:text-white-900 pr-6 text-sm border-t border-white-500 dark:border-gray-600 ">
                      <a
                        className="flex justify-end text-gray-200 dark:text-white-900"
                        target="_blank"
                        rel="noreferrer"
                        href={tx?.txExplorerUrl}
                      >
                        {`${tx?.txSignature?.slice(0, 4)}...`}
                        <img src={linkIcon} alt="linkIcon" />
                      </a>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  <h6>There&apos;s no transaction history</h6>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VaultHistoryTable;
