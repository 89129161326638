import { LPEditionData } from '../../../types/VaultTypes';
import { Modal, Button, Row } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import VaultEditionForm from '../VaultEditionForm';
import AdminFormInput from '../../../components/AdminFormInput';
import { useEffect, useMemo, useState } from 'react';
import {
  fundRatioRewards,
  setPoolBorrowFee,
  setPoolDebtCeiling,
  setPoolDepositFee,
  setPoolHarvestFee,
  syncGlobalDebt,
} from '../../../utils/ratio-lending-admin';
import { IIndexable } from '../../../types/admin-types';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';

import { Connection, PublicKey } from '@solana/web3.js';
import { calculateAPRFromFundAmount, calculateFundAmountFromAPR } from '../../../utils/ratio-lending';
import { API_ENDPOINT, RATIO_MINT_DECIMALS, RATIO_MINT_KEY, USDR_MINT_DECIMALS } from '../../../constants';
import { toast } from 'react-toastify';
import { getDateStr } from '../../../utils/utils';
import { useAllPoolInfo, useOracleInfo, usePoolInfo, useRFStateInfo } from '../../../contexts/state';
import { WalletAdapter } from '../../../contexts/wallet';
import BN from 'bn.js';
import { useAuthContextProvider } from '../../../contexts/authAPI';
interface VaultEditionModalProps {
  show: boolean;
  close: () => void;
  vault: Maybe<LPEditionData>;
}

interface Fees {
  borrow_fee_num: number;
  harvest_fee_num: number;
  deposit_fee_num: number;
}
// Tracks if a certain field on Fees has been changed.
interface FeesChanged {
  borrow_fee_num: boolean;
  harvest_fee_num: boolean;
  deposit_fee_num: boolean;
}

const defaultFeeValues: Fees = {
  borrow_fee_num: 0,
  harvest_fee_num: 0,
  deposit_fee_num: 0,
};
const defaultFeeValuesTrackers: FeesChanged = {
  borrow_fee_num: false,
  harvest_fee_num: false,
  deposit_fee_num: false,
};

export default function VaultEditionModal({ show, close, vault }: VaultEditionModalProps) {
  const { connection } = useConnection();
  const wallet = useWallet();

  const FeeContractUpdatersMap = {
    borrow_fee_num: async (connection: Connection, wallet: WalletAdapter, data: Fees) =>
      await setPoolBorrowFee(connection, wallet, Number(feeData?.borrow_fee_num), new PublicKey(vault?.address_id)),
    harvest_fee_num: async (connection: Connection, wallet: WalletAdapter, data: Fees) =>
      await setPoolHarvestFee(connection, wallet, Number(feeData?.harvest_fee_num), new PublicKey(vault?.address_id)),
    deposit_fee_num: async (connection: Connection, wallet: WalletAdapter, data: Fees) =>
      await setPoolDepositFee(connection, wallet, Number(feeData?.borrow_fee_num), new PublicKey(vault?.address_id)),
  };

  const globalInfo = useRFStateInfo();
  const poolInfo = usePoolInfo(vault?.address_id);
  const ratioPrice = useOracleInfo(RATIO_MINT_KEY);

  const [poolDebtCeilingValue, setPoolDebtCeilingValue] = useState(0);
  const [poolDebtValue, setPoolDebtValue] = useState(0);
  const [globalDebtValue, setGlobalDebtValue] = useState(0);
  const [ratioRewardsDuration, setRatioRewardsDuration] = useState(0);
  const [lastRewardFundStart, setLastRewardFundStart] = useState('');
  const [lastRewardFundEnd, setLastRewardFundEnd] = useState('');
  const [ratioRewardsAmount, setRatioRewardsAmount] = useState(0);
  const [ratioRewardAPR, setRatioRewardAPY] = useState(0);
  const [feeData, setFeeData] = useState<Fees>(defaultFeeValues);
  const [feeChangedTracker, setFeeChangedTracker] = useState<FeesChanged>(defaultFeeValuesTrackers);
  const { accessToken } = useAuthContextProvider();

  const usdrAmountMinted = +((poolInfo ? poolInfo.totalDebt.toNumber() : 0) / 10 ** USDR_MINT_DECIMALS).toFixed(
    USDR_MINT_DECIMALS
  );
  const [cvtApy2Amount, setCvtApy2Amount] = useState(false);

  const pools = useAllPoolInfo();
  const [totalUSDrDebt, setTotalUSDrDebt] = useState(0);
  const usdrSupply = +(
    new BN(globalInfo?.usdrMintInfo ? globalInfo?.usdrMintInfo.supply : 0).toNumber() /
    10 ** USDR_MINT_DECIMALS
  ).toFixed(USDR_MINT_DECIMALS);
  const expectedPoolDebt = useMemo(
    () => +(usdrSupply - totalUSDrDebt).toFixed(USDR_MINT_DECIMALS),
    [usdrSupply, totalUSDrDebt]
  );

  const vaultValues: LPEditionData = {
    address_id: vault?.address_id ?? '',
    vault_address_id: vault?.vault_address_id ?? null,
    page_url: vault?.page_url ?? null,
    icon: vault?.icon ?? null,
    platform_id: vault?.platform_id ?? null,
    platform_symbol: vault?.platform_symbol ?? null,
    pool_size: vault?.pool_size ?? null,
    symbol: vault?.symbol ?? null,
    collateralization_ratio: vault?.collateralization_ratio ?? null,
    liquidation_ratio: vault?.liquidation_ratio ?? null,
    borrow_fee_num: vault?.borrow_fee_num ?? null,
    harvest_fee_num: vault?.harvest_fee_num ?? null,
    deposit_fee_num: vault?.deposit_fee_num ?? null,
    risk_rating: vault?.risk_rating,
    reward_mint: vault?.reward_mint,
    assets: vault?.assets,
  };
  useEffect(() => {
    if (poolInfo) {
      const debtCeiling = poolInfo.debtCeiling.toNumber() / 10 ** USDR_MINT_DECIMALS;
      setPoolDebtCeilingValue(debtCeiling);
      const newLastRewardFundStart = poolInfo.lastRewardFundStart.toNumber();
      const newLastRewardFundEnd = poolInfo.lastRewardFundEnd.toNumber();
      const newLastRewardFundAmount = poolInfo.lastRewardFundAmount.toNumber();
      const days = Math.round(((newLastRewardFundEnd - newLastRewardFundStart) / (3600 * 24)) * 100) / 100;
      setRatioRewardsDuration(days);
      setLastRewardFundStart(getDateStr(newLastRewardFundStart));
      setLastRewardFundEnd(getDateStr(newLastRewardFundEnd));
      setRatioRewardsAmount(Math.round(newLastRewardFundAmount / 10 ** RATIO_MINT_DECIMALS));
    }
    if (globalInfo) {
      console.log(vault);
      setFeeData((prev) => {
        return {
          ...prev,
          borrow_fee_num: (poolInfo?.borrowFeeNumer.toNumber() * 100) / globalInfo?.feeDeno.toNumber(),
          harvest_fee_num: (poolInfo?.harvestFeeNumer.toNumber() * 100) / globalInfo?.feeDeno.toNumber(),
          deposit_fee_num: (poolInfo?.depositFeeNumer.toNumber() * 100) / globalInfo?.feeDeno.toNumber(),
        };
      });
    }
  }, [vault]);

  useEffect(() => {
    if (pools && poolInfo && vault) {
      let totalDebt = 0;
      Object.keys(pools)
        .filter((mint) => mint !== vault?.address_id)
        .map((mint) => pools[mint].totalDebt)
        .forEach((debt) => {
          totalDebt += debt.toNumber();
        });
      setTotalUSDrDebt(+(totalDebt / 10 ** USDR_MINT_DECIMALS).toFixed(USDR_MINT_DECIMALS));
    }
  }, [pools, poolInfo, vault]);

  useEffect(() => {
    if (cvtApy2Amount) {
      const amount = calculateFundAmountFromAPR(
        usdrAmountMinted,
        ratioRewardAPR / 100,
        ratioRewardsDuration,
        ratioPrice
      );
      setRatioRewardsAmount(amount);
    } else {
      const apr = calculateAPRFromFundAmount(usdrAmountMinted, ratioRewardsAmount, ratioRewardsDuration, ratioPrice);
      setRatioRewardAPY(apr * 100);
    }
  }, [cvtApy2Amount, usdrAmountMinted, ratioRewardsDuration, ratioRewardAPR, ratioRewardsAmount]);

  const handlePoolDebtCelilingChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: any = event.target.value ?? 0;
    setPoolDebtCeilingValue(value);
  };
  const handlePoolDebtChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: any = event.target.value ?? 0;
    setPoolDebtValue(value);
  };
  const handleGlobalDebtChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: any = event.target.value ?? 0;
    setGlobalDebtValue(value);
  };
  const handleRatioRewardAmount = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: any = event.target.value ?? 0;
    setRatioRewardsAmount(value);
  };
  const handleRatioRewardAPR = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: any = event.target.value ?? 0;
    console.log(value);
    setRatioRewardAPY(value);
  };
  const handleRatioRewardsDuration = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: any = event.target.value ?? 0;
    setRatioRewardsDuration(value);
  };
  const handleAPR2Amount = () => {
    setCvtApy2Amount((prev) => !prev);
  };
  const savePoolDebtCeiling = async () => {
    try {
      if (vaultValues.address_id) {
        await setPoolDebtCeiling(connection, wallet, poolDebtCeilingValue, new PublicKey(vaultValues.address_id));
        toast.success('PoolDebtCeiling has been saved successfully!');
      } else {
        toast.error('collateral mint address is not defined!');
      }
    } catch (e) {
      toast.error('Setting PoolDebtCeiling has been failed!');
    }
  };
  const synbPoolDebt = async () => {
    try {
      if (vaultValues.vault_address_id) {
        await syncGlobalDebt(
          connection,
          wallet,
          new PublicKey(vaultValues.vault_address_id),
          poolDebtValue,
          globalDebtValue
        );
        toast.success('PoolDebtCeiling has been saved successfully!');
      } else {
        toast.error('collateral mint address is not defined!');
      }
    } catch (e) {
      toast.error('Setting PoolDebtCeiling has been failed!');
    }
  };
  const fundRewards = async () => {
    try {
      if (vaultValues.address_id && ratioRewardsAmount > 0 && ratioRewardsDuration > 0) {
        await fundRatioRewards(
          connection,
          wallet,
          new PublicKey(vaultValues.vault_address_id),
          ratioRewardsAmount,
          ratioRewardsDuration
        );
        toast.success('has been funded successfully!');
      } else {
        toast.error('input amount and duration!');
      }
    } catch (e) {
      toast.error('Funding Ratio rewards has been failed!');
    }
  };
  /// Fees
  const handleFeeChange = (event: any) => {
    setFeeData((values) => ({
      ...values,
      [event.target.name]: event.target.value ?? 0,
    }));
    setFeeChangedTracker((values) => ({
      ...values,
      [event.target.name]: true,
    }));
  };
  const parseJsonResponse = (result: any): any => {
    if (Array.isArray(result)) {
      let ratios: Fees = defaultFeeValues;
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        ratios = {
          ...ratios,
          [Object.keys(element)[0]]: Object.values(element)[0],
        };
      }
      return ratios;
    }
    return result;
  };
  const updateFeeContractValues = async () => {
    await Promise.all(
      Object.keys(feeData)
        .filter((item) => (feeChangedTracker as IIndexable)[item])
        .map(async (item) => {
          await (FeeContractUpdatersMap as IIndexable)[item](connection, wallet, feeData);
        })
    );
  };
  const updateFeeDatabaseValues = async () => {
    const response = await fetch(`${API_ENDPOINT}/lpairs/${vault?.address_id}/fees`, {
      body: JSON.stringify(feeData),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      method: 'POST',
    });
    if (!response.ok) {
      toast.error('An error ocurred while saving the fees');
      throw await response.json();
    }
    setFeeData(parseJsonResponse(await response.json()));
  };
  const handleFeeSubmit = async (evt: any) => {
    evt.preventDefault();
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.stopPropagation();
      return;
    }
    try {
      await updateFeeContractValues();
      await updateFeeDatabaseValues();
      setFeeChangedTracker(defaultFeeValuesTrackers);
      toast.info('Fees saved successfully');
    } catch (error: unknown) {
      toast.error('There was an error when saving the values');
      throw error;
    }
    return;
  };
  return (
    <Modal
      show={show}
      onHide={() => close()}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="w-100 mw-100"
      centered
      className="dashboardModal__modal"
      data-theme="light"
    >
      <Modal.Header>
        <div className="dashboardModal__modal__header">
          <IoMdClose size={32} className="dashboardModal__modal__header-close" onClick={() => close()} />
          <h5>Edit {vault?.symbol} Lending Pool</h5>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="dashboardModal__modal__body">
          <VaultEditionForm values={vaultValues} onSave={() => close()} showFees={false} />
          <AdminFormInput
            handleChange={handlePoolDebtCelilingChange}
            label="Pool Debt Ceiling"
            name="poolDebtCeilingValue"
            value={poolDebtCeilingValue}
          />
          <Button onClick={savePoolDebtCeiling}>Set Debt Ceiling</Button>
          <AdminFormInput
            handleChange={handleGlobalDebtChange}
            label="Global Debt(USDr Supply as Default)"
            name="globalDebtValue"
            value={globalDebtValue}
          />
          <h4>{`Total Debt without this pool is ${totalUSDrDebt}, USDr Supply is ${usdrSupply}`}</h4>
          <h4>{`Total Debt of this pool is ${usdrAmountMinted}`}</h4>
          <AdminFormInput
            handleChange={handlePoolDebtChange}
            label={`Expected Pool Debt: ${expectedPoolDebt}`}
            name="poolDebtValue"
            value={poolDebtValue}
          />
          <Button onClick={synbPoolDebt}>Synchronize Debt</Button>
        </div>
        <div className="dashboardModal__modal__body">
          <h5>RATIO Emission Rate Calculator</h5>
          <AdminFormInput
            handleChange={handleRatioRewardsDuration}
            label="RATIO Rewards duration (days)"
            name="ratioRewardsDuration"
            value={ratioRewardsDuration}
          />
          <h5>
            {lastRewardFundStart} - {lastRewardFundEnd}
          </h5>
          {cvtApy2Amount ? (
            <AdminFormInput
              handleChange={handleRatioRewardAPR}
              label="APR(%)"
              name="ratioRewardAPR"
              value={ratioRewardAPR}
            />
          ) : (
            <AdminFormInput
              handleChange={handleRatioRewardAmount}
              label="RATIO Rewards amount"
              name="ratioRewardsAmount"
              value={ratioRewardsAmount}
            />
          )}
          <Button onClick={handleAPR2Amount}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="inherit">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.13797 7.80436L4.99989 9.66692H0.333008V5.00004L2.19557 6.86196L8.33301 0.723877L9.27613 1.667L3.13797 7.80436ZM13.6668 4.33316V9.00004L11.8042 7.13812L5.66677 13.2762L4.72365 12.3331L10.8617 6.19564L8.99981 4.33308L13.6668 4.33316Z"
                fill="inherit"
              ></path>
            </svg>
          </Button>
          {cvtApy2Amount ? (
            <AdminFormInput
              handleChange={handleRatioRewardAmount}
              label="RATIO Rewards amount"
              name="ratioRewardsAmount"
              value={ratioRewardsAmount}
            />
          ) : (
            <AdminFormInput
              handleChange={handleRatioRewardAPR}
              label="APR(%)"
              name="ratioRewardAPR"
              value={ratioRewardAPR}
            />
          )}
          <Button onClick={fundRewards}>Fund RATIO rewards</Button>
          <h5>Pool Fees Config</h5>
          <Row className="mb-3">
            <AdminFormInput
              handleChange={handleFeeChange}
              label="Borrow"
              name="borrow_fee_num"
              value={feeData?.borrow_fee_num}
            />
            <AdminFormInput
              handleChange={handleFeeChange}
              label="Deposit"
              name="deposit_fee_num"
              value={feeData?.deposit_fee_num}
            />
            <AdminFormInput
              yetNotImplemented={true}
              handleChange={handleFeeChange}
              label="Payback"
              name="payback_fee_num"
              value="0"
            />
            <AdminFormInput
              yetNotImplemented={true}
              handleChange={handleFeeChange}
              label="Stake"
              name="stake_fee_num"
              value="0"
            />
            <AdminFormInput
              yetNotImplemented={true}
              handleChange={handleFeeChange}
              label="Withdraw"
              name="withdraw_fee_num"
              value="0"
            />
            <AdminFormInput
              handleChange={handleFeeChange}
              label="Harvest"
              name="harvest_fee_num"
              value={feeData?.harvest_fee_num}
            />
          </Row>
          <Button onClick={handleFeeSubmit}>Save</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
