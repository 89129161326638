import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../../components/Header';
import AdminNavbar from '../../components/AdminNavbar';
import { ThemeContext } from '../../contexts/ThemeContext';
import { actionTypes } from '../../features/wallet';
import { ToastContainer } from 'react-toastify';
import { useRFStateInfo } from '../../contexts/state';
import { createGlobalState } from '../../utils/ratio-lending-admin';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';

import Button from '../../components/Button';

export default function AdminFormLayout(props: any) {
  const theme = useContext(ThemeContext);
  const { darkMode } = theme.state;
  const [menuOpen, setMenuOpen] = useState(false);
  const [collapseFlag, setCollapseFlag] = useState(false);

  const { connection } = useConnection();
  const wallet = useWallet();

  const dispatch = useDispatch();
  const globalState = useRFStateInfo();

  const onClickWalletBtn = () => {
    dispatch({ type: actionTypes.CONNECTED_WALLET });
  };

  const clickMenuTrigger = () => {
    setMenuOpen(!menuOpen);
  };

  const onCollapseMenu = () => {
    setCollapseFlag(!collapseFlag);
  };
  const onCreateProgramState = async () => {
    await createGlobalState(connection, wallet);
  };
  return (
    <div className="admin_form_page" data-theme={darkMode ? 'dark' : 'light'}>
      <div className="admin_form_page_container">
        <Header onClickWalletBtn={onClickWalletBtn} darkMode={darkMode} />
        {!globalState || Object.keys(globalState).length === 0 ? (
          <div className="global-state-not-found-container">
            <p>Global state was not found, it needs to be created in order to create vaults.</p>
            <Button className="mt-4 button--fill" onClick={onCreateProgramState}>
              Create Program State
            </Button>
          </div>
        ) : (
          <>
            <AdminNavbar
              darkMode={darkMode}
              clickMenuItem={clickMenuTrigger}
              open={menuOpen}
              collapseFlag={collapseFlag}
              setCollapseFlag={onCollapseMenu}
            />
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
            <div className="admin_form_page_content">{props.children}</div>
          </>
        )}
      </div>
    </div>
  );
}
