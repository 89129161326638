import React from 'react';

import telegram from '../../../assets/images/telegram.svg';
import twitter from '../../../assets/images/twitter.svg';
import medium from '../../../assets/images/medium.svg';
import telegramDark from '../../../assets/images/telegram-dark.svg';
import twitterDark from '../../../assets/images/twitter-dark.svg';
import mediumDark from '../../../assets/images/medium-dark.svg';

type props = {
  darkMode: boolean;
};

const UpToDate = ({ darkMode }: props) => {
  return (
    <div>
      <p className="text-lg font-medium text-gray-200 font-poppins dark:text-white-900">Stay Up to date</p>
      <div className="p-4 mt-3 space-y-2 text-center border rounded-lg border-white-500 dark:border-gray-600 bg-gradient-to-tr from-[#D48EC5]/20 via-[#FFC2AC]/20 to-[#FFF5C7]/20 dark:from-[#D48EC5]/40 dark:via-[#FFC2AC]/40 dark:to-[#FFF5C7]/40">
        <p className="text-sm text-center text-gray-200 dark:text-white-900">Keep up to date with all things Ratio</p>
        <div className="flex items-center justify-center gap-3">
          <a
            target="_blank"
            href="https://t.me/ratiofinance"
            rel="noreferrer"
            className="flex items-center justify-center w-10 h-10 border-t border-b border-l border-r rounded-md border-white-500 hover:opacity-80 dark:border-white-800"
          >
            <img src={darkMode ? telegramDark : telegram} alt="telegram" />
          </a>
          <a
            target="_blank"
            href="https://twitter.com/ratiofinance"
            rel="noreferrer"
            className="flex items-center justify-center w-10 h-10 border-t border-b border-l border-r rounded-md border-white-500 hover:opacity-80 dark:border-white-800"
          >
            <img src={darkMode ? twitterDark : twitter} alt="twitter" />
          </a>
          <a
            target="_blank"
            href="https://medium.com/@ratiofinance"
            rel="noreferrer"
            className="flex items-center justify-center w-10 h-10 border-t border-b border-l border-r rounded-md border-white-500 hover:opacity-80 dark:border-white-800"
          >
            <img src={darkMode ? mediumDark : medium} alt="medium" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default UpToDate;
