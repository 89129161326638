import React, { useState } from 'react';
import classNames from 'classnames';
import { usePoolInfo } from '../../../contexts/state';
import { Link } from 'react-router-dom';

// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import { formatUSD } from '../../../utils/utils';
import { USDR_MINT_DECIMALS } from '../../../constants';
import { TokenAmount } from '../../../utils/safe-math';
import { isSwimPool } from '../../../utils/helper';

import highRisk from '../../../assets/images/highrisk.svg';
import smallRatio from '../../../assets/images/smallRatio.svg';
import { ERiskLevel } from '../../../types/VaultTypes';
import linkIcon from '../../../assets/images/link.svg';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
// import USDrIcon from '../../../assets/images/USDr.svg';
// import infoIcon from '../../../assets/images/risklevel.svg';

const VaultDetails = ({ vaultData, mint, price }: any) => {
  const [showDetails, setShowDetails] = useState(false);
  const poolInfo = usePoolInfo(mint);
  const currentValue = Number(new TokenAmount(poolInfo?.totalDebt ?? 0, USDR_MINT_DECIMALS).fixed());
  const limit = Number(new TokenAmount(poolInfo?.debtCeiling ?? 1, USDR_MINT_DECIMALS).fixed());
  const percentage = (currentValue * 100) / limit;

  const success = percentage <= 80;
  const caution = percentage < 100 && percentage > 80;
  const warning = percentage >= 100;

  function isEmptyObject(obj) {
    return JSON.stringify(obj) === '{}';
  }

  const printApy = () => {
    if (isNaN(vaultData?.apr)) {
      return <LoadingSpinner className="spinner-border-sm text-info" />;
    }
    return Number(vaultData?.apr).toFixed(2) + '%';
  };

  const printTvl = () => {
    if (isNaN(vaultData.tvl) || vaultData.tvl === 0) {
      return <LoadingSpinner className="spinner-border-sm text-info" />;
    }
    return formatUSD.format(vaultData.tvl);
  };

  if (isEmptyObject(vaultData)) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <p className="mb-2 text-lg font-medium text-gray-200 font-poppins dark:text-white-900">About this Vault</p>
      <div
        className={classNames(
          '!border border-slate-300 rounded-2xl relative dark:border-gray-600 shadow-sm',
          { 'border-violet-300 dark:border-violet-300': status === 'warning' },
          { 'border-red-500 dark:border-red-500': status === 'danger' }
        )}
      >
        <div className="px-6 pt-6 space-y-2">
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">Platform:</p>
            <Link
              to={{
                pathname: '/app/instaswap',
                state: { platform: vaultData?.platform.name, token: vaultData?.platform.title },
              }}
            >
              <div className="relative flex items-center gap-1 mt-1">
                <img src={vaultData?.platform.icon} className="w-4" />
                <p className="text-sm font-semibold text-slate-700 font-poppins dark:text-white-900">
                  {vaultData?.platform.name}
                </p>
                <img src={linkIcon} alt="linkIcon" className="absolute top-0 -right-2" />
              </div>
            </Link>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">Minted USDr:</p>
            <div className="flex items-center justify-end gap-3 text-sm font-semibold font-poppins">
              <p
                className={classNames({
                  'text-green-500': success,
                  'text-amber-500': caution,
                  'text-red-500': warning,
                })}
              >
                {currentValue.toLocaleString('en-US', { maximumFractionDigits: 2 })}
              </p>
              <p
                className={classNames({
                  'text-green-500': success,
                  'text-amber-500': caution,
                  'text-red-500': warning,
                })}
              >
                {percentage.toFixed(2)}%
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">
              Collaterallization Debt Ratio:
            </p>
            <p className="text-sm font-semibold text-slate-700 font-poppins dark:text-white-900">
              {(100 / poolInfo?.ratio).toFixed(2)}%
            </p>
          </div>
          <div className={classNames('flex items-center justify-between', { 'pb-3': !showDetails })}>
            <div className="flex items-center gap-1 text-sm font-medium text-slate-600 font-poppins dark:text-white-900">
              <img src={smallRatio} alt="smallRatio" className="w-3 h-3" />
              Risk Rating
            </div>
            <div
              className={classNames(
                'flex items-center gap-1 text-sm font-semibold text-slate-700 font-poppins dark:text-white-900',
                {
                  'text-green-500': success,
                  'text-amber-500': caution,
                  'text-red-500': warning,
                }
              )}
            >
              {(vaultData.risk === ERiskLevel.EXTREME || vaultData.risk === ERiskLevel.HIGH) && (
                <img src={highRisk} alt="highriskIcon" className="w-4" />
              )}
              {!isSwimPool(vaultData?.platform?.symbol) && <p>{vaultData.risk}</p>}
            </div>
          </div>

          {showDetails && (
            <>
              {vaultData.ratioAPY !== 0 && (
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">
                    Mint Rewards APR:
                  </p>
                  <p className="text-sm font-semibold text-slate-700 font-poppins dark:text-white-900">
                    {Number(vaultData?.ratioAPY).toFixed(2)} %
                  </p>
                </div>
              )}
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">TVL:</p>
                <p className="text-sm font-semibold text-slate-700 font-poppins dark:text-white-900">{printTvl()}</p>
              </div>
              {!isSwimPool(vaultData?.platform?.symbol) && (
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">Yield Farm APR:</p>
                  <p className="text-sm font-semibold text-slate-700 font-poppins dark:text-white-900">{printApy()}</p>
                </div>
              )}
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">
                  {vaultData.title === 'USDC-USDR' ? 'USDC-USDr' : vaultData.title} LP Token Price:
                </p>
                <p className="text-sm font-semibold text-slate-700 font-poppins dark:text-white-900">
                  {formatUSD.format(+price?.currentPrice ?? 0)}
                </p>
              </div>
            </>
          )}
          <div
            onClick={() => setShowDetails(!showDetails)}
            className="py-[16px] -mx-6 text-center bg-slate-100 cursor-pointer rounded-bl-2xl rounded-br-2xl dark:bg-slate-800 dark:text-white-900 hover:bg-slate-100/90 border-t border-white-500 font-poppins text-sm text-slate-600 flex items-center gap-1 justify-center"
          >
            More details {!showDetails ? <IoIosArrowDown className="w-4" /> : <IoIosArrowUp className="w-4" />}
          </div>
          {/* <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">APR:</p>
            <div className="flex items-center gap-1">
              <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
                {Number(vaultData?.apr + vaultData?.ratioAPY).toFixed(2)}%
              </p>
              <div>
                {vaultData.ratioAPY === 0 && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 100 }}
                    overlay={
                      <Tooltip id="tooltip">
                        <div className="font-medium font-poppins">
                          <p className="mb-2 ">
                            <strong>APR</strong> is made up of:
                          </p>
                          <div className="flex items-center gap-2 mb-2">
                            <img src={USDrIcon} alt="USDrIcon" className="w-5 h-5" /> Mint APR:{' '}
                            {Number(vaultData?.ratioAPY).toFixed(2)}%
                          </div>
                          {!isSwimPool(vaultData?.platform?.symbol) && (
                            <div className="flex items-center gap-2 ">
                              <img src={vaultData.platform.icon} alt="USDrIcon" className="w-5 h-5" /> Yield Farming:{' '}
                              {printApy()}
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    }
                  >
                    <div className="cursor-pointer hover:opacity-80">
                      <img src={infoIcon} alt="infoIcon" />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default VaultDetails;
