import * as anchor from '@project-serum/anchor';

import { DEFAULT_PROGRAMS, depositCollateralTx, distributeRewardTx, withdrawCollateralTx } from '../../ratio-lending';
import {
  PublicKey,
  Transaction,
  Connection,
  // SignatureResult
} from '@solana/web3.js';
import {
  findMinerAddress,
  findMinterAddress,
  findQuarryAddress,
  QuarrySDK,
  QUARRY_ADDRESSES,
} from '@quarryprotocol/quarry-sdk';
import { Token as SToken } from '@saberhq/token-utils';
import { getProgramInstance, sendTransaction } from '../../rf-web3';

import { TokenAmount } from '../../safe-math';
import { getATAKey, getGlobalStatePDA, getPda, getPoolPDA, getVaultPDA } from '../../ratio-pda';
import {
  SABER_IOU_MINT,
  SBR_REWARDER,
  SBR_MINT_WRAPPER,
  SBR_ADDRESS,
  SBR_MINT,
  SABER_ADDRESSES,
  SABER_REDEEMER_KEY,
  MINT_PROXY_STATE,
  MINT_PROXY_AUTHORITY,
} from '@saberhq/saber-periphery';

import { createAssociatedTokenAccountInstruction } from '@solana/spl-token-v2';

import { publicKey, u64, u128, struct, u8, u16 } from '@project-serum/borsh';

export const QUARRY_INFO_LAYOUT = struct([
  u64('descriminator'),
  publicKey('rewarderKey'),
  publicKey('tokenMintKey'),
  u8('bump'),
  u16('index'),
  u8('tokenMintDecimals'),
  u64('famineTs'),
  u64('lastUpdateTs'),
  u128('rewardsPerTokenStored'),
  u64('annualRewardsRate'),
  u64('rewardsShare'),
  u64('totalTokensDeposited'),
  u64('numMiners'),
]);

export const SABER_IOU_MINT_DECIMALS = 6;
export const SBR_REWARDER_FEE_CLAIMER = '4Snkea6wv3K6qzDTdyJiF2VTiLPmCoyHJCzAdkdTStBK';
export async function deposit(
  connection: Connection,
  wallet: any,

  mintCollKey: PublicKey,

  amount: number
): Promise<string> {
  console.log('Deposit to Saber', amount);

  const transaction = new Transaction();

  const tx1: any = await depositCollateralTx(connection, wallet, amount, mintCollKey);
  transaction.add(tx1);

  const tx2 = await createSaberQuarryMinerIfneededTx(connection, wallet, mintCollKey);
  if (tx2) {
    transaction.add(tx2);
  }

  const tx3 = await stakeCollateralToSaberTx(amount, connection, wallet, mintCollKey);
  if (tx3) {
    transaction.add(tx3);
  }
  const txHash = await sendTransaction(connection, wallet, transaction);

  console.log('Saber deposit tx', txHash);
  return txHash.toString();
}

export async function withdraw(connection: Connection, wallet: any, mintCollKey: PublicKey, amount: number) {
  console.log('Withdraw from Saber', amount);

  const tx1 = new Transaction();

  const ix1 = await unstakeColalteralFromSaberTx(connection, wallet, amount, mintCollKey);
  if (ix1) {
    tx1.add(ix1);
  }

  const ix2 = await withdrawCollateralTx(connection, wallet, amount, mintCollKey);
  if (ix2) {
    tx1.add(ix2);
  }
  const txHash = await sendTransaction(connection, wallet, tx1);

  return txHash;
}

export async function withdrawIx(connection: Connection, wallet: any, mintCollKey: PublicKey, amount: number) {
  console.log('Withdraw from Saber', amount);

  const tx1 = new Transaction();

  const ix1 = await unstakeColalteralFromSaberTx(connection, wallet, amount, mintCollKey);
  if (ix1) {
    tx1.add(ix1);
  }

  const ix2 = await withdrawCollateralTx(connection, wallet, amount, mintCollKey);
  if (ix2) {
    tx1.add(ix2);
  }

  return tx1;
}

export async function harvest(connection: Connection, wallet: any, mintCollKey: PublicKey, needTx = false) {
  console.log('Harvest from Saber');

  const transaction = new Transaction();

  const tx1 = await harvestRewardsFromSaberTx(connection, wallet, mintCollKey);
  if (tx1) {
    transaction.add(tx1);
  }

  const tx2 = await distributeRewardTx(connection, wallet, mintCollKey);
  if (tx2) {
    transaction.add(tx2);
  }

  if (!needTx) {
    const txHash = await sendTransaction(connection, wallet, transaction);

    return txHash;
  }
  return transaction;
}

export const getQuarryInfo = async (connection: Connection, mintCollKey: PublicKey) => {
  const [quarryKey] = await findQuarryAddress(SBR_REWARDER, mintCollKey, QUARRY_ADDRESSES.Mine);
  const quarryInfo = await connection.getAccountInfo(quarryKey);
  const parsedData = QUARRY_INFO_LAYOUT.decode(quarryInfo?.data);
  return parsedData;
};

export const createSaberQuarryMinerIfneededTx = async (connection: Connection, wallet: any, mintCollKey: PublicKey) => {
  const program = getProgramInstance(connection, wallet);

  const vaultKey = getVaultPDA(wallet.publicKey, mintCollKey);
  const transaction = new Transaction();

  const sdk: QuarrySDK = QuarrySDK.load({
    provider: program.provider as any,
  });
  const rewarder = await sdk.mine.loadRewarderWrapper(SBR_REWARDER);

  //the decimal is not important here
  const poolMintToken = SToken.fromMint(mintCollKey, 6);
  const quarry = await rewarder.getQuarry(poolMintToken);

  const miner = await quarry.getMiner(vaultKey);

  if (!miner) {
    console.log('creating quarry miner');
    const poolKey = getPoolPDA(mintCollKey);

    const [minerKey, minerBump] = await findMinerAddress(quarry.key, vaultKey, QUARRY_ADDRESSES.Mine);
    const ataCollatMinerKey = getATAKey(minerKey, mintCollKey);
    transaction.add(
      program.instruction.createSaberQuarryMiner(minerBump, {
        accounts: {
          authority: wallet.publicKey,
          pool: poolKey,
          vault: vaultKey,
          miner: minerKey,
          ataCollatMiner: ataCollatMinerKey,
          quarry: quarry.key,
          rewarder: SBR_REWARDER,
          mintCollat: mintCollKey,
          quarryProgram: QUARRY_ADDRESSES.Mine,
          ...DEFAULT_PROGRAMS,
        },
      })
    );
    console.log('creating iou reward vault');

    const ataIouRewardVaultKey = getATAKey(vaultKey, SABER_IOU_MINT);

    transaction.add(
      createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        ataIouRewardVaultKey,
        vaultKey,
        new PublicKey(SABER_IOU_MINT)
      )
    );
  }
  return transaction;
};

export const stakeCollateralToSaberTx = async (
  amountToStake: number,
  userConnection: Connection,
  wallet: any,
  mintCollKey: PublicKey
) => {
  const program = getProgramInstance(userConnection, wallet);

  const poolKey = getPoolPDA(mintCollKey);
  const vaultKey = getVaultPDA(wallet.publicKey, mintCollKey);

  const ataCollatVault = getATAKey(vaultKey, mintCollKey);
  const [quarryKey] = await findQuarryAddress(SBR_REWARDER, mintCollKey, QUARRY_ADDRESSES.Mine);

  const [minerKey] = await findMinerAddress(quarryKey, vaultKey, QUARRY_ADDRESSES.Mine);
  const ataCollatMiner = getATAKey(minerKey, mintCollKey);

  console.log('staking to saber');

  const txn = new Transaction().add(
    program.instruction.stakeCollateralToSaber(new anchor.BN(amountToStake), {
      accounts: {
        authority: wallet.publicKey,
        pool: poolKey,
        vault: vaultKey,
        ataCollatVault: ataCollatVault,
        ataCollatMiner: ataCollatMiner,
        quarry: quarryKey,
        miner: minerKey,
        rewarder: SBR_REWARDER,
        quarryProgram: QUARRY_ADDRESSES.Mine,
        ...DEFAULT_PROGRAMS,
      },
    })
  );
  return txn;
};

export const unstakeColalteralFromSaberTx = async (
  connection: Connection,
  wallet: any,
  unstakeAmount: number,
  mintCollKey: PublicKey,
  user: PublicKey | string = wallet.publicKey
) => {
  const program = getProgramInstance(connection, wallet);

  const globalStateKey = getGlobalStatePDA();
  const poolKey = getPoolPDA(mintCollKey);
  const vaultKey = getVaultPDA(user, mintCollKey);

  const ataCollatVault = getATAKey(vaultKey, mintCollKey);
  const [quarryKey] = await findQuarryAddress(SBR_REWARDER, mintCollKey, QUARRY_ADDRESSES.Mine);

  const [minerKey] = await findMinerAddress(quarryKey, vaultKey, QUARRY_ADDRESSES.Mine);
  const ataCollatMiner = getATAKey(minerKey, mintCollKey);

  const transaction = new Transaction();
  const tokenAmount = +(await connection.getTokenAccountBalance(ataCollatVault)).value.amount;
  if (tokenAmount < unstakeAmount) {
    unstakeAmount -= tokenAmount;
    transaction.add(
      program.instruction.unstakeCollateralFromSaber(new anchor.BN(unstakeAmount), {
        accounts: {
          authority: wallet.publicKey,
          globalState: globalStateKey,
          pool: poolKey,
          vault: vaultKey,
          ataCollatVault: ataCollatVault,
          ataCollatMiner: ataCollatMiner,
          quarry: quarryKey,
          miner: minerKey,
          rewarder: SBR_REWARDER,
          quarryProgram: QUARRY_ADDRESSES.Mine,
          ...DEFAULT_PROGRAMS,
        },
      })
    );
  }
  return transaction;
};

export const harvestRewardsFromSaberTx = async (
  connection: Connection,
  wallet: any,
  mintCollKey: PublicKey,
  user: PublicKey | string = wallet.publicKey
) => {
  const program = getProgramInstance(connection, wallet);
  const [minter] = await findMinterAddress(SBR_MINT_WRAPPER, SBR_REWARDER, QUARRY_ADDRESSES.MintWrapper);
  const globalStateKey = getGlobalStatePDA();
  const poolKey = getPoolPDA(mintCollKey);
  const vaultKey = getVaultPDA(user, mintCollKey);

  const ataCollatVaultKey = getATAKey(vaultKey, mintCollKey);
  const [quarryKey] = await findQuarryAddress(SBR_REWARDER, mintCollKey, QUARRY_ADDRESSES.Mine);

  const [minerKey] = await findMinerAddress(quarryKey, vaultKey, QUARRY_ADDRESSES.Mine);
  const ataCollatMinerKey = getATAKey(minerKey, mintCollKey);

  const ataRewardVaultKey = getATAKey(vaultKey, SBR_MINT);
  const ataIouRewardVaultKey = getATAKey(vaultKey, SABER_IOU_MINT);

  const redemptionVaultKey = getATAKey(SABER_REDEEMER_KEY, SBR_ADDRESS);

  const [saberMinterInfo] = getPda([Buffer.from('anchor'), SABER_REDEEMER_KEY.toBuffer()], SABER_ADDRESSES.MintProxy);
  const stateInfo = await program.account.globalState.fetch(globalStateKey);
  const ataTreasuryReward = getATAKey(stateInfo.treasury, SABER_IOU_MINT);

  const tx = new Transaction();
  if (!(await connection.getAccountInfo(ataRewardVaultKey))) {
    tx.add(
      createAssociatedTokenAccountInstruction(wallet.publicKey, ataRewardVaultKey, vaultKey, new PublicKey(SBR_ADDRESS))
    );
  }
  if (!(await connection.getAccountInfo(ataIouRewardVaultKey))) {
    tx.add(
      createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        ataIouRewardVaultKey,
        vaultKey,
        new PublicKey(SABER_IOU_MINT)
      )
    );
  }

  tx.add(
    program.instruction.harvestRewardsFromSaber({
      accounts: {
        globalState: globalStateKey,
        pool: poolKey,
        vault: vaultKey,
        ataRewardVault: ataIouRewardVaultKey,
        ataRatioTreasury: ataTreasuryReward,

        ataCollatMiner: ataCollatMinerKey,
        ataCollatVault: ataCollatVaultKey, // this is SBR for this example

        quarry: quarryKey,
        miner: minerKey,
        rewarder: SBR_REWARDER,
        mintWrapper: SBR_MINT_WRAPPER,
        mintWrapperProgram: QUARRY_ADDRESSES.MintWrapper,
        minter,
        claimFeeTokenAccount: SBR_REWARDER_FEE_CLAIMER, // is this a quarry-specific account

        mintReward: SABER_IOU_MINT, // SBR for this example

        quarryProgram: QUARRY_ADDRESSES.Mine,
        ...DEFAULT_PROGRAMS,
      },
    }),
    program.instruction.redeemIouTokens(null, {
      accounts: {
        vault: vaultKey,
        ataIouRewardVault: ataIouRewardVaultKey,
        ataRewardVault: ataRewardVaultKey,

        iouMintReward: SABER_IOU_MINT, // SBR for this example
        mintReward: SBR_MINT, // SBR for this example

        redeemer: SABER_REDEEMER_KEY,
        redemptionVault: redemptionVaultKey,
        mintProxyState: MINT_PROXY_STATE,
        proxyMintAuthority: MINT_PROXY_AUTHORITY,
        minterInfo: saberMinterInfo,

        redeemerProgram: SABER_ADDRESSES.Redeemer,
        mintProxyProgram: SABER_ADDRESSES.MintProxy,
        ...DEFAULT_PROGRAMS,
      },
    })
  );
  return tx;
};

export async function calculateSaberReward(
  connection: Connection,
  userKey: PublicKey | string,
  mintColl: PublicKey | string,
  cacheData: { quarry: any; miner: any } | null = null
): Promise<[number, any]> {
  try {
    const program = getProgramInstance(connection, null);
    const vaultKey = getVaultPDA(userKey, mintColl);

    const sdk: QuarrySDK = QuarrySDK.load({
      provider: program.provider as any,
    });

    if (!cacheData || !cacheData.quarry) {
      const rewarder = await sdk.mine.loadRewarderWrapper(SBR_REWARDER);

      //the mint decimals is not important
      const poolMintToken = SToken.fromMint(mintColl, 6);

      const quarry = await rewarder.getQuarry(poolMintToken);

      const miner = await quarry.getMiner(vaultKey);
      cacheData = { quarry, miner };
    }
    if (!cacheData.miner) {
      cacheData.miner = await cacheData.quarry.getMiner(vaultKey);
    }
    const { quarry, miner } = cacheData;
    const payroll = quarry.payroll;

    const currentTimeStamp = new anchor.BN(Math.ceil(new Date().getTime() / 1000));
    const expectedWagesEarned = miner
      ? (
          await payroll.calculateRewardsEarned(
            currentTimeStamp,
            miner.balance,
            miner.rewardsPerTokenPaid,
            miner.rewardsEarned
          )
        ).toNumber()
      : 0;
    return [parseFloat(new TokenAmount(expectedWagesEarned, SABER_IOU_MINT_DECIMALS).fixed()), cacheData];
  } catch (e) {
    console.log(e);
    return [0, null];
  }
}
