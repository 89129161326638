import React, { useState } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { AreaChart, Area, XAxis, YAxis, Scatter, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import disconnectWalletIcon from '../../../assets/images/disconnect-wallet-icon.svg';
import classNames from 'classnames';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const PortfolioOverTime = () => {
  const { connected } = useWallet();
  const [dateRange, setDateRange] = useState('week');

  const onChangeDateRange = (range) => {
    setDateRange(range);
  };

  if (!connected) {
    return (
      <div>
        <p className="text-lg font-medium text-gray-200 font-poppins dark:text-white-900">My Vaults</p>
        <div className="border-t border-b border-l border-r border-white-500 min-h-[200px] mt-2 rounded-xl flex items-center justify-center flex-col">
          <img src={disconnectWalletIcon} alt="disconnectWalletIcon" />
          <p className="mt-3 font-medium text-gray-200 font-poppins dark:text-white-900">
            Connect your wallet to view your active vaults
          </p>
        </div>
      </div>
    );
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="px-3 py-2 bg-black border-none outline-none rounded-xl outline-hidden ring-slate-300">
          <p className="font-normal text-white-900 font-poppins">25/3/2022</p>
          <p className="font-normal text-white-900 font-poppins">{`Yield Earned: $${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full px-[15px] pt-[15px] mt-2 border-t border-b border-l border-r md:px-8 md:pt-8 h-80 border-white-500 rounded-xl dark:border-gray-600 ">
      <div className="flex items-center justify-between mb-20">
        <div>
          <p className="text-base font-medium text-gray-200 font-poppins dark:text-white-900">Portfolio Over Time</p>
          <p className="text-sm font-normal text-gray-200 font-poppins dark:text-white-900">
            Yield Earned: <span className="font-semibold">$352,503.86</span>
          </p>
        </div>
        <div className="flex gap-3">
          <button
            className={classNames(
              'p-2 text-center text-sm font-semibold border-t-2 border-b-2 border-l-2 border-r-2 border-white-500 font-poppins text-gray-200 rounded-lg hover:bg-gray-50 hover:dark:bg-gray-600  dark:text-white-900',
              { 'border-blue-500': dateRange === 'week' }
            )}
            onClick={() => onChangeDateRange('week')}
          >
            1W
          </button>
          <button
            className={classNames(
              'p-2 text-center text-sm font-semibold border-t-2 border-b-2 border-l-2 border-r-2 border-white-500 font-poppins text-gray-200 rounded-lg hover:bg-gray-50 hover:dark:bg-gray-600 dark:text-white-900',
              { 'border-blue-500': dateRange === 'month' }
            )}
            onClick={() => onChangeDateRange('month')}
          >
            1M
          </button>
          <button
            className={classNames(
              'p-2 text-center text-sm font-semibold border-t-2 border-b-2 border-l-2 border-r-2 border-white-500 font-poppins text-gray-200 rounded-lg hover:bg-gray-50 hover:dark:bg-gray-600 dark:text-white-900',
              { 'border-blue-500': dateRange === 'all' }
            )}
            onClick={() => onChangeDateRange('all')}
          >
            All
          </button>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="50%">
        <AreaChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FF69CE" stopOpacity={0.3} />
              <stop offset="95%" stopColor="#FF69CE" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="linear"
            dataKey="uv"
            strokeWidth={3}
            fillOpacity={1}
            stroke="#FF69CE"
            fill="url(#colorUv)"
            activeDot={{ strokeWidth: 3, r: 8 }}
          />
          <Tooltip content={CustomTooltip} isAnimationActive={false} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PortfolioOverTime;
