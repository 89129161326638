import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import RiskLevel from '../../components/Dashboard/RiskLevel';
import VaultDebt from '../../components/Dashboard/VaultDebt';
import PriceCard from '../../components/Dashboard/PriceCard';
import ModalCard from '../../components/Dashboard/ModalCard';
import AmountPanel from '../../components/Dashboard/AmountPanel';
import LiquidationHealth from '../../components/Dashboard/LiquidationHealth';
import LiquidationBuff from '../../components/Dashboard/LiquidationBuff';
import VaultDetails from '../../components/Dashboard/VaultDetails';
import WalletBalances from '../../components/Dashboard/AmountPanel/WalletBalances';

import share from '../../assets/images/share.svg';
import usdrIcon from '../../assets/images/USDr.png';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { USDR_MINT_KEY, USDR_MINT_DECIMALS } from '../../constants';
import { useAccountByMint } from '../../contexts/accounts';
import { TokenAmount } from '../../utils/safe-math';
import { selectors } from '../../features/dashboard';

import Breadcrumb from '../../components/Breadcrumb';
import { useRFStateInfo, useUserVaultInfo, usePoolInfo } from '../../contexts/state';
import { DEFAULT_NETWORK } from '../../constants';
import VaultHistoryTable from '../../components/Dashboard/VaultHistoryTable';
import MintableProgressBar from '../../components/Dashboard/MintableProgressBar';
import TokenCapBanner from '../../components/TokenCapBanner';
import LiquidationBanner from '../../components/LiquidationBanner';
import { createIncrementalProgram } from 'typescript';
import { InProgressPage } from '../../utils/utils';

const LIQUIDATION_STATUS_LIMIT = 0.15;

const VaultDashboard = () => {
  const { mint: vault_mint } = useParams<{ mint: string }>();
  const { connection } = useConnection();
  const wallet = useWallet();

  const poolInfo = usePoolInfo(vault_mint as string);

  const collAccount = useAccountByMint(vault_mint as string);
  const usdrAccount = useAccountByMint(USDR_MINT_KEY);

  const userVaultInfo = useUserVaultInfo(vault_mint as string);
  const globalState = useRFStateInfo();
  const [vaultData, setVaultData] = useState<any>({});

  const [lpWalletBalance, setLpWalletBalance] = useState(0);
  const [lpWalletBalanceUSD, setLpWalletBalanceUSD] = useState(0);
  const [usdrWalletBalance, setUsdrWalletBalance] = useState(0);

  const [depositValue, setDepositValue] = useState(0);
  const [withdrawValue, setWithdrawValue] = useState(0);
  const generateValue = Math.max(
    +new TokenAmount(((userVaultInfo as any)?.mintableUSDr ?? 0) - 10, USDR_MINT_DECIMALS).fixed(),
    0
  ).toFixed(USDR_MINT_DECIMALS);
  const [debtValue, setDebtValue] = useState(0);
  const [debtInterestValue, setDebtInterestValue] = useState(0);
  const [interestAPY, setInterestAPY] = useState(0);

  const [activeVaults, setActiveVaults] = useState<any>();

  /// liquidationStatus is warning or danger
  const [liquidationStatus, setLiquidationStatus] = useState('');

  const allVaults = useSelector(selectors.getAllVaults);
  const overview = useSelector(selectors.getOverview);
  const liquidation_data = useSelector(selectors.getUserHealth);

  const [vaultDebtData, setVaultDebtData] = useState({
    mint: vault_mint,
    usdrMint: USDR_MINT_KEY,
    usdrValue: 0,
    userHealth: 0,
  });

  const solanaBeachUrl = useMemo(
    () => `https://solana.fm/address/${vault_mint}?cluster=${DEFAULT_NETWORK}`,
    [vault_mint]
  );

  useEffect(() => {
    const p = allVaults.filter((item: any) => item.activeStatus);
    setActiveVaults(p);
  }, [overview, allVaults]);

  useEffect(() => {
    if (wallet && wallet.publicKey && poolInfo && collAccount) {
      const tokenAmount = new TokenAmount(collAccount.info.amount + '', poolInfo?.mintDecimals);
      setLpWalletBalance(Number(tokenAmount.fixed()));
    }
    return () => {
      setLpWalletBalance(0);
    };
  }, [wallet, collAccount, connection, poolInfo]);

  useEffect(() => {
    if (wallet && wallet.publicKey && usdrAccount) {
      const tokenAmount = new TokenAmount(usdrAccount.info.amount + '', USDR_MINT_DECIMALS);
      setUsdrWalletBalance(Number(tokenAmount.fixed()));
    }
    return () => {
      setUsdrWalletBalance(0);
    };
  }, [wallet, usdrAccount, connection]);

  useEffect(() => {
    if (poolInfo && globalState && globalState?.tvlCollatCeilingUsd) {
      //ternary operators are used here while the globalState paramters do not exist

      const globalTvlLimit = globalState?.tvlCollatCeilingUsd.toNumber();
      const tvl = globalState?.tvlUsd.toNumber();
      const availableCollat = (globalTvlLimit - tvl) / poolInfo.oraclePrice;
      //set the max amount of depositable LP to be equal to either the amount of lp the user holds, or the global limit
      const tmpMaxDeposit = Math.min(availableCollat, lpWalletBalance).toFixed(poolInfo?.mintDecimals);
      setDepositValue(Number(tmpMaxDeposit));

      setLpWalletBalanceUSD(poolInfo.currentPrice * lpWalletBalance);
    }
    return () => {
      setDepositValue(0);
    };
  }, [lpWalletBalance, poolInfo, globalState]);

  useEffect(() => {
    if (userVaultInfo && poolInfo) {
      const tmpWithdrawValue = new TokenAmount((userVaultInfo as any).totalColl, poolInfo?.mintDecimals).fixed();
      setWithdrawValue(Number(tmpWithdrawValue));
    }
    return () => {
      setWithdrawValue(0);
    };
  }, [userVaultInfo, poolInfo]);

  useEffect(() => {
    if (userVaultInfo) {
      const debtValue = +new TokenAmount((userVaultInfo as any).debt, USDR_MINT_DECIMALS).fixed();
      setDebtValue(debtValue);
      setDebtInterestValue((userVaultInfo as any).uiDebtInterest);
      setInterestAPY((userVaultInfo as any).interestAPY);
      if (vault_mint) {
        setVaultDebtData({
          mint: vault_mint,
          usdrMint: USDR_MINT_KEY,
          usdrValue: debtValue,
          userHealth: userVaultInfo.userHealth,
        });
      }
      if (userVaultInfo.userHealth <= LIQUIDATION_STATUS_LIMIT) {
        setLiquidationStatus('warning');
      } else {
        setLiquidationStatus('');
      }
    }

    return () => {
      setVaultDebtData({
        mint: vault_mint,
        usdrMint: USDR_MINT_KEY,
        usdrValue: 0,
        userHealth: 0,
      });
      setLiquidationStatus('');
    };
  }, [userVaultInfo, vault_mint]);

  useEffect(() => {
    const result: any = allVaults.find((item: any) => item.mint === vault_mint);
    if (result) {
      setVaultData(result);
    }
  }, [allVaults, vault_mint]);

  const isMobile = useMediaQuery({ maxWidth: 1024 });
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  // const isDefault = useMediaQuery({ minWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1025 });

  if (!poolInfo)
    return (
      <div className="col allvaults__loading">
        <div className="spinner-border text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      {/* {liquidationStatus !== '' && <LiquidationBanner status={liquidationStatus} />} */}
      <TokenCapBanner mint={vault_mint as string} />
      <div className="dark:bg-gray-900 bg-white-900">
        <div className="border-t border-b border-white-500 dark:border-gray-600">
          <div className="py-6 xl:border-b-0 xl:border-r border-white-500 dark:border-gray-600">
            <div className="ml-6 xl:ml-14">
              {activeVaults && <Breadcrumb vaultData={vaultData} availableVaults={activeVaults} />}
            </div>
            <div className="flex items-start justify-between pr-6 ml-6 xl:ml-14">
              <p className="mt-2 text-2xl font-medium text-gray-200 md:text-3xl font-poppins dark:text-white-900">
                {vaultData.title === 'USDC-USDR' ? 'USDC-USDr' : vaultData.title} Vault
              </p>
              <a href={solanaBeachUrl} rel="noreferrer" target="_blank" className="flex flex-col items-end text-right">
                <img src={share} alt="share" className="w-3 h-3" />
                <p className="text-sm text-gray-100 font-poppins dark:text-white-900">
                  View on <br></br>Solana FM
                </p>
              </a>
            </div>
            {/* <div className="row align-items-center no-md-gutters">
                <div className="col-lg-auto col-md-4 col-sm-12">
                  <RiskLevel level={vaultData.risk} isSinglePool={!isSwimPool(vaultData?.platform?.symbol)} />
                </div>
                <div className="col-lg-auto col-md-4 col-sm-12">
                  <MintableProgressBar mint={vault_mint as string} />
                </div>
                <div className="col-lg-auto col-md-4 col-sm-12">
                  <VaultDebt data={vaultDebtData} />
                </div>
                <div className="ml-auto col-lg-auto col-md-12 vaultdashboard__header_rightBox col-md-2 col-sm-12">
                  {isDesktop && (
                    <div className="flex flex-col items-end justify-end mb-4">
                      <img src={share} alt="share" className="" />
                      <a href={solanaBeachUrl} rel="noopener noreferrer" target="_blank">
                        View on
                      </a>
                      <a href={solanaBeachUrl} rel="noopener noreferrer" target="_blank">
                        Solana Beach
                      </a>
                    </div>
                  )}
                </div>
              </div> */}
          </div>
        </div>
        <div className="grid grid-cols-3 ml-6 mr-6 gap-14 xl:ml-14 xl:mr-0">
          <div className="col-span-3 xl:col-span-2">
            <div className="grid grid-cols-2 gap-6 mt-6">
              <div className="col-span-2 md:col-span-1">
                <VaultDetails
                  vaultData={vaultData}
                  mint={vault_mint as string}
                  poolInfo={poolInfo}
                  price={{ currentPrice: poolInfo ? poolInfo.currentPrice : '0' }}
                />
                {/* <PriceCard
                  price={{ currentPrice: poolInfo ? poolInfo.currentPrice : '0' }}
                  tokenName={vaultData?.title}
                  risk={poolInfo ? poolInfo.ratio : 0}
                /> */}
              </div>
              <div className="col-span-2 md:col-span-1">
                {vaultData.title === 'SOL-USDC' && (
                  <LiquidationBuff
                    price={userVaultInfo?.liquidationPrice ?? 0}
                    buffer={userVaultInfo?.liquidationBuffer ?? 0}
                  />
                )}
              </div>
              <div className="col-span-2 md:col-span-1">
                <ModalCard
                  mintAddress={vault_mint}
                  title="Tokens in Vault"
                  icon={vaultData.icon}
                  icons={vaultData.icons}
                  tokenName={vaultData.title}
                  depositValue={depositValue}
                  withdrawValue={withdrawValue}
                  debtValue={debtValue}
                  debtInterestValue={debtInterestValue}
                  interestAPY={interestAPY}
                  generateValue={generateValue}
                  type="deposit_withdraw"
                  price={poolInfo?.oraclePrice}
                  data={vaultData}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <ModalCard
                  mintAddress={vault_mint}
                  title="USDr Loan Amount"
                  icons={[usdrIcon]}
                  tokenName={vaultData.title}
                  debtValue={debtValue}
                  debtInterestValue={debtInterestValue}
                  interestAPY={interestAPY}
                  generateValue={generateValue}
                  usdrWalletValue={usdrWalletBalance}
                  type="borrow_payback"
                />
              </div>
            </div>
          </div>
          <div className="col-span-3 mt-0 lg:pt-[25px] xl:col-span-1">
            <AmountPanel
              mintAddress={vault_mint}
              collAmount={lpWalletBalance}
              collAmountUSD={lpWalletBalanceUSD}
              icon={vaultData.icon}
              icons={vaultData.icons}
              tokenName={vaultData.title}
              usdrAmount={usdrWalletBalance}
              platform={vaultData.platform}
              realUserRewardMint={poolInfo ? poolInfo.realUserRewardMint : ''}
            />
          </div>
          <div className="col-span-3 xl:col-span-2">
            {
              <div className="mb-5">
                <VaultHistoryTable mintAddress={vault_mint} />
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default VaultDashboard;
