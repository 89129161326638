import { SABER_IOU_MINT, SBR_MINT } from '@saberhq/saber-periphery';
import { SignatureResult } from '@solana/web3.js';
import React, { useEffect, useState } from 'react';
import {
  API_ENDPOINT,
  RATIO_MINT_DECIMALS,
  REFRESH_TIME_INTERVAL,
  REWARD_TIME_INTERVAL,
  USDR_MINT_DECIMALS,
} from '../constants';
import {
  calculateRewardByPlatform,
  getAllOracleState,
  getGlobalState,
  getUserState,
  getVaultState,
  COLL_RATIOS_DECIMALS,
  getAllLendingPool,
  // USD_FAIR_PRICE,
  getLendingPoolByMint,
  getFarmInfoByPlatform,
  estimateRATIOAPR,
  estimateRatioRewards,
  PLATFORM_IDS,
  getBlacklist,
  getRewardBalance,
  fetchAxiosWithRetry,
  POOL_INFO_LIST,
  calculateLiquidationPrice,
} from '../utils/ratio-lending';
import { RATIO_MINT_KEY, USDR_MINT_KEY } from '../constants';
import { getBalanceChange, postToRatioApi, prepareTransactionData, TxStatus } from '../utils/ratioApi';
import { SABER_IOU_MINT_DECIMALS } from '../utils/PoolInfoProvider/saber/saber-utils';
import { TokenAmount } from '../utils/safe-math';
import { calculateCollateralPrice, getMint, toUiAmount } from '../utils/utils';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';

import { BN } from '@project-serum/anchor';
import {
  calculateRaydiumTvlAndApr,
  RAY_MINT_DECIMALS,
  RAY_MINT_KEY,
} from '../utils/PoolInfoProvider/raydium/raydium-utils';
import { getATAKey } from '../utils/ratio-pda';
import { useSelector } from 'react-redux';
import { selectors } from '../features/dashboard';
import { platform } from 'os';

const ACTION_UPDATE_ALL = 'ACTION_UPDATE_ALL';
const actionMintList = [];

interface RFStateConfig {
  globalState: any;
  oracleState: any;
  poolState: any;
  vaultState: any;
  overview: any;
  loadingState: boolean;
  appendUserAction: (
    walletKey: string,
    mintCollat: string,
    affectedMint: string,
    action: string,
    amount: number,
    txid: string,
    fair_price: number,
    market_price: number,
    fee: number
  ) => void;
  subscribeTx: (txHash: string, onTxSent?: any, onTxSuccess?: any, onTxFailed?: any) => void;
}

const RFStateContext = React.createContext<RFStateConfig>({
  globalState: {},
  oracleState: {},
  poolState: {},
  vaultState: {},
  overview: {},
  appendUserAction: () => {},
  subscribeTx: () => {},
  loadingState: false,
});

export function RFStateProvider({ children = undefined as any }) {
  const { connection } = useConnection();
  const wallet = useWallet();

  const allVaults = useSelector(selectors.getAllVaults);

  const [globalState, setGlobalState] = useState<any>(null);
  const [oracleState, setOracleState] = useState<any>(null);
  const [poolState, setPoolState] = useState<any>(null);
  const [vaultState, setVaultState] = useState<any>(null);
  const [overview, setOverview] = useState<any>(null);
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const [toogleUpdateState, setToogleUpdateState] = useState(false);
  const [toogleUpdateReward, setToogleUpdateReward] = useState(false);
  const [walletBalanceChanged, setWalletBalanceChanged] = useState(false);
  const [version, setVersion] = useState(null);

  const [actionList, setActionList] = useState([]);

  const subscribeTx = async (txHash: string, onTxSent: any, onTxSuccess: any, onTxFailed: any) => {
    if (txHash) {
      onTxSent();
      console.log('Sent tx: ', txHash);
    } else {
      onTxFailed();
      return;
    }
    // connection.confirmTransaction();
    connection.onSignature(
      txHash,
      async function (signatureResult: SignatureResult) {
        console.log('onProcessed');
        if (!signatureResult.err) {
          onTxSuccess();
        } else {
          onTxFailed();
        }
      },
      'processed'
    );
  };
  const appendUserAction = async (
    walletKey: string,
    mintCollat: string,
    affectedMint: string,
    action: string,
    amount: number,
    txHash: string,
    fair_price: number,
    market_price: number,
    fee: number
  ) => {
    if (!txHash) return;
    postToRatioApi(
      prepareTransactionData(
        action,
        mintCollat,
        affectedMint,
        amount,
        txHash,
        'Waiting Confirmation ...',
        fair_price,
        market_price,
        fee
      ),
      `/transaction/${walletKey}/new`
    )
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
    connection.onSignature(
      txHash,
      async function (signatureResult: SignatureResult) {
        let newStatus: TxStatus = 'Failed';
        if (!signatureResult.err) {
          newStatus = 'Success';
          console.log('Transaction confirmed', txHash);
        } else {
          console.log('Transaction failed', txHash);
        }
        const txInfo = await connection.getTransaction(txHash, { commitment: 'confirmed' });
        const newAmount = getBalanceChange(txInfo, walletKey, affectedMint);
        if (newAmount) {
          postToRatioApi(
            prepareTransactionData(
              action,
              mintCollat,
              affectedMint,
              newAmount,
              txHash,
              newStatus,
              fair_price,
              market_price,
              fee
            ),
            `/transaction/${walletKey}/update`
          )
            .then(() => {})
            .catch((e) => {
              console.log(e);
            });
        }
      },
      'confirmed'
    );
    actionMintList.push(mintCollat);
  };
  const updateGlobalState = async () => {
    const state = await getGlobalState(connection);
    const blacklist = await getBlacklist(connection);
    let info = globalState ?? {};
    if (state) {
      let info = globalState ?? {};

      const usdrMintInfo = info.usdrMintInfo ?? (await getMint(connection, USDR_MINT_KEY));

      info = {
        ...state,
        tvlUsd: globalState?.tvlUsd,
        blacklist,
        mintableUSDr: Math.max(
          0,
          parseFloat(
            new TokenAmount(state.debtCeilingGlobal.toNumber() - state.totalDebt.toNumber(), USDR_MINT_DECIMALS).fixed()
          )
        ),
        usdrMintInfo: usdrMintInfo,
      };
      setGlobalState(info);
      return info;
    }
    if (blacklist) {
      info = {
        ...info,
        blacklist,
      };
    }
    setGlobalState(info);
    return info;
  };

  const updateOracleState = async () => {
    const oracles = await getAllOracleState(connection);
    const oracleInfos: any = oracleState ?? {};
    oracles.forEach((item) => {
      const oracle = item.account;
      const oracleMint = oracle.mint.toString();
      oracleInfos[oracleMint] = oracle;
    });

    const tokens: Array<{ mint: string; api: string }> = [
      { mint: SBR_MINT, api: 'SBR' },
      { mint: RATIO_MINT_KEY, api: 'RATIO' },
      { mint: RAY_MINT_KEY, api: 'RAY' },
    ];

    await Promise.all(
      tokens.map(async (tokenOracle) => {
        const res = await (await fetch(`${API_ENDPOINT}/coingecko/${tokenOracle.api}`)).json();
        if (res.error) oracleInfos[tokenOracle.mint] = 0;
        else oracleInfos[tokenOracle.mint] = res;
      })
    );
    setOracleState(oracleInfos);

    return oracleInfos;
  };

  const getPoolInfo = async (globalState, oracleState, poolInfo: any) => {
    const mintInfo = await getMint(connection, poolInfo.mintCollat);
    if (poolInfo && mintInfo && oracleState && globalState && !poolInfo.isPaused) {
      const oracleInfoA = oracleState[poolInfo.swapMintA];
      const oracleInfoB = oracleState[poolInfo.swapMintB];
      const lpSupply = parseFloat(new TokenAmount(mintInfo.supply.toString(), mintInfo.decimals).fixed());
      const tokenAmountA = parseFloat(
        new TokenAmount(
          (await connection.getTokenAccountBalance(poolInfo.swapTokenA)).value.amount,
          oracleInfoA.decimals
        ).fixed()
      );
      const tokenAmountB = parseFloat(
        new TokenAmount(
          (await connection.getTokenAccountBalance(poolInfo.swapTokenB)).value.amount,
          oracleInfoB.decimals
        ).fixed()
      );
      const ratio = globalState.collPerRisklv[poolInfo?.riskLevel].toNumber() / 10 ** COLL_RATIOS_DECIMALS;
      const { fairPrice, virtualPrice } =
        poolInfo.swapMintA.toString() === poolInfo.mintCollat.toString()
          ? {
              fairPrice: oracleInfoA.fairPrice.toNumber(),
              virtualPrice: oracleInfoA.marketPrice.toNumber(),
            }
          : calculateCollateralPrice(
              lpSupply,
              tokenAmountA,
              oracleInfoA.marketPrice.toNumber(),
              tokenAmountB,
              oracleInfoB.marketPrice.toNumber()
            );

      poolInfo.realUserRewardMint =
        poolInfo.mintReward.toString() === SABER_IOU_MINT.toString() ? SBR_MINT : poolInfo.mintReward.toString();
      poolInfo['rewardMintDecimals'] = (await getMint(connection, poolInfo.realUserRewardMint))?.decimals;

      // const activePrice = USD_FAIR_PRICE ? fairPrice : virtualPrice;
      const activePrice = virtualPrice;
      poolInfo['tvlUsd'] = activePrice * +new TokenAmount(poolInfo.totalColl.toString(), mintInfo.decimals).fixed();
      poolInfo['fairPrice'] = fairPrice;
      poolInfo['virtualPrice'] = virtualPrice;

      poolInfo['oraclePrice'] = activePrice;
      poolInfo['currentPrice'] = +new TokenAmount(activePrice, USDR_MINT_DECIMALS).fixed();

      poolInfo['ratio'] = ratio;
      poolInfo['tokenAmountA'] = tokenAmountA;
      poolInfo['tokenAmountB'] = tokenAmountB;
      poolInfo['mintDecimals'] = mintInfo.decimals;
      poolInfo['mintSupply'] = mintInfo.supply;
      if (poolInfo.platformType === PLATFORM_IDS.SABER) {
        poolInfo['farmInfo'] = await getFarmInfoByPlatform(connection, poolInfo.mintCollat, poolInfo.platformType);
        poolInfo['farmTVL'] =
          +new TokenAmount(activePrice, USDR_MINT_DECIMALS).fixed() *
          +new TokenAmount(poolInfo['farmInfo'].totalTokensDeposited, mintInfo.decimals).fixed();
        poolInfo['farmAPY'] =
          ((oracleState[SBR_MINT] *
            new TokenAmount(poolInfo['farmInfo'].annualRewardsRate, SABER_IOU_MINT_DECIMALS).toEther().toNumber()) /
            poolInfo['farmTVL']) *
          100;
      } else if (poolInfo.platformType === PLATFORM_IDS.SWIM) {
        poolInfo['farmInfo'] = null;
        poolInfo['farmTVL'] = +new TokenAmount(activePrice, USDR_MINT_DECIMALS).fixed() * lpSupply;
        poolInfo['farmAPY'] = '0.0';
      } else if (poolInfo.platformType === PLATFORM_IDS.RAYDIUM) {
        poolInfo['farmInfo'] = null;
        const { tvl, apr } = await calculateRaydiumTvlAndApr(
          connection,
          {
            price: Number.parseFloat(new TokenAmount(activePrice, USDR_MINT_DECIMALS).fixed()),
            mint: poolInfo.mintCollat,
          },
          {
            price: oracleState[RAY_MINT_KEY],
            decimals: RAY_MINT_DECIMALS,
          }
        );
        poolInfo['farmTVL'] = tvl;
        poolInfo['farmAPY'] = apr;
      }
      poolInfo['ratioAPY'] = estimateRATIOAPR(poolInfo, oracleState[RATIO_MINT_KEY]) * 100;

      poolInfo['apy'] = poolInfo['farmAPY'] + poolInfo['ratioAPY'];
    }
    return poolInfo;
  };

  const updatePoolStateByMint = async (globalState, oracleState, mint) => {
    const poolInfos: any = poolState ?? {};
    try {
      const pool = await getLendingPoolByMint(connection, mint);
      const poolInfo = await getPoolInfo(globalState, oracleState, pool);
      if (poolInfo) {
        poolInfos[mint] = poolInfo;
      }
    } catch (e) {
      console.log(e);
    }
    setPoolState(poolInfos);
    return poolInfos;
  };

  const updateAllPoolState = async (globalState, oracleState) => {
    let newGlobalTVL = new BN(0);
    const poolInfos: any = poolState ?? {};

    const { health: healthParamData, version: backVersion } = (await fetchAxiosWithRetry(`${API_ENDPOINT}/param`)).data;
    if (!version) setVersion(backVersion);
    else if (version !== backVersion) {
      // console.log(`Updated the backend into ${backVersion}`);
      // confirm(`New feature is integrated, ${backVersion}`);
      window.location.reload();
    }

    const healthParams = {};
    healthParamData
      .replaceAll('\r', '')
      .split('\n')
      .filter((item: string) => {
        return !item.startsWith('#') && item.trim().length !== 0;
      })
      .map((item: string) => item.replaceAll(' ', ''))
      .forEach((item: string) => {
        const idioms = item.split('=');
        if (idioms.length === 2) {
          healthParams[idioms[0]] = +idioms[1];
        }
      });

    const allPools = await getAllLendingPool(connection);

    const allRayPoolInfo: any = (await fetchAxiosWithRetry('https://api.raydium.io/v2/sdk/liquidity/mainnet.json')).data
      .official;
    const allRayPairInfo: Array<{ lpMint: string; apr7d: number; lpPrice: number }> = (
      await fetchAxiosWithRetry('https://api.raydium.io/v2/main/pairs')
    ).data;
    const allSaberPoolInfo = (await fetchAxiosWithRetry(`https://registry.saber.so/data/pools-info.mainnet.json`)).data
      .pools;
    for (let i = 0; i < allPools.length; i++) {
      const pool = allPools[i].account;
      const mint = pool.mintCollat.toString();

      let ammInfo = {};
      if (pool.platformType === PLATFORM_IDS.RAYDIUM) {
        const rayAmmInfo = allRayPoolInfo.find((item: any) => item.lpMint === mint);
        const rayPairInfo = allRayPairInfo.find((item: any) => item.lpMint === mint);
        ammInfo = {
          ...rayAmmInfo,
          ...rayPairInfo,
          platformType: 'raydium',
        };
      } else if (pool.platformType === PLATFORM_IDS.SABER) {
        const saberAmmInfo = allSaberPoolInfo.find((item: any) => item.lpToken.address === mint);
        ammInfo = {
          ...saberAmmInfo,
          platformType: 'saber',
        };
      }
      POOL_INFO_LIST[mint] = ammInfo;

      const poolInfo = await getPoolInfo(globalState, oracleState, pool);
      if (poolInfo) {
        const poolLegalName = allVaults
          .filter((item: any) => {
            return item.mint === mint;
          })
          .map((item: any) => {
            return [item.title, item.platform.name.toUpperCase()].join('_').replaceAll('-', '_');
          })[0];
        const liqDeltaMin = healthParams[poolLegalName + '_' + 'DMIN'] ?? 0;
        const liqTolerance = healthParams[poolLegalName + '_' + 'TOL'] ?? 0;
        if (liqDeltaMin === 0 || liqTolerance === 0) console.log({ poolLegalName, liqDeltaMin, liqTolerance });

        poolInfos[mint] = {
          ...poolInfo,
          liqTolerance,
          liqDeltaMin,
        };
        newGlobalTVL = newGlobalTVL.add(new BN(poolInfo.tvlUsd));
      }
    }
    if (globalState && Object.keys(globalState).length) {
      setGlobalState({
        ...globalState,
        tvlUsd: BN.max((globalState.totalDebt ?? new BN(0)).muln(1.10567), newGlobalTVL),
      });
    }
    setPoolState(poolInfos);
    return poolInfos;
  };

  const updateOverview = async () => {
    const userState = await getUserState(connection, wallet);
    setOverview(userState);
    return userState;
  };

  const getVaultStateByMint = async (globalState, oracleState, poolInfo, overview, mint: string) => {
    const vaultInfo = await getVaultState(connection, wallet, mint);
    if (
      globalState.debtCeilingUser &&
      globalState.debtCeilingGlobal &&
      globalState.totalDebt &&
      overview.totalDebt &&
      poolInfo &&
      vaultInfo
    ) {
      const harvestRate = globalState.feeDeno.sub(poolInfo.harvestFeeNumer).toNumber() / globalState.feeDeno.toNumber();
      const lockedColl = toUiAmount(vaultInfo.totalColl.toString(), poolInfo.mintDecimals);
      const tvlUsd = poolInfo.oraclePrice * lockedColl;
      const debtLimit = tvlUsd * poolInfo.ratio;
      const vaultDebtLimit = debtLimit - vaultInfo.debt.toNumber() - vaultInfo.usdrInterest.toNumber();
      const userDebtLimit = globalState.debtCeilingUser.toNumber() - overview.totalDebt.toNumber();
      const poolDebtLimit = poolInfo.debtCeiling.toNumber() - poolInfo.totalDebt.toNumber();
      const globalDebtLimit = globalState.debtCeilingGlobal.toNumber() - globalState.totalDebt.toNumber();
      const mintableUSDr = Math.max(0, Math.min(vaultDebtLimit, userDebtLimit, poolDebtLimit, globalDebtLimit));
      const reservedYieldReward = await getRewardBalance(connection, wallet, mint, poolInfo.mintReward);

      const [rewardWithoutFee, rewardCacheData] = await calculateRewardByPlatform(
        connection,
        wallet.publicKey,
        mint,
        poolInfo.platformType
      );
      const reward = +(rewardWithoutFee * harvestRate + reservedYieldReward).toFixed(USDR_MINT_DECIMALS);

      const reservedRatioReward = await getRewardBalance(connection, wallet, mint, RATIO_MINT_KEY);
      const ratioRewardWithoutFee = estimateRatioRewards(poolInfo, vaultInfo) / 10 ** RATIO_MINT_DECIMALS;
      const ratioReward = +(+ratioRewardWithoutFee * harvestRate + reservedRatioReward).toFixed(USDR_MINT_DECIMALS);
      const liquidationPrice = calculateLiquidationPrice(
        poolInfo,
        lockedColl,
        toUiAmount(vaultInfo.debt.toNumber(), USDR_MINT_DECIMALS)
      );
      const liquidationBuffer =
        liquidationPrice !== 0 && !Number.isNaN(liquidationPrice)
          ? (poolInfo.currentPrice - liquidationPrice) / liquidationPrice
          : -1;

      const usdrPrice = oracleState[USDR_MINT_KEY]?.marketPrice?.toNumber() / 10 ** USDR_MINT_DECIMALS;
      const interestAPY = (1 - usdrPrice ?? 1) * 100;
      return {
        poolInfo,
        ...vaultInfo,
        mint,
        lockedAmount: vaultInfo.totalColl.toNumber(),
        lockedUiAmount: lockedColl,
        tvlUsd,

        interestAPY,
        debt: vaultInfo.debt.toNumber() + vaultInfo.usdrInterest.toNumber(),
        uiDebt: toUiAmount(vaultInfo.debt.toNumber() + vaultInfo.usdrInterest.toNumber(), USDR_MINT_DECIMALS),
        uiDebtInterest: toUiAmount(vaultInfo.usdrInterest.toNumber(), USDR_MINT_DECIMALS),
        debtLimit: new TokenAmount(debtLimit, USDR_MINT_DECIMALS).toWei().toNumber(),

        mintableUSDr: new TokenAmount(mintableUSDr, USDR_MINT_DECIMALS).toWei().toNumber(),
        isReachedDebt: mintableUSDr <= 0 && vaultInfo.debt.toNumber() > 0,

        ratioReward,
        ratioRewardUSD: (ratioReward * oracleState[RATIO_MINT_KEY]).toFixed(USDR_MINT_DECIMALS),

        reward,
        rewardUSD: new TokenAmount(
          oracleState[poolInfo.mintReward.toString()] * reward,
          USDR_MINT_DECIMALS,
          false
        ).fixed(),
        rewardCacheData,
        reservedReward: reservedYieldReward,

        liquidationPrice,
        liquidationBuffer,
      };
    }
    return null;
  };

  const getVaultRewardByMint = async (globalState, oracleState, poolInfo, overview, vaultInfo, mint: string) => {
    if (
      globalState?.debtCeilingUser &&
      globalState?.debtCeilingGlobal &&
      globalState?.totalDebt &&
      overview?.totalDebt &&
      poolInfo &&
      vaultInfo &&
      wallet.publicKey
    ) {
      const harvestRate = globalState.feeDeno.sub(poolInfo.harvestFeeNumer).toNumber() / globalState.feeDeno.toNumber();
      const [rewardWithoutFee, rewardCacheData] = await calculateRewardByPlatform(
        connection,
        wallet.publicKey,
        mint,
        poolInfo.platformType,
        vaultInfo.rewardCacheData
      );
      const reward = +(rewardWithoutFee * harvestRate + (vaultInfo.reservedReward ?? 0)).toFixed(USDR_MINT_DECIMALS);

      const ratioRewardWithoutFee = new TokenAmount(
        estimateRatioRewards(poolInfo, vaultInfo),
        RATIO_MINT_DECIMALS,
        true
      ).fixed();
      const ratioReward = +(+ratioRewardWithoutFee * harvestRate).toFixed(USDR_MINT_DECIMALS);

      return {
        ...vaultInfo,
        reward,
        rewardUSD: new TokenAmount(
          oracleState[poolInfo.mintReward.toString()] * reward,
          USDR_MINT_DECIMALS,
          false
        ).fixed(),
        ratioReward,
        rewardData: rewardCacheData,
      };
    }
    return null;
  };

  const updateAllVaultState = async (globalState, oracleState, poolState, overview) => {
    const vaultInfos: any = vaultState ?? {};
    if (overview) {
      for (const mint of Object.keys(poolState)) {
        const vaultInfo = await getVaultStateByMint(globalState, oracleState, poolState[mint], overview, mint);
        if (vaultInfo) {
          vaultInfos[mint] = {
            ...vaultInfo,
          };
        }
      }
    }
    setVaultState(vaultInfos);
    return vaultInfos;
  };

  const updateRewardDisplay = async () => {
    const vaultInfos: any = vaultState ?? {};
    for (const mint of Object.keys(vaultInfos)) {
      const vaultInfo = await getVaultRewardByMint(
        globalState,
        oracleState,
        poolState[mint],
        overview,
        vaultInfos[mint],
        mint
      );
      if (vaultInfo) {
        vaultInfos[mint] = {
          ...vaultInfo,
        };
      }
    }
    setVaultState(vaultInfos);
    return vaultInfos;
  };

  const updateVaultStateByMint = async (globalState, oracleState, poolInfo, overview, mint) => {
    const vaultInfos: any = vaultState ?? {};
    if (overview) {
      const vaultInfo = await getVaultStateByMint(globalState, oracleState, poolInfo, overview, mint);
      if (vaultInfo) {
        vaultInfos[mint] = {
          ...vaultInfo,
        };
      }
    }
    setVaultState(vaultInfos);
    return vaultInfos;
  };

  const updateRFStateByMint = async (mint) => {
    console.log('----- Updating state by mint -----');
    setLoadingState(true);
    await updateVaultStateByMint(globalState, oracleState, poolState[mint], overview, mint);

    const newGlobalState = await updateGlobalState();
    const newOracleState = await updateOracleState();
    const newPoolState = await updatePoolStateByMint(newGlobalState, newOracleState, mint);
    const newOverview = await updateOracleState();
    await updateVaultStateByMint(newGlobalState, newOracleState, newPoolState[mint], newOverview, mint);
    setLoadingState(false);
    console.log('***** Updated state by mint *****');
  };

  const updateRFStateOverall = async () => {
    console.log('----- Updating all state -----');
    setLoadingState(true);
    const globalState = await updateGlobalState();
    const oracleState = await updateOracleState();
    const poolState = await updateAllPoolState(globalState, oracleState);
    const overview = await updateOverview();
    await updateAllVaultState(globalState, oracleState, poolState, overview);
    setLoadingState(false);
    console.log('***** Updated all state *****');
  };

  const updateRFState = async () => {
    if (actionList.includes(ACTION_UPDATE_ALL)) {
      setActionList([]);
      await updateRFStateOverall();
    } else {
      setActionList((prev) => prev.filter((action) => action !== actionList[0]));
      await updateRFStateByMint(actionList[0]);
    }
  };

  useEffect(() => {
    setInterval(() => {
      setToogleUpdateState((prev) => !prev);
    }, REFRESH_TIME_INTERVAL);
  }, []);

  useEffect(() => {
    setInterval(() => {
      setToogleUpdateReward((prev) => !prev);
    }, REWARD_TIME_INTERVAL);
  }, []);

  useEffect(() => {
    if (actionList.length && loadingState === false) {
      updateRFState();
    }
  }, [actionList, loadingState]);

  useEffect(() => {
    if (connection) {
      if (wallet && wallet.connected && !wallet.disconnecting && !wallet.connecting) {
        console.log('********Wallet is connected*******');
        connection.onAccountChange(wallet.publicKey, (acc) => {
          if (acc) {
            console.log('********Wallet balance changed*******');
            setActionList((prev) => [...prev, ...actionMintList]);
            actionMintList.length = 0;
          }
        });
      }
      setActionList((prev) => [...prev, ACTION_UPDATE_ALL]);
    }
    return () => {};
  }, [connection, wallet, toogleUpdateState]);

  useEffect(() => {
    if (!loadingState) {
      updateRewardDisplay();
    }
    return () => {};
  }, [toogleUpdateReward, loadingState]);

  /*useEffect(() => {
    if (wallet && wallet.connected && !wallet.disconnecting && !wallet.connecting) {
      console.log('Wallet is connected');
      connection.onAccountChange(wallet.publicKey, (acc) => {
        if (acc) {
          setWalletUpdated((prev) => !prev);
        }
      });
      if (activeAction === ACTION_IDLE) {
        console.log('Updated');
        setActiveAction(ACTION_UPDATE_ALL);
      }
    } else if (wallet && !wallet.connected) {
      setVaultState(null);
      setOverview(null);
    }

    return () => {
      setVaultState(null);
    };
  }, [wallet]);*/

  // useEffect(() => {
  //   //it means wallet is updated or connected to the frontend
  //   if (wallet && wallet.connected && !wallet.disconnecting && !wallet.connecting && wallet.publicKey && connection) {
  //     if (activeAction === ACTION_IDLE) {
  //       if (mintList.length > 0) {
  //         console.log('User Vault is updated');
  //         setActiveAction(mintList[0]);
  //         mintList.splice(0, 1);
  //       } else {
  //         setActiveAction(ACTION_UPDATE_ALL);
  //       }
  //     }
  //   }
  // }, [walletBalanceChanged, wallet]);

  return (
    <RFStateContext.Provider
      value={{
        globalState,
        oracleState,
        poolState,
        vaultState,
        overview,
        loadingState,
        appendUserAction: appendUserAction,
        subscribeTx,
      }}
    >
      {children}
    </RFStateContext.Provider>
  );
}

// function useRFState() {
//   const context = React.useContext(RFStateContext);
//   if (!context) {
//     throw new Error('[useRFState] Hook not used under Vaults context provider');
//   }
//   return context;
// }

export function useRFStateInfo() {
  const context = React.useContext(RFStateContext);

  return context.globalState;
}

export function useUserVaultInfo(mint: string) {
  const context = React.useContext(RFStateContext);

  return context.vaultState ? context.vaultState[mint] : null;
}

export function useAllPoolInfo() {
  const context = React.useContext(RFStateContext);

  return context.poolState;
}

export function usePoolInfo(mint: string) {
  const context = React.useContext(RFStateContext);

  return context.poolState ? context.poolState[mint] : null;
}
export function useOracleInfo(mint: string) {
  const context = React.useContext(RFStateContext);

  return context.oracleState ? context.oracleState[mint] : null;
}

export function useAllOracleInfo() {
  const context = React.useContext(RFStateContext);
  return context.oracleState;
}

export function useLoadingState() {
  const context = React.useContext(RFStateContext);

  return context.loadingState;
}

export function useUserOverview() {
  const context = React.useContext(RFStateContext);

  return context.overview;
}

export function useAllVaultInfo() {
  const context = React.useContext(RFStateContext);

  return context.vaultState;
}

export function useIsActiveUserVault(mint: string) {
  const context = React.useContext(RFStateContext);
  if (context.vaultState && context.vaultState[mint] && context.vaultState[mint]?.totalColl)
    return context.vaultState[mint]?.totalColl.toNumber() !== 0;
  else return false;
}

export function useAppendUserAction() {
  const context = React.useContext(RFStateContext);

  return context.appendUserAction;
}

export function useSubscribeTx() {
  const context = React.useContext(RFStateContext);

  return context.subscribeTx;
}
