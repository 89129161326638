import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { CachedPoolInfo, PairType } from '../../types/VaultTypes';
import { selectors, actionTypes } from '../../features/dashboard';

import FilterPanel from '../../components/FilterPanel';
import ComparingFooter from '../../components/ComparingFooter';
import TokenPairCard from '../../components/TokenPairCard';
import ActivePairCard from '../../components/ActivePairCard';
import TokenPairListItem from '../../components/TokenPairListItem';
import { getCoinPicSymbol } from '../../utils/helper';
import { LPair, RISK_RATING } from '../../types/VaultTypes';
import { toast } from 'react-toastify';
import { Banner, BannerIcon } from '../../components/Banner';
import { useVaultsContextProvider } from '../../contexts/vaults';
import ActivePairListItem from '../../components/ActivePairListItem';
import LoadingSpinner from '../../atoms/LoadingSpinner';
import { TokenAmount } from '../../utils/safe-math';
import { API_ENDPOINT, USDR_MINT_DECIMALS } from '../../constants';
import smallRatioIcon from '../../assets/images/smallRatio.svg';
import { FetchingStatus } from '../../types/fetching-types';
import { useIsTotalUSDrLimitReached } from '../../hooks/useIsTotalUSDrLimitReached';
import { useIsTVLLimitReached } from '../../hooks/useIsTVLLimitReached';
import { useIsUserUSDrLimitReached } from '../../hooks/useIsUserUSDrLimitReached';
import { useAllPoolInfo, useAllVaultInfo, useLoadingState, useUserOverview } from '../../contexts/state';
import { getTokenIcon } from '../../utils/utils';
import { fetchAxiosWithRetry } from '../../utils/ratio-lending';

const BaseVaultsPage = ({ showOnlyActive = false, title }: { showOnlyActive: boolean; title: string }) => {
  const dispatch = useDispatch();
  const compareVaultsList = useSelector(selectors.getCompareVaultsList);

  /// liquidation Status: warning or danger
  const [liquidationStatus, setLiquidationStatus] = useState('');

  const filter_data = useSelector(selectors.getFilterData);
  const sort_data = useSelector(selectors.getSortData);
  const view_data = useSelector(selectors.getViewData);
  const platform_data = useSelector(selectors.getPlatformData);
  const overview = useUserOverview();
  const userVaultInfos = useAllVaultInfo();
  const poolInfos = useAllPoolInfo();
  const loadingState = useLoadingState();
  const viewType = useSelector(selectors.getViewType);
  const [factorial, setFactorial] = useState<any>([]);
  const [cachedPoolInfos, setCachedPoolInfos] = useState<Map<string, CachedPoolInfo> | undefined>();

  const hasUserReachedUSDrLimit = useIsUserUSDrLimitReached();
  const hasReachedGlobalDebtLimit = useIsTotalUSDrLimitReached();
  const hasReachedTVLLimit = useIsTVLLimitReached();

  const { connected } = useWallet();

  const onViewType = (type: string) => {
    dispatch({ type: actionTypes.SET_VIEW_TYPE, payload: type });
  };

  const { status, error, vaults } = useVaultsContextProvider();

  // eslint-disable-next-line
  const filterData = (array1: any, array2: any, platform_data: any) => {
    if (array2.length === 0) {
      return array1;
    }
    return array1?.filter((item1: any) => {
      const item1Str = JSON.stringify(item1);
      return array2.find((item2: any) => {
        return item1Str.indexOf(item2.label) > -1;
      });
    });
  };

  function dynamicSort(sortProperty: string, viewProperty: string) {
    const sortOrder = viewProperty === 'ascending' ? 1 : -1;
    // sortOrder = sortProperty === 'risk' ? 1 : -1;

    return function (a: any, b: any) {
      const result = a[sortProperty] < b[sortProperty] ? -1 : a[sortProperty] > b[sortProperty] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function factorialOf() {
    if (vaults !== undefined) {
      const p = filterData(vaults, filter_data, platform_data)
        ?.map((item: LPair, index: any) => {
          const mint = item.address_id;
          const isVaultActive =
            userVaultInfos && userVaultInfos[mint] && userVaultInfos[mint].totalColl.toNumber() !== 0;
          const positionValue =
            userVaultInfos &&
            userVaultInfos[mint] &&
            +new TokenAmount((userVaultInfos[mint] as any)?.tvlUsd ?? 0, USDR_MINT_DECIMALS).fixed();
          const totalDebt =
            userVaultInfos &&
            userVaultInfos[mint] &&
            +new TokenAmount((userVaultInfos[mint] as any)?.debt ?? 0, USDR_MINT_DECIMALS).fixed();
          const poolData = {
            tvl: 0,
            apr: 0,
            ratioAPY: 0,
            realUserRewardMint: '',
            // ratioReward: userVaultInfos[mint] && userVaultInfos[mint].ratioReward,
          };
          // If cached data is not available, use it else wait for poolInfo State Update
          if (cachedPoolInfos && cachedPoolInfos[mint]) {
            poolData.tvl = Number(cachedPoolInfos[mint].pool_tvl_usd);
            poolData.apr = Number(cachedPoolInfos[mint].farm_apr);
            poolData.ratioAPY = Number(cachedPoolInfos[mint].ratio_apr);
          } else if (poolInfos && poolInfos[mint]) {
            poolData.tvl = poolInfos[mint].tvlUsd;
            poolData.apr = poolInfos[mint].farmAPY;
            poolData.ratioAPY = poolInfos[mint].ratioAPY;
            poolData.realUserRewardMint = poolInfos[mint].realUserRewardMint;
          }
          if (showOnlyActive === false || isVaultActive) {
            return {
              id: index,
              mint,
              icons: item.lpasset?.map((item) =>
                item.token_icon?.trim() === '' || item.token_icon === undefined
                  ? getCoinPicSymbol(item.token_symbole)
                  : item.token_icon
              ),
              icon: getTokenIcon(item.symbol.toLowerCase()),
              title: item.symbol,
              platform: {
                link: item.platform_site,
                name: item.platform_name,
                icon: item.platform_icon,
                symbol: item.platform_symbol,
              },
              risk: item.risk_rating,
              riskLevel: RISK_RATING[item.risk_rating as unknown as keyof typeof RISK_RATING],
              item: item,
              activeStatus: isVaultActive,
              positionValue: positionValue,
              totalDebt: totalDebt,
              experimentalStatus: item.experimental_status === 'true' ? true : false,
              ...poolData,
            };
          }
        })
        .filter(Boolean);
      let x;
      if (platform_data.value !== 'ALL') {
        x = p.filter((item: any) => item.platform.name === platform_data.value);
      } else {
        x = p;
      }
      // const fd = x.filter((item) => item.apr !== undefined);
      x.sort(dynamicSort(sort_data.value, view_data.value));

      if (showOnlyActive) {
        dispatch({ type: actionTypes.SET_ACTIVE_VAULT, payload: x });
      } else {
        dispatch({ type: actionTypes.SET_ALL_VAULT, payload: x });
      }
      return x;
    }
    return [];
  }

  useEffect(() => {
    (async () => {
      // To avoid multiple calls to Backend
      if (cachedPoolInfos!) return;
      const _cachedPoolInfos: Map<string, CachedPoolInfo> = new Map();
      const cachedApiResult = (await fetchAxiosWithRetry(`${API_ENDPOINT}/lpairs/cached`)).data;
      cachedApiResult.map((cachedPoolInfo: CachedPoolInfo) => {
        _cachedPoolInfos[cachedPoolInfo.mint] = cachedPoolInfo;
      });
      setCachedPoolInfos(_cachedPoolInfos);
    })();
  });

  useEffect(() => {
    setFactorial(factorialOf());
  }, [
    filter_data,
    sort_data,
    view_data,
    platform_data,
    vaults,
    userVaultInfos,
    poolInfos,
    cachedPoolInfos,
    loadingState,
  ]);

  const showContent = (vtype: string) => {
    if (!cachedPoolInfos && !poolInfos) {
      return (
        <div className="flex items-center justify-center min-h-[50vh]">
          <LoadingSpinner className="spinner-border-lg text-primary" />
        </div>
      );
    }

    const onCompareVault = (data: PairType, status: boolean) => {
      if (status) {
        dispatch({ type: actionTypes.SET_COMPARE_VAULTS_LIST, payload: [...compareVaultsList, data] });
      } else {
        const arr = compareVaultsList.filter((vault: PairType) => vault.id !== data.id);
        dispatch({ type: actionTypes.SET_COMPARE_VAULTS_LIST, payload: arr });
      }
    };

    // if (!connected) {
    //   return <h5 className="mt-5 ml-3 allvaults__emptyText">Please connect your wallet</h5>;
    // }

    if (vtype === 'grid' && showOnlyActive && !connected) {
      return <h5 className="mt-5 ml-3 dark:text-white-900">Please connect your wallet</h5>;
    }

    if (vtype === 'grid' && factorial.length === 0 && connected) {
      return <h5 className="mt-5 ml-3 dark:text-white-900">There are no active vaults to display</h5>;
    }

    if (vtype === 'grid') {
      return (
        <div className="grid gap-6 xl:grid-cols-3 lg:grid-cols-2">
          {factorial.map((item: any) => {
            if (cachedPoolInfos?.[item.mint].is_paused !== 0) return null;
            if (showOnlyActive === false)
              return (
                <TokenPairCard
                  data={item}
                  key={item.id}
                  onCompareVault={onCompareVault}
                  isFirstLoading={userVaultInfos === null}
                  liquidationStatus={liquidationStatus}
                  cachedPoolInfo={cachedPoolInfos?.[item.mint]}
                />
              );
            else
              return (
                <ActivePairCard
                  data={item}
                  key={item.id}
                  onCompareVault={onCompareVault}
                  liquidationStatus={liquidationStatus}
                  cachedPoolInfo={cachedPoolInfos?.[item.mint]}
                />
              );
          })}
        </div>
      );
    } else {
      return (
        <div className="overflow-x-auto">
          <table className="min-w-full mt-6">
            <thead>
              {showOnlyActive === false ? (
                <tr>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    Vault
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    Status
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    APR
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    Collateralization Ratio
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    Platform
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    <div className="flex gap-2">
                      <img src={smallRatioIcon} alt="lisklevel" className="w-3" />
                      Risk Rating
                    </div>
                  </th>
                  <th className="px-6 py-3 text-base font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600"></th>
                </tr>
              ) : (
                <tr>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    Vault
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    APR
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    Collateralization Ratio
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    <div className="flex gap-2">
                      <img src={smallRatioIcon} alt="lisklevel" className="w-5" />
                      Risk Rating
                    </div>
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    USDr Debt
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    USDr Available to Mint
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    Rewards Earned
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600">
                    Position Value
                  </th>
                  <th className="px-6 py-3 text-sm font-normal leading-4 tracking-wider text-left text-gray-100 border-b-2 font-poppins border-slate-300 dark:text-white-900 dark:border-gray-600"></th>
                </tr>
              )}
            </thead>
            <tbody>
              {factorial.map((item: any) => {
                if (cachedPoolInfos?.[item.mint].is_paused !== 0) return null;
                if (showOnlyActive === false)
                  return (
                    <TokenPairListItem
                      data={item}
                      key={item.id}
                      onCompareVault={onCompareVault}
                      isFirstLoading={userVaultInfos === null}
                      liquidationStatus={liquidationStatus}
                      cachedPoolInfo={cachedPoolInfos?.[item.mint]}
                    />
                  );
                else
                  return (
                    <ActivePairListItem
                      data={item}
                      key={item.id}
                      onCompareVault={onCompareVault}
                      liquidationStatus={liquidationStatus}
                      cachedPoolInfo={cachedPoolInfos?.[item.mint]}
                    />
                  );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  };

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <>
      {overview && (
        <div>
          {hasUserReachedUSDrLimit && !hasReachedGlobalDebtLimit && !hasReachedTVLLimit && (
            <Banner
              title="USDr Debt Limit Reached:"
              message="You have reached your overall USDr Debt Limit."
              bannerIcon={BannerIcon.riskLevel}
              className="debt-limit-reached"
            />
          )}
          {hasReachedGlobalDebtLimit && !hasReachedTVLLimit && (
            <Banner
              title="USDr Debt Limit Reached:"
              message="The global debt ceiling on the Ratio platform has been reached."
              bannerIcon={BannerIcon.riskLevel}
              className="debt-limit-reached"
            />
          )}
          {hasReachedTVLLimit && (
            <Banner
              title="TVL Limit Reached:"
              message="The global deposit ceiling on the Ratio platform has been reached."
              bannerIcon={BannerIcon.riskLevel}
              className="debt-limit-reached"
            />
          )}
        </div>
      )}
      <div className="mx-6 mt-4 xl:mx-14 min-h-[70vh] mb-20 dark:bg-gray-900 bg-white-900">
        <FilterPanel label={title} viewType={viewType} onViewType={onViewType} />

        {status === FetchingStatus.Error && toast.error(error)}
        {(status === FetchingStatus.Loading || status === FetchingStatus.NotAsked) && (
          <div className="flex items-center justify-center min-h-[50vh]">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {status === FetchingStatus.Finish && showContent(viewType)}
        {compareVaultsList.length > 0 && <ComparingFooter list={compareVaultsList} />}
      </div>
    </>
  );
};

export default BaseVaultsPage;
