import {
  Backend,
  Config,
  defaultVariables,
  DialectContextProvider,
  DialectThemeProvider,
  DialectUiManagementProvider,
  DialectWalletAdapter,
  IncomingThemeVariables,
} from '@dialectlabs/react-ui';
import * as anchor from '@project-serum/anchor';
import { useWallet, WalletContextState } from '@solana/wallet-adapter-react';
import { useEffect, useMemo, useState, useContext } from 'react';
import { ThemeContext } from './ThemeContext';

const DIALECT_PUBLIC_KEY = new anchor.web3.PublicKey(process.env.REACT_APP_DIALECT_ADDRESS);

export const themeVariables: IncomingThemeVariables = {
  light: {
    colors: {
      toggleBackgroundActive: 'bg-violet-300',
      toggleThumb: 'bg-white-900',
    },
    button: 'bg-gradient-to-r from-[#1C7AFF] to-[#5FB5FF]',
    bellButton: 'w-10 h-10 border-t border-l border-r border-b bg-white-900 border-white-400 shadow-none',
    modal: `shadow-xl rounded-2xl border-white-400 border-t border-l border-b border-r`,
    outlinedInput: `${defaultVariables.light.outlinedInput} !border-white-300 !border !rounded-lg focus-within:bg-white-900 focus-within:text-gray-200 text-gray-200`,
    textStyles: {
      label: `font-poppins`,
      input: 'font-poppins bg-transparent !border-none focus:border-none focus:shadow-none focus:ring-0',
      h1: `${defaultVariables.light.textStyles.h1} font-poppins font-semibold text-gray-200`,
    },
    xPaddedText: 'hidden',
  },
  dark: {
    colors: {
      toggleBackgroundActive: 'bg-violet-300',
      toggleThumb: 'bg-white-900',
    },
    button: 'bg-gradient-to-r from-[#1C7AFF] to-[#5FB5FF]',
    bellButton: 'w-10 h-10 border-t border-l border-r border-b bg-gray-900 text-slate-300',
    modal: `${defaultVariables.dark.modal} rounded-2xl border-t border-l border-r border-b  border-gray-200 bg-slate-900 shadow-xl`, // 0.4 opacity based on trial-and-error
    outlinedInput: `${defaultVariables.light.outlinedInput} !border !rounded-lg`,
    textStyles: {
      label: `font-poppins`,
      input: 'font-poppins bg-transparent !border-none focus:border-none focus:shadow-none focus:ring-0',
      h1: 'font-poppins',
    },
    xPaddedText: 'hidden',
  },
  animations: {
    popup: {
      enter: 'transition-all duration-300 origin-top-right',
      enterFrom: 'opacity-0 scale-75',
      enterTo: 'opacity-100 scale-100',
      leave: 'transition-all duration-100 origin-top-right',
      leaveFrom: 'opacity-100 scale-100',
      leaveTo: 'opacity-0 scale-75',
    },
  },
};

// TODO: move this to react-sdk and export
const walletToDialectWallet = (wallet: WalletContextState): DialectWalletAdapter => ({
  publicKey: wallet.publicKey!,
  connected: wallet.connected && !wallet.connecting && !wallet.disconnecting && Boolean(wallet.publicKey),
  signMessage: wallet.signMessage,
  signTransaction: wallet.signTransaction,
  signAllTransactions: wallet.signAllTransactions,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  diffieHellman: wallet.wallet?.adapter?._wallet?.diffieHellman
    ? async (pubKey) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return wallet.wallet?.adapter?._wallet?.diffieHellman(pubKey);
      }
    : undefined,
});

export function DialectProvider({ children = undefined as any }): JSX.Element {
  const wallet = useWallet();
  const theme = useContext(ThemeContext);
  const { darkMode } = theme.state;
  const [dialectWalletAdapter, setDialectWalletAdapter] = useState<DialectWalletAdapter>(() =>
    walletToDialectWallet(wallet)
  );

  useEffect(() => {
    setDialectWalletAdapter(walletToDialectWallet(wallet));
  }, [wallet]);

  const dialectConfig = useMemo(
    (): Config => ({
      backends: [Backend.DialectCloud],
      environment: 'production',
      dialectCloud: {
        tokenStore: 'local-storage',
      },
    }),
    []
  );

  return (
    <DialectContextProvider
      wallet={dialectWalletAdapter}
      config={dialectConfig}
      dapp={DIALECT_PUBLIC_KEY}
      gate={() => new Promise((resolve) => setTimeout(() => resolve(true), 3000))}
    >
      <DialectThemeProvider theme={darkMode ? 'dark' : 'light'} variables={themeVariables}>
        <DialectUiManagementProvider>{children}</DialectUiManagementProvider>
      </DialectThemeProvider>
    </DialectContextProvider>
  );
}
