import { useDapp } from '@dialectlabs/react-sdk';
import { WalletStatesWrapper, ConnectionWrapper, DashboardWrapper } from '@dialectlabs/react-ui';
import BroadcastAdmin from './BroadcastAdmin';
import AdminFormLayout from '../AdminFormLayout';

interface BroadcastProps {
  headless?: boolean;
  notificationTypeId?: string;
}

export function UnwrappedBroadcastForm(props: BroadcastProps) {
  const { dapp } = useDapp();

  if (!dapp) {
    return null;
  }

  console.log(dapp);

  return <BroadcastAdmin dapp={dapp} {...props} />;
}

function Broadcast(props: BroadcastProps) {
  return (
    <AdminFormLayout>
      <WalletStatesWrapper
        notConnectedMessage={
          <>
            Connect your Dapp’s wallet to create
            <br />
            broadcast notifications
          </>
        }
      >
        <ConnectionWrapper>
          <DashboardWrapper>
            <UnwrappedBroadcastForm {...props} />
          </DashboardWrapper>
        </ConnectionWrapper>
      </WalletStatesWrapper>
    </AdminFormLayout>
  );
}

export default Broadcast;
