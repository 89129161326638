import { PublicKey } from '@solana/web3.js';
import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { borrowUSDr, BORROW_ACTION, calculateLiquidationPrice } from '../../../utils/ratio-lending';
import Button from '../../Button';
import CustomInput from '../../CustomInput';
import AmountSlider from '../AmountSlider';
import LiquidationSlider from '../LiquidationSlider';
// import LiquidationEstimatedToken from '../LiquidationEstimatedToken';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  useAppendUserAction,
  useUserVaultInfo,
  usePoolInfo,
  useSubscribeTx,
  useRFStateInfo,
} from '../../../contexts/state';
import { isWalletApproveError } from '../../../utils/utils';
import { USDR_MINT_DECIMALS, USDR_MINT_KEY } from '../../../constants';
import { InProgressPage } from '../../../utils/utils';

const GenerateModal = ({ data }: any) => {
  const theme = useContext(ThemeContext);
  const { darkMode } = theme.state;
  const [show, setShow] = useState(false);
  const { connection } = useConnection();
  const wallet = useWallet();
  // eslint-disable-next-line
  const [mintTime, setMintTime] = useState('');

  const userState = useUserVaultInfo(data.mint);

  const [borrowAmount, setBorrowAmount] = useState<any>();

  const [mintStatus, setMintStatus] = useState(false);
  const [invalidStr, setInvalidStr] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [isMinting, setIsMinting] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const [amountValue, setAmountValue] = useState(0);
  const poolInfo = usePoolInfo(data?.mint);
  const globalState = useRFStateInfo();

  const appendUserAction = useAppendUserAction();
  const subscribeTx = useSubscribeTx();

  const borrow_fee = poolInfo.borrowFeeNumer.toNumber() / globalState.feeDeno.toNumber();

  useEffect(() => {
    if (userState) {
      const endDateOfLock = userState.lastMintTime.toNumber() + 3600;
      const unlockDateString = moment(new Date(endDateOfLock * 1000)).format('MM / DD /YYYY HH : MM : SS');

      setMintTime(unlockDateString);
    }
    return () => {
      setMintTime('');
    };
  }, [userState]);

  useEffect(() => {
    setDidMount(true);
    setBorrowAmount('');
    return () => setDidMount(false);
  }, []);

  const [estimatedHealth, setEstimatedHealth] = useState(0);

  // useEffect(() => {
  //   if (userState) {
  //     const newHealth = calculateLiquidationPrice(
  //       userState.poolInfo,
  //       userState.lockedUiAmount,
  //       userState.uiDebt + borrowAmount
  //     );
  //     console.log(newHealth);
  //     setEstimatedHealth(newHealth);
  //   }
  // }, [borrowAmount, userState]);

  if (!didMount) {
    return null;
  }

  const borrow = async () => {
    console.log('borrowAmount', +borrowAmount);
    if (!(+borrowAmount > 0 && +borrowAmount <= data.usdrValue)) {
      setMintStatus(true);
      setInvalidStr('Amount is invalid to generate USDr!');
      return;
    }

    setIsMinting(true);
    borrowUSDr(connection, wallet, +borrowAmount * 10 ** USDR_MINT_DECIMALS, new PublicKey(data.mint))
      .then((txHash: string) => {
        subscribeTx(
          txHash,
          () => toast.info('Mint Transaction Sent'),
          () => toast.success('Mint Confirmed.'),
          () => toast.error('Mint Transaction Failed')
        );
        appendUserAction(
          wallet.publicKey.toString(),
          data.mint,
          USDR_MINT_KEY,
          BORROW_ACTION,
          +borrowAmount,
          txHash,
          poolInfo.fairPrice,
          poolInfo.virtualPrice,
          borrowAmount * borrow_fee
        );
      })
      .catch((e) => {
        console.log(e);
        if (isWalletApproveError(e)) toast.warn('Wallet is not approved!');
        else toast.error('Transaction Error!');
      })
      .finally(() => {
        setIsMinting(false);
        setShow(false);
      });
  };

  return (
    <div className="">
      <Button
        className="px-8 py-2.5 font-poppins font-medium button--blue min-w-[120px]"
        onClick={() => setShow(!show)}
        disabled={data.usdrValue <= 0}
      >
        Mint
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setButtonDisabled(true);
        }}
        onEntered={() => {
          setBorrowAmount('');
          setAmountValue(0);
          setMintStatus(false);
          setButtonDisabled(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-theme={darkMode ? 'dark' : 'light'}
        className={darkMode ? 'dark' : 'light'}
      >
        <Modal.Header className="border-b bg-white-900 rounded-tl-xl rounded-tr-xl dark:bg-slate-700 border-white-500 dark:border-gray-600">
          <div className="p-[20px]">
            <IoMdClose
              size={32}
              className="absolute cursor-pointer top-12 right-10 hover:opacity-75 dark:text-white-900"
              onClick={() => {
                setButtonDisabled(true);
                setShow(false);
              }}
            />
            <div>
              <img src={data?.icons[0]} alt={data?.icons[0]?.toString()} />
            </div>
            <p className="mt-3 text-2xl font-semibold text-gray-200 font-poppins dark:text-white-900">Mint USDr</p>
            <p className="mt-1 text-base text-gray-100 font-poppins dark:text-white-900">
              Mint up to <strong>{data.usdrValue} USDr</strong>
            </p>
            <div className="mt-1 text-sm text-gray-200 font-poppins dark:text-white-900">
              Interest rate: <span className="font-semibold text-blue-500">150% APY</span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="dark:bg-slate-800 bg-slate-100 rounded-bl-xl rounded-br-xl">
          <div className="p-[20px]">
            <label className="text-sm text-gray-200 font-poppins dark:text-white-900">
              How much would you like to mint?
            </label>
            <CustomInput
              appendStr="Max"
              initValue={0}
              appendValueStr={'' + data.usdrValue}
              tokenStr={`USDr`}
              onTextChange={(value: any) => {
                setAmountValue((value / data.usdrValue) * 100);
                setBorrowAmount(value);
                setMintStatus(false);
                setButtonDisabled(false);
              }}
              maxValue={data.usdrValue}
              valid={mintStatus}
              invalidStr={invalidStr}
              value={borrowAmount}
            />
            <AmountSlider
              onChangeValue={(value) => {
                setBorrowAmount(Number(data.usdrValue * (value / 100)).toFixed(2));
                setAmountValue(value);
                setMintStatus(false);
                setButtonDisabled(false);
              }}
              value={amountValue}
            />
            <div style={{ color: '#07b127', fontSize: 12 }}>
              There will be a 0.5% loan origination fee associated with this transaction.
            </div>
            {/* {InProgressPage() && (
              <LiquidationSlider currentValue={userState?.userHealth} estimatedValue={estimatedHealth} />
            )} */}
            {/* <LiquidationEstimatedToken /> */}
            <Button
              disabled={borrowAmount <= 0 || buttonDisabled || isNaN(borrowAmount) || isMinting}
              className="w-full py-3 mt-6 font-medium button--blue font-poppins"
              onClick={borrow}
            >
              Mint USDr
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GenerateModal;
