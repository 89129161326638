import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { ThemeContext } from '../../../contexts/ThemeContext';
import Button from '../../Button';
import CustomInput from '../../CustomInput';
import AmountSlider from '../AmountSlider';
import LiquidationSlider from '../LiquidationSlider';
// import LiquidationEstimatedToken from '../LiquidationEstimatedToken';

import { useGetPoolManager } from '../../../hooks/useGetPoolManager';
import { useVaultsContextProvider } from '../../../contexts/vaults';
import { LPair } from '../../../types/VaultTypes';
import { useAppendUserAction, usePoolInfo, useSubscribeTx, useUserVaultInfo } from '../../../contexts/state';
import { isWalletApproveError } from '../../../utils/utils';
import { TokenAmount } from '../../../utils/safe-math';
import { calculateLiquidationPrice, WIHTDRAW_ACTION } from '../../../utils/ratio-lending';
import { USDR_MINT_DECIMALS } from '../../../constants';
import { InProgressPage } from '../../../utils/utils';

const WithdrawModal = ({ data }: any) => {
  const theme = useContext(ThemeContext);
  const { darkMode } = theme.state;
  const [show, setShow] = useState(false);

  const { connection } = useConnection();
  const wallet = useWallet();
  const poolInfo = usePoolInfo(data?.mint);
  const vaultInfo = useUserVaultInfo(data?.mint);

  const [withdrawAmount, setWithdrawAmount] = useState<any>();
  const [withdrawStatus, setWithdrawStatus] = useState(false);
  const [invalidStr, setInvalidStr] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(+data.debtValue !== 0);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [amountValue, setAmountValue] = useState(0);
  const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(0);

  const { vaults } = useVaultsContextProvider();
  const vault = useMemo(() => vaults.find((vault) => vault.address_id === (data.mint as string)), [vaults]);

  const PoolManagerFactory = useGetPoolManager(vault);
  const withdrawAmountUSD = new TokenAmount(withdrawAmount * data.tokenPrice, USDR_MINT_DECIMALS).fixed();

  const appendUserAction = useAppendUserAction();
  const subscribeTx = useSubscribeTx();

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    setWithdrawAmount('');
    return () => setDidMount(false);
  }, []);

  useEffect(() => {
    if (vaultInfo && poolInfo) {
      if (vaultInfo.debt === 0) {
        setMaxWithdrawalAmount(data.value);
      } else {
        const minTVLUSD = Math.ceil(vaultInfo.debt / poolInfo.ratio);
        const minColl =
          Math.ceil((minTVLUSD * 10 ** USDR_MINT_DECIMALS) / poolInfo.oraclePrice) / 10 ** USDR_MINT_DECIMALS;
        setMaxWithdrawalAmount(Math.max(+(data.value - minColl).toFixed(poolInfo.mintDecimals), 0));
      }
    } else setMaxWithdrawalAmount(0);
  }, [vaultInfo, poolInfo]);

  const [estimatedHealth, setEstimatedHealth] = useState(0);

  // useEffect(() => {
  //   if (vaultInfo) {
  //     const newHealth = calculateLiquidationPrice(
  //       vaultInfo.poolInfo,
  //       vaultInfo.lockedUiAmount - withdrawAmount,
  //       vaultInfo.uiDebt
  //     );
  //     setEstimatedHealth(newHealth);
  //   }
  // }, [withdrawAmount, vaultInfo]);

  if (!didMount) {
    return null;
  }

  const withdraw = async () => {
    try {
      console.log('Withdrawing', withdrawAmount);
      if (!(withdrawAmount && data.value >= withdrawAmount)) {
        setWithdrawStatus(true);
        setInvalidStr('Insufficient funds to withdraw!');
        return;
      }

      setIsWithdrawing(true);

      const txHash = await PoolManagerFactory?.withdrawLP(
        connection,
        wallet,
        vault as LPair,
        withdrawAmount * Math.pow(10, poolInfo?.mintDecimals ?? 0)
      );
      if (txHash) {
        subscribeTx(
          txHash,
          () => toast.info('Withdraw Transaction Sent'),
          () => toast.success('Withdraw Confirmed.'),
          () => toast.error('Withdraw Transaction Failed')
        );

        appendUserAction(
          wallet.publicKey.toString(),
          data.mint,
          data.mint,
          WIHTDRAW_ACTION,
          +withdrawAmount,
          txHash,
          poolInfo.fairPrice,
          poolInfo.virtualPrice,
          0
        );

        setWithdrawAmount(0);
      }
    } catch (err) {
      if (isWalletApproveError(err)) toast.warn('Wallet is not approved!');
      else toast.error('Transaction Error!');
    }
    setIsWithdrawing(false);
    setShow(false);
  };

  return (
    <div className="">
      <Button
        className="px-6 py-2.5 text-sm hover:opacity-80 font-medium text-blue-300 border-t-2 border-b-2 border-l-2 border-r-2 border-blue-300 font-poppins dark:border-t-0 dark:border-l-0 dark:border-r-0 dark:border-b-0 dark:bg-white-900 dark:py-3 min-w-[120px]"
        onClick={() => setShow(!show)}
      >
        Withdraw
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setButtonDisabled(true);
          setShow(false);
        }}
        onEntered={() => {
          setAmountValue(0);
          setWithdrawAmount('');
          setWithdrawStatus(false);
          setButtonDisabled(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-theme={darkMode ? 'dark' : 'light'}
        className={darkMode ? 'dark' : 'light'}
      >
        <Modal.Header className="border-b bg-white-900 rounded-tl-xl rounded-tr-xl dark:bg-slate-700 border-white-500 dark:border-gray-600">
          <div className="p-[20px]">
            <IoMdClose
              size={32}
              className="absolute cursor-pointer top-12 right-10 hover:opacity-75 dark:text-white-900"
              onClick={() => {
                setButtonDisabled(true);
                setShow(false);
              }}
            />
            <div>
              {data.icon && (
                <div>
                  <img src={data.icon} alt={data.title} className="w-24" />
                </div>
              )}
            </div>
            <p className="mt-3 text-2xl font-semibold text-gray-200 font-poppins dark:text-white-900">
              Withdraw assets from vault
            </p>
            <p className="mt-1 text-base text-gray-100 font-poppins dark:text-white-900">
              Withdraw up to{' '}
              <span className="font-semibold">
                {maxWithdrawalAmount} {data.title}
              </span>{' '}
              tokens from your vault.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="dark:bg-slate-800 bg-slate-100 rounded-bl-xl rounded-br-xl">
          <div className="p-[20px]">
            <p className="mb-2 text-sm text-gray-200 font-poppins dark:text-white-900">
              How much would you like to withdraw?
            </p>
            <CustomInput
              appendStr="Max"
              initValue={0}
              appendValueStr={`${maxWithdrawalAmount}`}
              tokenStr={`${data.title}`}
              onTextChange={(value: any) => {
                setAmountValue((value / maxWithdrawalAmount) * 100);
                setWithdrawAmount(value);
                setWithdrawStatus(false);
                setButtonDisabled(false);
              }}
              maxValue={maxWithdrawalAmount}
              valid={withdrawStatus}
              invalidStr={invalidStr}
              value={withdrawAmount}
            />
            <p className="mt-2 text-sm text-gray-200 font-poppins dark:text-white-900">
              USD: <strong className="font-semibold font-poppins">${withdrawAmountUSD}</strong>
            </p>
            <AmountSlider
              onChangeValue={(value) => {
                setWithdrawAmount(Number(maxWithdrawalAmount * (value / 100)).toFixed(2));
                setAmountValue(value);
                setWithdrawStatus(false);
                setButtonDisabled(false);
              }}
              value={amountValue}
            />
            {/* {InProgressPage() && (
              <LiquidationSlider currentValue={vaultInfo?.userHealth} estimatedValue={estimatedHealth} />
            )} */}
            {/* <LiquidationEstimatedToken /> */}
            <Button
              className="w-full py-3 mt-6 font-medium button--blue font-poppins"
              disabled={withdrawAmount <= 0 || buttonDisabled || isWithdrawing}
              onClick={withdraw}
            >
              Withdraw Assets
            </Button>
            <p className="mt-2 text-sm font-normal text-center text-gray-200 font-poppins dark:text-white-900">
              Please harvest your rewards before you withdraw
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WithdrawModal;
