import { PublicKey } from '@solana/web3.js';
export const RAYDIUM_FARM_PROGRAM_ID = new PublicKey('9KEPoZmtHUrBbhWN1v1KWLMkkvwY6WLtAVUCPRtRjP4z');
export const RAYDIUM_FARM_VERSION = 5;
export const SABER_SWAP_PROGRAM_ID = new PublicKey('SSwpkEEcbUqx4vtoEByFjSkhKdCT862DNVb52nZg1UZ');

export const REFRESH_TIME_INTERVAL = +(process.env.REACT_APP_REFRESH_TIME_INTERVAL || 60_000);
export const REWARD_TIME_INTERVAL = +(process.env.REACT_APP_REWARD_TIME_INTERVAL || 1_000);
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3001';

export enum Roles {
  ADMIN = 'ADMIN',
  USER = 'USER',
  DEVELOPER = 'DEVELOPER',
}

export const DEFAULT_NETWORK = 'mainnet-qn1';

export const Networks = [
  { value: 'mainnet', label: 'Mainnet' },
  { value: 'devnet', label: 'Devnet' },
];

export const WRAPPED_SOL_MINT = 'So11111111111111111111111111111111111111112';
export const USDC_MINT = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v';
export const RAYDIUM_USDT_USDC_LP_MINT = 'As3EGgLtUVpdNpE6WCKauyNRrCCwcQ57trWQ3wyRXDa6';

export const RATIO_MINT_KEY = 'ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J';
export const RATIO_MINT_DECIMALS = 6;

export const RATIO_LENDING_PROGRAM_ID =
  process.env.REACT_APP_RF_LENDING_ID || 'RFLeGTwFXiXXETdJkZuu9iKgXNkYbywLpTu1TioDsDQ';
export const USDR_MINT_KEYPAIR = [];
export const REAL_USDR_MINT = 'USDrbBQwQbQ2oWHUPfA8QBHcyVxKUq1xHyXsSLKdUq2';
export const USDR_MINT_KEY = process.env.REACT_APP_USDR_MINT || REAL_USDR_MINT;
export const USDR_MINT_DECIMALS = 6;

type ENV = 'mainnet-beta' | 'testnet' | 'devnet' | 'localnet';
export const SOLANA_CLUSTER: ENV = 'mainnet-beta';
export const DEFAULT_FEE_NUM = 30;
