import { useMemo, useState } from 'react';
// import { Table } from 'react-bootstrap';
import Button from '../../Button';
import { useGetPoolManager } from '../../../hooks/useGetPoolManager';
import { useVaultsContextProvider } from '../../../contexts/vaults';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { LPair } from '../../../types/VaultTypes';
import { toast } from 'react-toastify';
import {
  useAppendUserAction,
  useUserVaultInfo,
  useSubscribeTx,
  useRFStateInfo,
  usePoolInfo,
} from '../../../contexts/state';
import { isWalletApproveError } from '../../../utils/utils';
import { isSwimPool } from '../../../utils/helper';
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import { harvestRatioReward, HARVEST_ACTION } from '../../../utils/ratio-lending';
import RatioIcon from '../../../assets/images/USDr.svg';

const TokensEarned = ({ data }: any) => {
  const { vaults } = useVaultsContextProvider();
  const vault = useMemo(() => vaults.find((vault) => vault.address_id === (data.mintAddress as string)), [vaults]);

  const { connection } = useConnection();
  const wallet = useWallet();

  const poolManager = useGetPoolManager(vault);

  const poolInfo = usePoolInfo(data.mint);
  const vaultState = useUserVaultInfo(data.mintAddress);

  const [isHarvesting, setIsHarvesting] = useState(false);
  const [isHarvestingRatio, setIsHarvestingRatio] = useState(false);

  const appendUserAction = useAppendUserAction();
  const subscribeTx = useSubscribeTx();
  const globalState = useRFStateInfo();

  const harvest_reward_fee = poolInfo?.harvestFeeNumer.toNumber() / globalState.feeDeno.toNumber();

  const harvest = async () => {
    try {
      if (!poolManager || !poolManager?.harvestReward) {
        throw new Error('Pool manager factory not initialized');
      }

      console.log('Harvesting...');
      setIsHarvesting(true);
      const txHash = await poolManager?.harvestReward(connection, wallet, vault as LPair);
      subscribeTx(
        txHash,
        () => toast.info('Harvest Transaction Sent'),
        () => toast.success('Harvest Confirmed.'),
        () => toast.error('Harvest Transaction Failed')
      );
      appendUserAction(
        wallet.publicKey.toString(),
        data.mintAddress,
        data.realUserRewardMint,
        HARVEST_ACTION,
        vaultState ? vaultState.reward : 0,
        txHash,
        0,
        0,
        harvest_reward_fee
      );
    } catch (err) {
      console.error(err);
      if (isWalletApproveError(err)) toast.warn('Wallet is not approved!');
      else toast.error('Transaction Error!');
    }
    setIsHarvesting(false);
  };

  const harvestRatio = async () => {
    try {
      console.log('Harvesting Ratio...');
      setIsHarvestingRatio(true);
      const txHash = await harvestRatioReward(connection, wallet, vault.address_id);
      subscribeTx(
        txHash,
        () => toast.info('Harvest Ratio Transaction Sent'),
        () => toast.success('Harvest Ratio Confirmed.'),
        () => toast.error('Harvest Ratio Transaction Failed')
      );
      console.log('Amount to Harvest ' + vaultState.ratioReward);
      appendUserAction(
        wallet.publicKey.toString(),
        data.mintAddress,
        'ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J',
        HARVEST_ACTION,
        vaultState ? vaultState.ratioReward : 0,
        txHash,
        0,
        0,
        0
      );
    } catch (err) {
      console.error(err);
      if (isWalletApproveError(err)) toast.warn('Wallet is not approved!');
      else toast.error('Transaction Error!');
    }
    setIsHarvestingRatio(false);
  };

  return (
    <div className="mt-8">
      <p className="pl-6 text-lg font-medium text-gray-200 font-poppins dark:text-white-900">Tokens Earned</p>
      <table className="w-full mt-3 lg:min-w-full min-w-max">
        <thead>
          <tr>
            <th className="pl-6 text-sm font-light text-gray-100 border-b-2 border-white-500 dark:border-gray-600 font-poppins dark:text-white-900">
              Asset
            </th>
            <th className="text-sm font-light text-gray-100 border-b-2 border-white-500 dark:border-gray-600 font-poppins dark:text-white-900">
              Rewards
            </th>
            <th className="text-sm font-light text-gray-100 border-b-2 border-white-500 dark:border-gray-600 font-poppins dark:text-white-900">
              USD
            </th>
            <th className="text-sm font-light text-gray-100 border-b-2 border-white-500 dark:border-gray-600 font-poppins dark:text-white-900"></th>
          </tr>
        </thead>
        <tbody>
          {!isSwimPool(data?.platform?.symbol) && (
            <tr>
              <td className="py-[15px] border-b border-white-500 pl-6 dark:border-gray-600">
                <img src={data?.platform?.icon} alt="SBR" className="w-8" />
              </td>
              <td className="text-sm font-medium text-gray-200 border-b dark:border-gray-600 font-poppins border-white-500 dark:text-white-900">
                {vaultState ? vaultState.reward : 0}
              </td>
              <td className="text-sm font-medium text-gray-200 border-b dark:border-gray-600 font-poppins border-white-500 dark:text-white-900">
                {!vaultState?.rewardUSD ? (
                  <LoadingSpinner className="spinner-border-sm text-info" />
                ) : (
                  `$  ${vaultState?.rewardUSD}`
                )}
              </td>
              <td className="pr-2 text-sm font-medium text-gray-200 border-b dark:border-gray-600 font-poppins border-white-500 ">
                <Button className="button--blue py-[10px] btn-block" onClick={harvest} disabled={isHarvesting}>
                  Harvest
                </Button>
              </td>
            </tr>
          )}
          <tr>
            <td className="py-[15px] pl-6">
              <img src={RatioIcon} alt="RatioIcon" className="w-8" />
            </td>
            <td className="text-sm font-medium text-gray-200 font-poppins dark:text-white-900">
              {vaultState ? vaultState.ratioReward : 0}{' '}
            </td>
            <td className="text-sm font-medium text-gray-200 font-poppins dark:text-white-900">
              {!vaultState?.ratioRewardUSD ? (
                <LoadingSpinner className="spinner-border-sm text-info" />
              ) : (
                `$  ${vaultState?.ratioRewardUSD}`
              )}
            </td>
            <td className="pr-2 text-sm font-medium text-gray-200 font-poppins">
              <Button
                className="px-2 button--blue py-[10px] btn-block"
                onClick={harvestRatio}
                disabled={isHarvestingRatio}
              >
                Harvest
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <div className="px-4">
        <Button className="button--blue generate btn-block" onClick={harvest} disabled={isHarvesting}>
          Harvest
        </Button>
      </div> */}
    </div>
  );
};

export default TokensEarned;
