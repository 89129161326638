import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import { shortenAddress } from '../../utils/utils';
import { useLocalStorageState } from '../../utils/utils';
import { TokenAmount } from '../../utils/safe-math';
import { USDR_MINT_DECIMALS } from '../../constants';
import { useWallet } from '@solana/wallet-adapter-react';
import { useRFStateInfo } from '../../contexts/state';
import { NavBarProgressBarTotalUSDr } from '../Navbar/NavBarProgressBarTotalUSDr';
import GuideModal from '../GuideModal';
import SwitchButton from '../SwitchButton';

import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
// import { useAutoConnect } from '../../contexts/AutoConnectProvider';
import { NotificationsButton } from '@dialectlabs/react-ui';

// import PhantomIcon from '../../assets/images/phantom.svg';

type HeaderProps = {
  onClickWalletBtn: () => void;
  darkMode: boolean;
  enable?: boolean;
};

const Header = (headerProps: HeaderProps) => {
  const { onClickWalletBtn, enable } = headerProps;
  const wallet = useWallet();
  const { connected, connect } = useWallet();
  const [hover, setHover] = React.useState(false);
  const history = useHistory();
  const [autoConnect, setAutoConnect] = useLocalStorageState('autoConnect');
  const [providerUrl, setProviderUrl] = useLocalStorageState('walletProvider');

  const globalState = useRFStateInfo();
  const currentTVL = Number(new TokenAmount(globalState?.tvlUsd ?? 0, USDR_MINT_DECIMALS).fixed());

  useEffect(() => {
    if (connected) {
      setHover(false);
    }
  }, [connected]);

  const onClickDisconnect = () => {
    wallet?.disconnect();
    setAutoConnect(null);
    setProviderUrl(null);
    history.push('/app/dashboard');
    window.location.reload();
  };

  const renderWalletConnection = () => {
    if (connected) {
      return (
        <div
          className="flex relative gap-2 items-center w-48 px-3 pt-3 pb-2.5 border-t border-b border-l border-r border-white-400 dark:border-gray-200 cursor-pointer rounded-lg dark:text-white-900
          before:content-['Connected_Wallet'] before:absolute before:-top-2 before:left-1.5 before:text-slate-400 before:font-poppins before:text-xs before:font-medium before:bg-white before:px-1.5 before:dark:bg-gray-900 before:dark:text-white-900 before:bg-white-900"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onClickDisconnect}
        >
          <div
            className={classNames({
              'p-1.5 bg-green-200 text-green-900 rounded-full': !hover,
              'p-1.5 bg-rose-200 rounded-full text-rose-600': hover,
            })}
          >
            {hover ? <IoMdCloseCircle /> : <FaCheck />}
          </div>
          {hover ? (
            <h6 className="!mb-0 font-poppins font-regular text-regular text-gray-200 dark:text-white-900">
              Disconnect
            </h6>
          ) : (
            <h6 className="!mb-0 font-poppins font-regular text-regular text-gray-200 dark:text-white-900">
              {shortenAddress(`${wallet?.publicKey}`)}
            </h6>
          )}
        </div>
      );
    } else {
      return (
        <button
          onClick={connected ? onClickWalletBtn : connect}
          className="px-3 py-3 text-sm font-medium button button--blue font-poppins"
        >
          Connect Wallet
        </button>
      );
    }
  };

  const renderTotalTVLCap = () => {
    return enable && connected ? (
      <div
        className="relative px-3 pt-4 pb-4 min-w-[120px] md:min-w-[90px] border-t border-b border-l border-r border-white-400 dark:border-gray-200 cursor-pointer rounded-lg font-poppins font-medium text-green-900 dark:text-green-300
        before:content-['Total_TVL'] before:absolute before:-top-2 before:left-1.5 before:text-slate-400 before:font-poppins before:text-xs before:font-normal before:bg-white before:px-1.5 before:dark:bg-gray-900 before:bg-white-900 before:dark:text-white-900"
      >
        {currentTVL.toLocaleString('en-US', { maximumFractionDigits: 2 })}
      </div>
    ) : null;
  };

  const renderTotalUSDrDebt = () => {
    return enable && connected ? (
      <div
        className="relative px-3 pt-4 pb-2.5 border-t border-b border-l border-r border-white-400 dark:border-gray-200 cursor-pointer rounded-lg font-poppins font-medium text-green-900 dark:text-green-300
        before:content-['Total_USDr_Debt'] before:absolute before:-top-2 before:left-1.5 before:text-slate-400 before:font-poppins before:text-xs before:font-normal before:bg-white before:px-1.5 before:dark:bg-gray-900 before:dark:text-white-900 before:bg-white-900"
      >
        <NavBarProgressBarTotalUSDr className="w-100" shouldDisplayLabel={false} shouldDisplayCurrency={true} />
      </div>
    ) : null;
  };

  return (
    <div className="flex items-center justify-end px-6 py-6 w-100">
      <div className="justify-end gap-4 xl:flex xl:items-center lg:block w-100">
        <div className="flex items-center justify-end gap-3 lg:order-last">
          <div className="hidden md:block">{connected && enable && <GuideModal />}</div>
          <SwitchButton />
          {/* {renderWalletConnection()} */}
          {connected && wallet?.publicKey.toString() !== process.env.REACT_APP_DIALECT_ADDRESS && (
            <NotificationsButton
              dialectId="dialect-notifications"
              notifications={[
                {
                  name: 'Welcome to Ratio Finance!',
                  detail:
                    'Stay up to date with the latest cap updates, liquidation alerts, collateral additions and more',
                },
              ]}
              pollingInterval={15000}
              channels={['web3', 'email', 'sms', 'telegram']}
              bellClassName="!shadow-sm"
            />
          )}
          <WalletMultiButton
            className={classNames(
              '!py-[1.7rem] md:mr-4 rounded-[0.5rem]',
              connected
                ? "bg-white-900 dark:bg-gray-900 dark:text-white-900 dark:border-gray-200 text-gray-200 border border-solid border-white-500 before:content-['Connected_Wallet'] before:absolute before:-top-2 before:left-1.5 before:text-slate-400 before:font-poppins before:text-xs before:font-normal before:bg-white before:px-1.5 before:dark:bg-gray-900 before:dark:text-white-900 before:bg-white-900"
                : 'button button--blue'
            )}
            // startIcon={<PhantomIcon />}
          >
            {!connected ? 'Connect Wallet' : shortenAddress(`${wallet?.publicKey}`)}
          </WalletMultiButton>
        </div>
        <div className="flex gap-3 mt-[20px] md:mt-5 xl:mt-0">
          <div className="w-100">{renderTotalUSDrDebt()}</div>
          <div className="">{renderTotalTVLCap()}</div>
        </div>
        {connected && <div className="w-0.5 h-6 bg-slate-300 hidden xl:block" />}
      </div>
    </div>
  );
};

export default Header;
