import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from '../../../contexts/ThemeContext';
import Button from '../../Button';
import TokenSelect from '../TokenSelect';

import { RadioGroup } from '@headlessui/react';
import { useFetchTokens } from '../../../hooks/useFetchTokens';
import { FetchingStatus } from '../../../types/fetching-types';
import { actionTypes, selectors } from '../../../features/dashboard';

import { IoFilter, IoClose } from 'react-icons/io5';
import { HiOutlineCheck } from 'react-icons/hi';
import { XCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const vaultTypes = [
  {
    name: 'Volatile',
    value: 'volatile',
  },
  {
    name: 'Stable',
    value: 'stable',
  },
];

const lendingTypes = [
  {
    name: 'Mints USDr',
    value: 'mints',
  },
  {
    name: 'No Minting',
    value: 'no_mints',
  },
];

const platforms = [
  {
    name: 'SABER',
    value: 'saber',
    icon: '/images/saber.svg',
  },
  {
    name: 'ORCA',
    value: 'orca',
    icon: '/images/orca.svg',
  },
  {
    name: 'RAYDIUM',
    value: 'raydium',
    icon: '/images/raydium.svg',
  },
];

const RadioBox = ({ options, value, onChange, className }: any) => {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <div className={classNames('flex items-center justify-center space-x-4', className)}>
        {options.map((type) => (
          <RadioGroup.Option
            key={type.name}
            value={type}
            className={({ active, checked }) =>
              `${checked ? 'ring-2 ring-white dark:ring-0 ring-opacity-60 ring-offset-2 ring-offset-blue-500' : ''}
                ${checked ? 'text-white-900' : 'bg-white dark:bg-slate-800 dark:text-white-900'}
                relative flex justify-between cursor-pointer rounded-lg py-2.5 focus:outline-none w-full border-t border-r border-l border-b border-white-500 dark:border-gray-600`
            }
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center justify-center w-full">
                  <div className="flex items-center">
                    <div className="text-base font-poppins">
                      <RadioGroup.Label
                        as="p"
                        className={`text-gray-200 dark:text-white-900 flex items-center gap-2 ${
                          checked ? 'font-semibold' : 'font-normal'
                        }`}
                      >
                        {type.icon && <img src={type.icon} alt={type.name} />}
                        {type.name}
                      </RadioGroup.Label>
                    </div>
                  </div>
                  {checked && (
                    <div className="absolute p-px bg-blue-500 rounded-full -top-2 -right-2 text-white-900 shrink-0">
                      <HiOutlineCheck className="w-4 h-4" />
                    </div>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

const FilterModal = () => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const { darkMode } = theme.state;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVaultType, setVaultTypeSelected] = useState();
  const [selectedMintingType, setMintingTypeSelected] = useState();
  const [selectedPlatform, setPlatformSelected] = useState();

  const filter_data = useSelector(selectors.getFilterData);

  const { tokens, status, error } = useFetchTokens();

  const filterOptions = useMemo(() => {
    if (tokens.length === 0 || status !== FetchingStatus.Finish) {
      return [];
    }
    const t = tokens.filter((item: any) => item.status === 1);

    return t.map(({ symbol, icon }) => {
      return {
        value: symbol,
        label: symbol,
        network: null, // For the moment we leave it as null, since we will have to integrate the rest of the platforms later on
        icon: [icon],
      };
    });
  }, [tokens, status]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const onFilterChange = (values: any) => {
    dispatch({ type: actionTypes.SET_FILTER_DATA, payload: values });
  };

  const handleRemoveValue = (e: any) => {
    if (!onFilterChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue = filter_data.find((val: any) => val.value === buttonName);
    if (!removedValue) return;
    onFilterChange(filter_data.filter((val: any) => val.value !== buttonName));
  };

  const onClearFilters = () => {
    onFilterChange([]);
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="px-4 py-2.5 flex items-center gap-2 text-sm font-normal text-gray-200 bg-black border-t border-b border-l border-r rounded-md font-poppins bg-opacity-20 hover:bg-opacity-30 border-white-600 dark:border-gray-600 dark:bg-slate-800 dark:text-white-900"
        >
          <IoFilter className="w-5 h-5 text-gray-200 dark:text-white-900" />
          Filters
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            return;
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className={classNames('fixed inset-0 overflow-y-auto', { dark: darkMode })}>
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg px-10 py-8 overflow-hidden text-left align-middle transition-all transform bg-white border shadow-xl dark:bg-slate-800 dark:border-gray-600 rounded-2xl">
                  <div className="relative flex items-end justify-end">
                    <IoClose
                      className="w-8 h-8 text-gray-200 cursor-pointer dark:text-white-900 hover:opacity-80"
                      onClick={closeModal}
                    />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-semibold leading-6 text-gray-200 dark:text-white-900 font-poppins"
                  >
                    Select Filters
                  </Dialog.Title>
                  <div className="mt-6">
                    <p className="text-sm font-normal text-gray-200 dark:text-white-900 font-poppins">Vault Type</p>
                    <RadioBox
                      options={vaultTypes}
                      value={selectedVaultType}
                      onChange={setVaultTypeSelected}
                      className="mt-2.5"
                    />
                    <p className="mt-4 text-sm font-normal text-gray-200 dark:text-white-900 font-poppins">Lending</p>
                    <RadioBox
                      options={lendingTypes}
                      value={selectedMintingType}
                      onChange={setMintingTypeSelected}
                      className="mt-2.5"
                    />
                  </div>
                  <div className="relative">
                    <p className="relative mt-4 text-sm font-normal text-gray-200 dark:text-white-900 font-poppins">
                      Tokens
                    </p>
                    <div className="relative w-full">
                      <TokenSelect
                        options={filterOptions}
                        onFilterChange={onFilterChange}
                        filterValue={filter_data}
                        placeholder="Search vaults by token"
                        isMulti
                      />
                    </div>
                    <div className="flex flex-wrap items-center gap-2">
                      {filter_data.map((val: any) => (
                        <div
                          key={val.value}
                          className="flex items-center gap-1 pl-1.5 pr-1 border-t border-b border-l border-r rounded-xl border-slate-300 dark:border-slate-600 py-1 shadow-sm"
                        >
                          <img src={val.icon[0]} alt="icon" className="w-4 h-4 rounded-full" />
                          <p className="text-xs font-medium font-poppins dark:text-white-900">{val.label}</p>
                          <button name={val.value} onClick={handleRemoveValue} className="hover:opacity-80">
                            <XCircleIcon className="w-4 text-slate-500 dark:text-white-900" />
                          </button>
                        </div>
                      ))}
                      {filter_data.length > 0 && (
                        <button
                          onClick={onClearFilters}
                          className="text-xs font-medium text-gray-400 dark:text-white-900 font-poppins hover:opacity-75"
                        >
                          Clear all filters
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="mt-6">
                    <p className="mt-4 text-sm font-normal text-gray-200 font-poppins dark:text-white-900">Platform</p>
                    <RadioBox
                      options={platforms}
                      value={selectedPlatform}
                      onChange={setPlatformSelected}
                      className="mt-2.5"
                    />
                  </div>
                  <div className="mt-7">
                    <Button
                      disabled={false}
                      className="w-full py-3 text-base font-medium button button--blue font-poppins"
                      onClick={closeModal}
                    >
                      Apply Filters
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default FilterModal;
