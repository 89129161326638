import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
} from '@solana/spl-token-v2';
import * as anchor from '@project-serum/anchor';
import {
  Connection,
  PublicKey,
  SystemProgram,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  Transaction,
} from '@solana/web3.js';
import { getProgramInstance, sendAndConfirmAllTransaction, sendTransaction } from './rf-web3';
import {
  RATIO_MINT_DECIMALS,
  USDR_MINT_DECIMALS,
  USDR_MINT_KEY,
  RAYDIUM_FARM_PROGRAM_ID,
  RATIO_MINT_KEY,
  REAL_USDR_MINT,
} from '../constants';
import { calculateSaberReward, getQuarryInfo } from './PoolInfoProvider/saber/saber-utils';
import {
  getATAKey,
  getBlacklistPDA,
  getGlobalStatePDA,
  getOraclePDA,
  getPoolPDA,
  getUserStatePDA,
  getVaultPDA,
} from './ratio-pda';
import { BN } from '@project-serum/anchor';
import { TokenAmount } from './safe-math';
import {
  calculateRaydiumReward,
  getAssociatedLedgerAccount,
  getRaydiumFarmInfo,
  isRaydiumLp,
} from './PoolInfoProvider/raydium/raydium-utils';
import axios from 'axios';
import { toUiAmount } from './utils';
import { InstaSwap } from '@ratio-finance/instaswap-core';
import { LPair } from '../types/VaultTypes';
import { IPoolManagerStrategy } from './PoolInfoProvider/IPoolManagerStrategy';
// eslint-disable-next-line
//@ts-expect-error
import { getAccount } from '@solana/spl-token';
export const COLL_RATIOS_DECIMALS = 8;
export const COLL_RATIOS_ARR_SIZE = 10;

export const DEPOSIT_ACTION = 'Deposit';
export const WIHTDRAW_ACTION = 'Withdraw';
export const BORROW_ACTION = 'Borrow';
export const PAYBACK_ACTION = 'Payback';
export const HARVEST_ACTION = 'Harvest';

//const HISTORY_TO_SHOW = 5;
export const USD_FAIR_PRICE = true;
export const POOL_INFO_LIST: { [key: string]: any } = {}; //should include platform_symbol, symbol, verion, tvl, apr,

// default platform values
export declare type PlatformType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export const PLATFORM_IDS = {
  RAYDIUM: 0,
  ORCA: 1,
  SABER: 2,
  MERCURIAL: 3,
  SWIM: 4,
};

export const DEFAULT_PROGRAMS = {
  systemProgram: SystemProgram.programId,
  tokenProgram: TOKEN_PROGRAM_ID,
  associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
  rent: SYSVAR_RENT_PUBKEY,
  clock: SYSVAR_CLOCK_PUBKEY,
};

export const GLOBAL_TVL_LIMIT = 1_000_000_000_000;
export const GLOBAL_DEBT_CEILING = 1500_000_000;
export const USER_DEBT_CEILING = 1500_000_000;
export const POOL_DEBT_CEILING = 1500_000_000;

export async function getGlobalState(connection: Connection) {
  const program = getProgramInstance(connection, null);
  const globalStateKey = getGlobalStatePDA();
  return await program.account.globalState.fetchNullable(globalStateKey);
}
export async function getBlacklist(connection: Connection) {
  const program = getProgramInstance(connection, null);
  const blacklistKey = getBlacklistPDA();
  return await program.account.blackList.fetchNullable(blacklistKey);
}
export async function getAllOracleState(connection: Connection) {
  const program = getProgramInstance(connection, null);
  return await program.account.oracle.all();
}

export async function getUserCount(connection: Connection) {
  const program = getProgramInstance(connection, null);
  const users = await program.account.userState.all();
  return users.length;
}

export async function getUserState(connection: Connection, wallet: any) {
  if (!wallet || !wallet.publicKey) {
    return null;
  }
  const program = getProgramInstance(connection, wallet);

  const userStateKey = getUserStatePDA(wallet.publicKey);
  return await program.account.userState.fetchNullable(userStateKey);
}

export async function getVaultState(connection: Connection, wallet: any, mintCollat: string | PublicKey) {
  if (!wallet || !wallet.publicKey) {
    return null;
  }
  const program = getProgramInstance(connection, wallet);

  const vaultKey = getVaultPDA(wallet.publicKey, new PublicKey(mintCollat));
  return await program.account.vault.fetchNullable(vaultKey);
}
export async function getAllVault(connection: Connection) {
  const program = getProgramInstance(connection);

  return await program.account.vault.all();
}
export async function getLendingPoolByMint(connection: Connection, mint: string | PublicKey): Promise<any | undefined> {
  const program = getProgramInstance(connection, null);
  const tokenPoolKey = getPoolPDA(mint);
  return await program.account.pool.fetchNullable(tokenPoolKey);
}

export async function getAllLendingPool(connection: Connection): Promise<any[]> {
  const program = getProgramInstance(connection, null);
  return await program.account.pool.all();
}

export async function depositCollateralTx(connection: Connection, wallet: any, amount: number, mintCollat: PublicKey) {
  const program = getProgramInstance(connection, wallet);

  const globalStateKey = getGlobalStatePDA();
  const stateInfo = await program.account.globalState.fetch(globalStateKey);
  const poolKey = getPoolPDA(mintCollat);

  const poolData = await program.account.pool.fetch(poolKey);

  const oracleMintA = poolData.swapMintA;
  const oracleMintB = poolData.swapMintB;

  const swapTokenA = poolData.swapTokenA;
  const swapTokenB = poolData.swapTokenB;

  const oracleAKey = getOraclePDA(oracleMintA);
  const oracleBKey = getOraclePDA(oracleMintB);

  const mintReward = poolData.mintReward;

  const vaultKey = getVaultPDA(wallet.publicKey, mintCollat);
  const userStateKey = getUserStatePDA(wallet.publicKey);

  const userTokenATA = getATAKey(wallet.publicKey, mintCollat);
  const vaultATAKey = getATAKey(vaultKey, mintCollat);
  const ataCollatTreasury = getATAKey(stateInfo.treasury, mintCollat);
  console.log(stateInfo.treasury.toString(), ataCollatTreasury.toString());
  const transaction = new Transaction();

  if (!(await connection.getAccountInfo(ataCollatTreasury))) {
    transaction.add(
      createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        ataCollatTreasury,
        stateInfo.treasury,
        new PublicKey(mintCollat)
      )
    );
  }

  try {
    await program.account.userState.fetch(userStateKey);
  } catch {
    console.log('creating user state');
    transaction.add(
      program.instruction.createUserState({
        accounts: {
          authority: wallet.publicKey,
          userState: userStateKey,
          ...DEFAULT_PROGRAMS,
        },
      })
    );
  }
  try {
    await program.account.vault.fetch(vaultKey);
  } catch {
    const isRaydium = isRaydiumLp(mintCollat);
    let ledgerKey;
    let raydiumFarmInfo;
    if (isRaydium) {
      console.log('creating raydium ledger');
      raydiumFarmInfo = await getRaydiumFarmInfo(connection, mintCollat, 5);
      if (!raydiumFarmInfo) {
        throw "Can't get raydium farm info";
      }
      ledgerKey = await getAssociatedLedgerAccount({
        programId: RAYDIUM_FARM_PROGRAM_ID,
        poolId: raydiumFarmInfo.publicKey,
        owner: vaultKey,
      });
      const txLedger = program.instruction.createRaydiumLedger({
        accounts: {
          // account that owns the vault
          authority: wallet.publicKey,
          // state account where all the platform funds go thru or maybe are stored
          pool: poolKey,
          // the user's vault is the authority for the collateral tokens within it
          vault: vaultKey,

          raydiumProgram: RAYDIUM_FARM_PROGRAM_ID,

          stakePool: raydiumFarmInfo.publicKey,

          stakerInfo: ledgerKey,

          ...DEFAULT_PROGRAMS,
        },
      });
      transaction.add(txLedger);
    }

    console.log('creating vault');
    const tx = program.instruction.createVault({
      accounts: {
        // account that owns the vault
        authority: wallet.publicKey,
        // state account where all the platform funds go thru or maybe are stored
        pool: poolKey,
        // the user's vault is the authority for the collateral tokens within it
        vault: vaultKey,
        // this is the vault's ATA for the collateral's mint, previously named tokenColl
        ataCollatVault: vaultATAKey,
        // the mint address for the specific collateral provided to this vault
        mintCollat: mintCollat,
        ...DEFAULT_PROGRAMS,
      },
      remainingAccounts: isRaydium
        ? [
            {
              pubkey: ledgerKey ?? new PublicKey(''),
              isWritable: false,
              isSigner: false,
            },
            {
              pubkey: raydiumFarmInfo?.publicKey ?? new PublicKey(''),
              isWritable: false,
              isSigner: false,
            },
          ]
        : [],
    });
    transaction.add(tx);
    if (mintReward.toString() !== mintCollat.toString()) {
      console.log('creating reward vault');
      const ataRewardVaultKey = getATAKey(vaultKey, mintReward);
      if (!(await connection.getAccountInfo(ataRewardVaultKey))) {
        transaction.add(
          createAssociatedTokenAccountInstruction(
            wallet.publicKey,
            ataRewardVaultKey,
            vaultKey,
            new PublicKey(mintReward)
          )
        );
      }
    }
  }
  console.log('depositing collateral to ratio');

  const ix = program.instruction.depositCollateral(new anchor.BN(amount), {
    accounts: {
      authority: wallet.publicKey,
      globalState: globalStateKey,
      pool: poolKey,
      vault: vaultKey,
      userState: userStateKey,
      ataCollatVault: vaultATAKey,
      ataCollatTreasury: ataCollatTreasury,
      ataCollatUser: userTokenATA,
      mintCollat: mintCollat,
      oracleA: oracleAKey,
      oracleB: oracleBKey,
      swapTokenA,
      swapTokenB,
      ...DEFAULT_PROGRAMS,
    },
  });

  transaction.add(ix);

  return transaction;
}

export async function withdrawCollateralTx(connection: Connection, wallet: any, amount: number, mintCollat: PublicKey) {
  const program = getProgramInstance(connection, wallet);

  const globalStateKey = getGlobalStatePDA();
  const blacklistKey = getBlacklistPDA();
  const poolKey = getPoolPDA(mintCollat);

  const poolData = await program.account.pool.fetch(poolKey);

  const oracleMintA = poolData.swapMintA;
  const oracleMintB = poolData.swapMintB;

  const swapTokenA = poolData.swapTokenA;
  const swapTokenB = poolData.swapTokenB;

  const oracleAKey = getOraclePDA(oracleMintA);
  const oracleBKey = getOraclePDA(oracleMintB);

  const vaultKey = getVaultPDA(wallet.publicKey, mintCollat);
  const vaultATAKey = getATAKey(vaultKey, mintCollat);

  const userStateKey = getUserStatePDA(wallet.publicKey);

  const ataColl = getATAKey(wallet.publicKey, mintCollat);

  const transaction = new Transaction();
  if (!(await connection.getAccountInfo(ataColl))) {
    transaction.add(
      createAssociatedTokenAccountInstruction(wallet.publicKey, ataColl, wallet.publicKey, new PublicKey(mintCollat))
    );
  }
  const withdrawInstruction = await program.instruction.withdrawCollateral(new anchor.BN(amount), {
    accounts: {
      authority: wallet.publicKey,
      globalState: globalStateKey,
      blacklist: blacklistKey,
      pool: poolKey,
      vault: vaultKey,
      userState: userStateKey,
      ataCollatVault: vaultATAKey,
      ataCollatUser: ataColl,
      mintCollat,

      oracleA: oracleAKey,
      oracleB: oracleBKey,
      swapTokenA,
      swapTokenB,

      ...DEFAULT_PROGRAMS,
    },
  });

  transaction.add(withdrawInstruction);
  return transaction;
}

export async function getRewardBalance(
  connection: Connection,
  wallet: any,
  mintColl: PublicKey | string,
  rewardMint: PublicKey | string
) {
  try {
    const vaultKey = getVaultPDA(wallet.publicKey, mintColl);
    const ataVaultReward = getATAKey(vaultKey, rewardMint);

    const res = await connection.getTokenAccountBalance(ataVaultReward);
    return res.value.uiAmount;
  } catch {
    return 0;
  }
}
export async function distributeRewardTx(
  connection: Connection,
  wallet: any,
  mintColl: PublicKey | string,
  mintReward: PublicKey | string | null = null
) {
  const program = getProgramInstance(connection, wallet);

  const poolKey = getPoolPDA(mintColl);
  const globalStateKey = getGlobalStatePDA();

  if (!mintReward) {
    const poolInfo = await program.account.pool.fetch(poolKey);
    mintReward = poolInfo.mintReward;
  }
  const vaultKey = getVaultPDA(wallet.publicKey, mintColl);
  const ataVaultReward = getATAKey(vaultKey, mintReward);

  const ataUserReward = getATAKey(wallet.publicKey, mintReward);

  const transaction = new Transaction();
  if (!(await connection.getAccountInfo(ataUserReward))) {
    transaction.add(
      createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        ataUserReward,
        wallet.publicKey,
        new PublicKey(mintReward)
      )
    );
  }

  const ix = await program.instruction.distributeReward({
    accounts: {
      authority: wallet.publicKey,
      globalState: globalStateKey,
      pool: poolKey,
      vault: vaultKey,
      ataRewardVault: ataVaultReward,
      ataRewardUser: ataUserReward,
      ...DEFAULT_PROGRAMS,
    },
  });

  transaction.add(ix);
  return transaction;
}

export async function harvestRatioRewardTx(
  connection: Connection,
  wallet: any,
  mintColl: PublicKey | string,
  userKey: PublicKey | string = wallet.publicKey
) {
  const program = getProgramInstance(connection, wallet);

  const globalStateKey = getGlobalStatePDA();
  const poolKey = getPoolPDA(mintColl);

  const stateInfo = await program.account.globalState.fetch(globalStateKey);

  const vaultKey = getVaultPDA(userKey, mintColl);

  const ataRatioGlobal = getATAKey(globalStateKey, stateInfo.ratioMint);
  const ataRatioVault = getATAKey(vaultKey, stateInfo.ratioMint);
  const ataRatioTreasury = getATAKey(stateInfo.treasury, stateInfo.ratioMint);
  const transaction = new Transaction();

  if (!(await connection.getAccountInfo(ataRatioVault))) {
    transaction.add(
      createAssociatedTokenAccountInstruction(wallet.publicKey, ataRatioVault, vaultKey, stateInfo.ratioMint)
    );
  }
  if (!(await connection.getAccountInfo(ataRatioTreasury))) {
    transaction.add(
      createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        ataRatioTreasury,
        stateInfo.treasury,
        new PublicKey(stateInfo.ratioMint)
      )
    );
  }

  const harvestIx = await program.instruction.harvestRatio({
    accounts: {
      globalState: globalStateKey,
      pool: poolKey,
      vault: vaultKey,
      ataRatioGlobal,
      ataRatioVault,
      ataRatioTreasury,
      ...DEFAULT_PROGRAMS,
    },
  });
  transaction.add(harvestIx);
  return transaction;
}

export async function harvestRatioReward(connection: Connection, wallet: any, mintColl: PublicKey | string) {
  console.log('Harvesting ratio token');

  const tx = new Transaction();
  const harvestTx = await harvestRatioRewardTx(connection, wallet, mintColl);
  const distTx = await distributeRewardTx(connection, wallet, mintColl, RATIO_MINT_KEY);

  tx.add(harvestTx);
  tx.add(distTx);

  const txHash = await sendTransaction(connection, wallet, tx);

  if (txHash?.value?.err) {
    console.error('ERROR ON TX ', txHash.value.err);
    throw txHash.value.err;
  }

  return txHash.toString();
}

export async function borrowUSDr(connection: Connection, wallet: any, amount: number, mintCollat: PublicKey) {
  const program = getProgramInstance(connection, wallet);

  const globalStateKey = getGlobalStatePDA();
  const blacklistKey = getBlacklistPDA();
  const usdrMint = new PublicKey(USDR_MINT_KEY);
  const poolKey = getPoolPDA(mintCollat);

  const stateInfo = await program.account.globalState.fetch(globalStateKey);
  const treasuryKey = stateInfo.treasury;

  const poolData = await program.account.pool.fetch(poolKey);

  const oracleMintA = poolData.swapMintA;
  const oracleMintB = poolData.swapMintB;
  const oracleAKey = getOraclePDA(oracleMintA);
  const oracleBKey = getOraclePDA(oracleMintB);

  const swapTokenA = poolData.swapTokenA;
  const swapTokenB = poolData.swapTokenB;

  const ataUSDr = getATAKey(wallet.publicKey, usdrMint);
  const ataUSDrTreasury = getATAKey(treasuryKey, usdrMint);

  const vaultKey = getVaultPDA(wallet.publicKey, mintCollat);
  const userStateKey = getUserStatePDA(wallet.publicKey);

  const transaction = new Transaction();

  if (!(await connection.getAccountInfo(ataUSDr))) {
    transaction.add(createAssociatedTokenAccountInstruction(wallet.publicKey, ataUSDr, wallet.publicKey, usdrMint));
  }

  if (!ataUSDrTreasury.equals(ataUSDr) && !(await connection.getAccountInfo(ataUSDrTreasury))) {
    transaction.add(createAssociatedTokenAccountInstruction(wallet.publicKey, ataUSDrTreasury, treasuryKey, usdrMint));
  }

  const borrowInstruction = await program.instruction.borrowUsdr(new anchor.BN(amount), {
    accounts: {
      authority: wallet.publicKey,
      globalState: globalStateKey,
      blacklist: blacklistKey,
      treasury: treasuryKey,
      pool: poolKey,
      vault: vaultKey,
      userState: userStateKey,
      oracleA: oracleAKey,
      oracleB: oracleBKey,
      swapTokenA,
      swapTokenB,

      mintCollat,

      mintUsdr: usdrMint,
      ataUsdr: ataUSDr,
      ataUsdrTreasury: ataUSDrTreasury,
      ...DEFAULT_PROGRAMS,
    },
  });

  transaction.add(borrowInstruction);

  const txHash = await sendTransaction(connection, wallet, transaction);

  if (txHash?.value?.err) {
    console.error('ERROR ON TX ', txHash.value.err);
    throw txHash.value.err;
  }
  console.log(`User borrowed ${amount / Math.pow(10, USDR_MINT_DECIMALS)} USD , transaction id = ${txHash}`);

  return txHash.toString();
}

export async function repayUSDr(connection: Connection, wallet: any, amount: number, mintColl: PublicKey) {
  const program = getProgramInstance(connection, wallet);

  const globalStateKey = getGlobalStatePDA();
  const poolKey = getPoolPDA(mintColl);
  const usdrMint = USDR_MINT_KEY;

  const vaultKey = getVaultPDA(wallet.publicKey, mintColl);
  const userStateKey = getUserStatePDA(wallet.publicKey);

  const ataUserUSDr = getATAKey(wallet.publicKey, usdrMint);

  const stateInfo = await program.account.globalState.fetch(globalStateKey);
  const treasuryKey = stateInfo.treasury;
  const ataUsdrTreasury = getATAKey(treasuryKey, usdrMint);

  const transaction = new Transaction();
  const ix = await program.instruction.repayUsdr(new anchor.BN(amount), {
    accounts: {
      authority: wallet.publicKey,
      globalState: globalStateKey,
      ataUsdrTreasury,
      pool: poolKey,
      vault: vaultKey, // TODO: vault -> vault
      userState: userStateKey,
      mintUsdr: usdrMint,
      ataUsdr: ataUserUSDr,
      ...DEFAULT_PROGRAMS,
    },
  });
  transaction.add(ix);

  const txHash = await sendTransaction(connection, wallet, transaction, []);

  if (txHash?.value?.err) {
    console.error('ERROR ON TX ', txHash.value.err);
    throw txHash.value.err;
  }
  console.log(`User repaid ${amount / Math.pow(10, USDR_MINT_DECIMALS)} USD , txId = ${txHash}`);

  return txHash;
}

export async function calculateRewardByPlatform(
  connection: Connection,
  userKey: PublicKey | string,
  mintColl: PublicKey | string,
  platformType: number,
  cacheData: any = null
): Promise<[number, any]> {
  let reward = 0;
  let cacheDataUpdated = null;
  if (platformType === PLATFORM_IDS.SABER) {
    [reward, cacheDataUpdated] = await calculateSaberReward(connection, userKey, mintColl, cacheData);
  } else if (platformType === PLATFORM_IDS.RAYDIUM) {
    [reward, cacheDataUpdated] = await calculateRaydiumReward(connection, userKey, new PublicKey(mintColl), cacheData);
  }

  return [reward, cacheDataUpdated];
}

export async function getFarmInfoByPlatform(
  connection: Connection,
  mintCollKey: string | PublicKey,
  platformType: number
) {
  if (platformType === PLATFORM_IDS.SABER) {
    return await getQuarryInfo(connection, new PublicKey(mintCollKey));
  } else if (platformType === PLATFORM_IDS.RAYDIUM) {
    const poolVersion = POOL_INFO_LIST[mintCollKey.toString()].version;
    return await getRaydiumFarmInfo(connection, new PublicKey(mintCollKey), poolVersion);
  } else {
    return null;
  }
}

const ACC_PRECISION = new BN(100 * 1000 * 1000 * 1000);
export function estimateRatioRewards(poolData: any, vaultData: any) {
  const currentTimeStamp = Math.ceil(new Date().getTime() / 1000);
  const duration = new BN(Math.max(currentTimeStamp - poolData.lastRewardTime, 0));

  const reward_per_share =
    poolData.lastRewardFundEnd.toNumber() > currentTimeStamp
      ? poolData.tokenPerSecond.mul(duration).mul(ACC_PRECISION).div(poolData.totalDebt)
      : new BN(0);
  const acc_reward_per_share = poolData.accRewardPerShare.add(reward_per_share);
  const pending_amount = new BN(vaultData.debt.toString())
    .mul(acc_reward_per_share)
    .div(ACC_PRECISION)
    .sub(vaultData.ratioRewardDebt);
  const total_reward = vaultData.ratioRewardAmount.add(pending_amount);

  return Math.max(+total_reward.toString(), 0);
}

const ONE_YEAR_IN_SEC = 365 * 24 * 3600;

export function estimateRATIOAPY(poolData: any, ratio_price: number) {
  const apr = estimateRATIOAPR(poolData, ratio_price);
  const apy = (1 + apr / 365) ** 365 - 1;
  return apy;
}

export function calculateFundAmountFromAPY(mintAmount: number, apy: number, duration: number, ratioPrice: number) {
  const apr = (Math.pow(apy / 100 + 1, 1 / 365) - 1) * 365;
  return calculateFundAmountFromAPR(mintAmount, apr, duration, ratioPrice);
}

export function calculateAPYFromFundAmount(mintAmount: number, amount: number, duration: number, ratioPrice: number) {
  const apr = calculateAPRFromFundAmount(mintAmount, amount, duration, ratioPrice);
  const apy = (1 + apr / 100 / 365) ** 365 - 1;
  return apy;
}

export function estimateRATIOAPR(poolData: any, ratio_price: number) {
  const currentTimeStamp = Math.ceil(new Date().getTime() / 1000);
  const fundStart = poolData.lastRewardFundStart.toNumber();
  const fundEnd = poolData.lastRewardFundEnd.toNumber();

  if (fundEnd < currentTimeStamp) {
    return 0;
  }
  const annual_reward_amount =
    Number(new TokenAmount(poolData.tokenPerSecond.toString(), RATIO_MINT_DECIMALS).fixed()) * ONE_YEAR_IN_SEC;
  const annual_reward_value = annual_reward_amount * ratio_price;
  const usdrMinted = +new TokenAmount(poolData.totalDebt.toString(), USDR_MINT_DECIMALS, true).fixed();

  const apr = annual_reward_value === 0 ? 0 : annual_reward_value / usdrMinted;

  return (apr * (fundEnd - currentTimeStamp)) / (fundEnd - fundStart);
}

export function calculateFundAmountFromAPR(mintAmount: number, apr: number, duration: number, ratioPrice: number) {
  const tpd = (apr * mintAmount) / ratioPrice / 365;
  const amount = tpd * duration;
  return Math.ceil(amount * 1000000) / 1000000;
}

export function calculateAPRFromFundAmount(mintAmount: number, amount: number, duration: number, ratioPrice: number) {
  const tpd = (amount * ratioPrice) / duration;
  const annual_reward_value = tpd * 365;
  const apr = annual_reward_value / mintAmount;
  return apr;
}

export const fetchAxiosWithRetry = async (url: string, maxRetry = 3) => {
  let count = 0;
  let error = undefined;
  while (count < maxRetry) {
    try {
      return await axios.get(url);
    } catch (e) {
      error = e;
      count++;
    }
  }
  throw error;
};

export const calculateLiquidationPrice = (poolInfo: any, collatAmount: number, debt: number) => {
  const tolerance = poolInfo.liqTolerance;
  const delta_min = poolInfo.liqDeltaMin;
  const fair_price = toUiAmount(poolInfo.fairPrice, USDR_MINT_DECIMALS);

  const LP_Tokens = collatAmount;
  const USDr_debt = debt + 10 ** -10;
  const CR = 1 / poolInfo.ratio;

  const myCTD = LP_Tokens / USDr_debt;
  const myQuad = (2 * delta_min) / (fair_price * (1 - tolerance) ** 2);

  const LiqPrice_1 = -(myCTD + myQuad);
  const LiqPrice_2 = Math.sqrt(
    (myCTD + myQuad) ** 2 +
      ((4 * delta_min) / (fair_price ** 2 * (1 - tolerance) ** 2)) *
        (-CR - delta_min / (1 - tolerance) ** 2 + delta_min)
  );
  const LiqPrice_3 = -(2 * delta_min) / (fair_price ** 2 * (1 - tolerance) ** 2);
  const Liquidation_Price = (LiqPrice_1 + LiqPrice_2) / LiqPrice_3;
  const Liquidation_Price_1 = (LiqPrice_1 - LiqPrice_2) / LiqPrice_3;

  return Liquidation_Price;
};

const findMaxWithdrawalAmount = (debt: number, totalColl: number, poolInfo: any) => {
  const minColl = Math.ceil(debt / poolInfo?.ratio);
  const minCollUSD = Math.ceil((minColl * 10 ** USDR_MINT_DECIMALS) / poolInfo?.oraclePrice);
  const _totalColl = totalColl;
  return Math.max(_totalColl - minCollUSD, 0);
};

const requiredWithdrawalAmount = (debt: number, poolInfo: any) => {
  const debtInUsd = debt / 10 ** USDR_MINT_DECIMALS;
  const lpPrice = poolInfo?.oraclePrice / 10 ** poolInfo?.mintDecimals;
  const requiredAmount = Math.ceil((debtInUsd / lpPrice) * 10 ** poolInfo?.mintDecimals);
  return requiredAmount + 0.01 * requiredAmount;
};

export async function autoRepayUSDr(
  connection: Connection,
  wallet: any,
  amount: number,
  mintColl: string,
  poolInfo: any,
  poolManagerFactory: IPoolManagerStrategy,
  vaultLPair: LPair
): Promise<{ txHashes?: string[]; err?: string }> {
  const program = getProgramInstance(connection, wallet);
  const instaSwap = new InstaSwap(connection);
  await instaSwap.load();

  const globalStateKey = getGlobalStatePDA();
  const poolKey = getPoolPDA(mintColl);
  const vaultKey = getVaultPDA(wallet.publicKey, mintColl);
  const userStateKey = getUserStatePDA(wallet.publicKey);

  const ataUserUSDr = getATAKey(wallet.publicKey, USDR_MINT_KEY);

  const globalStateData = await program.account.globalState.fetch(globalStateKey);
  const treasuryKey = globalStateData.treasury;
  const ataUsdrTreasury = getATAKey(treasuryKey, USDR_MINT_KEY);

  let transactions: Transaction[] = [];

  const txHashes: string[] = [];

  let userVaultData = await program.account.vault.fetch(vaultKey);

  // Total Debt = usdr debt + intrest
  let tempDebt = userVaultData.debt.toNumber() + userVaultData.usdrInterest.toNumber();
  let tempTotalColl = userVaultData.totalColl.toNumber();
  let tempMaxWithdrawalAmount = findMaxWithdrawalAmount(tempDebt, tempTotalColl, poolInfo);
  let tempRepayAmount = amount;
  let totalPayBackDone = 0;
  let counter = 0;
  let retryCounter = 0;
  let oldDebt: number;
  let status = true;

  console.log({
    tempDebt,
    tempMaxWithdrawalAmount,
    tempTotalColl,
    tempRepayAmount,
    totalPayBackDone,
  });

  if (tempDebt <= 0) {
    console.log(`There is no debt to repay!`);
    return { err: `There is no debt to repay!` };
  }
  if (tempMaxWithdrawalAmount <= 0 && tempRepayAmount === 0) {
    console.log(`There is nothing to be withdrawn!`);
    return { err: `There is nothing to be withdrawn!` };
  }

  while ((tempDebt > 0 && tempMaxWithdrawalAmount > 0) || (tempDebt > 0 && tempRepayAmount > 0)) {
    console.log({
      tempDebt,
      tempMaxWithdrawalAmount,
      tempTotalColl,
      tempRepayAmount,
      totalPayBackDone,
    });
    counter++;
    // Repaying USDR
    if (tempRepayAmount > 0) {
      console.log('Simulating Repay Tx');
      const repayIx = program.instruction.repayUsdr(new anchor.BN(tempRepayAmount), {
        accounts: {
          authority: wallet.publicKey,
          globalState: globalStateKey,
          ataUsdrTreasury,
          pool: poolKey,
          vault: vaultKey, // TODO: vault -> vault
          userState: userStateKey,
          mintUsdr: USDR_MINT_KEY,
          ataUsdr: ataUserUSDr,
          ...DEFAULT_PROGRAMS,
        },
      });

      transactions.push(new Transaction().add(repayIx));
      totalPayBackDone += tempRepayAmount;
      tempDebt = Math.max(tempDebt - tempRepayAmount, 0);
      tempMaxWithdrawalAmount = findMaxWithdrawalAmount(tempDebt, tempTotalColl, poolInfo);
    }

    const tempWithdrawalAmount = Math.min(tempMaxWithdrawalAmount, requiredWithdrawalAmount(tempDebt, poolInfo));
    if (tempWithdrawalAmount !== 0 && tempDebt !== 0) {
      // Withdrawing LP
      console.log('Simulating Withdraw Tx');
      const withdrawTx = await poolManagerFactory?.withdrawLPIx(connection, wallet, vaultLPair, tempWithdrawalAmount);
      transactions.push(withdrawTx);
    }

    const userUsdrOldBalance = Number((await getAccount(connection, ataUserUSDr)).amount.toString());
    const txResults = await sendAndConfirmAllTransaction(connection, wallet, transactions);
    const userUsdrNewBalance = Number((await getAccount(connection, ataUserUSDr)).amount.toString());

    txResults.forEach((result, txHash) => {
      // Retry failed tx, due to slippage.. etc
      if (result.meta.err?.InstructionError[1].Custom === 1) {
        console.log(`Instruction Error for tx: `, txHash);
        if (retryCounter > 2) {
          console.log(`Maximum retries reached, Auto Payback Failed`);
          status = false;
        }
        console.log(`Retrying failed tx, retry count: `, retryCounter);
        const usdrBalanceDiff = userUsdrNewBalance - userUsdrOldBalance;
        if (usdrBalanceDiff === 0) {
          // Check whether swap worked as expected, if not, throw error
          status = false;
          return;
        }
        // Roll back variable state to previous
        tempRepayAmount = usdrBalanceDiff;
        tempDebt = oldDebt;
        counter--;
        retryCounter++;
      } else if (result.meta.err?.InstructionError[1].Custom && status) {
        // Check if any other ix error, if so, throw error
        console.log(`Instruction Error for tx: `, txHash);
        console.log(`Error Code :`, result.meta.err?.InstructionError[1].Custom);
        status = false;
      }
    });
    if (!status) break; // condition whether tx failed, if so, break;
    if (oldDebt === tempDebt) continue; // retry the tx
    oldDebt = tempDebt;
    txHashes.push(...txResults.keys());

    console.log({
      tempDebt,
      tempMaxWithdrawalAmount,
      tempTotalColl,
      tempRepayAmount,
      totalPayBackDone,
    });
    userVaultData = await program.account.vault.fetch(vaultKey);
    tempTotalColl = userVaultData.totalColl.toNumber();
    tempMaxWithdrawalAmount = findMaxWithdrawalAmount(tempDebt, tempTotalColl, poolInfo);

    transactions = [];

    if (tempWithdrawalAmount === 0 || tempDebt === 0) {
      console.log(`Auto Pay Back Finished !`);
      break;
    }
    // Swapping LP to USDR
    console.log('Simulating Lp To Usdr Tx');
    const instaSellEstimatedOutput = await instaSwap.getWithdrawOutUiAmount(
      mintColl,
      tempWithdrawalAmount / 10 ** poolInfo?.mintDecimals,
      REAL_USDR_MINT,
      1
    );
    const instaSellTransactions = await instaSwap.getSellTransactions(
      wallet.publicKey,
      mintColl,
      tempWithdrawalAmount / 10 ** poolInfo?.mintDecimals,
      REAL_USDR_MINT,
      1
    );
    for (const tx of instaSellTransactions.allTransactions) {
      transactions.push(tx);
    }
    tempRepayAmount = instaSellEstimatedOutput * 10 ** poolInfo?.mintDecimals;

    // If repay amount > totalDebt, make repay amount = totalDebt
    const totalOutStandingDebt = userVaultData.debt.toNumber() + userVaultData.usdrInterest.toNumber();
    tempRepayAmount = Math.min(tempRepayAmount, totalOutStandingDebt);
  }

  if (status) {
    console.log(`User repaid ${totalPayBackDone / Math.pow(10, USDR_MINT_DECIMALS)} USDr , txIds = ${txHashes}`);
    console.log(`Auto PayBack Done using ${counter} consecutive payback rounds`);
    return { txHashes };
  } else {
    return { err: `Failed / Partially completed` };
  }
}
