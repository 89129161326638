// import { Table } from 'react-bootstrap';
import classNames from 'classnames';
import USDr from '../../../assets/images/USDr.png';

const WalletBalances = ({ icon, tokenName, collAmount, collAmountUSD, usdrAmount, className }: any) => {
  return (
    <div className={classNames(className)}>
      <p className="pl-6 text-lg font-medium text-gray-200 font-poppins dark:text-white-900">Wallet Balances</p>
      <table className="w-full mt-3 lg:min-w-full min-w-max">
        <thead>
          <tr>
            <th className="pl-6 text-sm font-light text-gray-100 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
              Asset
            </th>
            <th className="text-sm font-light text-gray-100 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
              Balance
            </th>
            <th className="text-sm font-light text-gray-100 border-b-2 border-white-500 font-poppins dark:text-white-900 dark:border-gray-600">
              USD
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="flex items-center gap-3 py-4 pl-6 text-sm font-medium text-gray-200 border-b font-poppins dark:text-white-900 border-white-500 dark:border-gray-600">
              {icon && <img src={icon} alt="icon" className="w-12" />}
              {tokenName === 'USDC-USDR' ? 'USDC-USDr' : tokenName}
            </td>
            <td className="text-sm font-medium text-gray-200 border-b font-poppins border-white-500 dark:text-white-900 dark:border-gray-600">
              {collAmount}
            </td>
            <td className="text-sm font-medium text-gray-200 border-b font-poppins border-white-500 dark:text-white-900 dark:border-gray-600">
              ${collAmountUSD?.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="flex items-center gap-3 py-3 pl-6 text-sm font-medium text-gray-200 border-b font-poppins dark:text-white-900 border-white-500 dark:border-gray-600">
              <img src={USDr} alt="RAY" className="w-7" /> USDr
            </td>
            <td className="text-sm font-medium text-gray-200 border-b font-poppins border-white-500 dark:text-white-900 dark:border-gray-600">
              {usdrAmount}
            </td>
            <td className="text-sm font-medium text-gray-200 border-b font-poppins border-white-500 dark:text-white-900 dark:border-gray-600">
              ${usdrAmount?.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WalletBalances;
