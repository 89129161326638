import { SmartWalletWrapper, GokiSDK, findTransactionAddress } from '@gokiprotocol/client';
import { BN, Provider } from '@project-serum/anchor';
import {
  AccountMeta,
  BPF_LOADER_PROGRAM_ID,
  Connection,
  PublicKey,
  Transaction,
  TransactionInstruction,
} from '@solana/web3.js';
import { nu64, struct, u8 } from './PoolInfoProvider/raydium/marshmallow';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token-v2';
import { getATAKey } from './ratio-pda';
import { sendAndConfirmTransaction } from './rf-web3';
import { sleep } from './utils';

export function transfer(source: PublicKey, destination: PublicKey, owner: PublicKey, amount: number) {
  const dataLayout = struct([u8('instruction'), nu64('amount')]);

  const keys: AccountMeta[] = [
    { pubkey: source, isSigner: false, isWritable: true },
    { pubkey: destination, isSigner: false, isWritable: true },
    { pubkey: owner, isSigner: true, isWritable: false },
  ];

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    {
      instruction: 3,
      amount,
    },
    data
  );

  return new TransactionInstruction({
    keys,
    programId: TOKEN_PROGRAM_ID,
    data,
  });
}

export const setUpgradeAuthorityIx = (
  programAddress: PublicKey,
  currentAuthorityAddress: PublicKey,
  newAuthorityAddress: PublicKey
) => {
  const upgradeProgramId = new PublicKey('BPFLoaderUpgradeab1e11111111111111111111111');
  const upgradeData = new BN(4, 10);

  const [programDataAddress] = PublicKey.findProgramAddressSync([programAddress.toBuffer()], upgradeProgramId);
  console.log(programAddress.toString(), currentAuthorityAddress.toString(), newAuthorityAddress.toString());
  const keys: AccountMeta[] = [
    { pubkey: programDataAddress, isWritable: true, isSigner: false },
    { pubkey: currentAuthorityAddress, isWritable: false, isSigner: true },
    { pubkey: newAuthorityAddress, isWritable: false, isSigner: false },
  ];

  return new TransactionInstruction({
    programId: upgradeProgramId,
    data: upgradeData.toArrayLike(Buffer, 'le', 4),
    keys,
  });
};

export async function getGokiSmartWallet(connection: Connection, wallet: any, gokiWalletKey: string | PublicKey) {
  const provider = new Provider(connection, wallet, Provider.defaultOptions());
  const sdk = GokiSDK.load({
    provider: provider as any,
  });
  return await SmartWalletWrapper.load(sdk, new PublicKey(gokiWalletKey));
}

export async function createNewTx(smartWallet: SmartWalletWrapper, originTx: Transaction) {
  console.log(`Old TxCount = ${smartWallet.data?.numTransactions.toNumber()}`);

  const { tx: smartTx } = await smartWallet.newTransaction({
    instructions: originTx.instructions,
  });
  const newTx = new Transaction();
  newTx.add(...smartTx.instructions);
  await sendAndConfirmTransaction(smartWallet.provider.connection, smartWallet.provider.wallet, newTx);
  await sleep(5000);
  await smartWallet.reloadData();
  console.log(`New TxCount = ${smartWallet.data?.numTransactions.toNumber()}`);
}

export async function createTransansferAllInstruction(
  smartWallet: SmartWalletWrapper,
  mint: string | PublicKey,
  targetWallet: string | PublicKey
) {
  const source = getATAKey(smartWallet.key, mint);
  const dest = getATAKey(targetWallet, mint);

  const balance = +(await smartWallet.provider.connection.getTokenAccountBalance(source)).value.amount;
  console.log(`Transfer ${balance} ${mint.toString()}`);
  return transfer(source, dest, smartWallet.key, balance);
}

export async function apporveLastTx(smartWallet: SmartWalletWrapper) {
  const txIndex = smartWallet.data?.numTransactions.toNumber() - 1;
  console.log(`Last Tx Index = ${txIndex}`);
  const [txKey] = await findTransactionAddress(smartWallet.key, txIndex);
  const smartTx = await smartWallet.approveTransaction(txKey);
  const newTx = new Transaction();
  newTx.add(...smartTx.instructions);
  await sendAndConfirmTransaction(smartWallet.provider.connection, smartWallet.provider.wallet, newTx);
  await sleep(5000);
}

export async function executeeLastTx(smartWallet: SmartWalletWrapper) {
  const txIndex = smartWallet.data?.numTransactions.toNumber() - 1;
  console.log(`Last Tx Index = ${txIndex}`);
  const [txKey] = await findTransactionAddress(smartWallet.key, txIndex);
  const smartTx = await smartWallet.executeTransaction({
    transactionKey: txKey,
  });
  const newTx = new Transaction();
  newTx.add(...smartTx.instructions);
  await sendAndConfirmTransaction(smartWallet.provider.connection, smartWallet.provider.wallet, newTx);
  await sleep(5000);
}

export async function getLastTransctionInfo(smartWallet: SmartWalletWrapper) {
  const txIndex = smartWallet.data?.numTransactions.toNumber() - 1;
  return await smartWallet.fetchTransactionByIndex(txIndex);
}
