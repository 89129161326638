import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

interface Option {
  value: string;
  label: string;
}

type CustomSelectProps = {
  options: Array<Option>;
  selected: Option;
  className: string;
  onChange?: (value: any) => void;
};

const CustomSelect = ({ options, selected, className, onChange }: CustomSelectProps) => {
  //   const CustomOption = (props: any) => {
  //     const { children, innerProps, data } = props;
  //     return (
  //       <div className="customSelect__option">
  //         <div {...innerProps} className="px-6 py-2">
  //           <img src={data.icon[0]} alt={children} />
  //           {/* {data.icon[1] && <img src={data.icon[1]} alt={children} className="secondIcon" />} */}
  //           <span className="ml-3">{children}</span>
  //         </div>
  //       </div>
  //     );
  //   };

  //   const Control = ({ children, selectProps, ...rest }: any) => {
  //     return (
  //       <components.Control {...rest}>
  //         <img src={selectProps?.value?.icon[0]} alt={children} />{' '}
  //         {/* {selectProps?.value?.icon[1] && <img src={selectProps?.value?.icon[1]} alt={children} className="secondIcon" />}{' '} */}
  //         {children}
  //       </components.Control>
  //     );
  //   };
  // const [selected, setSelected] = useState(options[0]);

  return (
    <div className={className}>
      <Listbox value={selected} onChange={onChange}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full py-2.5 pl-3 pr-10 text-left border-t border-b border-l border-r rounded-lg cursor-default dark:bg-slate-800 dark:border-slate-600 bg-white-900 border-white-500 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block text-sm font-normal truncate font-work dark:text-white-900">{selected.label}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="w-5 h-5 text-gray-400 dark:text-white-900" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute w-full z-50 py-1 mt-1 overflow-auto text-base border-b border-t border-r border-l border-white-500 dark:border-gray-600 bg-white-900 dark:bg-slate-800 rounded-md shadow-lg min-w-[100px] max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 font-work dark:text-white-900 ${
                      active ? 'bg-white-600 dark:bg-slate-600' : 'text-gray-900'
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {person.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-teal-600">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default CustomSelect;
