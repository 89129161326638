import TokensEarned from './TokensEarned';
import WalletBalances from '../../../components/Dashboard/AmountPanel/WalletBalances';

const AmountPanel = (data: any) => {
  return (
    <div className="border shadow-sm xl:border-r-0 rounded-tl-2xl rounded-bl-2xl xl:rounded-br-none xl:rounded-tr-none rounded-tr-2xl rounded-br-2xl border-white-500 dark:border-gray-600">
      <WalletBalances
        mintAddress={data.mintAddress}
        collAmount={data.collAmount}
        collAmountUSD={data.collAmountUSD}
        icon={data.icon}
        icons={data.icons}
        tokenName={data.tokenName}
        usdrAmount={data.usdrAmount}
        className="pt-6"
      />
      <TokensEarned data={data} />
    </div>
  );
};

export default AmountPanel;
