import React, { useState } from 'react';
import infoIcon from '../../../../assets/images/risklevel.svg';

type props = {
  total: string;
  usdr: string;
  interest: string;
  apy: string;
};

const InterestRatePopover = ({ total, usdr, interest, apy }: props) => {
  const [tooltipStatus, setTooltipStatus] = useState(0);

  return (
    <div
      className="relative mt-0 md:mt-0"
      onMouseEnter={() => setTooltipStatus(1)}
      onMouseLeave={() => setTooltipStatus(0)}
    >
      <div className="mb-3 cursor-pointer">
        <img src={infoIcon} alt="info" className="opacity-75 hover:opacity-100" />
      </div>
      {tooltipStatus === 1 && (
        <div
          role="tooltip"
          className="absolute left-0 z-20 ml-8 -mt-12 transition duration-150 ease-in-out bg-gray-800 shadow-2xl min-w-[210px] dark:bg-slate-50 rounded-xl"
        >
          <svg
            className="absolute bottom-0 left-0 h-20 -ml-2 -top-3 fill-slate-700 dark:fill-slate-50"
            width="9px"
            height="16px"
            viewBox="0 0 9 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
              <g
                id="Tooltips-"
                transform="translate(-874.000000, -1029.000000)"
                className="fill-slate-700 dark:fill-slate-200"
              >
                <g id="Group-3-Copy-16" transform="translate(850.000000, 975.000000)">
                  <g id="Group-2" transform="translate(24.000000, 0.000000)">
                    <polygon
                      id="Triangle"
                      transform="translate(4.500000, 62.000000) rotate(-90.000000) translate(-4.500000, -62.000000) "
                      points="4.5 57.5 12.5 66.5 -3.5 66.5"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <div className="font-medium dark:text-gray-200 font-poppins text-white-900">
            <div className="px-4 py-3 text-sm font-semibold bg-slate-700 rounded-t-xl dark:bg-slate-200">
              Interest rate: {apy}% APY
            </div>
            <div className="flex justify-between px-4 pt-2 text-sm dark:text-gray-200 text-white-900">
              <p>USDr owed</p>
              <p>{usdr}</p>
            </div>
            <div className="flex justify-between px-4 pt-1 pb-2 text-sm dark:text-gray-200 text-white-900">
              <p>Interest owed</p>
              <p>{interest}</p>
            </div>
            <div className="flex justify-between pt-2 pb-3 mx-4 text-sm border-t border-white-900 dark:border-gray-600 dark:text-gray-200 text-white-900">
              <p className="whitespace-nowrap">Total owed</p>
              <p>{total}</p>
            </div>
          </div>
        </div>
      )}{' '}
    </div>
  );
};

export default InterestRatePopover;
