export const SET_COMPARE_VAULTS = 'SET_COMPARE_VAULTS';

export const SET_COMPARE_VAULTS_LIST = 'SET_COMPARE_VAULTSLIST';

export const SET_FILTER_DATA = 'SET_FILTER_DATA';

export const SET_ALL_VAULT = 'SET_ALL_VAULT';

export const SET_ACTIVE_VAULT = 'SET_ACTIVE_VAULT';

export const SET_INACTIVE_VAULT = 'SET_INACTIVE_VAULT';

export const SET_SORT_DATA = 'SET_SORT_DATA';

export const SET_PLATFORM_DATA = 'SET_PLATFORM_DATA';

export const SET_VIEW_DATA = 'SET_VIEW_DATA';

export const SET_VIEW_TYPE = 'SET_VIEW_TYPE';

export const SET_TERMS_CONDITIONS = 'SET_TERMS_CONDITIONS';

export const SET_USER_HEALTH = 'SET_USER_HEALTH';
