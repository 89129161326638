import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { PlusSmIcon } from '@heroicons/react/solid';
import { Formik } from 'formik';
import { PencilIcon, CheckIcon, XIcon } from '@heroicons/react/solid';
import PlatformSelection from '../../components/InstaBuyLpCard/PlatformSelection';

type props = {
  submitLPair: (values: any) => void;
  isEditMode: boolean;
  initValues?: any;
  platforms: any;
};

export default function LPairModal({ submitLPair, isEditMode, initValues, platforms }: props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<any>();
  useEffect(() => {
    if (platforms) {
      if (!isEditMode) {
        setSelectedPlatform(platforms[0]);
      } else {
        const p = platforms.find((item) => item.id === initValues.platform);
        setSelectedPlatform(p);
      }
    }
  }, [isEditMode, initValues, platforms]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function addNewLPair(values, setSubmitting) {
    const p = { ...values, platform: selectedPlatform.id };
    submitLPair(p);
    setSubmitting(false);
    setIsOpen(false);
  }

  function onChangePlatform(option) {
    setSelectedPlatform(option);
  }

  return (
    <>
      <div className="">
        {!isEditMode && (
          <button
            className="flex items-center justify-center w-20 py-1.5 bg-green-400 rounded-lg mt-4 hover:opacity-75 cursor-pointer shadow-md hover:shadow-none"
            onClick={openModal}
          >
            <PlusSmIcon className="w-6 h-6 text-white-900" />
            <p className="font-bold text-white-900 font-work">New</p>
          </button>
        )}
        {isEditMode && (
          <button onClick={openModal}>
            <PencilIcon className="w-7 h-7 text-white-900 p-1.5 bg-green-400 rounded-full hover:opacity-75 cursor-pointer" />
          </button>
        )}
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal} unmount={false}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Pair Details
                  </Dialog.Title>

                  <Formik
                    initialValues={
                      initValues
                        ? initValues
                        : {
                            id: '',
                            address_id: '',
                            decimals: '',
                            platform: '',
                            name: '',
                            decimalsA: '',
                            decimalsB: '',
                            tokenA: '',
                            tokenB: '',
                            swap_account: '',
                          }
                    }
                    validate={(values) => {
                      const errors: any = {};
                      if (!values.address_id) {
                        errors.address_id = 'Required';
                      }
                      if (!values.decimals) {
                        errors.decimals = 'Required';
                      } else if (
                        isNaN(values.decimals) ||
                        parseInt(values.decimals) < 1 ||
                        parseInt(values.decimals) > 100
                      ) {
                        errors.decimals = 'Input number between 1 to 100';
                      }
                      if (!values.name) {
                        errors.name = 'Required';
                      }
                      if (!values.tokenA) {
                        errors.tokenA = 'Required';
                      }
                      if (!values.tokenB) {
                        errors.tokenB = 'Required';
                      }
                      if (!values.decimalsA) {
                        errors.decimalsA = 'Required';
                      }
                      if (!values.decimalsB) {
                        errors.decimalsB = 'Required';
                      }
                      if (!values.swap_account) {
                        errors.swap_account = 'Required';
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      addNewLPair(values, setSubmitting);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="flex flex-col mt-4">
                            <label className="">
                              <span className="text-sm font-medium text-slate-700 font-work">Address </span>
                              <input
                                type="text"
                                name="address_id"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address_id}
                                className="block w-full mt-1 text-sm font-normal rounded-md shadow-sm font-work border-slate-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              />
                              <p className="mt-1 text-xs text-red-500 font-work">
                                {errors.address_id && touched.address_id && errors.address_id}
                              </p>
                            </label>
                            <label className="">
                              <span className="text-sm font-medium text-slate-700 font-work">Decimals </span>
                              <input
                                type="text"
                                name="decimals"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.decimals}
                                className="block w-full mt-1 text-sm font-normal rounded-md shadow-sm font-work border-slate-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              />
                              <p className="mt-1 text-xs text-red-500 font-work">
                                {errors.decimals && touched.decimals && errors.decimals}
                              </p>
                            </label>
                            <label className="">
                              <span className="text-sm font-medium text-slate-700 font-work">Platform </span>
                              {selectedPlatform && (
                                <PlatformSelection
                                  options={platforms}
                                  onSelectToken={onChangePlatform}
                                  selected={selectedPlatform}
                                />
                              )}
                            </label>
                            <label className="">
                              <span className="text-sm font-medium text-slate-700 font-work">Name </span>
                              <input
                                type="text"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                className="block w-full mt-1 text-sm font-normal rounded-md shadow-sm font-work border-slate-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              />
                              <p className="mt-1 text-xs text-red-500 font-work">
                                {errors.name && touched.name && errors.name}
                              </p>
                            </label>
                            <label className="">
                              <span className="text-sm font-medium text-slate-700 font-work">TokenA </span>
                              <input
                                type="text"
                                name="tokenA"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.tokenA}
                                className="block w-full mt-1 text-sm font-normal rounded-md shadow-sm font-work border-slate-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              />
                              <p className="mt-1 text-xs text-red-500 font-work">
                                {errors.tokenA && touched.tokenA && errors.tokenA}
                              </p>
                            </label>
                            <label className="">
                              <span className="text-sm font-medium text-slate-700 font-work">TokenA Decimals </span>
                              <input
                                type="text"
                                name="decimalsA"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.decimalsA}
                                className="block w-full mt-1 text-sm font-normal rounded-md shadow-sm font-work border-slate-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              />
                              <p className="mt-1 text-xs text-red-500 font-work">
                                {errors.decimalsA && touched.decimalsA && errors.decimalsA}
                              </p>
                            </label>

                            <label className="">
                              <span className="text-sm font-medium text-slate-700 font-work">TokenB </span>
                              <input
                                type="text"
                                name="tokenB"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.tokenB}
                                className="block w-full mt-1 text-sm font-normal rounded-md shadow-sm font-work border-slate-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              />
                              <p className="mt-1 text-xs text-red-500 font-work">
                                {errors.tokenB && touched.tokenB && errors.tokenB}
                              </p>
                            </label>
                            <label className="">
                              <span className="text-sm font-medium text-slate-700 font-work">TokenB Decimals </span>
                              <input
                                type="text"
                                name="decimalsB"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.decimalsB}
                                className="block w-full mt-1 text-sm font-normal rounded-md shadow-sm font-work border-slate-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              />
                              <p className="mt-1 text-xs text-red-500 font-work">
                                {errors.decimalsB && touched.decimalsB && errors.decimalsB}
                              </p>
                            </label>

                            <label className="">
                              <span className="text-sm font-medium text-slate-700 font-work">Swap account </span>
                              <input
                                type="text"
                                name="swap_account"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.swap_account}
                                className="block w-full mt-1 text-sm font-normal rounded-md shadow-sm font-work border-slate-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              />
                              <p className="mt-1 text-xs text-red-500 font-work">
                                {errors.swap_account && touched.swap_account && errors.swap_account}
                              </p>
                            </label>
                            <div className="flex justify-end gap-1">
                              <button
                                type="button"
                                className="flex items-center gap-1 px-4 py-2 mt-3 text-sm font-medium text-blue-900 rounded-lg hover:bg-blue-50 "
                                onClick={closeModal}
                                disabled={isSubmitting}
                              >
                                <XIcon className="w-5 h-5" />
                                Cancel
                              </button>
                              <button
                                className="flex items-center gap-1 px-4 py-2 mt-3 text-sm font-medium text-blue-900 rounded-lg hover:bg-blue-50 "
                                type="submit"
                                disabled={isSubmitting}
                              >
                                <CheckIcon className="w-5 h-5" />
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
