import BaseVaultsPage from '../BaseVaultsPage';

import BaseContainer from '../../components/BaseContainer';

const AllVaults = () => {
  return <BaseVaultsPage showOnlyActive={false} title="Available Vaults" />; //<BaseContainer page="available_vaults" showOnlyActive={false} />;
};

// const AllVaults = () => {
//   return <BaseContainer page="available_vaults" showOnlyActive={false} />;
// };

export default AllVaults;
