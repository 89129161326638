import React from 'react';
import imageLogo from '../../assets/images/image-logo.svg';
import telegram from '../../assets/images/telegram.svg';
import twitter from '../../assets/images/twitter.svg';
import medium from '../../assets/images/medium.svg';
import telegramDark from '../../assets/images/telegram-dark.svg';
import twitterDark from '../../assets/images/twitter-dark.svg';
import mediumDark from '../../assets/images/medium-dark.svg';
import whitepaperPDF from '../../assets/whitepaper.pdf';
import privacypolicyPDF from '../../assets/privacy-policy.pdf';
import termsPDF from '../../assets/terms.pdf';
import ackee_logo from '../../assets/images/ackee_logo.svg';
import kudelski_logo from '../../assets/images/kudelski_logo.png';

type FooterProps = {
  darkMode: boolean;
};

const Footer = ({ darkMode }: FooterProps) => {
  return (
    <div className="px-[25px] pb-[80px] pt-[30px] border-t lg:py-10 xl:px-20 md:px-12 md:pb-24 md:pt-10 border-slate-200 dark:border-gray-700">
      <div className="flex justify-between">
        <div>
          <img src={imageLogo} alt="imageLogo" />
          <p className="mt-4 mb-2 text-sm font-normal text-slate-500 font-poppins dark:text-white-900">Audited by:</p>
          <div className="flex">
            <img src={kudelski_logo} alt="kudelski_logo" style={{ width: 90 }} />
            <img src={ackee_logo} alt="ackee_logo" style={{ width: 150, marginLeft: 15 }} />
          </div>
        </div>
        <div className="flex items-start gap-4">
          <a
            target="_blank"
            href="https://t.me/ratiofinance"
            rel="noreferrer"
            className="flex items-center justify-center w-12 h-12 border-t border-b border-l border-r rounded-md border-slate-300 hover:opacity-80 dark:border-gray-700"
          >
            <img src={darkMode ? telegramDark : telegram} alt="telegram" />
          </a>
          <a
            target="_blank"
            href="https://twitter.com/ratiofinance"
            rel="noreferrer"
            className="flex items-center justify-center w-12 h-12 border-t border-b border-l border-r rounded-md border-slate-300 hover:opacity-80 dark:border-gray-700"
          >
            <img src={darkMode ? twitterDark : twitter} alt="twitter" />
          </a>
          <a
            target="_blank"
            href="https://medium.com/@ratiofinance"
            rel="noreferrer"
            className="flex items-center justify-center w-12 h-12 border-t border-b border-l border-r rounded-md border-slate-300 hover:opacity-80 dark:border-gray-700"
          >
            <img src={darkMode ? mediumDark : medium} alt="medium" />
          </a>
        </div>
      </div>
      <div className="justify-between mt-5 md:flex">
        <p className="text-xs font-poppins font-regular text-slate-500 dark:text-white-900">
          Copyright © {new Date().getFullYear()}. Ratio Protocol.
          <br /> All rights reserved.
        </p>
        <ul className="gap-10 md:flex mt-[15px] md:mt-0">
          <li>
            <a
              target="_blank"
              href={whitepaperPDF}
              rel="noreferrer"
              className="text-sm font-normal font-poppins text-slate-500 hover:underline dark:text-white-900"
            >
              Whitepaper
            </a>
          </li>
          <li className="mt-[10px] md:mt-0">
            <a
              target="_blank"
              href={termsPDF}
              rel="noreferrer"
              className="text-sm font-normal font-poppins text-slate-500 hover:underline dark:text-white-900"
            >
              Terms & Conditions
            </a>
          </li>
          <li className="mt-[10px] md:mt-0">
            <a
              target="_blank"
              href={privacypolicyPDF}
              rel="noreferrer"
              className="text-sm font-normal font-poppins text-slate-500 hover:underline dark:text-white-900"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
