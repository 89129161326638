import axios from 'axios';
import { API_ENDPOINT } from '../constants';
import { store } from '../store';
import { io, Socket } from 'socket.io-client';
import { actionTypes, selectors } from '../features/dashboard';

export async function apiCall(path = '', data: any = null, method: any = 'GET', token: string = null) {
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

  const url = API_ENDPOINT + path;

  console.log(url, data);

  return axios
    .request({
      url,
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(token && { 'x-access-token': JSON.stringify(token) }),
      },
      [dataOrParams]: data,
    })
    .then((resp) => {
      return resp;
    })
    .then((json) => json)
    .catch((error) => {
      console.log(error);
    });
}

export const CLIENT_SOCKET: Socket = io(API_ENDPOINT);

CLIENT_SOCKET.on('message', (data) => {
  console.log('Message from backend', data);
});
CLIENT_SOCKET.on('health', (data) => {
  console.log('user_health', data);
  store.dispatch({ type: actionTypes.SET_USER_HEALTH, payload: data });
});
