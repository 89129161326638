import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { usePoolInfo } from '../../../contexts/state';
import { formatUSD } from '../../../utils/utils';
import Button from '../../Button';

import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import { USDR_MINT_DECIMALS } from '../../../constants';
import { TokenAmount } from '../../../utils/safe-math';
import { HiExternalLink } from 'react-icons/hi';
import classNames from 'classnames';
import smallRatio from '../../../assets/images/smallRatio.svg';
import { ERiskLevel } from '../../../types/VaultTypes';
import highRisk from '../../../assets/images/highrisk.svg';
import { ProgressBarVaultUSDr } from '../../Navbar/ProgressBarVaultUSDr';

const VaultCard = ({ vault, connected, onClickDeposit, onClickView, cachedPoolInfo }: any) => {
  const poolInfo = usePoolInfo(vault.mint);
  const [showDetails, setShowDetails] = useState(false);
  const currentValue = Number(new TokenAmount(poolInfo?.totalDebt ?? 0, USDR_MINT_DECIMALS).fixed());
  const limit = Number(new TokenAmount(poolInfo?.debtCeiling ?? 1, USDR_MINT_DECIMALS).fixed());
  const percentage = (currentValue * 100) / limit;

  const totalDebt = +new TokenAmount((vault as any)?.debt ?? 0, USDR_MINT_DECIMALS).fixed();
  const positionValue = +new TokenAmount((vault as any)?.tvlUsd ?? 0, USDR_MINT_DECIMALS).fixed();
  const mintableUSDr = +new TokenAmount((vault as any)?.mintableUSDr ?? 0, USDR_MINT_DECIMALS).fixed();

  const success = percentage <= 80;
  const caution = percentage < 100 && percentage > 80;
  const warning = percentage >= 100;

  const printTvl = () => {
    if (isNaN(vault.tvl) || vault.tvl === 0) {
      return <LoadingSpinner className="spinner-border-sm text-info" />;
    }
    return formatUSD.format(vault.tvl);
  };

  const printApy = () => {
    if (isNaN(vault?.apr)) {
      return <LoadingSpinner className="spinner-border-sm text-info" />;
    }
    return Number(vault?.apr).toFixed(2) + '%';
  };

  return (
    <div className="shadow-sm">
      <div className="border rounded-lg border-white-500 dark:border-gray-600">
        <div className="p-6 ">
          <div className="flex items-start gap-3">
            <img src={vault.icon} alt={'Token1'} className="w-16" />
            <div>
              <p className="text-lg font-semibold font-poppins dark:text-white-900">{vault.title} LP</p>
              <p className="text-sm text-gray-100 dark:text-white-900">
                <span className="font-medium text-gray-200 dark:text-white-900">TVL:</span> {printTvl()}
              </p>
            </div>
          </div>
        </div>
        <div className="p-6 space-y-3 border-t border-b border-slate-300 bg-slate-100 dark:bg-slate-800 dark:border-gray-600">
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">Platform:</p>
            <Link to="/app/instaswap">
              <div className="relative flex items-center gap-1 mt-1">
                <img src={vault.platform.icon} className="w-5" />
                <p className="text-base font-semibold text-slate-700 font-poppins dark:text-white-900">
                  {vault.platform.name}
                </p>
                <HiExternalLink className="absolute top-0 w-3 text-gray-100 -right-4 dark:text-white-900" />
              </div>
            </Link>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">
              Collaterallization Debt Ratio:
            </p>
            <p className="text-base font-semibold text-slate-700 font-poppins dark:text-white-900">
              {cachedPoolInfo ? (100 / cachedPoolInfo.ratio).toFixed(2) : (100 / poolInfo?.ratio).toFixed(2)}%
            </p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">Yield Farm APR:</p>
            <p className="text-base font-semibold text-slate-700 font-poppins dark:text-white-900">{printApy()}</p>
          </div>
          {vault.ratioAPY !== 0 && (
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-slate-600 font-poppins dark:text-white-900">Mint Rewards APR:</p>
              <p className="text-base font-semibold text-slate-700 font-poppins dark:text-white-900">
                {Number(vault?.ratioAPY).toFixed(2)} %
              </p>
            </div>
          )}
          <div className={classNames('flex items-center justify-between')}>
            <div className="flex items-center gap-1 text-sm font-medium text-slate-600 font-poppins dark:text-white-900">
              <img src={smallRatio} alt="smallRatio" className="w-3 h-3" />
              Risk Rating
            </div>
            <div
              className={classNames(
                'flex items-center gap-1 text-base font-semibold text-slate-600 font-poppins dark:text-white-900',
                {
                  'text-green-500': success,
                  'text-amber-500': caution,
                  'text-red-500': warning,
                }
              )}
            >
              {(vault.risk === ERiskLevel.EXTREME || vault.risk === ERiskLevel.HIGH) && (
                <img src={highRisk} alt="highriskIcon" className="w-4" />
              )}
              <p>{vault.risk}</p>
            </div>
          </div>
        </div>
        <div className="px-6 py-4">
          {vault.activeStatus ? (
            <Button
              disabled={!connected}
              className="py-2.5 button button--blue w-100 font-poppins font-medium"
              onClick={() => onClickView(vault.mint)}
            >
              View Vault
            </Button>
          ) : (
            <Button
              disabled={!connected}
              className="py-2.5 button button--blue w-100 font-poppins font-medium"
              onClick={() => onClickDeposit(vault.mint)}
            >
              Deposit
            </Button>
          )}
        </div>
        <div
          onClick={() => setShowDetails(!showDetails)}
          className="flex items-center justify-center gap-1 py-4 text-sm font-normal text-center text-gray-200 border-t rounded-bl-lg rounded-br-lg cursor-pointer font-poppins dark:text-white-900 dark:border-gray-600 border-white-500 hover:opacity-80"
        >
          More details {!showDetails ? <IoIosArrowDown className="w-4" /> : <IoIosArrowUp className="w-4" />}
        </div>
      </div>
    </div>
  );
};

export default VaultCard;
