import { Dapp, DialectSdkError, useDappNotificationSubscriptions } from '@dialectlabs/react-sdk';
import clsx from 'clsx';
import { ReactNode, useEffect, useMemo, useState, Fragment } from 'react';
import Button from '../../components/Button';
import useDappAudience from '../../hooks/useDappAudience';
import { Combobox, Transition } from '@headlessui/react';
import { HiOutlineCheck, HiChevronDown } from 'react-icons/hi';
import ToastMessage from '../../components/ToastMessage';
import * as anchor from '@project-serum/anchor';

const GENERAL_BROADCAST = 'general-broadcast';
const MESSAGE_BYTES_LIMIT = 800;
const TITLE_BYTES_LIMIT = 100;

interface broadcastAdminProps {
  dapp: Dapp;
  headless?: boolean;
  notificationTypeId?: string;
}

function BroadcastAdmin({ dapp, headless, notificationTypeId: notificationTypeIdExternal }: broadcastAdminProps) {
  const { subscriptions: notificationsSubscriptions, errorFetching: errorFetchingNotificationSubscriptions } =
    useDappNotificationSubscriptions();
  const [notificationTypeId, setNotificationTypeId] = useState<string | null>(
    notificationsSubscriptions[0]?.notificationType.id ?? null
  );

  // Consider moving error handling to the useDapp context
  const [errorMessage, setErrorMessage] = useState<string | undefined | null>(null);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [isSending, setIsSending] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const textEncoder = useMemo(() => new TextEncoder(), []);
  const titleLength = useMemo(() => textEncoder.encode(title).length, [textEncoder, title]);
  const messageLength = useMemo(() => textEncoder.encode(message).length, [textEncoder, message]);

  useEffect(
    () => setErrorMessage(errorFetchingNotificationSubscriptions?.msg),
    [errorFetchingNotificationSubscriptions]
  );

  useEffect(() => {
    !notificationTypeId &&
      notificationsSubscriptions.length > 0 &&
      setNotificationTypeId(notificationsSubscriptions[0]?.notificationType.id ?? null);
  }, [notificationTypeId, notificationsSubscriptions]);

  useEffect(() => {
    setNotificationTypeId(notificationTypeIdExternal ?? null);
  }, [notificationTypeIdExternal]);

  const {
    users: usersAddresses,
    totalCount: usersCount,
    isFetching: isLoadingAudience,
  } = useDappAudience({ notificationTypeId });

  const [selected, setSelected] = useState('Broadcast');
  const [query, setQuery] = useState('');

  const noUsers = usersCount === 0;
  const isSubmitDisabled =
    !title || !message || messageLength > MESSAGE_BYTES_LIMIT || titleLength > TITLE_BYTES_LIMIT || noUsers;
  let usersInfo: ReactNode = `${usersCount} user${usersCount > 1 ? 's' : ''}`;

  if (isLoadingAudience) {
    usersInfo = <div>Loading...</div>;
  } else if (noUsers) {
    usersInfo = 'No users yet';
  }

  const sendBroadcastMessage = async () => {
    if (noUsers) {
      setErrorMessage('No users in the audience for this broadcast');
      return;
    }

    if (title === '') {
      setErrorMessage('Title is required.');
      return;
    }

    if (message === '') {
      setErrorMessage('Message is required.');
      return;
    }

    setIsSending(true);
    const recipient = selected === 'Broadcast' ? null : new anchor.web3.PublicKey(selected);
    try {
      if (selected === 'Broadcast') {
        await dapp.messages.send({
          title,
          message,
        });
      } else {
        await dapp.messages.send({
          title,
          message,
          recipient,
        });
      }

      setTitle('');
      setMessage('');
      setErrorMessage(null);
      setStatusMessage('Broadcast successfully sent and will be delivered soon');
      setSelected('Broadcast');
    } catch (error) {
      const errMessage = (error as DialectSdkError)?.msg;
      setErrorMessage(errMessage);
      setSelected('Broadcast');
    } finally {
      setIsSending(false);
    }
  };

  const renderNotificationTypeSelect = () => {
    const users = ['Broadcast', ...usersAddresses];
    const filteredPeople =
      query === ''
        ? users
        : users.filter((person) =>
            person.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
          );
    return (
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative w-2/3 overflow-hidden text-left bg-white border-none rounded-lg shadow-sm cursor-default border-white-500">
            <Combobox.Input
              className="w-full py-2.5 pl-3 pr-10 font-poppins text-base leading-5 text-gray-200 border border-white-500 focus:ring-0 rounded-lg"
              // displayValue={(person) => person}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <HiChevronDown className="w-5 h-5 text-gray-200" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute z-30 w-2/3 py-1 mt-1 overflow-auto text-base rounded-md shadow-lg bg-white-900 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.length === 0 && query !== '' ? (
                <div className="relative px-4 py-2 text-base text-gray-200 cursor-default select-none font-poppins">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((person) => (
                  <Combobox.Option
                    key={person}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 font-poppins ${
                        active ? 'bg-teal-600 text-white' : 'text-gray-200'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{person}</span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <HiOutlineCheck className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    );
  };

  return (
    <div className="flex flex-col">
      {!headless ? (
        <>
          <div className="flex items-end gap-3">
            <p className="text-2xl font-semibold text-gray-200 font-poppins">Create broadcast</p>
            <p className="text-sm text-gray-200 font-poppins mb-0.5 flex gap-1">
              (Total Users: <div className="font-semibold">{usersInfo}</div>)
            </p>
          </div>
          <p className="mt-6 text-sm text-gray-200 font-poppins">Select wallet address</p>
          {renderNotificationTypeSelect()}
        </>
      ) : null}
      <div>
        <p className="mt-3 text-sm text-gray-200 font-poppins">Title</p>
        <input
          placeholder="Title"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          value={title}
          className={clsx(
            'w-2/3 mt-1 border border-white-500 rounded-lg font-poppins py-2 pl-3 pr-10 font-poppins text-gray-200 focus:ring-0 focus:outline-none'
          )}
        />
      </div>
      <div>
        <p className="mt-3 text-sm text-gray-200 font-poppins">Messages</p>
        <textarea
          placeholder="Write message..."
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          value={message}
          // FIXME: add the outlined texarea class to the theme
          className={clsx(
            'w-2/3 mt-1 border border-white-500 rounded-lg font-poppins py-2 pl-3 pr-10 font-poppins text-gray-200 focus:ring-0 focus:outline-none'
          )}
        />
        <div className="dt-text-xs dt-pl-1 dt-opacity-50">
          Limit: {messageLength}/{MESSAGE_BYTES_LIMIT}
        </div>
      </div>

      <Button
        className="py-2.5 button button--blue w-2/3 font-poppins font-medium text-base mt-4"
        onClick={sendBroadcastMessage}
      >
        {isSending ? 'Sending...' : 'Send'}
      </Button>
      <p className="w-2/3 mt-4 text-sm text-gray-200 font-poppins">
        On-chain messages not currently supported by this dashboard. Please use the CLI to send broadcast messages
        on-chain.
      </p>

      <ToastMessage
        message={errorMessage ? `Error sending broadcast: ${errorMessage}` : statusMessage}
        isError={Boolean(errorMessage)}
        isSuccess={Boolean(statusMessage)}
        onClose={() => {
          errorMessage ? setErrorMessage(null) : setStatusMessage('');
        }}
      />
    </div>
  );
}

export default BroadcastAdmin;
