import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LoadingSpinner from '../../atoms/LoadingSpinner';

import { TokenPairCardProps } from '../../types/VaultTypes';
import { formatUSD } from '../../utils/utils';
import { isSwimPool, hasInstaLp } from '../../utils/helper';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { useLoadingState, usePoolInfo } from '../../contexts/state';
import { useWallet } from '@solana/wallet-adapter-react';

import Button from '../Button';
import SwimVaultInfoPopover from '../../components/SwimVaultInfoPopover';

import linkIcon from '../../assets/images/link.svg';
import infoIcon from '../../assets/images/risklevel.svg';
import USDrIcon from '../../assets/images/USDr.svg';
import { IoWarningOutline } from 'react-icons/io5';
import { MdOutlineErrorOutline } from 'react-icons/md';
import Experimental from '../Experimental';
import ExperimentalModal from '../ExperimentalModal';

const TokenPairListItem = (tokenPairCardProps: TokenPairCardProps) => {
  const { data, isFirstLoading, liquidationStatus, cachedPoolInfo } = tokenPairCardProps;
  const history = useHistory();

  const { connected } = useWallet();

  const hasUserReachedDebtLimit = false;
  const poolInfo = usePoolInfo(data.mint);

  const [isClicked, setIsClicked] = useState<boolean>(false);

  const showDashboard = () => {
    if (!connected) {
      toast('Please connect your wallet!');
    } else {
      history.push(`/app/vaultdashboard/${data.mint}`);
    }
  };

  const renderModalButton = (status: boolean) => {
    if (isClicked) {
      return (
        <Button disabled className="py-2.5 mx-3 mt-2 button button--blue w-100">
          <LoadingSpinner className="spinner-border-sm text-dark" />
        </Button>
      );
    }
    return (
      <div>
        {status ? (
          data?.experimentalStatus ? (
            <ExperimentalModal connected={connected} onClickEvent={showDashboard} btnText="Enter Vault" />
          ) : (
            <Button
              disabled={!connected}
              className="py-2.5 button button--blue w-full font-poppins font-medium"
              onClick={showDashboard}
            >
              Enter Vault
            </Button>
          )
        ) : !data?.experimentalStatus ? (
          <ExperimentalModal connected={connected} onClickEvent={onClickDeposit} btnText="Deposit" />
        ) : (
          <Button
            disabled={!connected}
            className="py-2.5 button button--blue w-full font-poppins font-medium whitespace-nowrap"
            onClick={onClickDeposit}
          >
            Deposit
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (poolInfo && isClicked) {
      setIsClicked(false);
      history.push(`/app/vaultsetup/${data.mint}`);
    }
  }, [poolInfo]);

  const onClickDeposit = () => {
    if (!connected) {
      toast('Please connect your wallet!');
    } else {
      if (poolInfo) {
        history.push(`/app/vaultsetup/${data.mint}`);
      } else {
        setIsClicked(true);
      }
    }
  };

  const printTvl = () => {
    if (isNaN(data.tvl)) {
      return <LoadingSpinner className="spinner-border-sm text-info" />;
    }
    return formatUSD.format(data.tvl);
  };

  return (
    <>
      <tr>
        <td className="px-6 py-6 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <div>
            <div className="flex items-start gap-3">
              <img src={data.icon} alt={'Token1'} className="w-16" />
              {data.platform.name === 'SWIM' && <SwimVaultInfoPopover tokenData={data} />}
              <div>
                <div className="flex items-center gap-1">
                  <p
                    className={classNames(
                      'text-base font-semibold font-poppins text-slate-800 dark:text-white-900 whitespace-nowrap',
                      { '!text-violet-300 dark:text-violet-300': liquidationStatus === 'warning' },
                      { '!text-red-500 dark:text-red-500': liquidationStatus === 'danger' }
                    )}
                  >
                    {data.title === 'USDC-USDR' ? 'USDC-USDr' : data.title}
                  </p>
                  {data?.experimentalStatus && (
                    <Experimental className="w-5 h-5 mb-1 text-gray-100 dark:text-white-900" />
                  )}
                  {liquidationStatus !== 'warning' && (
                    <IoWarningOutline className="w-5 h-5 mb-1 text-violet-300 dark:text-violet-300" />
                  )}
                  {liquidationStatus === 'danger' && (
                    <MdOutlineErrorOutline className="w-5 h-5 text-red-500 dark:text-red-500" />
                  )}
                </div>
                <p className="text-xs font-medium font-poppins text-slate-600 dark:text-white-900">TVL {printTvl()}</p>
              </div>
            </div>
          </div>
          {hasUserReachedDebtLimit && (
            <div className="flex gap-2 mt-2 font-semibold text-red-500 font-poppins">
              <IoAlertCircleOutline size={20} />
              <p>USDr Limit Reached: {hasUserReachedDebtLimit}</p>
            </div>
          )}
        </td>
        <td className="px-6 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <p className="text-sm font-medium text-green-500 font-poppins">{data?.activeStatus && 'Active'}</p>
        </td>
        <td className="px-6 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <div className="flex items-center gap-2">
            <p className="text-base font-semibold font-poppins text-slate-700 dark:text-white-900">
              {Number(data?.apr + data?.ratioAPY).toFixed(2)}%
            </p>
            {data.ratioAPY !== 0 && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 100 }}
                overlay={
                  <Tooltip id="tooltip">
                    <div className="font-medium font-poppins">
                      <p>
                        <strong>APR</strong> is made up of:
                      </p>
                      <div className="flex items-center gap-2 mt-1">
                        <img src={USDrIcon} alt="USDrIcon" /> Mint APR: {Number(data?.ratioAPY).toFixed(2)}%
                      </div>
                      {!isSwimPool(data.platform.symbol) && (
                        <div className="flex items-center gap-2 mt-2">
                          <img src={USDrIcon} alt="USDrIcon" /> Yield Farming: {Number(data?.apr).toFixed(2)}%
                        </div>
                      )}
                    </div>
                  </Tooltip>
                }
              >
                <div className="cursor-pointer hover:opacity-75">
                  <img src={infoIcon} alt="infoIcon" />
                </div>
              </OverlayTrigger>
            )}
          </div>
        </td>
        <td className="px-6 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
            {cachedPoolInfo ? (100 / cachedPoolInfo.ratio).toFixed(2) : (100 / poolInfo?.ratio).toFixed(2)}%
          </p>
        </td>
        <td className="px-6 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          {hasInstaLp(data.title) ? (
            <a href={data.item.page_url} target="_blank" rel="noreferrer">
              <div className="relative inline-block gap-1 mt-1">
                <div className="flex items-center gap-1 ">
                  <img src={data.platform.icon} className="w-5" />
                  <p className="text-base font-semibold text-slate-700 font-poppins dark:text-white-900">
                    {data.platform.name}
                  </p>
                </div>
                <img src={linkIcon} alt="linkIcon" className="absolute top-0 -right-2" />
              </div>
            </a>
          ) : (
            <Link to={{ pathname: '/app/instaswap', state: { platform: data.platform.name, token: data.title } }}>
              <div className="relative inline-block mt-1">
                <div className="flex items-center gap-1 ">
                  <img src={data.platform.icon} className="w-5" />
                  <p className="text-base font-semibold text-slate-700 font-poppins dark:text-white-900 hover:underline hover:underline-offset-2">
                    {data.platform.name}
                  </p>
                </div>
                <img src={linkIcon} alt="linkIcon" className="absolute top-0 -right-2" />
              </div>
            </Link>
          )}
        </td>
        <td className="px-6 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          {!isSwimPool(data.platform.symbol) && (
            <p className={classNames(data.risk, 'font-poppins font-medium bg-transparent')}>{data.risk} </p>
          )}
        </td>
        <td className="px-6 whitespace-no-wrap border-b border-slate-300 dark:border-gray-600">
          {renderModalButton(data.activeStatus)}
        </td>
      </tr>
    </>
  );
};

export default TokenPairListItem;
