import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react';

const GuideModal = () => {
  const { connected } = useWallet();

  const onClickGuideBtn = () => {
    window.open('https://docs.ratio.finance');
  };

  return (
    <button
      disabled={!connected}
      className="px-[10px] py-3 text-sm font-medium md:px-4 button button--blue font-poppins w-100"
      onClick={onClickGuideBtn}
    >
      Read the Docs
    </button>
  );
};

export default GuideModal;
