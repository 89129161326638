export const REQUEST_ALL_LPSWPAIRS = 'REQUEST_ALL_LPSWPAIRS';
export const RECEIVE_ALL_LPSWPAIRS = 'RECEIVE_ALL_LPSWPAIRS';
export const REQUEST_ADD_LPSWPAIR = 'REQUEST_ADD_LPSWPAIR';
export const RECEIVE_ADD_LPSWPAIR = 'RECEIVE_ADD_LPSWPAIR';
export const REQUEST_UPDATE_LPSWPAIR = 'REQUEST_UPDATE_LPSWPAIR';
export const RECEIVE_UPDATE_LPSWPAIR = 'RECEIVE_UPDATE_LPSWPAIR';
export const REQUEST_DELETE_LPSWPAIR = 'REQUEST_DELETE_LPSWPAIR';
export const RECEIVE_DELETE_LPSWPAIR = 'RECEIVE_DELETE_LPSWPAIR';

export const getAllLpSwapPairs = () => ({ type: REQUEST_ALL_LPSWPAIRS });
export const addNewLpSwapPair = (data) => ({ type: REQUEST_ADD_LPSWPAIR, payload: data });
export const updateLpSwapPair = (data) => ({ type: REQUEST_UPDATE_LPSWPAIR, payload: data });
export const deleteLpSwapPair = (data) => ({ type: REQUEST_DELETE_LPSWPAIR, payload: data });
