/* eslint-disable */

import { LPair } from '../../../types/VaultTypes';
import { GenericPoolManager } from '../GenericPoolManager';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { deposit, harvest, withdraw, withdrawIx } from './saber-utils';

export class SaberPoolManager extends GenericPoolManager {
  async depositLP(connection: Connection, wallet: any, vault: LPair, amount: number): Promise<string> {
    const txHash = await deposit(connection, wallet, new PublicKey(vault.address_id), amount);
    // this.postTransactionToApi(txHash, vault.address_id, 'deposit', wallet?.publicKey, 'confirmed');
    return txHash;
  }

  async withdrawLP(connection: Connection, wallet: any, vault: LPair, amount: number): Promise<string> {
    const txHash = await withdraw(connection, wallet, new PublicKey(vault.address_id), amount);
    // this.postTransactionToApi(txHash, vault.address_id, 'withdraw', wallet?.publicKey, 'confirmed');
    return txHash;
  }

  async withdrawLPIx(connection: Connection, wallet: any, vault: LPair, amount: number): Promise<Transaction> {
    const tx = await withdrawIx(connection, wallet, new PublicKey(vault.address_id), amount);
    // this.postTransactionToApi(txHash, vault.address_id, 'withdraw', wallet?.publicKey, 'confirmed');
    return tx;
  }

  async harvestReward(connection: Connection, wallet: any, vault: LPair): Promise<string> {
    const txHash = await harvest(connection, wallet, new PublicKey(vault.address_id));
    // this.postTransactionToApi(txHash as string, vault.address_id, 'harvest', wallet?.publicKey, 'confirmed');
    return txHash;
  }

  getTokenName() {
    return 'SBR';
  }
}
