import { formatUSD } from '../../../utils/utils';
import InformationPopover from './InfomationPopover';

interface PriceCardInterface {
  title?: string;
  titleIcon?: boolean;
  mainValue?: string;
  mainUnit?: string;
  currentPrice?: string;
  minimumRatio?: string;
  stabilityFee?: string;
}

type PriceCardProps = {
  price: PriceCardInterface;
  tokenName: string;
  risk: number;
};

const PriceCard = ({ price, tokenName, risk }: PriceCardProps) => {
  return (
    <div>
      <div className="!border border-slate-300 rounded-2xl relative dark:border-gray-600 shadow-sm">
        <div className="p-6 space-y-1">
          <div className="flex items-center gap-3">
            <p className="text-lg font-medium text-gray-200 font-poppins dark:text-white-900">
              Collateralization Debt Ratio
            </p>
            <InformationPopover />
          </div>

          <p className="text-2xl font-semibold text-blue-500 font-poppins">
            {!risk ? '...' : (100 / risk).toFixed(2)}%
          </p>
        </div>
        <div className="p-6 border-t rounded-b-2xl rounded-bl-2xl dark:bg-slate-800 bg-slate-100 border-white-500 dark:border-gray-600">
          {price?.currentPrice && (
            <div className="space-y-1">
              <p className="text-sm font-normal text-gray-100 font-poppins dark:text-white-900">
                Current {tokenName} LP Market Price
              </p>
              <p className="font-semibold text-gray-200 text-md font-poppins dark:text-white-900">
                {formatUSD.format(+price?.currentPrice ?? 0)}
              </p>
            </div>
          )}
          {price?.minimumRatio && (
            <div className="flex justify-between mt-1">
              <div>
                <p className="text-sm font-normal text-gray-100 font-poppins dark:text-white-900">Minimum Ratio</p>
                <p className="font-semibold text-gray-200 text-md font-poppins dark:text-white-900">
                  {price?.minimumRatio}
                </p>
              </div>
              <div>
                <p className="text-sm font-normal text-gray-100 font-poppins dark:text-white-900">Stability Fee</p>
                <p className="font-semibold text-right text-gray-200 text-md font-poppins dark:text-white-900">
                  {price?.stabilityFee}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
