import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import * as dotenv from 'dotenv';

import { Connection } from '@solana/web3.js';
import { InstaSwapProvider } from '@ratio-finance/instaswap-hook';

// import { WalletProvider } from './contexts/wallet';
// import { ConnectionProvider } from './contexts/connection';
import { ContextProvider } from './contexts/ContextProvider';
import { AccountsProvider } from './contexts/accounts';
// import { MarketProvider } from './contexts/market';
import { ThemeProvider } from './contexts/ThemeContext';
import Layer from './pages/Layer';
import AdminPanel from './pages/AdminPanel';
import { AuthProvider } from './contexts/auth';
import NotFound from './pages/NotFound';
import { VaultsContextProvider } from './contexts/vaults';
import { RFStateProvider } from './contexts/state';
import { DialectProvider } from './contexts/dialect';

dotenv.config();
const App: React.FC = () => {
  return (
    <ContextProvider>
      <AuthProvider>
        <AccountsProvider>
          <ThemeProvider>
            <DialectProvider>
              <VaultsContextProvider>
                <RFStateProvider>
                  <InstaSwapProvider connection={new Connection('https://api.metaplex.solana.com', 'confirmed')}>
                    <Router>
                      <Switch>
                        <Route path="/app" component={Layer} />
                        <Route path="/adminpanel" component={AdminPanel} />
                        <Route path="/">
                          <Redirect to="/app" />
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    </Router>
                  </InstaSwapProvider>
                </RFStateProvider>
              </VaultsContextProvider>
            </DialectProvider>
          </ThemeProvider>
        </AccountsProvider>
      </AuthProvider>
    </ContextProvider>
  );
};

export default App;
