import React, { useState } from 'react';

import ValueBox from './ValueBox';
import Button from '../../Button';

import { USDR_MINT_DECIMALS } from '../../../constants';
import { TokenAmount } from '../../../utils/safe-math';

import allVaultIcon from '../../../assets/images/all-vaults-icon.svg';
import circleusdIcon from '../../../assets/images/circle-usd.svg';
import usdrmintedIcon from '../../../assets/images/usdrminted.svg';

type props = {
  connected: boolean;
  userOverview: any;
  userVaultInfos: any;
};

const Overview = ({ connected, userOverview, userVaultInfos }: props) => {
  const activeVaultCount = userOverview ? userOverview.activeVaults.toString() : '0';
  const totalMinted = Number(new TokenAmount(userOverview ? userOverview.totalDebt : 0, USDR_MINT_DECIMALS).fixed());
  const [totalLocked, setTotalLocked] = useState(0);

  const getActiveVaultInfo = async function (activeVaults: any[]) {
    const vaults = Object.values(activeVaults);
    let tvlUSD = 0;
    for (const vault of vaults) {
      const pv = +new TokenAmount((vault as any)?.tvlUsd ?? 0, USDR_MINT_DECIMALS).fixed();
      tvlUSD += pv;
    }
    return {
      tvlUSD,
    };
  };

  React.useEffect(() => {
    if (userVaultInfos) {
      getActiveVaultInfo(userVaultInfos).then(({ tvlUSD }) => {
        setTotalLocked(tvlUSD);
      });
    }
  }, [userVaultInfos && Object.keys(userVaultInfos).length]);

  return (
    <div>
      <p className="text-lg font-medium text-gray-200 font-poppins dark:text-white-900">My Overview</p>
      <div className="items-center gap-4 mt-2 2xl:flex xl:block md:flex">
        <ValueBox className="w-full px-3 pt-3 pb-2.5">
          <div className="flex items-center gap-2">
            <img src={allVaultIcon} alt="allVaultIcon" className="w-5" />
            <p className="text-sm font-normal text-gray-200 whitespace-nowrap font-poppins dark:text-white-900">
              Total Vaults
            </p>
          </div>
          <p className="mt-1 text-xl font-semibold text-gray-200 font-poppins dark:text-white-900">
            {activeVaultCount && parseInt(activeVaultCount)}
          </p>
        </ValueBox>
        <ValueBox className="w-full px-3 pt-3 pb-2.5 2xl:mt-0 lg:mt-0 xl:mt-[15px] md:mt-0 mt-[15px]">
          <div className="flex items-center gap-2">
            <img src={circleusdIcon} alt="circleusdIcon" />
            <p className="text-sm font-normal text-gray-200 font-poppins dark:text-white-900">Total Value</p>
          </div>
          <p className="mt-1 text-xl font-semibold text-gray-200 font-poppins dark:text-white-900">
            ${totalLocked.toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </p>
        </ValueBox>
      </div>
      <div className="gap-4 md:flex xl:block">
        <ValueBox className="items-center justify-between w-full p-3 mt-3 lg:flex md:block">
          <div className="flex items-center gap-2">
            <img src={usdrmintedIcon} alt="usdrmintedIcon" />
            <p className="text-sm font-normal text-gray-200 font-poppins dark:text-white-900">USDr Minted:</p>
          </div>
          <p className="mt-1 text-xl font-semibold text-gray-200 font-poppins dark:text-white-900">
            {(Math.ceil(totalMinted * 100) / 100).toFixed(2)}
          </p>
        </ValueBox>
        <ValueBox className="flex items-center justify-between w-full px-3 pt-3 pb-2.5 mt-3">
          <div className="">
            <p className="text-sm font-normal text-gray-200 font-poppins dark:text-white-900">
              Available to Harvest (USD)
            </p>
            <p className="mt-1 text-xl font-semibold text-gray-200 font-poppins dark:text-white-900">--</p>
          </div>
          {/* <Button
            disabled={!connected}
            className="py-2.5 button button--blue px-3  text-sm font-poppins font-medium whitespace-nowrap"
          >
            Harvest All
          </Button> */}
        </ValueBox>
      </div>
    </div>
  );
};

export default Overview;
