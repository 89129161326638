import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { formatUSD } from '../../utils/utils';
import { isSwimPool, hasInstaLp } from '../../utils/helper';
import { TokenAmount } from '../../utils/safe-math';

import LoadingSpinner from '../../atoms/LoadingSpinner';
import SwimVaultInfoPopover from '../../components/SwimVaultInfoPopover';
import Button from '../Button';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { useGetPoolManager } from '../../hooks/useGetPoolManager';

import { TokenPairCardProps } from '../../types/VaultTypes';
import linkIcon from '../../assets/images/link.svg';
import { isWalletApproveError } from '../../utils/utils';
import smallRatioIcon from '../../assets/images/smallRatio.svg';

import { IoWarningOutline } from 'react-icons/io5';
import { MdOutlineErrorOutline } from 'react-icons/md';

import Experimental from '../Experimental';
import ExperimentalModal from '../ExperimentalModal';

import {
  useAppendUserAction,
  usePoolInfo,
  useUserVaultInfo,
  useSubscribeTx,
  useRFStateInfo,
} from '../../contexts/state';
import { HARVEST_ACTION } from '../../utils/ratio-lending';
import USDrIcon from '../../assets/images/USDr.svg';
import infoIcon from '../../assets/images/risklevel.svg';
import { USDR_MINT_DECIMALS } from '../../constants';

const ActivePairCard = ({ data, liquidationStatus }: TokenPairCardProps) => {
  const history = useHistory();

  const { connection } = useConnection();
  const wallet = useWallet();
  const { publicKey, connected } = useWallet();

  const vaultState = useUserVaultInfo(data.mint);
  const poolInfo = usePoolInfo(data.mint);
  const totalDebt = +new TokenAmount((vaultState as any)?.debt ?? 0, USDR_MINT_DECIMALS).fixed();
  const positionValue = +new TokenAmount((vaultState as any)?.tvlUsd ?? 0, USDR_MINT_DECIMALS).fixed();
  const mintableUSDr = +new TokenAmount((vaultState as any)?.mintableUSDr ?? 0, USDR_MINT_DECIMALS).fixed();

  const [isHarvesting, setIsHarvesting] = useState(false);

  const poolManager = useGetPoolManager(data.item);

  const subscribeTx = useSubscribeTx();
  const appendUserAction = useAppendUserAction();
  const globalState = useRFStateInfo();

  const harvest_reward_fee = poolInfo.harvestFeeNumer.toNumber() / globalState.feeDeno.toNumber();

  const printTvl = () => {
    if (isNaN(data.tvl)) {
      return <LoadingSpinner className="spinner-border-sm text-info" />;
    }
    return formatUSD.format(data.tvl);
  };

  const showDashboard = () => {
    if (!connected) {
      toast('Please connect your wallet!');
    } else {
      history.push(`/app/vaultdashboard/${data.mint}`);
    }
  };

  const harvest = async () => {
    if (!publicKey) {
      console.log('error', 'Wallet not connected!');
      return;
    }
    setIsHarvesting(true);

    try {
      if (!poolManager || !poolManager?.harvestReward) {
        throw new Error('Pool manager factory not initialized');
      }

      console.log('Harvesting...');
      const txHash = await poolManager?.harvestReward(connection, wallet, data.item);
      subscribeTx(
        txHash,
        () => toast.info('Harvest Transaction Sent'),
        () => toast.success('Harvest Confirmed.'),
        () => toast.error('Harvest Transaction Failed')
      );
      appendUserAction(
        publicKey.toString(),
        data.mint,
        data.realUserRewardMint,
        HARVEST_ACTION,
        vaultState ? vaultState.reward : 0,
        txHash,
        0,
        0,
        harvest_reward_fee
      );
    } catch (err) {
      console.error(err);
      if (isWalletApproveError(err)) toast.warn('Wallet is not approved!');
      else toast.error('Transaction Error!');
    }

    setIsHarvesting(false);
  };

  const renderModalButton = () => {
    return (
      <div className="mx-3 w-100">
        <div className="flex gap-3">
          <Button
            disabled={!connected || isHarvesting}
            className="py-2.5 button button--blue w-100 font-poppins font-medium"
            onClick={harvest}
          >
            Harvest
          </Button>
          {data?.experimentalStatus ? (
            <ExperimentalModal connected={connected} onClickEvent={showDashboard} btnText="Enter Vault" />
          ) : (
            <Button
              disabled={!connected}
              className="py-2.5 button button--blue w-100 font-poppins font-medium"
              onClick={showDashboard}
            >
              Enter Vault
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="mt-3">
        <div
          className={classNames(
            'border-l border-t border-r border-b border-slate-300 dark:border-gray-600 rounded-[8px] relative',
            { 'border-violet-300 dark:border-violet-300': liquidationStatus === 'warning' },
            {
              'border-red-500 dark:border-red-500': liquidationStatus === 'danger',
            }
          )}
        >
          <div className="flex items-center justify-between h-20 px-[20px] py-[20px] relative">
            <div className="flex items-start gap-3">
              <div>
                <img src={data.icon} alt={'Token1'} className="w-16" />{' '}
              </div>
              {data.platform.name === 'SWIM' && <SwimVaultInfoPopover tokenData={data} />}
              <div>
                <div className="flex items-start gap-2">
                  <p className="text-base font-semibold font-poppins dark:text-white-900">{data.title}</p>
                  {data?.experimentalStatus && (
                    <Experimental className="w-5 h-5 mb-1 text-gray-100 dark:text-white-900" />
                  )}
                </div>
                <p className="text-xs font-normal text-gray-600 font-poppins dark:text-white-900">TVL {printTvl()}</p>
                {/* <a href={data.platform.link} target="_blank" rel="noreferrer">
                  <div className="mt-1 d-inline-flex align-items-center position-relative">
                    <img src={data.platform.icon} />
                    <p className="ml-1 semiBold">{data.platform.name}</p>
                    <img src={linkIcon} alt="linkIcon" className="activepaircard__titleBox--linkIcon" />
                  </div>
                </a> */}
              </div>
            </div>

            {liquidationStatus === 'warning' && (
              <div className="absolute top-0 right-0 px-1.5 py-1 text-xs font-normal bg-violet-300/10 rounded-tr-[8px]  font-poppins flex items-center justify-center gap-2 w-32">
                <IoWarningOutline className="w-8 h-8 mb-2 text-violet-300 dark:text-violet-300" />
                <p className="text-[12px] font-medium text-violet-300 font-poppins">Liquidation Warning</p>
              </div>
            )}
            {liquidationStatus === 'danger' && (
              <div className="absolute top-0 right-0 px-1.5 py-1 text-xs font-normal bg-violet-300/10 rounded-tr-[8px]  font-poppins flex items-center justify-center gap-2 w-32">
                <MdOutlineErrorOutline className="w-8 h-8 mb-2 text-red-500 dark:text-red-500" />
                <p className="text-[12px] font-medium text-red-500 dark:text-red-500 font-poppins">Vault Liquidated</p>
              </div>
            )}
            {/* <div className="activepaircard__riskBox">
              <div className="text-right">
                <div className="d-flex align-items-center">
                  <img src={smallRatioIcon} alt="smallRatio" />
                  <p className="mx-1">Risk Rating</p>
                </div>
                <div className="mt-1 d-flex justify-content-end">
                  <h6 className={classNames('ml-1', data.risk)}>{data.risk} </h6>
                </div>
              </div>
            </div> */}
          </div>
          <div className="h-60 px-[22px] py-[24px] border-t border-slate-300 border-b bg-slate-100 dark:bg-slate-800  dark:border-gray-600">
            <div className="flex items-center justify-between">
              <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">Platform:</p>
              <div className="semiBold">
                {hasInstaLp(data.title) ? (
                  <a href={data.item.page_url} target="_blank" rel="noreferrer">
                    <div className="relative flex items-center gap-1 mt-1">
                      <img src={data.platform.icon} className="w-5 h-5" />
                      <p className="text-base font-semibold text-slate-700 font-poppins dark:text-white-900">
                        {data.platform.name}
                      </p>
                      <img src={linkIcon} alt="linkIcon" className="absolute top-0 -right-2 " />
                    </div>
                  </a>
                ) : (
                  <Link to={{ pathname: '/app/instaswap', state: { platform: data.platform.name, token: data.title } }}>
                    <div className="relative flex items-center gap-1 mt-1">
                      <img src={data.platform.icon} className="w-5 h-5" />
                      <p className="text-base font-semibold text-slate-700 font-poppins dark:text-white-900">
                        {data.platform.name}
                      </p>
                      <img src={linkIcon} alt="linkIcon" className="absolute top-0 -right-2 " />
                    </div>
                  </Link>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between mt-2">
              <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">APR:</p>
              <div className="flex">
                <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
                  {Number(data?.apr + data?.ratioAPY).toFixed(2)}%
                </p>
                {data.ratioAPY !== 0 && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 100 }}
                    overlay={
                      <Tooltip id="tooltip">
                        <div className="activepaircard__overlaytooltip">
                          <p>
                            <strong>APR</strong> is made up of:
                          </p>
                          <div className="flex mb-1">
                            <img src={USDrIcon} alt="USDrIcon" /> Mint APR: {Number(data?.ratioAPY).toFixed(2)}%
                          </div>
                          {!isSwimPool(data?.platform?.symbol) && (
                            <div className="flex mt-2">
                              <img src={data.platform.icon} alt="USDrIcon" /> Yield Farming:{' '}
                              {Number(data?.apr).toFixed(2)}%
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    }
                  >
                    <div className="activepaircard__overlaytrigger">
                      <img src={infoIcon} alt="infoIcon" />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>

            <div className={classNames('flex justify-between mt-2')}>
              <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">
                Collateralization Ratio:
              </p>
              <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
                {(100 / poolInfo?.ratio).toFixed(2)}%
              </p>
            </div>
            {!isSwimPool(data?.platform?.symbol) && (
              <div className="flex items-center justify-between mt-2">
                <div className="flex items-center gap-1">
                  <img src={smallRatioIcon} alt="smallRatio" className="w-4" />
                  <p className="font-medium text-slate-600 dark:text-white-900 font-poppins">Risk Rating:</p>
                  {/* <img src={liskLevelIcon} alt="lisklevel" /> */}
                </div>
                <h6 className={classNames('ml-1 font-poppins font-semibold bg-transparent', data.risk)}>
                  {data.risk}{' '}
                </h6>
              </div>
            )}
            <div className="flex justify-between mt-2">
              <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">USDr Debt:</p>
              <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
                {Number(totalDebt.toFixed(2))}
              </p>
            </div>
            <div className="flex justify-between mt-2">
              <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">
                USDr Available to Mint:
              </p>
              <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
                {Number(mintableUSDr.toFixed(2)).toFixed(2)}
              </p>
            </div>
          </div>
          <div className="px-[22px] py-[24px] border-slate-300 border-b bg-slate-100 dark:bg-slate-800 dark:border-gray-600">
            <div className="flex justify-between">
              <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">Rewards Earned:</p>

              <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
                {!vaultState?.rewardUSD ? (
                  <LoadingSpinner className="spinner-border-sm text-info" />
                ) : (
                  `$  ${vaultState?.rewardUSD}`
                )}
              </p>
            </div>
            <div className="flex justify-between mt-2">
              <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">Position Value:</p>
              <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
                $ {positionValue.toFixed(2)}
              </p>
            </div>
          </div>
          <div className="flex py-3">
            {connected ? (
              renderModalButton()
            ) : (
              <OverlayTrigger
                placement="top"
                trigger="click"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="tooltip">Connect your wallet to unlock this.</Tooltip>}
              >
                <div className="col">
                  <Button className="mt-2 button--disabled generate">Open Vault</Button>
                </div>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivePairCard;
