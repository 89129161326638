import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';

import { IoWarningOutline } from 'react-icons/io5';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import Button from '../Button';
import { selectors } from '../../features/dashboard';
import Experimental from '../Experimental';

import { TokenPairCardProps } from '../../types/VaultTypes';
import { formatUSD } from '../../utils/utils';
import { isSwimPool, hasInstaLp } from '../../utils/helper';

import smallRatioIcon from '../../assets/images/smallRatio.svg';
import { IoAlertCircleOutline } from 'react-icons/io5';
import linkIcon from '../../assets/images/link.svg';
import LoadingSpinner from '../../atoms/LoadingSpinner';
import SwimVaultInfoPopover from '../../components/SwimVaultInfoPopover';
import { useLoadingState, usePoolInfo } from '../../contexts/state';
import { ProgressBarVaultUSDr } from '../Navbar/ProgressBarVaultUSDr';
import USDrIcon from '../../assets/images/USDr.svg';
import infoIcon from '../../assets/images/risklevel.svg';

import ExperimentalModal from '../ExperimentalModal';

const TokenPairCard = (tokenPairCardProps: TokenPairCardProps) => {
  const { data, onCompareVault, isFirstLoading, liquidationStatus, cachedPoolInfo } = tokenPairCardProps;
  const history = useHistory();

  const compare_vaults_status = useSelector(selectors.getCompareVaultsStatus);
  const { connected } = useWallet();

  const [checked, setChecked] = React.useState(false);

  const hasUserReachedDebtLimit = false;
  const poolInfo = usePoolInfo(data.mint);
  const loadingState = useLoadingState();
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const renderModalButton = (status: boolean) => {
    if (isClicked) {
      return (
        <Button disabled className="py-2.5 mx-3 mt-2 button button--blue w-100 ">
          <LoadingSpinner className="spinner-border-sm text-dark dark:text-white-900" />
        </Button>
      );
    }
    return (
      <div className="mx-3 w-100">
        <div className="flex">
          {status ? (
            data?.experimentalStatus ? (
              <ExperimentalModal connected={connected} onClickEvent={showDashboard} btnText="Enter Vault" />
            ) : (
              <Button
                disabled={!connected}
                className="py-2.5 button button--blue w-100 font-poppins font-medium"
                onClick={showDashboard}
              >
                Enter Vault
              </Button>
            )
          ) : data?.experimentalStatus ? (
            <ExperimentalModal connected={connected} onClickEvent={onClickDeposit} btnText="Deposit" />
          ) : (
            <Button
              disabled={!connected}
              className="py-2.5 button button--blue w-100 font-poppins font-medium"
              onClick={onClickDeposit}
            >
              Deposit
            </Button>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (poolInfo && isClicked) {
      setIsClicked(false);
      history.push(`/app/vaultsetup/${data.mint}`);
    }
  }, [poolInfo]);

  const handleChangeComparison = (e: any) => {
    setChecked(e.target.checked);
    onCompareVault(data, e.target.checked);
  };

  const onClickDeposit = () => {
    if (!connected) {
      toast('Please connect your wallet!');
    } else {
      if (poolInfo) {
        history.push(`/app/vaultsetup/${data.mint}`);
      } else {
        setIsClicked(true);
      }
    }
  };

  const showDashboard = () => {
    if (!connected) {
      toast('Please connect your wallet!');
    } else {
      history.push(`/app/vaultdashboard/${data.mint}`);
    }
  };

  const printTvl = () => {
    if (isNaN(data.tvl) || data.tvl === 0) {
      return <LoadingSpinner className="spinner-border-sm text-info" />;
    }
    return formatUSD.format(data.tvl);
  };

  const printApy = () => {
    if (isNaN(data?.apr)) {
      return <LoadingSpinner className="spinner-border-sm text-info" />;
    }
    return Number(data?.apr).toFixed(2) + '%';
  };

  return (
    <>
      <div className="mt-3">
        <div
          className={classNames(
            'border-l border-t border-r border-b border-slate-300 dark:border-gray-600 rounded-[8px] relative',
            { 'border-violet-300 dark:border-violet-300': hasUserReachedDebtLimit || liquidationStatus === 'warning' },
            {
              'border-red-500 dark:border-red-500':
                liquidationStatus === 'danger' && !loadingState && data.activeStatus,
            }
          )}
        >
          <div className="flex items-center justify-between h-20 px-[20px] py-[20px] relative">
            <div className="flex items-start gap-3">
              <img src={data.icon} alt={'Token1'} className="w-16" />{' '}
              {data.platform.name === 'SWIM' && <SwimVaultInfoPopover tokenData={data} />}
              <div className="">
                <div className="flex items-start gap-2">
                  <p className="text-base font-semibold font-poppins dark:text-white-900">
                    {data.title === 'USDC-USDR' ? 'USDC-USDr' : data.title}
                  </p>
                  {data?.experimentalStatus && (
                    <Experimental className="w-5 h-5 mb-1 text-gray-100 dark:text-white-900" />
                  )}
                </div>
                <p className="text-xs font-normal text-gray-600 font-poppins dark:text-white-900">TVL {printTvl()}</p>
              </div>
            </div>

            {liquidationStatus !== 'warning' &&
              liquidationStatus !== 'danger' &&
              !loadingState &&
              data.activeStatus && (
                <div className="absolute top-0 right-0 px-1.5 py-1 text-xs font-normal bg-green-200 rounded-tr-[8px] text-emerald-600 font-poppins">
                  ● Active
                </div>
              )}
            {liquidationStatus === 'warning' && !loadingState && data.activeStatus && (
              <div className="absolute top-0 right-0 px-1.5 py-1 text-xs font-normal bg-violet-300/10 rounded-tr-[8px]  font-poppins flex items-center justify-center gap-2 w-32">
                <IoWarningOutline className="w-8 h-8 mb-2 text-violet-300 dark:text-violet-300" />
                <p className="text-[12px] font-medium text-violet-300 font-poppins">Liquidation Warning</p>
              </div>
            )}
            {liquidationStatus === 'danger' && !loadingState && data.activeStatus && (
              <div className="absolute top-0 right-0 px-1.5 py-1 text-xs font-normal bg-violet-300/10 rounded-tr-[8px]  font-poppins flex items-center justify-center gap-2 w-32">
                <MdOutlineErrorOutline className="w-8 h-8 mb-2 text-red-500 dark:text-red-500" />
                <p className="text-[12px] font-medium text-red-500 dark:text-red-500 font-poppins">Vault Liquidated</p>
              </div>
            )}
          </div>
          <div className="h-56 p-[22px] border-t border-slate-300 border-b bg-slate-100 dark:bg-slate-800  dark:border-gray-600">
            <div className="flex items-center justify-between">
              <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">Platform:</p>
              {hasInstaLp(data.title) ? (
                <a href={data.item.page_url} target="_blank" rel="noreferrer">
                  <div className="relative flex items-center gap-1 mt-1">
                    <img src={data.platform.icon} className="w-5" />
                    <p className="text-base font-semibold text-slate-700 font-poppins dark:text-white-900">
                      {data.platform.name}
                    </p>
                    <img src={linkIcon} alt="linkIcon" className="absolute top-0 -right-2" />
                  </div>
                </a>
              ) : (
                <Link to={{ pathname: '/app/instaswap', state: { platform: data.platform.name, token: data.title } }}>
                  <div className="relative flex items-center gap-1 mt-1">
                    <img src={data.platform.icon} className="w-5" />
                    <p className="text-base font-semibold text-slate-700 font-poppins dark:text-white-900">
                      {data.platform.name}
                    </p>
                    <img src={linkIcon} alt="linkIcon" className="absolute top-0 -right-2" />
                  </div>
                </Link>
              )}
            </div>

            <div className="flex items-center justify-between mt-2">
              <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">APR:</p>
              <div className="flex">
                <p className="font-semibold font-poppins text-slate-700 dark:text-white-900">
                  {Number(data?.apr + data?.ratioAPY).toFixed(2)}%
                </p>
                {data.ratioAPY !== 0 && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 100 }}
                    overlay={
                      <Tooltip id="tooltip">
                        <div className="tokenpaircard__overlaytooltip">
                          <p>
                            <strong>APR</strong> is made up of:
                          </p>
                          <div className="flex mb-2">
                            <img src={USDrIcon} alt="USDrIcon" className="w-5 h-5" /> Mint APR:{' '}
                            {Number(data?.ratioAPY).toFixed(2)}%
                          </div>
                          {!isSwimPool(data?.platform?.symbol) && (
                            <div className="flex">
                              <img src={data.platform.icon} alt="USDrIcon" className="w-5 h-5" /> Yield Farming:{' '}
                              {printApy()}
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    }
                  >
                    <div className="tokenpaircard__overlaytrigger">
                      <img src={infoIcon} alt="infoIcon" />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>

            <div className={classNames('flex justify-between mt-2')}>
              <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">
                Collateralization Ratio:
              </p>
              <p className="font-semibold font-poppins text-slate-600 dark:text-white-900">
                {cachedPoolInfo ? (100 / cachedPoolInfo.ratio).toFixed(2) : (100 / poolInfo?.ratio).toFixed(2)}%
              </p>
            </div>
            {!isSwimPool(data?.platform?.symbol) && (
              <div className="flex items-center justify-between mt-2 ">
                <div className="flex items-center gap-1">
                  <img src={smallRatioIcon} alt="smallRatio" className="w-4" />
                  <p className="text-base font-medium text-slate-600 font-poppins dark:text-white-900">Risk Rating:</p>
                  {/* <img src={liskLevelIcon} alt="lisklevel" /> */}
                </div>
                <h6 className={classNames(data.risk, 'ml-1 font-poppins font-semibold bg-transparent')}>
                  {data.risk}{' '}
                </h6>
              </div>
            )}
            <ProgressBarVaultUSDr mint={data.mint} className={classNames(' mt-2')} cachedPoolInfo={cachedPoolInfo} />
          </div>
          {compare_vaults_status ? (
            <div className={"classNames('', { 'tokenpaircard__btnBox--checked': checked })"}>
              <label>
                <input type="checkbox" className="filled-in" checked={checked} onChange={handleChangeComparison} />
                <span>Compare this vault</span>
              </label>
            </div>
          ) : (
            <div className="flex py-3">{renderModalButton(data.activeStatus)}</div>
          )}
          {
            /* TODO: fix this */ hasUserReachedDebtLimit && (
              <div className="tokenpaircard__warningBox">
                <div>
                  <IoAlertCircleOutline size={23} />
                </div>
                <p>
                  <strong>USDr Limit Reached:</strong> {hasUserReachedDebtLimit}
                </p>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default TokenPairCard;
