import { useEffect, useState } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { Button, Form, Row } from 'react-bootstrap';

import AdminFormLayout from '../AdminFormLayout';
import {
  apporveLastTx,
  createNewTx,
  createTransansferAllInstruction,
  executeeLastTx,
  getGokiSmartWallet,
  getLastTransctionInfo,
  setUpgradeAuthorityIx,
} from '../../utils/goki-smart';
import { PublicKey, Transaction } from '@solana/web3.js';
import { SmartWalletWrapper } from '@gokiprotocol/client';
import { sendAndConfirmTransaction, sendTransaction } from '../../utils/rf-web3';
import { getATAKey } from '../../utils/ratio-pda';
import { createAssociatedTokenAccountInstruction } from '@solana/spl-token-v2';
import AdminFormInput from '../../components/AdminFormInput';

export default function GokiTransition() {
  const { connection } = useConnection();
  const wallet = useWallet();
  const [updateFlag, setUpdateFlag] = useState(false);
  const [gokiSmart, setGokiSmart] = useState<SmartWalletWrapper>(null);
  const [lastTx, setLastTx] = useState(null);
  const [owners, setOwners] = useState([]);
  const [gokiWalletKey, setGokiWalletKey] = useState('7rVQDeYpMmKk7LugqZ76CDaFL6oPw6e2Pju9bJSe1GrX');
  const [tokenMint, setTokenMint] = useState('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v');
  const [programId, setProgramId] = useState('RFLeGTwFXiXXETdJkZuu9iKgXNkYbywLpTu1TioDsDQ');
  const [newAuthority, setNewAuthority] = useState('HXCRCJVSpoNPG53ZkCsp6XGBf1Qn3LaxzVqnDepAANDM');

  useEffect(() => {
    setInterval(() => {
      setUpdateFlag((prev) => !prev);
    }, 5000);
  }, []);

  useEffect(() => {
    if (gokiSmart) {
      gokiSmart.reloadData().then(() => {
        getLastTransctionInfo(gokiSmart).then((txInfo) => {
          setLastTx(txInfo);
        });
      });
    }
  }, [updateFlag]);

  useEffect(() => {
    if (connection && wallet) {
      getGokiSmartWallet(connection, wallet, gokiWalletKey).then((newGokiSmart) => {
        setOwners(newGokiSmart.data?.owners.map((item) => item.toString()));
        setGokiSmart(newGokiSmart);
        getLastTransctionInfo(newGokiSmart).then((txInfo) => {
          setLastTx(txInfo);
        });
      });
    }
  }, [connection, wallet, gokiWalletKey]);

  const setGokiOwners = async () => {
    const tx = new Transaction();
    const gokiTx = await gokiSmart.setOwners([wallet.publicKey]);

    tx.add(...gokiTx.instructions);

    await sendTransaction(connection, wallet, tx);
  };

  const setThreshold = async () => {
    const tx = new Transaction();
    const gokiTx = await gokiSmart.changeThreshold(1);

    tx.add(...gokiTx.instructions);

    await sendTransaction(connection, wallet, tx);
  };

  const createTransfer = async () => {
    const targetWallet = newAuthority;
    const targetAccount = getATAKey(targetWallet, tokenMint);
    console.log(`Transfer ${tokenMint} to ${targetWallet}`);
    const tx = new Transaction();
    if (!(await connection.getAccountInfo(targetAccount))) {
      tx.add(
        createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          targetAccount,
          new PublicKey(targetWallet),
          new PublicKey(tokenMint)
        )
      );
    }

    const gokiTx = (
      await gokiSmart.newTransaction({
        instructions: [await createTransansferAllInstruction(gokiSmart, tokenMint, targetWallet)],
      })
    ).tx;

    tx.add(...gokiTx.instructions);

    await sendTransaction(connection, wallet, tx);
  };
  const createChangeAuthority = async () => {
    const tx = new Transaction();

    const gokiTx = (
      await gokiSmart.newTransaction({
        instructions: [setUpgradeAuthorityIx(new PublicKey(programId), gokiSmart.key, new PublicKey(newAuthority))],
      })
    ).tx;

    tx.add(...gokiTx.instructions);

    await sendTransaction(connection, wallet, tx);
  };
  const approve = async () => {
    apporveLastTx(gokiSmart);
  };
  const execute = async () => {
    executeeLastTx(gokiSmart);
  };

  const handleGokiWalletKeyChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: any = event.target.value ?? '';
    setGokiWalletKey(value);
  };

  const handleTokenMintChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: any = event.target.value ?? '';
    setTokenMint(value);
  };
  const handleProgramIdChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: any = event.target.value ?? '';
    setProgramId(value);
  };
  const handleNewAuthorityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: any = event.target.value ?? '';
    setNewAuthority(value);
  };
  return (
    <AdminFormLayout>
      <AdminFormInput
        md="6"
        handleChange={handleGokiWalletKeyChange}
        label="Goki Wallet Key"
        name="gokiWalletKey"
        value={gokiWalletKey}
      />
      <h5 className="mt-3">Signers:</h5>
      {owners.map((owner: string, index: number) => {
        return <p key={owner}>{owner}</p>;
      })}
      <h5 className="mt-3">Threshold: {gokiSmart?.data?.threshold.toNumber()}</h5>
      {owners.includes(wallet.publicKey.toString()) ? (
        <>
          <AdminFormInput
            md="6"
            handleChange={handleNewAuthorityChange}
            label="New Authority"
            name="newAuthority"
            value={newAuthority}
          />
          <AdminFormInput
            md="6"
            handleChange={handleTokenMintChange}
            label="Token Mint"
            name="tokenMint"
            value={tokenMint}
          />

          <div style={{ lineHeight: 5 }}>
            <Button
              size="sm"
              onClick={createTransfer}
              disabled={wallet?.publicKey.toString() !== '5Tpi3fWL6XwKqNAV8Th3HK5g6bW3ceKaJ5pqz8GtyL85'}
            >
              Create Transfer Transaction
            </Button>
          </div>
          <AdminFormInput
            md="6"
            handleChange={handleProgramIdChange}
            label="Program ID"
            name="programId"
            value={programId}
          />
          <Button
            variant="primary"
            onClick={createChangeAuthority}
            disabled={wallet?.publicKey.toString() !== '5Tpi3fWL6XwKqNAV8Th3HK5g6bW3ceKaJ5pqz8GtyL85'}
          >
            Create ChangeAuthority Transaction
          </Button>
          <br />
          <h5>Last Transaction Info:</h5>
          <h5 className="mt-3">Tx Index: {gokiSmart?.data?.numTransactions.toNumber() - 1}</h5>
          <p>Proposer: {lastTx?.proposer.toString()}</p>
          <p>
            Executed At:{' '}
            {lastTx?.executedAt.toNumber() > 0
              ? new Date(lastTx?.executedAt.toNumber() * 1000).toString()
              : 'Not Executed'}
          </p>
          <h5 className="mt-3">Signed by:</h5>
          {owners.map((owner: string, index: number) => {
            if (lastTx?.signers[index]) return <p key={owner}>{owner}</p>;
            else <></>;
          })}

          <Button
            variant="primary"
            onClick={approve}
            disabled={lastTx?.signers[owners.indexOf(wallet?.publicKey.toString())]}
          >
            Approve Last Transaction
          </Button>
          <Button
            variant="primary"
            onClick={execute}
            disabled={
              lastTx?.signers.filter((item) => item).length < gokiSmart?.data?.threshold.toNumber() ||
              lastTx?.executedAt.toNumber() > 0
            }
          >
            Execute Last Transaction
          </Button>
        </>
      ) : (
        <></>
      )}
    </AdminFormLayout>
  );
}
