import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useVaultsContextProvider } from '../../contexts/vaults';
import { ThemeContext } from '../../contexts/ThemeContext';
import { RISK_RATING } from '../../types/VaultTypes';
import { CachedPoolInfo, LPair } from '../../types/VaultTypes';
import { API_ENDPOINT, USDR_MINT_DECIMALS } from '../../constants';
import { useWallet } from '@solana/wallet-adapter-react';

import { TokenAmount } from '../../utils/safe-math';
import { useLoadingState, useAllPoolInfo, useAllVaultInfo, useUserOverview } from '../../contexts/state';
import { getCoinPicSymbol } from '../../utils/helper';
import { getTokenIcon } from '../../utils/utils';
import { selectors, actionTypes } from '../../features/dashboard';
import { FetchingStatus } from '../../types/fetching-types';
import { fetchAxiosWithRetry } from '../../utils/ratio-lending';

import MyDashboard from '../MyDashboard';
import AvailableVaults from '../AvailableVaults';

type props = {
  page: string;
  showOnlyActive?: boolean;
  title?: string;
};

const BaseContainer = ({ page, showOnlyActive, title }: props) => {
  const theme = useContext(ThemeContext);
  const { darkMode } = theme.state;

  const { connected } = useWallet();
  const dispatch = useDispatch();
  const userOverview = useUserOverview();
  const userVaultInfos = useAllVaultInfo();
  const poolInfos = useAllPoolInfo();
  const [factorial, setFactorial] = useState<any>([]);
  const { status, error, vaults } = useVaultsContextProvider();

  const [cachedPoolInfos, setCachedPoolInfos] = useState<Map<string, CachedPoolInfo> | undefined>();

  const filter_data = useSelector(selectors.getFilterData);
  const sort_data = useSelector(selectors.getSortData);
  const view_data = useSelector(selectors.getViewData);
  const platform_data = useSelector(selectors.getPlatformData);
  const viewType = useSelector(selectors.getViewType);

  const loadingState = useLoadingState();

  useEffect(() => {
    (async () => {
      // To avoid multiple calls to Backend
      if (cachedPoolInfos!) return;
      const _cachedPoolInfos: Map<string, CachedPoolInfo> = new Map();
      const cachedApiResult = (await fetchAxiosWithRetry(`${API_ENDPOINT}/lpairs/cached`)).data;
      cachedApiResult.map((cachedPoolInfo: CachedPoolInfo) => {
        _cachedPoolInfos[cachedPoolInfo.mint] = cachedPoolInfo;
      });
      setCachedPoolInfos(_cachedPoolInfos);
    })();
  }),
    [];

  useEffect(() => {
    setFactorial(factorialOf());
  }, [
    connected,
    filter_data,
    sort_data,
    view_data,
    platform_data,
    vaults,
    userVaultInfos && Object.keys(userVaultInfos)?.length,
    userOverview,
    poolInfos,
    cachedPoolInfos,
    loadingState,
    page,
  ]);

  const filterData = (array1: any, array2: any, platform_data: any) => {
    if (array2.length === 0) {
      return array1;
    }
    return array1?.filter((item1: any) => {
      const item1Str = JSON.stringify(item1);
      return array2.find((item2: any) => {
        return item1Str.indexOf(item2.label) > -1;
      });
    });
  };

  function dynamicSort(sortProperty: string, viewProperty: string) {
    const sortOrder = viewProperty === 'ascending' ? 1 : -1;
    // sortOrder = sortProperty === 'risk' ? 1 : -1;

    return function (a: any, b: any) {
      const result = a[sortProperty] < b[sortProperty] ? -1 : a[sortProperty] > b[sortProperty] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function factorialOf() {
    if (vaults !== undefined) {
      const p = filterData(vaults, filter_data, platform_data)
        ?.map((item: LPair, index: any) => {
          const mint = item.address_id;
          const isVaultActive =
            userVaultInfos && userVaultInfos[mint] && userVaultInfos[mint].totalColl.toNumber() !== 0;
          const positionValue =
            userVaultInfos &&
            userVaultInfos[mint] &&
            +new TokenAmount((userVaultInfos[mint] as any)?.tvlUsd ?? 0, USDR_MINT_DECIMALS).fixed();
          const totalDebt =
            userVaultInfos &&
            userVaultInfos[mint] &&
            +new TokenAmount((userVaultInfos[mint] as any)?.debt ?? 0, USDR_MINT_DECIMALS).fixed();
          const poolData = {
            tvl: 0,
            apr: 0,
            ratioAPY: 0,
            realUserRewardMint: '',
            // ratioReward: userVaultInfos[mint] && userVaultInfos[mint].ratioReward,
          };
          if (cachedPoolInfos && cachedPoolInfos[mint]) {
            poolData.tvl = Number(cachedPoolInfos[mint].pool_tvl_usd);
            poolData.apr = Number(cachedPoolInfos[mint].farm_apr);
            poolData.ratioAPY = Number(cachedPoolInfos[mint].ratio_apr);
          } else if (poolInfos && poolInfos[mint]) {
            poolData.tvl = poolInfos[mint].tvlUsd;
            poolData.apr = poolInfos[mint].farmAPY;
            poolData.ratioAPY = poolInfos[mint].ratioAPY;
            poolData.realUserRewardMint = poolInfos[mint].realUserRewardMint;
          }
          if (showOnlyActive === false || isVaultActive) {
            return {
              id: index,
              mint,
              icons: item.lpasset?.map((item) =>
                item.token_icon?.trim() === '' || item.token_icon === undefined
                  ? getCoinPicSymbol(item.token_symbole)
                  : item.token_icon
              ),
              icon: getTokenIcon(item.symbol.toLowerCase()),
              title: item.symbol,
              platform: {
                link: item.platform_site,
                name: item.platform_name,
                icon: item.platform_icon,
                symbol: item.platform_symbol,
              },
              risk: item.risk_rating,
              riskLevel: RISK_RATING[item.risk_rating as unknown as keyof typeof RISK_RATING],
              item: item,
              activeStatus: isVaultActive,
              positionValue: positionValue,
              totalDebt: totalDebt,
              ...poolData,
            };
          }
        })
        .filter(Boolean);
      let x;
      if (platform_data.value !== 'ALL') {
        x = p.filter((item: any) => item.platform.name === platform_data.value);
      } else {
        x = p;
      }
      // const fd = x.filter((item) => item.apr !== undefined);
      x.sort(dynamicSort(sort_data.value, view_data.value));
      if (showOnlyActive) {
        dispatch({ type: actionTypes.SET_ACTIVE_VAULT, payload: x });
      } else {
        dispatch({ type: actionTypes.SET_ALL_VAULT, payload: x });
      }
      return x;
    }
    return [];
  }

  if (connected && (status === FetchingStatus.Loading || status === FetchingStatus.NotAsked || userOverview === null)) {
    return (
      <div className="flex items-center justify-center min-h-[50vh]">
        <div className="spinner-border text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const onViewType = (type: string) => {
    dispatch({ type: actionTypes.SET_VIEW_TYPE, payload: type });
  };

  return (
    <>
      {status === FetchingStatus.Error && toast.error(error)}
      {status === FetchingStatus.Finish && page === 'dashboard' && (
        <MyDashboard
          connected={connected}
          userOverview={userOverview}
          userVaultInfos={userVaultInfos}
          vaultsData={factorial}
        />
      )}
      {status === FetchingStatus.Finish && page === 'available_vaults' && (
        <AvailableVaults
          connected={connected}
          userOverview={userOverview}
          userVaultInfos={userVaultInfos}
          vaultsData={factorial}
          poolInfos={poolInfos}
          darkMode={darkMode}
          onViewType={onViewType}
          viewType={viewType}
          cachedPoolInfos={cachedPoolInfos}
        />
      )}
    </>
  );
};

export default BaseContainer;
