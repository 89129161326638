import React from 'react';
import classNames from 'classnames';
import riskLevelIcon from '../../assets/images/risklevelwhite.svg';
import warningIcon from '../../assets/images/highrisk-light-icon.svg';

enum BannerIcon {
  riskLevel = 'riskLevel',
  warningLevel = 'warningLevel',
}

type BannerProps = {
  title: string;
  message: string;
  className?: string;
  bannerIcon: BannerIcon;
  props?: any;
};

const iconMapped = {
  riskLevel: riskLevelIcon,
  warningLevel: warningIcon,
};

const Banner = ({ title, message, className, bannerIcon, props }: BannerProps) => {
  return (
    <div {...props} className={classNames('flex items-start gap-3 xl:px-11 px-5 py-5', className)}>
      <img src={iconMapped[bannerIcon]} className="w-6 h-6" />
      <p className="font-semibold font-poppins text-white-900">
        {title} <span className="font-medium font-poppins text-white-900">{message}</span>
      </p>
    </div>
  );
};

export { Banner, BannerIcon };
