/* eslint-disable no-case-declarations */
import {
  RECEIVE_ADD_LPSWPAIR,
  RECEIVE_ALL_LPSWPAIRS,
  RECEIVE_UPDATE_LPSWPAIR,
  RECEIVE_DELETE_LPSWPAIR,
} from './actionTypes';
import { adminpanelActionTypes } from './types';

const initialState = {
  lp_swap_pairs: [],
};

export default (state = initialState, action: adminpanelActionTypes) => {
  switch (action.type) {
    case RECEIVE_ALL_LPSWPAIRS:
      return { ...state, lp_swap_pairs: action.payload };
    case RECEIVE_ADD_LPSWPAIR:
      return {
        ...state,
        lp_swap_pairs: [...state.lp_swap_pairs, action.payload],
      };

    case RECEIVE_UPDATE_LPSWPAIR:
      return {
        ...state,
        lp_swap_pairs: state.lp_swap_pairs.map((item) => (item.id === action.payload.id ? action.payload : item)),
      };

    case RECEIVE_DELETE_LPSWPAIR:
      return {
        ...state,
        lp_swap_pairs: state.lp_swap_pairs.filter((item) => item.id !== action.payload.id),
      };

    default:
      return state;
  }
};
