import React, { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useWallet } from '@solana/wallet-adapter-react';
import { HiExternalLink } from 'react-icons/hi';
import NavbarItem from './../NavbarItem/NavbarItem';
// import Button from '../Button';
import logo from '../../assets/images/logo-side.svg';
import instaBuyIcon from '../../assets/images/insta-buy-icon.svg';
import darkLogo from '../../assets/images/dark-logoside.svg';
import collapseLogo from '../../assets/images/image-logo.svg';
import allVaultsIcon from '../../assets/images/all-vaults-icon.svg';
import homeIcon from '../../assets/images/home-icon.svg';
import activeVaultsIcon from '../../assets/images/active-vaults-icon.svg';
// import fairdropIcon from '../../assets/images/fairdrop.svg';
import { RiMenuFoldLine, RiMenuUnfoldLine } from 'react-icons/ri';
// import { IoWalletOutline } from 'react-icons/io5';
import { TokenAmount } from '../../utils/safe-math';
import { selectors } from '../../features/dashboard';
import { LPair } from '../../types/VaultTypes';
import { useVaultsContextProvider } from '../../contexts/vaults';
import { useAllVaultInfo, useUserOverview } from '../../contexts/state';
import atrix_logo_black from '../../assets/images/atrix_logo_black.svg';
import atrix_logo_white from '../../assets/images/atrix_logo_white.svg';
import linkIcon from '../../assets/images/link.svg';
import { USDR_MINT_DECIMALS } from '../../constants';
import { InProgressPage } from '../../utils/utils';
import orcaIcon from '../../assets/images/orca.svg';
import kaminoDark from '../../assets/images/kamino-dark.svg';
import kaminoLight from '../../assets/images/kamino.svg';

type NavbarProps = {
  clickMenuItem: () => void;
  setCollapseFlag: () => void;
  open: boolean;
  darkMode: boolean;
  collapseFlag: boolean;
};

const Navbar = ({ clickMenuItem, open, darkMode, collapseFlag, setCollapseFlag }: NavbarProps) => {
  const location = useLocation();
  const history = useHistory();
  const [navIndex, setNavIndex] = useState(location.pathname);
  const { connected } = useWallet();
  const userOverview = useUserOverview();

  const activeVaultCount = userOverview ? userOverview.activeVaults.toString() : '0';
  const totalMinted = Number(new TokenAmount(userOverview ? userOverview.totalDebt : 0, USDR_MINT_DECIMALS).fixed());

  // const totalLocked = Number(new TokenAmount(userOverview ? userOverview.tvlUsd : 0, USDR_MINT_DECIMALS).fixed());
  const [totalLocked, setTotalLocked] = useState(0);
  const [activeVaultsData, setActiveVaultsData] = useState([]);
  const userVaultInfos = useAllVaultInfo();

  const { vaults: all_vaults } = useVaultsContextProvider();
  const active_vaults = useSelector(selectors.getActiveVaults);

  React.useEffect(() => {
    setNavIndex(location.pathname);
  }, [location.pathname]);

  const getActiveVaultInfo = async function (activeVaults: any[]) {
    const vaults = Object.values(activeVaults);
    let tvlUSD = 0;
    const avdArr: any = [];
    for (const vault of vaults) {
      const { mint, debt }: any = vault;
      const pv = +new TokenAmount((vault as any)?.tvlUsd ?? 0, USDR_MINT_DECIMALS).fixed();
      tvlUSD += pv;
      // const pv = price * Number(new TokenAmount(lockedAmount as string, mintInfo.decimals).fixed());
      const title = all_vaults?.find((vault: LPair) => vault.address_id === mint)?.symbol;
      const vaultValue: any = {
        title,
        mint,
        pv,
        debt: new TokenAmount(debt, USDR_MINT_DECIMALS).fixed(),
      };
      avdArr.push(vaultValue);
    }
    return {
      tvlUSD,
      activeVaults: avdArr,
    };
  };

  React.useEffect(() => {
    if (userVaultInfos) {
      getActiveVaultInfo(userVaultInfos).then(({ activeVaults, tvlUSD }) => {
        setActiveVaultsData(activeVaults);
        setTotalLocked(tvlUSD);
      });
    }
  }, [userVaultInfos]);

  const onItemClick = (index: string) => {
    setNavIndex(index);
    history.push(`${index}`);
    clickMenuItem();
  };

  return (
    <div
      className={classNames(
        'md:w-72 w-full z-10 md:visible fixed top-0 left-0 bottom-0 shadow-2xl pt-7 md:pt-10 border-r-0 bg-white-900 dark:bg-gray-900 dark:shadow-2xl dark:border-r dark:border-gray-700',
        { 'md:w-32': collapseFlag },
        open ? 'visible' : 'invisible'
      )}
    >
      <div className="flex justify-center">
        <img src={collapseFlag ? collapseLogo : darkMode ? darkLogo : logo} alt="logo" className="mb-3" />
      </div>
      <div className="mt-4">
        {InProgressPage() ? (
          <NavbarItem
            icon={homeIcon}
            name="My Dashboard"
            active={navIndex === '/app/dashboard'}
            navIndex="/app/dashboard"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
        ) : (
          <></>
        )}
        <NavbarItem
          icon={allVaultsIcon}
          name="Available Vaults"
          active={
            navIndex.split('/')[2] === 'available-vaults' ||
            navIndex.split('/')[2] === 'vaultsetup' ||
            navIndex.split('/')[2] === 'vaultdashboard'
          }
          navIndex="/app/available-vaults"
          onItemClick={onItemClick}
          collapseFlag={collapseFlag}
        />
        <NavbarItem
          icon={activeVaultsIcon}
          name="My Active Vaults"
          active={navIndex === '/app/active-vaults'}
          navIndex="/app/active-vaults"
          onItemClick={onItemClick}
          collapseFlag={collapseFlag}
          expands={true}
          expandData={active_vaults}
          positionValues={activeVaultsData}
        />
        {/* <NavbarItem
          icon={fairdropIcon}
          name="RATIO Fairdrop"
          active={navIndex === '/dashboard/fairdrop'}
          navIndex="/dashboard/fairdrop"
          onItemClick={onItemClick}
          collapseFlag={collapseFlag}
        /> */}
        {/* <NavbarItem
          icon={archivedVaultsIcon}
          name="My Archived Vaults"
          active={navIndex === '/dashboard/my-archived-vaults'}
          navIndex="/dashboard/my-archived-vaults"
          onItemClick={onItemClick}
        /> */}
        <NavbarItem
          icon={instaBuyIcon}
          name="Insta-swap"
          active={navIndex === '/app/instaswap'}
          navIndex="/app/instaswap"
          onItemClick={onItemClick}
          collapseFlag={collapseFlag}
          className="border-b"
        />
        <div className="flex flex-col">
          <a
            target="_blank"
            href="https://app.kamino.finance/liquidity/CofEPsAoV6bdn7guCPHhmb5nJ1xmnhZ4Ha2zZVx14Ppb"
            rel="noreferrer"
            className={classNames(
              'flex gap-1 mt-6 text-sm text-gray-200 font-poppins dark:text-white-900',
              collapseFlag ? 'pl-3' : 'pl-7'
            )}
          >
            <img src={darkMode ? kaminoDark : kaminoLight} alt="orcaIcon" className="w-5 h-5 mr-1" />
            {collapseFlag ? (
              <span>Kamino</span>
            ) : (
              <p>
                <span className="font-medium ">Lend</span> on Kamino
              </p>
            )}
            <HiExternalLink className="w-3" />
          </a>
          <a
            target="_blank"
            href="https://www.orca.so/whirlpools/browse"
            rel="noreferrer"
            className={classNames(
              'flex gap-1 mt-6 text-sm text-gray-200 font-poppins dark:text-white-900',
              collapseFlag ? 'pl-3' : 'pl-7'
            )}
          >
            <img src={orcaIcon} alt="orcaIcon" className="w-5 mr-1" />
            {collapseFlag ? (
              <span>Orca</span>
            ) : (
              <p>
                <span className="font-medium ">Trade</span> on Orca
              </p>
            )}
            <HiExternalLink className="w-3" />
          </a>
        </div>

        {/* Atrix link <div
          className={classNames(
            'pt-4 text-left border-t border-slate-200 dark:border-gray-700',
            collapseFlag ? 'pl-2' : 'pl-8'
          )}
          style={{ paddingRight: collapseFlag ? `0.5rem` : '0rem' }}
        >
          {!collapseFlag && (
            <img src={darkMode ? atrix_logo_white : atrix_logo_black} alt="atrix_logo" className="w-32 pb-2" />
          )}
          <div className="!flex">
            <a
              target="_blank"
              href="https://app.atrix.finance/liquidity/7V7igBALu1xyu4ZkXfuS6LnfkZ1aA6DKBx55ouA6Jhbm/deposit"
              rel="noreferrer"
              className="text-sm font-normal font-poppins text-slate-500 dark:text-white-900"
            >
              Stake USDr-USDC on Atrix
            </a>
            <img src={linkIcon} alt="linkIcon" className="ml-1" />
          </div>
        </div> */}
      </div>
      <div>
        {connected ? (
          !collapseFlag ? (
            <div className="absolute left-0 right-0 ml-auto mr-auto bottom-28">
              <hr
                className="relative border-slate-200 dark:border-gray-700
                before:content-['My_Overview'] before:absolute before:left-8 before:-top-2 before:font-poppins before:bg-white-900 before:px-1 before:font-medium before:text-xs before:text-gray-700  before:dark:bg-gray-900 before:dark:text-white-900 "
              />
              <div className="flex items-center justify-between pt-[15px] pr-4 pl-7">
                <p className="text-xs font-medium text-gray-500 font-poppins dark:text-white-900">Active Vaults</p>
                <p className="px-2.5 py-px text-sm font-medium bg-orange-400 rounded-2xl font-poppins text-white-900">
                  {activeVaultCount && parseInt(activeVaultCount)}
                </p>
              </div>
              <div className="flex items-center justify-between pt-3 pr-4 pl-7">
                <p className="text-xs font-medium text-gray-500 font-poppins dark:text-white-900">Total Vault Value</p>
                <p className="px-2.5 py-px text-sm font-medium bg-orange-400 rounded-2xl font-poppins text-white-900">
                  $ {totalLocked.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </p>
              </div>
              <div className="flex items-center justify-between pt-3 pr-4 pl-7">
                <p className="text-xs font-medium text-gray-500 font-poppins dark:text-white-900">USDr Minted</p>
                <p className="px-2.5 py-px text-sm font-medium bg-emerald-200 rounded-2xl font-poppins text-green-900">
                  {(Math.ceil(totalMinted * 100) / 100).toFixed(2)}
                </p>
              </div>
            </div>
          ) : null
        ) : null}
      </div>
      <div
        className="absolute flex items-center gap-4 cursor-pointer bottom-5 left-10 hover:opacity-80"
        onClick={setCollapseFlag}
      >
        {!collapseFlag ? (
          <RiMenuFoldLine size={25} className="text-slate-600 dark:text-white-900" />
        ) : (
          <RiMenuUnfoldLine size={25} className="text-slate-600 dark:text-white-900" />
        )}
        {!collapseFlag && (
          <p className="text-sm font-medium font-poppins text-slate-600 dark:text-white-900">Collapse Menu</p>
        )}
      </div>
    </div>
  );
};

export default Navbar;
