import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

type props = {
  options: Array<any>;
  selected: any;
  iconClassName: string;
  onSelectToken: (token: any) => void;
};

const TokenSelection = ({ options, selected, iconClassName, onSelectToken }: props) => {
  if (options.length === 0) return <div> Connect your wallet </div>;
  return (
    <div className="">
      <Listbox value={selected} onChange={onSelectToken}>
        <div className="relative mt-1">
          {selected && (
            <Listbox.Button className="w-full py-2.5 pl-3 pr-10 text-left bg-white-900 dark:bg-gray-800 border-t border-r border-l border-b rounded-md cursor-default border-white-400 dark:border-gray-200 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="flex items-center justify-between text-base font-medium text-gray-200 truncate dark:text-white-900 font-poppins">
                <div className="flex items-center gap-2">
                  <img src={selected.logoURI} alt={selected.name} className={iconClassName} />
                  {selected.symbol}
                </div>
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="w-5 h-5 text-gray-400 dark:text-white-900" aria-hidden="true" />
              </span>
            </Listbox.Button>
          )}
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base border rounded-md shadow-lg bg-white-900 border-white-500 dark:border-gray-600 dark:bg-gray-800 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((token, tokenIdx) => (
                <Listbox.Option
                  key={tokenIdx}
                  className={({ active }) =>
                    `relative cursor-default  select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-white-600 dark:bg-slate-400' : 'text-gray-100'
                    }`
                  }
                  value={token}
                >
                  {({ selected }) => (
                    <>
                      <div className={`flex items-center gap-2 truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        <img src={token.logoURI} alt={token.name} className={iconClassName} />
                        <p className="text-base text-gray-200 dark:text-white-900 font-poppins font-regular">
                          {token.symbol}
                        </p>
                      </div>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-900">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default TokenSelection;
