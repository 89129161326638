import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useWallet } from '@solana/wallet-adapter-react';
import NavbarItem from '../NavbarItem';
import Button from '../Button';
import logo from '../../assets/images/logo-side.svg';
import darkLogo from '../../assets/images/dark-logoside.svg';
import collapseLogo from '../../assets/images/image-logo.svg';
import { RiMenuFoldLine, RiMenuUnfoldLine } from 'react-icons/ri';
import { IoWalletOutline } from 'react-icons/io5';
import { useAuthContextProvider } from '../../contexts/authAPI';
import { InProgressPage } from '../../utils/utils';

type NavbarProps = {
  clickMenuItem: () => void;
  setCollapseFlag: () => void;
  open: boolean;
  darkMode: boolean;
  collapseFlag: boolean;
};

const AdminNavbar = ({ clickMenuItem, open, darkMode, collapseFlag, setCollapseFlag }: NavbarProps) => {
  const location = useLocation();
  const history = useHistory();
  const [navIndex, setNavIndex] = useState(location.pathname);
  const { connected, connect } = useWallet();
  const { user } = useAuthContextProvider();

  useEffect(() => {
    setNavIndex(location.pathname);
  }, [location.pathname]);

  const onItemClick = (index: string) => {
    setNavIndex(index);
    history.push(`${index}`);
    clickMenuItem();
  };

  return (
    user && (
      <div
        className={classNames(
          'md:w-72 w-full z-50 md:visible fixed top-0 left-0 bottom-0 shadow-lg pt-5 md:pt-12 border-r-0 bg-white-900 dark:bg-gray-900 dark:shadow-2xl dark:border-r dark:border-gray-700',
          { 'navbar-vertical--collapse': collapseFlag },
          { closed: open }
        )}
      >
        <div className="flex items-center justify-center ">
          <img
            src={collapseFlag ? collapseLogo : darkMode ? darkLogo : logo}
            alt="logo"
            className="mt-4 ml-4 mt-md-0 ml-md-0"
          />
        </div>
        <div className="h-[80vh] mt-4 overflow-auto">
          <NavbarItem
            name="Whitelist"
            active={navIndex === '/adminpanel/whitelist'}
            navIndex="/adminpanel/whitelist"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          <NavbarItem
            name="Blacklist"
            active={navIndex === '/adminpanel/blacklist'}
            navIndex="/adminpanel/blacklist"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          <NavbarItem
            name="Global params"
            active={navIndex === '/adminpanel/globalparams'}
            navIndex="/adminpanel/globalparams"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          <NavbarItem
            name="Fees"
            active={navIndex === '/adminpanel/fees'}
            navIndex="/adminpanel/fees"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          <NavbarItem
            name="Collateralization ratios"
            active={navIndex === '/adminpanel/collateralizationratios'}
            navIndex="/adminpanel/collateralizationratios"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          <NavbarItem
            name="Vaults"
            active={navIndex === '/adminpanel/vaults'}
            navIndex="/adminpanel/vaults"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          <NavbarItem
            name="Admin tasks"
            active={navIndex === '/adminpanel/tasks'}
            navIndex="/adminpanel/tasks"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          <NavbarItem
            name="Tokens"
            active={navIndex === '/adminpanel/tokens'}
            navIndex="/adminpanel/tokens"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          <NavbarItem
            name="Swap LP Tokens"
            active={navIndex === '/adminpanel/swaplptokens'}
            navIndex="/adminpanel/swaplptokens"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          <NavbarItem
            name="Create Broadcast"
            active={navIndex === '/adminpanel/broadcast'}
            navIndex="/adminpanel/broadcast"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          <NavbarItem
            name="Overview"
            active={navIndex === '/adminpanel/overview'}
            navIndex="/adminpanel/overview"
            onItemClick={onItemClick}
            collapseFlag={collapseFlag}
          />
          {InProgressPage() ? (
            <NavbarItem
              name="Danger Zone"
              active={navIndex === '/adminpanel/dangerzone'}
              navIndex="/adminpanel/dangerzone"
              onItemClick={onItemClick}
              collapseFlag={collapseFlag}
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          {!connected && (
            <Button
              onClick={connect}
              className={classNames('button--fill walletBtn', { 'walletBtn--collapse': collapseFlag })}
            >
              {!collapseFlag ? <div>Connect Wallet</div> : <IoWalletOutline size={30} />}
            </Button>
          )}
        </div>
        <div className="flex items-center justify-center gap-2" onClick={setCollapseFlag}>
          {!collapseFlag ? (
            <RiMenuFoldLine size={25} color="#4c646f" />
          ) : (
            <RiMenuUnfoldLine size={25} color="#4c646f" />
          )}
          {!collapseFlag && <p className="text-sm font-medium text-gray-200 font-poppins">Collapse Menu</p>}
        </div>
      </div>
    )
  );
};

export default AdminNavbar;
