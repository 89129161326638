import { call, put, takeLatest } from 'redux-saga/effects';
import {
  REQUEST_ALL_LPSWPAIRS,
  RECEIVE_ALL_LPSWPAIRS,
  REQUEST_ADD_LPSWPAIR,
  RECEIVE_ADD_LPSWPAIR,
  REQUEST_UPDATE_LPSWPAIR,
  RECEIVE_UPDATE_LPSWPAIR,
  REQUEST_DELETE_LPSWPAIR,
  RECEIVE_DELETE_LPSWPAIR,
} from './actionTypes';
import { apiCall } from '../../utils/apiCall';

const getLpSwapPairsApi = (data: any, token: string) => apiCall('/lpswapairs', data, 'GET', token);
const addLpSwapPairsApi = (data: any, token: string) => apiCall('/lpswapairs', data, 'POST', token);
const updateLpSwapPairsApi = (data: any, token: string) => apiCall(`/lpswapairs/${data.id}`, data, 'PUT', token);
const deleteLpSwapPairsApi = (data: any, token: string) => apiCall(`/lpswapairs/${data.id}`, data, 'DELETE', token);

function* getAllLpSwapPairs() {
  try {
    const { data } = yield call(getLpSwapPairsApi, null, null);
    yield put({ type: RECEIVE_ALL_LPSWPAIRS, payload: data });
  } catch (e) {
    console.log(e.message);
  }
}

function* addNewLpSwapPairs(action: any) {
  try {
    const { data } = yield call(addLpSwapPairsApi, action.payload, null);
    yield put({ type: RECEIVE_ADD_LPSWPAIR, payload: data });
  } catch (e) {
    console.log(e.message);
  }
}

function* updateLpSwapPairs(action: any) {
  try {
    const { data } = yield call(updateLpSwapPairsApi, action.payload, null);
    yield put({ type: RECEIVE_UPDATE_LPSWPAIR, payload: data });
  } catch (e) {
    console.log(e.message);
  }
}

function* deleteLpSwapPairs(action: any) {
  try {
    const { data } = yield call(deleteLpSwapPairsApi, action.payload, null);
    yield put({ type: RECEIVE_DELETE_LPSWPAIR, payload: data });
  } catch (e) {
    console.log(e.message);
  }
}

export default function* adminpanelSaga() {
  yield takeLatest(REQUEST_ALL_LPSWPAIRS, getAllLpSwapPairs);
  yield takeLatest(REQUEST_ADD_LPSWPAIR, addNewLpSwapPairs);
  yield takeLatest(REQUEST_UPDATE_LPSWPAIR, updateLpSwapPairs);
  yield takeLatest(REQUEST_DELETE_LPSWPAIR, deleteLpSwapPairs);
}
